<template lang="">
  <app-section-loader :status="loader" />

  <q-card class="q-pa-md">
    <div class="row no-wrap justify-evenly">
      <div class="col" cols="12" sm="12" md="4" lg="4">
        <h6 class="text-primary">PagSeguro:</h6>
        <br />

        <q-input
          class="q-mb-md"
          ref="preNota"
          rounded
          outlined
          dense
          label="Pré-Nota"
          readonly
          v-model="idNotaFiscalCapa"
        />

        <q-input
          class="q-mb-md"
          ref="cliente"
          rounded
          outlined
          dense
          :label="$t('message.customer')"
          readonly
          v-model="nomeCliente"
        />

        <q-input
          class="q-mb-md"
          ref="status"
          rounded
          outlined
          dense
          :label="$t('message.status')"
          readonly
          v-model="situacao"
        />

        <div class="row q-mb-md">
          <q-input
            ref="email"
            rounded
            outlined
            dense
            :label="$t('message.email')"
            required
            class="col col-sm-10"
            :readonly="!editable"
            v-model="email"
          />

          <q-btn
            :disable="!podeTrocarEmail"
            flat
            rounded
            size="sm"
            :icon="iconEditCheck"
            color="blue"
            class="q-mb-xs"
            @click="editOrCheck()"
          >
            <tooltip-custom label="Modificar Email" />
          </q-btn>
        </div>

        <q-separator />

        <h6 class="text-primary">Histórico:</h6>

        <q-table
          :loading="loader"
          :rows="rows"
          :columns="columns"
          dense
          hide-pagination
          table-header-class="table-header"
          :rows-per-page-options="[0]"
          :noDataLabel="$t('message.noDataFound')"
          class="my-sticky-header-table"
          virtual-scroll
          :style="{ height: alturaTabela + 'px' }"
        >
        </q-table>

        <div class="text-center">
          <q-btn
            :disable="!podeReenviarEmail"
            color="info"
            class="q-mr-sm q-mt-md"
            type="button"
            size="md"
            rounded
            @click="reenviarEmail()"
          >
            Reenviar Email
          </q-btn>

          <q-btn
            :disable="!podeCancelarCheckout"
            color="danger"
            class="q-mt-sm q-mt-md"
            size="md"
            type="sair"
            @click="cancelarCheckout()"
            rounded
          >
            Cancelar Checkout
          </q-btn>
        </div>
      </div>
    </div>
  </q-card>
</template>
<script>
import { sizeTotal } from "Helpers";
import { showSuccess, showError } from "@/notifications/notify";
import api from "Api";

export default {
  name: "PagSeguroComponent",
  props: ["idnotafiscalcapa"],
  data() {
    return {
      loader: false,
      idNotaFiscalCapa: 0,
      nomeCliente: null,
      situacao: null,
      email: null,
      alturaTabela: 0,
      iconEditCheck: "edit",
      editable: false,
      podeTrocarEmail: true,
      podeReenviarEmail: true,
      podeCancelarCheckout: true,
      rows: [],
      columns: [
        {
          label: "Dt. Log",
          field: "dtLog",
          name: "dtLog",
          align: "center",
          sortable: true,
        },
        {
          label: "Descrição",
          field: "faseLog",
          name: "faseLog",
          align: "left",
          sortable: true,
        },
        {
          label: "Mensagem",
          field: "mensagem",
          name: "mensagem",
          align: "left",
          sortable: true,
        },
        {
          label: "Usuário",
          field: "usuario",
          name: "usuario",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    initialize() {
      this.alturaTabela = sizeTotal() - 420;
      this.listarDados();
    },
    editOrCheck() {
      this.editable = this.iconEditCheck == "edit";
      this.iconEditCheck = this.editable ? "check" : "edit";
      if (!this.editable) {
        this.alterarEmail();
      }
    },
    listarDados() {
      api.get(`/v1/pagseguro/${this.idnotafiscalcapa}`).then((res) => {
        if (res.data.length == 0) {
          showError("Pré Nota não encontrada!");
          return;
        }
        this.idNotaFiscalCapa = res.data[0].preNota;
        this.nomeCliente = res.data[0].nome;
        this.situacao = res.data[0].situacaoAtual;
        this.email = res.data[0].email;
        this.rows = res.data;
        this.validaStatus(res.data[0].idstatus);
      });
    },
    alterarEmail() {
      const data = { email: this.email };
      api
        .post(`v1/pagseguro/${this.idnotafiscalcapa}/alteraremail`, data)
        .then((_) => {
          this.listarDados();
          showSuccess("Email alterado");
        })
        .catch((err) => showError(err));
    },
    reenviarEmail() {
      api
        .post(`v1/pagseguro/${this.idnotafiscalcapa}/reenviaremail`)
        .then((_) => {
          this.listarDados();
          showSuccess("Solicitado Reenvio de Email");
        })
        .catch((err) => showError(err));
    },
    cancelarCheckout() {
      api
        .post(`v1/pagseguro/${this.idnotafiscalcapa}/cancelarcheckout`)
        .then((_) => {
          this.listarDados();
          showSuccess("Solicitado Checkout");
        })
        .catch((err) => showError(err));
    },
    validaStatus(idstatus) {
      this.podeTrocarEmail = true;
      this.podeReenviarEmail = true;
      this.podeCancelarCheckout = true;
      if (
        idstatus == 5 ||
        idstatus == 7 ||
        idstatus == 8 ||
        idstatus == 9 ||
        idstatus == 10
      ) {
        this.podeTrocarEmail = false;
        this.podeReenviarEmail = false;
      }
      if (idstatus == 1 || idstatus == 2) {
        this.podeReenviarEmail = false;
      }
      if (idstatus != 1 && idstatus != 2 && idstatus != 3) {
        this.podeCancelarCheckout = false;
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
