<template>
  <ModalForm
    ref="modalFormUploadMeta"
    :title="$t('message.uploadGoal')"
    :customClass="style"
  >
    <template v-slot:body>
      <div class="row q-mb-xl q-mt-md items-center">
        <div class="col-6 text-subtitle1 text-weight-medium">
          {{ this.$t("message.selectAction") }}:
        </div>
        <div class="col-6">
          <q-select
            filled
            required
            option-label="label"
            option-value="value"
            v-model="acao"
            :options="opcoesAcao"
            map-options
            emit-value
            :label="$t('message.action')"
            class="q-mr-lg"
            :rules="actionRule"
            style="width: 334px"
          />
        </div>
      </div>

      <div class="row q-mb-xl q-mt-md items-center">
        <div class="col-6 text-subtitle1 text-weight-medium">
          {{ this.$t("message.selectXlsxWithGoals") }}:
        </div>
        <div class="col-6">
          <q-input
            type="file"
            id="input"
            filled
            clearable
            @update:model-value="
              (val) => {
                file = val[0];
              }
            "
          />
        </div>
      </div>

      <q-separator />
      <q-btn
        color="success"
        @click.prevent="aoCarregar()"
        :label="$t('message.load')"
        class="bg-green text-white q-mt-lg"
      />
      <q-btn
        :label="$t('message.close')"
        @click.prevent="aoFechar()"
        class="bg-red text-white q-ml-md q-mt-lg"
      />
    </template>
  </ModalForm>
</template>

<script>
import { Loading, QSpinnerGears } from "quasar";
import ModalForm from "Components/Widgets/ModalForm";
import readXlsxFile from "read-excel-file";
import api from "Api";
import { showError } from "@/notifications/notify";
import { mapActions } from "vuex";
import { arrayMonths } from "Helpers";

export default {
  name: "SubirMetaExcel",
  components: { ModalForm },

  data() {
    return {
      loader: true,
      file: null,
      grupos: null,
      representantes: null,
      acao: null,
      opcoesAcao: [
        {
          label: this.$t("message.newGoal"),
          value: "novo",
        },
        {
          label: this.$t("message.reviseGoal"),
          value: "revisar",
        },
      ],
      actionRule: [(v) => !!v || this.$t("message.actionRequired")],
      style: {
        width: "700px",
        height: "420px",
      },
    };
  },
  methods: {
    ...mapActions("metas", [
      "setAcao",
      "setMeta",
      "setExibir",
      "setDesabilitarMenu",
      "setCabecalho",
      "setRevisaoXlsx",
    ]),

    aoAbrir() {
      this.$refs.modalFormUploadMeta.abrir();
    },

    aoFechar() {
      this.$refs.modalFormUploadMeta.fechar();
      this.acao = null;
      this.file = null;
    },

    async aoCarregar() {
      this.loader = true;
      Loading.show({
        message: this.$t("message.loadingData"),
        spinner: QSpinnerGears,
      });

      if (this.acao == "revisar") {
        this.setRevisaoXlsx(true);
      }

      await readXlsxFile(this.file)
        .then((sheet) => {
          for (const indexRow in sheet) {
            if (indexRow == 0) {
              continue;
            }

            const row = sheet[indexRow];

            for (let mes of arrayMonths.months) {
              const object = {
                registroInvalido: this.validarDados(row, mes.id),
                grupoId: row[0],
                grupoDescription: this.getGrupoDescricao(row[0]),
                representanteId: row[1],
                representanteDescription: this.getRepresentanteNome(row[1]),
                mesId: mes.id,
                mesDescription: mes.description,
                valor: this.$filters.formatDecimal(row[mes.id + 2]),
                quilos: this.$filters.formatDecimal(row[mes.id + 14]),
              };
              this.setMeta(object);
            }
          }

          const cabecalhoObj = {
            ano: new Date().getFullYear(),
          };
          this.setCabecalho(cabecalhoObj);

          this.setDesabilitarMenu(true);
          this.setAcao(this.acao);
          this.setExibir(true);
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          Loading.hide();
          this.aoFechar();
        });
    },

    validarDados(row, mes) {
      let registroInvalido = false;
      registroInvalido = typeof row[0] != "number";

      registroInvalido = registroInvalido || typeof row[1] != "number";
      // registroInvalido = registroInvalido || typeof row[mes + 2] != "number";
      // row[mes + 2] == 0 ||
      // row[mes + 2] == null;
      // registroInvalido = registroInvalido || typeof row[mes + 14] != "number";
      // row[mes + 14] == 0 ||
      // row[mes + 14] == null;

      registroInvalido =
        registroInvalido || !this.getGrupoDescricao(row[0]) ? true : false;
      registroInvalido =
        registroInvalido || !this.getRepresentanteNome(row[1]) ? true : false;

      return registroInvalido;
    },

    getGrupoDescricao(grupo) {
      return this.grupos.find((v) => v.cdGrupo == grupo)?.dsGrupo;
    },

    getRepresentanteNome(representante) {
      return this.representantes.find((v) => v.idrepresentante == representante)
        ?.nomeRepresentante;
    },

    obterGrupo() {
      api
        .get("/v1/comercial/metas/grupos")
        .then((res) => (this.grupos = res.data))
        .catch((err) => showError(err));
    },

    obterRepresentante() {
      api
        .get("/v1/representantes")
        .then((res) => (this.representantes = res.data))
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.obterGrupo();
    this.obterRepresentante();
  },
};
</script>
