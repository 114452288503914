<template>
  <div class="r7-user-block-container">
    <q-list v-if="user" clickable class="rounded-borders">
      <q-item clickable>
        <q-item-section topthumbnail>
          <img
            :src="previewImage"
            alt="avatar"
            height="40"
            width="40"
            class="img-responsive rounded"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-white fw-bold">{{
            user.nome
          }}</q-item-label>
        </q-item-section>

        <q-menu
          bottom
          offset-y
          left
          class="r7-userblock-dropdown"
          nudge-top="-10"
          nudge-right="0"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on }">
            <q-btn dark icon v-on="on" class="ma-0">
              <q-icon>more_vert</q-icon>
            </q-btn>
          </template>
          <div class="dropdown-content">
            <div class="dropdown-top primary">
              <span class="q-ml-sm fs-12">{{ user.email }}</span>
            </div>

            <q-list class="dropdown-list" clickable>
              <q-item auto-close clickable @click="detalheUsuario">
                <q-icon class="material-icons text-blue" style="font-size: 23px"
                  >person</q-icon
                >
                <span>{{ $t("message.userProfile") }}</span>
              </q-item>

              <q-item clickable>
                <language-provider />
                <q-icon
                  class="material-icons text-grey-7"
                  style="font-size: 23px"
                  >language</q-icon
                >
                <span class="q-ml-xs">
                  {{ $t("message.language") }}
                </span>
              </q-item>

              <q-item clickable @click="openEmail()">
                <q-icon
                  class="material-icons text-orange-7"
                  style="font-size: 23px"
                  >mail</q-icon
                >
                <span>E-mail</span>
              </q-item>

              <q-item auto-close clickable @click="setAtualizar()">
                <q-icon
                  class="material-icons text-green"
                  style="font-size: 23px"
                  >refresh</q-icon
                >
                <span>{{ $t("message.reload") }}</span>
              </q-item>

              <q-item auto-close clickable @click="logoutUser">
                <q-icon
                  class="material-icons text-red q-ml-xs"
                  style="font-size: 22px"
                  >logout</q-icon
                >
                <span>{{ $t("message.logOut") }}</span>
              </q-item>
            </q-list>
          </div>
        </q-menu>
      </q-item>
    </q-list>
  </div>
</template>

<script>
import { Buffer } from "buffer";
import LanguageProvider from "./LanguageProvider.vue";
import { mapActions } from "vuex";
import { auth } from "Helpers/authentication";

export default {
  name: "UserBlock",
  components: { LanguageProvider },

  data() {
    return {
      previewImage: undefined,
      user: this.$store.getters["auth/getUser"],
    };
  },
  methods: {
    ...mapActions("common", ["setAtualizar"]),

    logoutUser() {
      auth.logout();
    },
    openEmail() {
      window.open("http://webmail.rosset.com.br", "_blank");
    },
    detalheUsuario() {
      this.$router.push({
        name: "usuariodetalhe",
        params: { id: this.user.id },
      });
    },
  },
  created() {
    if (this.user?.thumbnailPhoto) {
      this.previewImage = `data:image/png;base64,${Buffer.from(
        this.user.thumbnailPhoto.data
      ).toString("base64")}`;
    } else {
      this.previewImage = process.env.BASE_URL + "/static/avatars/user-0.jpg";
    }
  },
};
</script>
