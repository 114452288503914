<template>
  <div>
    <q-card>
      <q-card-section>
        <div class="text-h6 subtitulo">{{ subtitulo }}</div>
        <div class="text-subtitle2 text-red-10 text-bold">
          Valor Total: {{ valorTotal }}
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="row q-col-gutter-md">
          <div
            class="col"
            v-for="(coluna, ind1) in dados"
            :key="'coluna' + ind1"
          >
            <q-list
              bordered
              v-for="(celula, ind2) in dados[ind1]"
              :key="'celula' + ind1 + ind2"
            >
              <q-item v-ripple dense>
                <q-item-section>
                  <q-item-label class="text-bold text-center mes"
                    >{{ celula.label }}:</q-item-label
                  >
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-red-10">{{
                    celula.valor
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
export default {
  name: "ListarDuplicatasDetalhesComponent",
  props: {
    empresa: {
      type: String,
    },
    marca: {
      type: String,
    },
    cliente: {
      type: String,
    },
    valorTotal: {
      type: String,
      default: "0",
    },
    dias: {
      type: String,
    },
    subtitulo: {
      type: String,
    },
  },
  data() {
    return {
      loader: true,
      max: 11,
      dados: [],
    };
  },
  methods: {
    initialize() {
      this.listarVencidos();
    },
    listarVencidos() {
      this.loader = true;
      const empresa = this.empresa;
      const marca = this.marca;
      const cliente = this.cliente;
      api
        .get(
          `/v1/financeiro/duplicatas/valoresavencervencidos?empresa=${empresa}&marca=${marca}&cliente=${cliente}&dias=${this.dias}`
        )
        .then((res) => {
          if (!res.data || res.data.length == 0) {
            return;
          }
          const registros = res.data.length;

          let coluna = 0;
          let linha = 0;
          this.dados.push([]);
          for (let registro = 0; registro < registros; registro++) {
            if (linha > this.max) {
              coluna++;
              linha = 0;
              this.dados.push([]);
            }
            const data = {
              label: res.data[registro].dtVencimento,
              valor: this.$filters.formatWithoutDecimal(
                res.data[registro].valor
              ),
            };

            this.dados[coluna].push(data);
            linha++;
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style scoped>
@media print {
  .text-h4 {
    font-size: 1.4rem !important;
  }
  .mes {
    font-size: 12px !important;
  }
  .subtitulo {
    font-size: 1.1rem !important;
  }
}
</style>
