<template>
  <!-- Breakpoints acima de "small" -->
  <div class="row gt-sm" no-gutters>
    <div class="col" id="imagemBanner" style="height: 100vh">
      <div class="containerImagem-r7">
        <q-img :src="imagem" class="imagem-r7" />
      </div>
    </div>

    <div class="col" items-center id="formularioLogin">
      <div class="row flex-center q-my-xl q-pt-xl" style="min-width: 384px">
        <q-img :src="appLogo" fit width="384px" style="min-width: 384px" />
      </div>
      <br />
      <div class="row flex-center" no-gutters>
        <span style="max-width: 80%; font-size: 20px">
          {{ $t("message.enterUsernameAndPasswordToAccessControlPanelOf") }}
        </span>
      </div>

      <div class="row flex-center q-mt-lg" no-gutters>
        <div clas="col ">
          <q-form autocomplete="on">
            <q-input
              filled
              :label="$t('message.user')"
              :rules="userRules"
              v-model="username"
              required
              style="min-width: 384px"
              autocomplete="on"
              @update:model-value="handleInput()"
            ></q-input>
            <q-input
              :label="$t('message.password')"
              v-model="password"
              type="password"
              :rules="passwordRules"
              required
              filled
              @keyup.enter="submit"
              style="min-width: 384px"
              autocomplete="on"
              @update:model-value="handleInput()"
            ></q-input>
            <div class="row q-mt-md">
              <slot name="botao" />
            </div>
          </q-form>
        </div>
      </div>
      <div class="row flex-center q-mt-sm" no-gutters>
        <slot name="languages" />
      </div>
    </div>
  </div>

  <!-- Breakpoints abaixo de "medium" -->
  <div class="row lt-md" no-gutters>
    <div class="col" items-center id="formularioLogin">
      <div class="row flex-center q-my-xl q-pt-xl" style="min-width: 90vw">
        <q-img :src="appLogo" fit width="90vw" style="min-width: 90vw" />
      </div>
      <br />
      <div class="row flex-center" no-gutters>
        <span style="max-width: 100%; font-size: 20px">
          {{ $t("message.enterUsernameAndPasswordToAccessControlPanelOf") }}
        </span>
      </div>

      <q-form autocomplete="on">
        <div class="row flex-center q-mt-lg">
          <div clas="col-12">
            <q-input
              filled
              :label="$t('message.user')"
              :rules="userRules"
              v-model="username"
              required
              style="min-width: 100vw"
              autocomplete="on"
              @update:model-value="handleInput()"
            ></q-input>
          </div>
        </div>
        <div class="row flex-center q-mt-sm">
          <div clas="col-12">
            <q-input
              :label="$t('message.password')"
              v-model="password"
              type="password"
              :rules="passwordRules"
              required
              filled
              @keyup.enter="submit"
              style="min-width: 100vw"
              autocomplete="on"
              @update:model-value="handleInput()"
            ></q-input>
          </div>
        </div>
        <div class="row flex-center q-mt-lg">
          <div clas="col-12">
            <slot name="botao" />
          </div>
        </div>
      </q-form>
      <div class="row flex-center q-mt-sm">
        <slot name="languages" />
      </div>
    </div>
  </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";

export default {
  name: "Login",
  emits: ["inputUsername", "inputPassword", "submit"],
  data() {
    return {
      checkbox: false,
      username: null,
      password: null,
      userRules: [(v) => !!v || this.$t("message.userRequired")],
      passwordRules: [(v) => !!v || this.$t("message.passwordRequired")],
      appLogo: AppConfig.appLogo2,
      imagem: AppConfig.login,
    };
  },

  methods: {
    handleInput() {
      this.$emit("inputUsername", this.username);
      this.$emit("inputPassword", this.password);
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
