import SetoresQAListDetailView from "Views/RH/QA/SetoresQAListDetailView";
import TemasQAListView from "Views/RH/QA/TemasQAListView";
import TemasQADetailView from "Views/RH/QA/TemasQADetailView";
import GabaritoQAListView from "Views/RH/QA/GabaritoQAListView";
import PerguntasQADetailView from "Views/RH/QA/PerguntasQADetailView";
import RespostasQADetailView from "Views/RH/QA/RespostasQADetailView";
import CandidatosQAListView from "Views/RH/QA/CandidatosQAListView";
import CandidatosQADetailView from "Views/RH/QA/CandidatosQADetailView";
import ResultadoCandidatoView from "Views/RH/QA/ResultadoCandidatoView";

const routes = [
  {
    path: "/qasetores",
    name: "qasetores",
    component: SetoresQAListDetailView,
    meta: {
      requiresAuth: true,
      title: "message.workplace",
      breadcrumb: ["message.hr", "message.qa", "message.workplace"],
    },
  },
  {
    path: "/qatemas",
    name: "qatemas",
    component: TemasQAListView,
    meta: {
      requiresAuth: true,
      title: "message.testTheme",
      breadcrumb: ["message.hr", "message.qa", "message.testTheme"],
    },
  },
  {
    path: "/qatemasdetalhe/:id",
    name: "temasqadetailview",
    component: TemasQADetailView,
    meta: {
      requiresAuth: true,
      title: "message.testTheme",
      breadcrumb: ["message.hr", "message.qa", "message.testTheme"],
    },
  },
  {
    path: "/qagabarito",
    name: "qagabarito",
    component: GabaritoQAListView,
    meta: {
      requiresAuth: true,
      title: "message.quiz",
      breadcrumb: ["message.hr", "message.qa", "message.quiz"],
    },
  },
  {
    path: "/qaperguntasdetalhe/:id",
    name: "perguntasqadetailview",
    component: PerguntasQADetailView,
    meta: {
      requiresAuth: true,
      title: "message.questions",
      breadcrumb: ["message.hr", "message.qa", "message.questions"],
    },
  },
  {
    path: "/qarespostasdetalhe/:id",
    name: "respostasqadetailview",
    component: RespostasQADetailView,
    meta: {
      requiresAuth: true,
      title: "message.answers",
      breadcrumb: ["message.hr", "message.qa", "message.answers"],
    },
  },
  {
    path: "/qacandidatos",
    name: "qacandidatos",
    component: CandidatosQAListView,
    meta: {
      requiresAuth: true,
      title: "message.candidates",
      breadcrumb: ["message.hr", "message.qa", "message.candidates"],
    },
  },
  {
    path: "/qacandidatosdetalhe/:id",
    name: "candidatosqadetailview",
    component: CandidatosQADetailView,
    meta: {
      requiresAuth: true,
      title: "message.candidates",
      breadcrumb: ["message.hr", "message.qa", "message.candidates"],
    },
  },
  {
    path: "/qaresultadocandidato/:id",
    name: "resultadocandidatoview",
    component: ResultadoCandidatoView,
    meta: {
      requiresAuth: true,
      title: "message.candidateResult",
      breadcrumb: ["message.hr", "message.qa", "message.candidateResult"],
    },
  },
];

export default routes;
