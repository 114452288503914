<template>
  <q-card class="q-pa-md">
    <div class="row no-wrap justify-evenly">
      <div class="col-6" cols="12" sm="12" md="6" lg="6">
        <span class="text-primary text-subtitle1 text-weight-bolder"
          >Selecione o usuário que deseja simular</span
        >
        <br />
        <UsuariosTecusuaSelectComponent
          :usuarioId="tecusua.id"
          @selected="selectedUser($event)"
          :required="true"
          :rounded="true"
          :filled="false"
        />
        <div class="text-center">
          <q-btn
            color="success"
            class="q-mr-sm q-mt-md"
            type="button"
            @click="simular()"
            size="md"
            rounded
            :disable="tecusua.id == null"
          >
            {{ $t("message.userSimulate") }}
          </q-btn>

          <q-btn
            color="danger"
            class="q-mt-sm q-mt-md"
            size="md"
            type="button"
            @click.prevent="$router.go(-1)"
            rounded
          >
            {{ $t("message.close") }}
          </q-btn>
        </div>
      </div>
    </div>
  </q-card>
</template>
<script>
import UsuariosTecusuaSelectComponent from "./UsuariosTecusuaSelectComponent.vue";
import apic from "Api/cobol";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "SimularUsuarioComponent",
  components: { UsuariosTecusuaSelectComponent },
  data() {
    return {
      tecusua: {
        id: null,
        description: null,
        setor: null,
      },
    };
  },
  methods: {
    selectedUser(item) {
      this.tecusua = item;
    },
    async simular() {
      if (this.tecusua.id == null) {
        showError("Selecione um usuário para simular");
      }
      const params = new URLSearchParams();
      params.append("acao", "simular");
      params.append("simular", this.tecusua.id);
      const result = await apic.post("/cgi-bin/intra4630.exe", params);
      const success = result.data.success;

      if (success) {
        showSuccess(
          `Simulando o usuário: ${this.tecusua.id} do setor: ${this.tecusua.setor}`
        );
      } else {
        showError(result.data.message);
      }
    },
  },
};
</script>
