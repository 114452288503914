<template>
  <div class="boxNoResult" v-if="!isQuestionarioPreenchido">
    <span>{{ $t("message.noDataFound") }}</span>
  </div>
  <q-card class="my-card" flat bordered v-else>
    <q-card-section horizontal>
      <q-card-section class="q-pt-xs">
        <div class="text-h4 q-mt-sm">{{ resultadoData.candidato.nome }}</div>
        <div class="text-subtitle2 q-mt-sm">
          {{ resultadoData.candidato.setor }}
        </div>
        <div class="text-subtitle2 q-mt-sm">
          {{ resultadoData.candidato.vaga }}
        </div>
      </q-card-section>
      <q-space />

      <q-card-section class="col-5 flex flex-end">
        <div class="boxResult">
          <span class="result">{{ score }}%</span>
          <span class="text-subtitle1 flex flex-center">acertos</span>
        </div>
      </q-card-section>
    </q-card-section>

    <q-card-section>
      <template v-for="tema in resultadoData.temas" :key="tema.idTema">
        <q-separator />
        <div class="row q-mt-sm">
          <div class="col">
            <span class="text-h6 text-weight-bold"
              >Tema: {{ tema.descricaoTema }}</span
            >
          </div>
        </div>

        <template v-for="(resposta, idx) in tema.respostas" :key="idx">
          <div class="row q-mt-sm">
            <div class="col">
              <span class="pergunta"
                >{{ idx + 1 }}-){{ resposta.pergunta }}
              </span>
            </div>
          </div>

          <QuestionarioComplementoComponent
            :complemento="resposta.complemento"
          />

          <div class="row q-mt-sm">
            <q-icon
              size="xs"
              :color="resposta.iconColorResult"
              class="material-icons"
            >
              {{ resposta.iconResult }}
            </q-icon>

            <div class="col">
              <span class="resposta" :class="resposta.textColorResult"
                >Resposta Candidato:{{ resposta.respostaCandidato }}</span
              >
            </div>
          </div>
          <div class="row q-mt-sm">
            <div class="col">
              <q-icon size="xs" color="positive" class="material-icons">
                check
              </q-icon>
              <span class="text-positive"
                >Resposta Correta: {{ resposta.respostaCorreta }}</span
              >
            </div>
          </div>
        </template>
      </template>
    </q-card-section>
  </q-card>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import QuestionarioComplementoComponent from "./QuestionarioComplementoComponent.vue";

export default {
  name: "ResultadoCandidatoComponent",
  components: { QuestionarioComplementoComponent },
  data() {
    return {
      loader: true,
      idCandidato: this.$route.params.id,
      isQuestionarioPreenchido: false,

      resultadoData: {
        candidato: {
          nome: null,
          setor: null,
          vaga: null,
        },
        qtAcertos: 0,
        qtPerguntas: 0,
        temas: [],
      },
    };
  },
  computed: {
    score() {
      return Math.round(
        (this.resultadoData.qtAcertos / this.resultadoData.qtPerguntas) * 100
      );
    },
  },
  methods: {
    obterResultadoDoCandidato() {
      if (!this.idCandidato || this.idCandidato == 0) {
        showError("Candidato deve ser informado!");
        return;
      }
      this.loader = true;
      api
        .get(`/v1/rh/qa/resultado/candidato/${this.idCandidato}`)
        .then((res) => {
          if (res.data) {
            this.montaResultadoCandidato(res.data);
            this.isQuestionarioPreenchido = true;
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    montaResultadoCandidato(resultado) {
      this.resultadoData.candidato.nome = resultado[0].nome;
      this.resultadoData.candidato.setor = resultado[0].descricaoSetor;
      this.resultadoData.candidato.vaga = resultado[0].vaga;

      resultado.forEach((res) => {
        const found = this.resultadoData.temas.findIndex(
          (f) => f.idTema == res.idTema
        );

        const objResposta = {
          idResultado: res.idResultado,
          pergunta: res.pergunta,
          complemento: res.complemento,
          respostaCandidato: res.respostaCandidato,
          respostaCorreta: res.respostaCorreta,
          textColorResult: res.acerto == 1 ? "text-positive" : "text-negative",
          iconResult: res.acerto == 1 ? "check" : "close",
          iconColorResult: res.acerto == 1 ? "positive" : "negative",
        };

        if (found < 0) {
          const objTema = {
            idTema: res.idTema,
            descricaoTema: res.descricaoTema,
            respostas: [],
          };
          objTema.respostas.push(objResposta);
          this.resultadoData.temas.push(objTema);
        } else {
          this.resultadoData.temas[found].respostas.push(objResposta);
        }

        this.resultadoData.qtPerguntas += 1;
        this.resultadoData.qtAcertos += res.acerto;
      });
    },
  },
  mounted() {
    this.obterResultadoDoCandidato();
  },
};
</script>

<style scoped>
.boxResult {
  box-sizing: border-box;

  position: relative;

  width: 269px;
  height: 168px;

  background: #ffffff;
  border: 1px solid #000000;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.result {
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
}
.flex-end {
  justify-items: flex-end;
}
</style>
