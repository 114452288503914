import PedidoUnico from "Views/Comercial/PedidoUnico";
import RegraNegocio from "Views/Comercial/RegraNegocio";
import CadastroDeMetas from "Views/Comercial/Metas/CadastroDeMetas";
import CalculadoraCustos from "Views/Comercial/CalculadoraCustos";
import AtualizacaoTabelaPreco from "Views/Comercial/AtualizacaoTabelaPreco";
import ListarGruposReferenciasView from "Views/Comercial/ListarGruposReferenciasView";
import PagSeguroView from "Views/Comercial/PagSeguroView";

const routes = [
  {
    path: "/pedidounico/:idpedido?",
    name: "pedidounico",
    component: PedidoUnico,
    meta: {
      requiresAuth: true,
      title: "message.orderInquiry",
      breadcrumb: [
        "message.comercial",
        "message.order",
        "message.orderInquiry",
      ],
    },
  },
  {
    path: "/regrasdenegocio",
    name: "regrasdenegocio",
    component: RegraNegocio,
    meta: {
      requiresAuth: true,
      title: "message.tradingRules",
      breadcrumb: ["message.comercial", "message.tradingRules"],
    },
  },
  {
    path: "/cadastrodemetas",
    name: "cadastrodemetas",
    component: CadastroDeMetas,
    meta: {
      requiresAuth: true,
      title: "message.goalsRegistration",
      breadcrumb: ["message.comercial", "message.goalsRegistration"],
    },
  },
  {
    path: "/calculadoracustos",
    name: "calculadoracustos",
    component: CalculadoraCustos,
    meta: {
      requiresAuth: true,
      title: "message.costCalculator",
      breadcrumb: ["message.comercial", "message.costCalculator"],
    },
  },
  {
    path: "/atualizacaotabelapreco",
    name: "atualizacaotabelapreco",
    component: AtualizacaoTabelaPreco,
    meta: {
      requiresAuth: true,
      title: "message.priceListUpdate",
      breadcrumb: ["message.comercial", "message.priceListUpdate"],
    },
  },
  {
    path: "/gruposreferencias",
    name: "gruposreferencias",
    component: ListarGruposReferenciasView,
    meta: {
      requiresAuth: true,
      title: "message.groupsReferences",
      breadcrumb: ["message.comercial", "message.groupsReferences"],
    },
  },
  {
    path: "/pagsegurorosset/:idnotafiscalcapa?",
    name: "pagsegurorosset",
    component: PagSeguroView,
    meta: {
      requiresAuth: true,
      title: "message.pagSeguro",
      breadcrumb: ["message.comercial", "message.pagSeguro"],
    },
  },
];

export default routes;
