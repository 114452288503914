<template>
  <q-list bordered class="rounded-borders">
    <template v-for="(item, index) in documentsList" :key="index">
      <LgpdLista
        :icon="item.icon"
        :description="item.description"
        :pdf="item.pdf"
        :sub="item.sub"
        :html="item.html"
      />
      <q-separator v-if="index + 1 !== documentsList.length" spaced />
    </template>
  </q-list>
</template>

<script>
import LgpdLista from "./LgpdLista";
export default {
  name: "LgpdListaDocumentos",
  components: { LgpdLista },
  data() {
    return {
      documentsList: [
        {
          description:
            "Política empresarial de privacidade e proteção de dados",
          pdf: "politica empresarial de privacidade e proteção de Dados.pdf",
          sub: {
            description: "O que é e qual o seu objetivo?",
            pdf: "one page - o que é Politica Empresarial.pdf",
          },
          icon: "mdi-domain",
        },
        {
          description: "Política de manuseio de dados pessoais",
          pdf: "politica de manuseio de dados pessoais.pdf",
          sub: {
            description: "O que é e qual o seu objetivo?",
            pdf: "one page - qual o objetivo da política de Manuseio e a Tabela de Temporalidade.pdf",
          },
          icon: "mdi-target-account",
          html: {
            description: "Tabelas de Temporalidade",
            path: "/lgpdtemporalidade",
          },
        },
        {
          description:
            "Procedimento para coleta, uso e gestão do consentimento",
          pdf: "procedimento para coleta, uso e gestão do Consentimento.pdf",
          sub: {
            description: "O que é e qual o seu objetivo?",
            pdf: "one page - qual o objetivo da política de Coleta, Uso e Gestão do Consentimento.pdf",
          },
          icon: "mdi-account-edit-outline",
        },
        {
          description: "Manual novos projetos - privacy by design",
          pdf: "manual novos projetos - privacy by design.pdf",
          sub: {
            description: "O que é e qual o seu objetivo?",
            pdf: "one page - qual o objetivo de privacy by design.pdf",
          },
          icon: "mdi-star-circle-outline",
        },
        {
          description:
            "Política de compartilhamento de dados pessoais com terceiros",
          pdf: "politica de compartilhamento de dados pessoais com terceiros.pdf",
          sub: {
            description: "O que é e qual o seu objetivo?",
            pdf: "one page - qual o objetivo da política de Compartilhamento de Dados Pessoais com Terceiros.pdf",
          },
          icon: "mdi-share-variant-outline",
        },
        {
          description:
            "Procedimentos de resposta a incidentes de segurança com dados pessoais",
          pdf: "procedimentos de resposta a incidentes de segurança com Dados Pessoais.pdf",
          sub: {
            description: "O que é e qual o seu objetivo?",
            pdf: "one page - qual o objetivo do procedimento de resposta a incidentes de segurança com Dados Pessoais.pdf",
          },
          icon: "mdi-forum-outline",
        },
        {
          description: "Aviso interno de privacidade",
          pdf: "aviso interno de privacidade.pdf",
          sub: {
            description: "O que é e qual o seu objetivo?",
            pdf: "one page - qual o objetivo do aviso interno de privacidade.pdf",
          },
          icon: "mdi-shield-alert-outline",
        },
      ],
    };
  },
  methods: {
    openDocument(document) {},
  },
};
</script>
