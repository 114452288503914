<template lang="">
  <ModalForm
    ref="modalFormCadastro"
    title="Aprovação de Limites de Compras Automática"
    :customClass="estilo"
    :persistent="true"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="aoSalvar()">
        <div class="row q-mb-xs q-mt-md">
          <div class="col-4 q-pr-xs">
            <FornecedoresSelectComponent
              @selected="selectedCentro($event)"
              :fornecedorId="registroData.cdFornec"
              :required="true"
            />
          </div>
          <div class="col-4 q-px-xs">
            <GruposComprasSelectComponent
              @selected="selectedGrupo($event)"
              :grupoId="registroData.cdGrupo"
              :autofocus="true"
              :required="true"
            />
          </div>
          <div class="col-4 q-pl-xs">
            <CentrosCustoSelectComponent
              @selected="selectedCentro($event)"
              :centroId="registroData.cdCentro"
            />
          </div>
        </div>

        <div class="row q-mb-xs q-mb-md">
          <div class="col-4 q-pr-xs">
            <MarcasSelectComponent
              @selected="selectedMarca($event)"
              :marcaId="registroData.cdEmpresa"
              label="Escolha a Empresa"
              :required="true"
            />
          </div>

          <div class="col-4 q-px-xs">
            <UsuarioSelecaoTecusuaSgtComponent
              @selected="selectedResponsavel($event)"
              :usuarioId="registroData.responsavel"
              label="Escolha o responsável"
              :required="true"
            />
          </div>

          <div class="col-4"></div>
        </div>

        <div class="row q-mb-md">
          <div class="col-4 q-pr-xs">
            <q-input label="Dt. Início" filled v-model="registroData.dtInicio">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      mask="DD/MM/YYYY"
                      today-btn
                      v-model="registroData.dtInicio"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="col-4 q-px-xs">
            <q-input
              label="Dt. Encerramento"
              filled
              v-model="registroData.dtFim"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      mask="DD/MM/YYYY"
                      today-btn
                      v-model="registroData.dtFim"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-4 q-px-xs">
            <q-input
              label="Dt. Aviso para Renovação"
              filled
              v-model="registroData.dtAvisoRenovacao"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      mask="DD/MM/YYYY"
                      today-btn
                      v-model="registroData.dtAvisoRenovacao"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </div>

        <div class="row q-mb-md">
          <div class="col-4 q-pr-xs">
            <InputQuantity
              v-model.lazy="registroData.vlLimite"
              :filled="true"
              :required="false"
              label="Valor Limite"
            />
          </div>

          <div class="col-4">
            <InputQuantity
              v-model.lazy="registroData.pcVariacao"
              :filled="true"
              :required="false"
              label="Percentual de Variação"
              class="q-ml-xs"
            >
              <template v-slot:append> % </template>
            </InputQuantity>
          </div>
          <div class="col-4"></div>
        </div>

        <q-separator />
        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-mt-md"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="fechar()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
</template>
<script>
import ModalForm from "Components/Widgets/ModalForm";
import InputQuantity from "Components/Widgets/InputQuantity";
import GruposComprasSelectComponent from "Areas/Compras/GruposCompras/GruposComprasSelectComponent";
import CentrosCustoSelectComponent from "Areas/Compras/CentrosCusto/CentrosCustoSelectComponent";
import FornecedoresSelectComponent from "Areas/Compras/Fornecedores/FornecedoresSelectComponent";
import MarcasSelectComponent from "Areas/Admin/Marcas/MarcasSelectComponent";
import UsuarioSelecaoTecusuaSgtComponent from "Areas/Admin/Usuarios/UsuarioSelecaoTecusuaSgtComponent";
import moment from "moment";
export default {
  name: "FormLimitesAprovacaoComprasAutomaticaComponent",
  emits: ["save"],
  components: {
    ModalForm,
    InputQuantity,
    GruposComprasSelectComponent,
    CentrosCustoSelectComponent,
    FornecedoresSelectComponent,
    MarcasSelectComponent,
    UsuarioSelecaoTecusuaSgtComponent,
  },
  data() {
    return {
      estilo: {
        width: "60vw",
        height: "70vh",
      },

      registroDataEmpty: {
        cdFornec: 0,
        cdGrupo: 0,
        cdCentro: 0,
        cdEmpresa: 0,
        responsavel: null,
        dtInicio: moment().format("DD/MM/YYYY"),
        dtFim: moment().format("DD/MM/YYYY"),
        dtAvisoRenovacao: moment().format("DD/MM/YYYY"),
        vlLimite: 0,
        pcVariacao: 0,
      },
      registroData: {
        cdFornec: 0,
        cdGrupo: 0,
        cdCentro: 0,
        cdEmpresa: 0,
        responsavel: null,
        dtInicio: moment().format("DD/MM/YYYY"),
        dtFim: moment().format("DD/MM/YYYY"),
        dtAvisoRenovacao: moment().format("DD/MM/YYYY"),
        vlLimite: 0,
        pcVariacao: 0,
      },
    };
  },
  // computed: {
  //   formatedDateIni: {
  //     get() {
  //       // console.log("aqui");
  //       // return moment(this.registroData.dtInicio).format("DD/MM/YYYY");
  //       console.log(this.registroData.dtInicio);
  //       return this.registroData.dtInicio;
  //     },
  //     set() {
  //       this.registroData.dtInicio = this.parseDate(value);
  //     },
  //   },
  // },
  methods: {
    selectedGrupo(item) {
      this.registroData.cdGrupo = item?.id;
    },
    selectedCentro(item) {
      this.registroData.cdCentro = item?.id;
    },
    selectedFornecedor(item) {
      this.registroData.cdFornec = item?.id;
    },
    selectedMarca(item) {
      this.registroData.cdEmpresa = item?.id;
    },
    selectedResponsavel(item) {
      this.registroData.responsavel = item?.id;
    },
    abrir(reg) {
      if (reg) {
        this.registroData = { ...reg };
        this.registroData.vlLimite *= 100;
        this.registroData.pcVariacao *= 100;
      } else {
        this.clearModal();
      }
      this.$refs.modalFormCadastro.abrir();
    },
    fechar() {
      this.$refs.modalFormCadastro.fechar();
    },
    clearModal() {
      this.registroData = { ...this.registroDataEmpty };
    },
    aoSalvar() {
      this.$emit("save", this.registroData);
    },
  },
};
</script>
