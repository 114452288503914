import axios from "axios";

const apic = axios.create({
  baseURL:
    process.env.VUE_APP_COBOL_ENDPOINT ||
    `${window.location.protocol}//${window.location.host}`,
  withCredentials: true,
});

export default apic;
