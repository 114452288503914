<template>
  <page-title-bar></page-title-bar>

  <div class="q-px-md">
    <ProdutosConfeccaoDetalheComponente />
  </div>
</template>

<script>
import ProdutosConfeccaoDetalheComponente from "Components/Areas/Produtos/Confeccao/ProdutosConfeccaoDetalheComponente";
export default {
  name: "ProdutosConfeccaoDetalheView",
  components: { ProdutosConfeccaoDetalheComponente },
};
</script>
