<template>
  <ModalForm
    ref="modalInventariarEspecifico"
    title="Inventariar IP Específico"
    :customClass="estilo"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="aoSalvar()">
        <div class="row q-mb-md">
          <div class="col q-pr-xs">
            <q-input
              type="text"
              filled
              v-model="ip"
              label="Informe o IP a ser inventariado"
            />
          </div>
        </div>
        <q-separator />

        <q-btn
          color="success"
          type="submit"
          :label="$t('message.run')"
          class="bg-green text-white q-mt-md"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="aoFechar()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
</template>

<script>
import InputQuantity from "Components/Widgets/InputQuantity";
import ModalForm from "Components/Widgets/ModalForm";

export default {
  name: "NovoRegistroMetas",
  emits: ["informarIp"],
  components: {
    InputQuantity,
    ModalForm,
  },
  data() {
    return {
      estilo: {
        width: "50vw",
        height: "25vh",
      },
      ip: null,
    };
  },

  methods: {
    aoAbrir() {
      this.$refs.modalInventariarEspecifico.abrir();
    },

    aoFechar() {
      this.ip = null;
      this.$refs.modalInventariarEspecifico.fechar();
    },

    aoSalvar() {
      this.$emit("informarIp", this.ip);
    },
  },
};
</script>
