<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <HeaderTabelaListaDePrecosItem />
    </div>

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        dense
        hide-pagination
        virtual-scroll
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
      >
        <template v-slot:top-left v-if="exibir">
          <q-btn
            dark
            size="md"
            class="q-mb-xm"
            color="primary"
            @click="adicionarItemLista"
          >
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:top-right>
          <q-input
            class="q-ml-sm"
            borderless
            dense
            debounce="300"
            v-model="filter"
            :placeholder="$t('message.search')"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <exportar-excel
            v-if="rows"
            :data="rows"
            name="listadeitenslistaprecos"
            class="q-mx-sm"
          />
        </template>

        <template v-slot:body-cell-actionsEdit="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-tooltip>{{ $t("message.edit") }}</q-tooltip>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="onUpdate(props.row.id)"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-actionsDelete="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-tooltip>{{ $t("message.delete") }}</q-tooltip>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click="onDelete(props.row.id)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-temEstampa="props">
          <q-td auto-width>
            <span>
              {{ props.row.temEstampa === "N" ? "Não" : "Sim" }}
            </span>
          </q-td>
        </template>
      </q-table>

      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        @onConfirm="deletarItem"
      ></delete-confirmation-dialog>
    </div>
  </div>
</template>

<script>
import api from "Api";
import HeaderTabelaListaDePrecosItem from "Components/Areas/Compras/Salete/ListaDePrecosItem/HeaderTabelaListaDePrecosItem";
import { mapGetters } from "vuex";

import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "ListadePrecosItem",
  components: {
    HeaderTabelaListaDePrecosItem,
  },
  props: {
    exibir: { type: Boolean, default: true },
  },

  data() {
    return {
      loader: false,
      filter: null,
      dadosLista: {},

      itemSelecionadoParaDelecao: {},

      listaPreco: {},

      rows: [],
      columns: [
        {
          label: this.$t("message.familyStock"),
          field: "familiaEstoque",
          name: "familiaEstoque",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.familyStockDescription"),
          field: "dsFamiliaEstoque",
          name: "dsFamiliaEstoque",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.physicalFinish"),
          field: "cdAcabFisi",
          name: "cdAcabFisi",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.physicalFinishDescription"),
          field: "dsAcabFisi",
          name: "dsAcabFisi",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.reference"),
          field: "referencia",
          name: "referencia",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.hasPrint"),
          field: "temEstampa",
          name: "temEstampa",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.variantOf"),
          field: "varianteDe",
          name: "varianteDe",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.variantUntil"),
          field: "varianteAte",
          name: "varianteAte",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.quantityOf"),
          field: "quantidadeDe",
          name: "quantidadeDe",
          format: (v) => this.$filters.formatDecimal(v),
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.quantityUntil"),
          field: "quantidadeAte",
          name: "quantidadeAte",
          format: (v) => this.$filters.formatDecimal(v),
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.price"),
          field: "preco",
          name: "preco",
          format: (v) => this.$filters.formatCurrency(v),
          align: "right",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
          sortable: false,
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    ...mapGetters("compras", ["getDadosListaPrecoSalete"]),

    listarPrecosItens() {
      this.dadosLista = this.getDadosListaPrecoSalete();
      this.numeroTabela = this.dadosLista.nrTabela;

      api
        .get(`v1/compras/salete/listarprecositens/tabela/${this.numeroTabela}`)
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    adicionarItemLista() {
      this.$router.push({
        name: "listadeprecositemdetalhe",
        params: { id: 0 },
      });
    },

    onUpdate(id) {
      this.$router.push({
        name: "listadeprecositemdetalhe",
        params: { id },
      });
    },

    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.itemSelecionadoParaDelecao = id;
    },
    async deletarItem() {
      this.$refs.deleteConfirmationDialog.close();

      await api
        .delete(
          `v1/compras/salete/excluirprecoitem/${this.itemSelecionadoParaDelecao}`
        )
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarPrecosItens());
    },
  },
  created() {
    this.listarPrecosItens();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 580px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
