export default {
  setPedido(context, payload) {
    context.commit("onChangePedido", payload);
  },
  setSituacao(context, payload) {
    context.commit("onChangeSituacao", payload);
  },
  setCalculadora(context, payload) {
    context.commit("onChangeCalculadora", payload);
  },
};
