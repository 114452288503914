export default {
  setGrupoEconomico(context, payload) {
    context.commit("onChangeGrupoEconomico", payload);
  },
  setRegraNegocio(context, payload) {
    context.commit("onChangeRegraNegocio", payload);
  },
  setDadosDoCliente(context, payload) {
    context.commit("onChangeDadosDoCliente", payload);
  },
};
