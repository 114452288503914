<template>
  <ModalForm
    ref="modalFormEditarRevisarMeta"
    :title="infoModal.tituloModal"
    :customClass="style"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="onUpdateOrReview">
        <div class="row q-mb-xl q-mt-md items-center">
          <div class="col-6 text-subtitle1 text-weight-medium">
            {{ infoModal.textoModal }}:
          </div>
          <div class="col-6">
            <q-select
              filled
              required
              option-label="label"
              option-value="value"
              v-model="anoMeta"
              :options="opcoesMeta"
              map-options
              emit-value
              :label="$t('message.chooseGoal')"
              class="q-mx-sm"
              :rules="metaRules"
              @update:model-value="atualizarAno($event)"
            />
          </div>
        </div>

        <q-separator />
        <q-btn
          v-if="infoModal.acaoModal == 'deletar'"
          color="success"
          @click.stop="deletarMeta"
          :label="infoModal.tituloBotao"
          class="bg-green text-white q-mt-lg"
        />
        <q-btn
          v-else
          color="success"
          type="submit"
          :label="infoModal.tituloBotao"
          class="bg-green text-white q-mt-lg"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="aoFechar()"
          class="bg-red text-white q-ml-md q-mt-lg"
        />
      </q-form>
    </template>
  </ModalForm>
  <confirmation-dialog ref="confirmationDialog" @onConfirm="deletarAoConfirmar">
  </confirmation-dialog>
</template>

<script>
import ModalForm from "Components/Widgets/ModalForm";
import { deepEqual } from "Helpers";
import { mapActions, mapGetters } from "vuex";
import api from "Api";
import { showSuccess, showError, showAlert } from "@/notifications/notify";

export default {
  name: "SeletorMeta",
  components: { ModalForm },
  data() {
    return {
      style: {
        width: "600px",
        height: "300px",
      },
      infoModal: {
        tituloModal: null,
        textoModal: null,
        tituloBotao: null,
        acaoModal: null,
      },
      anoMeta: null,
      opcoesMeta: [],
      metaRules: [(v) => v !== null || this.$t("message.goalRequired")],
    };
  },
  computed: {
    ...mapGetters("metas", ["getCabecalho"]),
  },
  watch: {
    getCabecalho(n, o) {
      if (deepEqual(n, o)) return;
      this.anoMeta = n.anoMeta;
    },
  },
  methods: {
    ...mapActions("metas", [
      "setCabecalho",
      "setMeta",
      "setExibir",
      "setDesabilitarMenu",
    ]),

    initialize() {
      this.opcoesMeta = [];
      const anoAtual = new Date().getFullYear();
      for (let i = 0; i < 2; i++) {
        const ano = anoAtual + i;
        const obj = {
          label: ano,
          value: ano,
        };
        this.opcoesMeta.push(obj);
      }
    },

    aoAbrir(infoModal) {
      this.$refs.modalFormEditarRevisarMeta.abrir();
      this.infoModal = infoModal;
    },

    aoFechar() {
      this.anoMeta = null;
      this.$refs.modalFormEditarRevisarMeta.fechar();
    },

    onUpdateOrReview() {
      api
        .get(`v1/comercial/metas?ano=${this.anoMeta}`)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.montarMeta(res.data);
          } else {
            return showError(this.$t("message.unavailableGoal"));
          }
          this.setExibir(true);
        })
        .catch((err) => showError(err))
        .finally(() => this.aoFechar());
    },

    montarMeta(metas) {
      for (const index in metas) {
        const meta = metas[index];
        const obj = {
          linha: parseInt(index) + 1,
          grupoId: meta.cdGrupo,
          grupoDescription: meta.dsGrupo,
          mesId: meta.mes,
          mesDescription: meta.dsMes,
          representanteId: meta.cdRepresentante,
          representanteDescription: meta.nomeRepresentante,
          valor: meta.vlItem,
          quilos: meta.qtKilos,
        };
        this.setMeta(obj);
      }
      const cabecalhoObj = {
        ano: metas[0].ano,
        descricao: metas[0].dsMeta,
        periodo: metas[0].dsPeriodo,
      };
      this.setCabecalho(cabecalhoObj);
      this.setDesabilitarMenu(true);
    },

    deletarMeta() {
      this.$refs.confirmationDialog.open();
    },

    deletarAoConfirmar() {
      this.$refs.confirmationDialog.close();
      api
        .delete(`v1/comercial/metas?ano=${this.anoMeta}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.aoFechar());
    },

    atualizarAno(value) {
      api
        .get(`v1/comercial/metas?ano=${value}`)
        .then((res) => {
          if (!res.data || res.data.length == 0) {
            showAlert(this.$t("message.unavailableGoal"));
            this.anoMeta = null;
          }
        })
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
