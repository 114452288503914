<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <div class="row">
        <div class="col-8">
          <q-form ref="form" class="q-gutter-md" autofocus @submit="salvar">
            <div class="row">
              <div class="col-8">
                <q-input
                  rounded
                  outlined
                  dense
                  v-model="userData.nome"
                  :rules="nameRules"
                  :label="messageName"
                  required
                >
                  <template v-slot:prepend>
                    <q-icon :name="icon" />
                  </template>
                </q-input>
              </div>
              <div class="col">
                <q-input
                  rounded
                  outlined
                  dense
                  class="q-ml-sm"
                  v-model="userData.usuario"
                  :label="$t('message.user')"
                  :disable="id > 0"
                  required
                  :rules="userRules"
                >
                  <template v-slot:prepend>
                    <q-icon :name="icon" />
                  </template>
                </q-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <q-input
                  rounded
                  outlined
                  dense
                  v-model="userData.email"
                  :rules="emailRules"
                  :label="messageEmail"
                  required
                >
                  <template v-slot:prepend>
                    <q-icon :name="icon" />
                  </template>
                </q-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <NivelAcessoSelecao
                  v-if="user.nivelAcessoId === 1"
                  :prepend-icon="icon"
                  @selected="obterNivelAcesso($event)"
                  :nivelAcessoId="userData.nivelAcessoId"
                  :required="true"
                  :rules="nivelAcessoRules"
                  :rounded="true"
                ></NivelAcessoSelecao>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <q-input
                  rounded
                  outlined
                  dense
                  v-model="userData.nomecliente"
                  :label="$t('message.customer')"
                  :readonly="true"
                  @click="abrirModalComListaDeClientes"
                  v-if="userData.ldapUser !== 1"
                >
                  <template v-slot:prepend>
                    <q-icon :name="icon" />
                  </template>
                </q-input>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <q-input
                  rounded
                  outlined
                  dense
                  :label="$t('message.alternativeUser')"
                  v-model="usuarioAlternativo"
                  v-if="user.nivelAcessoId === 1 && userData.ldapUser === 1"
                >
                  <template v-slot:prepend>
                    <q-icon :name="icon" />
                  </template>
                </q-input>
              </div>
            </div>

            <div class="row q-mt-lg q-pt-sm">
              <div class="col">
                <SeletorDashboardPadrao
                  v-if="userData.nivelAcessoId"
                  :dashboardId="userData.dashboardId"
                  :nivelAcessoId="userData.nivelAcessoId"
                  :prependIcon="icon"
                  @selected="selectedDashboardPadrao($event)"
                />
              </div>
            </div>

            <div class="row q-mb-sm">
              <div class="col">
                <q-toggle
                  v-if="user.nivelAcessoId === 1"
                  v-model="userData.highlevel"
                  color="blue"
                  label="HighLevel"
                  checked-icon="check"
                  unchecked-icon="clear"
                  :true-value="1"
                  :false-value="0"
                ></q-toggle>
              </div>

              <div class="col">
                <q-toggle
                  v-model="userData.showNotificationLgpd"
                  color="blue"
                  :label="$t('message.showNotificationLgpd')"
                  checked-icon="check"
                  unchecked-icon="clear"
                  :true-value="1"
                  :false-value="0"
                ></q-toggle>
              </div>

              <div class="col">
                <q-toggle
                  v-model="userData.ldapUser"
                  color="blue"
                  label="Ldap"
                  :true-value="1"
                  :false-value="0"
                  checked-icon="check"
                  unchecked-icon="clear"
                  :disable="!!id"
                ></q-toggle>
              </div>

              <div class="col">
                <q-toggle
                  v-model="userData.idCompany"
                  :label="userData.idCompany == 1 ? 'Rosset' : 'Salete'"
                  :true-value="1"
                  :false-value="72"
                  :class="userData.idCompany == 1 ? 'text-blue' : 'text-orange'"
                  :disable="!!id"
                ></q-toggle>
              </div>
            </div>

            <q-btn
              color="success"
              class="q-mr-sm q-mt-sm"
              type="submit"
              size="md"
              rounded
            >
              {{ $t("message.save") }}
            </q-btn>

            <q-btn
              color="danger"
              class="q-mt-sm"
              size="md"
              type="sair"
              @click.prevent="$router.go(-1)"
              rounded
            >
              {{ $t("message.close") }}
            </q-btn>

            <q-btn
              color="info"
              class="q-mt-sm"
              size="md"
              @click.prevent="resetarSenha"
              :disable="desabilitarEsqueceuSenha"
              rounded
              :label="titleButton"
            />
          </q-form>
        </div>

        <div class="col col-height-auto q-ml-md">
          <LinhaDoTempoSenhas :userData="userData" />
        </div>
      </div>

      <q-dialog v-model="dialog" full-width>
        <keep-alive>
          <ListaClienteParaUsuario
            ref="listaCliente"
            @clienteSelecionado="selecionarCliente($event)"
          >
          </ListaClienteParaUsuario>
        </keep-alive>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import api from "Api";
import NivelAcessoSelecao from "Components/Areas/Admin/NiveisAcesso/NivelAcessoSelecao";
import ListaClienteParaUsuario from "Components/Areas/Clientes/ListaClienteParaUsuario";
import SeletorDashboardPadrao from "Components/Areas/Admin/Dashboards/SeletorDashboardPadrao";
import LinhaDoTempoSenhas from "Components/Areas/Admin/Usuarios/LinhaDoTempoSenhas";
import { showError, showSuccess } from "@/notifications/notify";
import { clearObject } from "Helpers";

export default {
  name: "UsuarioDetalhe",
  components: {
    NivelAcessoSelecao,
    ListaClienteParaUsuario,
    SeletorDashboardPadrao,
    LinhaDoTempoSenhas,
  },
  data() {
    return {
      loader: true,
      dialog: false,
      id: this.$route.params.id,

      user: this.$store.getters["auth/getUser"],

      userData: {
        nome: "",
        email: null,
        usuario: null,
        highlevel: 0,
        showNotificationLgpd: 1,
        dashboardId: null,
        nivelAcessoId: 0,
        ldapUser: 0,
        idCompany: 1,
      },
      usuarioAlternativo: null,
      nivelAcessoDescricao: null,
      nivelAcessoRules: [(v) => !!v || this.$t("message.accessLevelRequired")],
      userRules: [(v) => !!v || this.$t("message.userRequired")],
      nameRules: [
        (v) => !!v || this.$t("message.nameRequired"),
        (v) =>
          (v && v.length <= 100) ||
          `${this.$t("message.name")}
           ${this.$t("message.mustbelessthan")} 100
           ${this.$t("message.characters")}`,
      ],
      emailRules: [
        (v) => !!v || this.$t("message.emailRequired"),
        (v) => /.+@.+\..+/.test(v) || this.$t("message.emailMustBeValid"),
        (v) =>
          (v && v.length <= 100) ||
          `${this.$t("message.email")}
           ${this.$t("message.mustbelessthan")} 100
           ${this.$t("message.characters")}`,
      ],

      messageName: this.$t("message.name"),
      messageEmail: this.$t("message.email"),
      messageUser: this.$t("message.user"),
      messageAccesLevel: this.$t("message.accessLevel"),
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
    desabilitarEsqueceuSenha() {
      if (this.userData.ldapUser === 1) return true;
      return !this.id;
    },

    titleButton() {
      return this.userData.resetpasswordtoken
        ? this.$t("message.resendEmailToken")
        : this.$t("message.resetPassword");
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id;
      this.obterUsuario();
    },
  },
  methods: {
    obterUsuario() {
      this.loader = true;
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/usuarios/${this.id}`)
        .then((response) => {
          this.userData = response.data;
          this.userData.idCompany = response.data.idcompany;
          this.usuarioAlternativo =
            this.userData.usuario === this.userData.usuarioalternativo
              ? ""
              : this.userData.usuarioalternativo;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    salvar() {
      let message;
      let method;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
      } else {
        message = "message.successAddedRecord";
        method = "post";
      }

      this.loader = true;

      this.userData.username = this.userData.usuario;

      api[method](`/v1/usuarios/${this.id}`, this.userData)
        .then(() => {
          this.trocarUsuarioAlternativo();
          if (!this.id) {
            clearObject(this.userData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    trocarUsuarioAlternativo() {
      const mudarUsuarioAlternativo =
        this.userData.usuarioalternativo !== this.usuarioAlternativo;

      if (this.userData.usuario === this.usuarioAlternativo) {
        showError("Você está simulando o seu usuário, não é permitido!");
        return;
      }
      if (mudarUsuarioAlternativo) {
        api
          .post("/v1/usuarios/trocarUsuarioAlternativo", {
            ...this.userData,
            usuarioAlternativo: this.usuarioAlternativo,
          })
          .catch((err) => showError(err));
      }
    },

    obterNivelAcesso(item) {
      this.userData.nivelAcessoId = item.id;
    },

    abrirModalComListaDeClientes() {
      this.dialog = true;
    },

    selecionarCliente(value) {
      this.userData.nomecliente = value.nome;
      this.userData.idpessoafj = value.idpessoafj;
      this.dialog = false;
    },

    resetarSenha() {
      this.loader = true;
      api
        .post(`/auth/forgotPassword/`, { email: this.userData.email })
        .then(() => showSuccess(this.$t("message.requestSent")))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    selectedDashboardPadrao(item) {
      this.userData.dashboardId = item.id;
    },
  },
  mounted() {
    this.obterUsuario();
  },
};
</script>
