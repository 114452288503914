import { mapGetters } from "vuex";

export const printTableMixin = {
  data() {
    return {
      originalStyle: "",
      originalClass: "",
    };
  },
  computed: {
    ...mapGetters("common", ["shouldRemoveStyleAndClass"]),
    tableStyle() {
      return this.shouldRemoveStyleAndClass
        ? ""
        : { height: this.alturaTabela + "px" };
    },
  },
  watch: {
    shouldRemoveStyleAndClass(newValue) {
      const tableElement = this.$refs.myTable.$el;
      if (newValue) {
        this.originalStyle = tableElement.getAttribute("style");
        this.originalClass = tableElement.className;
        tableElement.removeAttribute("style");
        tableElement.classList.remove("r7-sticky-header-table-2-lines");
        tableElement.classList.remove("my-sticky-header-table");
      } else {
        if (this.originalStyle) {
          tableElement.setAttribute("style", this.originalStyle);
        } else {
          tableElement.removeAttribute("style");
        }
        tableElement.className = this.originalClass;
      }
    },
  },
};
