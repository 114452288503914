<template>
  <q-timeline align-top dense>
    <q-timeline-entry small>
      <div>
        <div class="font-weight-normal">
          <strong>{{ $t("message.passwordLastSet") }}</strong>
        </div>
        <div>{{ userData.passwordlastset }}</div>
      </div>
    </q-timeline-entry>

    <q-timeline-entry :color="tentativasInvalidasCor()" small>
      <div>
        <div class="font-weight-normal">
          <strong>{{ $t("message.badPasswordCount") }}</strong>
        </div>
        <div>{{ userData.badpasswordcount }}</div>
      </div>
    </q-timeline-entry>

    <q-timeline-entry small>
      <div>
        <div class="font-weight-normal">
          <strong>{{ $t("message.lastBadPassword") }}</strong>
        </div>
        <div>{{ userData.lastbadpassword }}</div>
      </div>
    </q-timeline-entry>
  </q-timeline>
</template>

<script>
export default {
  name: "LinhaDoTempoSenhas",
  props: {
    userData: Object,
  },

  methods: {
    tentativasInvalidasCor() {
      if (this.userData.badpasswordcount === 0) return "green";
      if (this.userData.badpasswordcount < 3) return "yellow";
      return "red";
    },
  },
};
</script>
