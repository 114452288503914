<template>
  <template v-for="(col, idx) in column" :key="idx">
    <q-td v-if="col.titleTotal">{{ label }}</q-td>
    <q-td v-else-if="!col.detail" style="text-align: right">
      {{ totalizador(col) }}
    </q-td>
  </template>
</template>

<script>
export default {
  name: "Totalizador",
  props: {
    colunas: {
      type: Array,
      default: null,
    },
    dados: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: "Total",
    },
    visibleColumns: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {};
  },
  computed: {
    column() {
      return this.visibleColumns
        ? this.colunas.filter((col) => this.visibleColumns.includes(col.name))
        : this.colunas;
    },
  },
  methods: {
    showColumn() {},
    totalizador(column) {
      if (!column.total) return null;

      const arrayValores = this.dados.map((e) => e[column.field]);

      const totalizador = (p, c) => parseFloat(p) + parseFloat(c || 0);

      const total = arrayValores.reduce(totalizador, 0);

      return this.$filters.formatDecimal(total);
    },
  },
};
</script>
