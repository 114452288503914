<template>
  <Indicadores class="noPrint" @onFilter="filterData($event)" />

  <q-table
    ref="myTable"
    :rows="tbFaturamento"
    :columns="columns"
    :rows-per-page-options="[0]"
    :no-data-label="$t('message.noDataFound')"
    hide-pagination
    class="q-mx-sm q-my-sm r7-sticky-header-table-2-lines"
    dense
    :loading="loading"
    :visibleColumns="visibleColumns"
    id="tableData"
    :style="tableStyle"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th class="bg-white" auto-width />
        <q-th class="bg-white" auto-width />
        <q-th
          colspan="2"
          class="bgGreenLight r7-default-text-color text-center"
        >
          Mês:
          <select id="mes">
            <option
              v-for="(month, index) in months"
              :key="index"
              :selected="index + 1 == mes"
              :value="month.id"
            >
              {{ month.description }}
            </option>
          </select>
        </q-th>
        <q-th colspan="2" class="bgGreen r7-default-text-color"
          >Ano:
          <select id="ano">
            <option :value="new Date().getFullYear() - 3">
              {{ new Date().getFullYear() - 3 }}
            </option>
            <option :value="new Date().getFullYear() - 2">
              {{ new Date().getFullYear() - 2 }}
            </option>
            <option :value="new Date().getFullYear() - 1">
              {{ new Date().getFullYear() - 1 }}
            </option>
            <option selected :value="new Date().getFullYear()">
              {{ new Date().getFullYear() }}
            </option>
          </select>
        </q-th>
        <q-th class="bgSalmon">
          <img
            :src="imgOk"
            @click="confirm()"
            style="cursor: pointer"
            class="noPrint"
          />
        </q-th>
      </q-tr>

      <q-tr>
        <q-th class="bg-white" auto-width />
        <q-th class="bg-white" auto-width />
        <q-th class="bgGreenLight r7-text-color-green">Faturamento</q-th>
        <q-th class="bgGreenLight r7-text-color-green">Compras</q-th>
        <q-th class="bgGreen r7-text-color-green">Faturamento</q-th>
        <q-th class="bgGreen r7-text-color-green">Compras</q-th>
        <q-th class="bgSalmon r7-text-color-green">Diferença</q-th>
      </q-tr>
    </template>

    <template v-slot:body="props">
      <q-tr
        :props="props"
        :class="props.row.cdSegmento == 99 ? 'totalizador' : ''"
      >
        <q-td auto-width class="bgYellow">
          <q-btn
            size="xs"
            color="accent"
            class="noPrint"
            round
            dense
            @click="expand(props.row)"
            :icon="props.row.expand ? 'remove' : 'add'"
            v-if="props.row.cdSegmento !== 99"
          />
        </q-td>

        <q-td
          :props="props"
          v-for="col in props.cols"
          :key="col.name"
          :class="col.class"
        >
          {{ col.value }}
        </q-td>
      </q-tr>

      <q-tr
        v-show="props.row.expand"
        v-for="(row, idx) in props.row.lojas"
        :key="idx"
        :props="props"
      >
        <q-th class="bgYellow" auto-width />

        <q-td v-for="(col, idx) in props.cols" :key="idx" :class="col.class">
          <span v-if="idx == 0">
            {{ row.descricao }}
          </span>
          <span v-else>
            {{ formatValue(row[col.field]) }}
          </span>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<script>
import Api from "Api";
import { showError } from "@/notifications/notify";
import Indicadores from "./Indicadores.vue";
import MonthSelector from "Components/Widgets/MonthSelector";
import { arrayMonths, sizeTotal } from "Helpers";

import { printTableMixin } from "Mixin/printTableMinx";

export default {
  name: "FaturamentoMesAnoFranquiasComponent",
  components: { Indicadores, MonthSelector },
  mixins: [printTableMixin],
  data() {
    return {
      loading: true,
      ano: null,
      mes: null,
      filterInit: { stValor: true, stQtde: false },
      months: arrayMonths.months,
      imgOk: process.env.BASE_URL + "/static/img/ok.gif",
      alturaTabela: 0,

      tbFaturamento: [],
      columns: [
        {
          label: "",
          name: "lojas",
          field: "lojas",
        },
        {
          label: "",
          name: "descricao",
          field: "descricao",
          sortable: false,
          align: "left",
          class: "bgYellow text-left",
        },
        {
          label: "",
          name: "fatuValorMes",
          field: "fatuValorMes",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreenLight text-right",
        },
        {
          label: "",
          name: "compraValorMes",
          field: "compraValorMes",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreenLight text-right",
        },
        {
          label: "",
          name: "fatuValorAno",
          field: "fatuValorAno",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreen text-right",
        },
        {
          label: "",
          name: "compraValorAno",
          field: "compraValorAno",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreen text-right",
        },
        {
          label: "",
          name: "difValor",
          field: "difValor",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgSalmon text-right",
        },
        {
          label: "",
          name: "fatuQtdeMes",
          field: "fatuQtdeMes",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreenLight text-right",
        },
        {
          label: "",
          name: "compraQtdeMes",
          field: "compraQtdeMes",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreenLight text-right",
        },
        {
          label: "",
          name: "fatuQtdeAno",
          field: "fatuQtdeAno",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreen text-right",
        },
        {
          label: "",
          name: "compraQtdeAno",
          field: "compraQtdeAno",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgGreen text-right",
        },
        {
          label: "",
          name: "difQtde",
          field: "difQtde",
          sortable: false,
          align: "right",
          format: (v) => this.$filters.formatWithoutDecimal(v),
          class: "bgSalmon text-right",
        },
      ],
      visibleColumnsOri: [
        "descricao",
        "fatuValorMes",
        "fatuQtdeMes",
        "compraValorMes",
        "compraQtdeMes",
        "fatuValorAno",
        "fatuQtdeAno",
        "compraValorAno",
        "compraQtdeAno",
        "difValor",
        "difQtde",
      ],
      visibleColumns: [],
      // originalStyle: "",
      // originalClass: "",
    };
  },
  methods: {
    filterData(filter = this.filterInit) {
      let visibleColumns = [...this.visibleColumnsOri];

      const removeColumns = (filter) => {
        return visibleColumns.filter((f) => f.indexOf(filter) == -1);
      };

      this.filter = filter;

      if (!filter.stValor) {
        visibleColumns = removeColumns("Valor");
      }
      if (!filter.stQtde) {
        visibleColumns = removeColumns("Qtde");
      }

      this.visibleColumns = visibleColumns;
    },
    initialize() {
      this.tbFaturamento = [];
      this.visibleColumns = [];
      this.filterData();
      this.obterFaturamento();
      this.alturaTabela = sizeTotal() - 36; // 36 é o tamanho do componente antes da tabela
    },
    obterFaturamento() {
      this.loading = true;
      Api.get(`/v1/faturamento/franquias/mes/${this.mes}/ano/${this.ano}`)
        .then((res) => (this.tbFaturamento = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loading = false));
    },

    confirm() {
      const selAno = document.getElementById("ano");
      const selMes = document.getElementById("mes");
      this.ano = selAno.value;
      this.mes = selMes.value;
      this.initialize();
    },
    formatValue(v) {
      return this.$filters.formatWithoutDecimal(v);
    },
    expand(row) {
      row.expand = !row.expand;
    },
  },
  mounted() {
    this.ano = this.$route.query.ano || new Date().getFullYear();
    this.mes = this.$route.query.mes || new Date().getMonth() + 1;
    this.initialize();
  },
};
</script>
