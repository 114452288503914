<template>
  <div>
    <title-bar :title="$t('message.ordersList')">
      <template v-slot:funcoes>
        <q-space />

        <q-btn flat round size="small">
          <q-tooltip
            anchor="bottom middle"
            self="center middle"
            :offset="[10, 10]"
            class="text-body2"
            >{{ $t("message.filter") }}</q-tooltip
          >
          <q-icon
            @click="showFilter = !showFilter"
            class="material-icons text-grey-9"
          >
            filter_alt
          </q-icon>
        </q-btn>

        <exportar-excel
          v-if="rows"
          :data="rows"
          name="listapedidosporsituacao"
          class="q-mr-sm q-mt-xs"
        />
      </template>
    </title-bar>

    <app-section-loader :status="loader" />
    <q-grid
      ref="table"
      :data="rows"
      :columns="columns"
      :dense="true"
      :columns_filter="showFilter"
      :totalizador="true"
      :subtotalizador="true"
    >
      <template v-slot:pedido="props">
        <router-link
          :to="{
            name: 'pedidoUnico',
            params: { idPedido: props.value },
          }"
        >
          <q-tooltip anchor="bottom right" self="top right" class="text-body2">
            {{ $t("message.salesOrder") }}
          </q-tooltip>
          {{ props.value }}
        </router-link>
      </template>
    </q-grid>
  </div>
</template>

<script>
import api from "Api";
import { mapGetters } from "vuex";
import { showError } from "@/notifications/notify";
import { filtrarSituacao, filtrarPedido } from "Helpers/FiltrosComerciais";
import { aguardar } from "Helpers";

export default {
  name: "ListaPedidosSituacao",
  props: ["idCliente", "idRepresentante"],
  data() {
    return {
      filtroColuna: false,
      rows: [],
      dadosOriginal: [],
      grupoEconomico: null,
      loader: true,
      showFilter: false,
      filter: null,
      columns: [
        {
          label: this.$t("message.order"),
          field: "pedido",
          name: "pedido",
          sortable: true,
          align: "right",
          titleTotal: true,
        },
        {
          label: "Item",
          field: "itempedido",
          name: "itempedido",
          align: "right",
        },
        {
          label: this.$t("message.client"),
          field: "nome",
          name: "nome",
          align: "left",
        },
        {
          label: this.$t("message.customerOrder"),
          field: "pedidocliente",
          name: "pedidocliente",
          align: "right",
        },
        {
          label: this.$t("message.orderType"),
          field: "tipoPedido",
          name: "tipoPedido",
          align: "left",
        },
        {
          label: this.$t("message.status"),
          field: "dsResumo",
          name: "dsResumo",
          align: "left",
        },
        {
          label: this.$t("message.immediateDelivery"),
          field: "prontaEntrega",
          name: "prontaEntrega",
          align: "center",
        },
        {
          label: this.$t("message.scheduledDeliveryDate"),
          field: "dtEntregaItem",
          name: "dtEntregaItem",
          sortable: true,
          align: "center",
        },
        {
          label: this.$t("message.deliveryWeek"),
          field: "semanaEntrega",
          name: "semanaEntrega",
          sortable: true,
          align: "left",
        },
        {
          label: "Un.",
          field: "dssigla",
          name: "dssigla",
          align: "center",
        },
        {
          label: this.$t("message.quantity"),
          field: "quantidadeDigitacao",
          name: "quantidadeDigitacao",
          total: true,
          align: "right",
          format: "decimal",
        },
        {
          label: this.$t("message.quantityKg"),
          field: "quantidadeKg",
          name: "quantidadeKg",
          total: true,
          align: "right",
          format: "decimal",
        },
        {
          label: this.$t("message.balance"),
          field: "saldo",
          name: "saldo",
          total: true,
          align: "right",
          format: "decimal",
        },
        {
          label: this.$t("message.orderDate"),
          field: "emissao",
          name: "emissao",
          sortable: true,
          align: "center",
        },

        {
          label: this.$t("message.article"),
          field: "dsArtigo",
          name: "dsArtigo",
          align: "left",
        },
        {
          label: this.$t("message.color"),
          field: "descricaoCor",
          name: "descricaoCor",
          align: "left",
        },
        {
          label: this.$t("message.reservedQuantityKg"),
          field: "reservadoKg",
          name: "reservadoKg",
          total: true,
          align: "right",
          format: "decimal",
        },
        {
          label: this.$t("message.reservedQuantityQtd"),
          field: "reservadoQtd",
          name: "reservadoQtd",
          total: true,
          align: "right",
          format: "decimal",
        },
        {
          label: this.$t("message.reservedQuantityFat"),
          field: "reservadoFat",
          name: "reservadoFat",
          total: true,
          align: "right",
          format: "decimal",
        },
        {
          label: this.$t("message.partialDelivery"),
          field: "parcial",
          name: "parcial",
          align: "left",
        },
        {
          label: this.$t("message.combinedOrders"),
          field: "pedidosconjugados",
          name: "pedidosconjugados",
          align: "left",
        },
        {
          label: this.$t("message.requirements"),
          field: "exigencias",
          name: "exigencias",
          align: "left",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("clientes", ["getGrupoEconomico"]),
    ...mapGetters({ computedGetPedido: "comercial/getPedido" }),
    ...mapGetters({ computedGetSituacao: "comercial/getSituacao" }),
  },

  watch: {
    getGrupoEconomico(val) {
      this.grupoEconomico = val;
      this.listaPedidosPorSituacao();
    },
    computedGetPedido: "filtrarExibirPedido",
    computedGetSituacao: {
      handler(val) {
        this.filtrarExibirSituacaoPedido(val);
      },
      deep: true,
    },
  },

  methods: {
    listarPedidosPorSituacao() {
      api
        .post("/v1/comercial/pedidos/listapedidossituacao", {
          idCliente: this.idCliente,
          idRepresentante: this.idRepresentante,
          grupoeconomico: this.grupoEconomico,
        })
        .then((res) => {
          this.loader = true;
          if (res.data) {
            this.rows = res.data;
            this.dadosOriginal = this.rows;
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    filtrarExibirPedido(pedido) {
      if (!this.loader) {
        this.rows = filtrarPedido(this.dadosOriginal, pedido);
      } else {
        aguardar(pedido, this.filtrarExibirPedido, 5000);
      }
    },
    filtrarExibirSituacaoPedido(situacao) {
      if (!this.loader) {
        this.rows = filtrarSituacao(this.dadosOriginal, situacao);
      } else {
        aguardar(situacao, this.filtrarExibirSituacaoPedido, 5000);
      }
    },
  },
  created() {
    this.listarPedidosPorSituacao();
  },
};
</script>
