import axios from "axios";
import { userKey } from "@/global";

// Cria uma instância do Axios
const api = axios.create({
  baseURL:
    process.env.VUE_APP_PYTHON_ENDPOINT ||
    `${window.location.protocol}//${window.location.host}/api/py`,
  withCredentials: true, // Isso é necessário para que os cookies sejam enviados junto com as requisições
});

// Define o cabeçalho Authorization se o endpoint estiver configurado
if (process.env.VUE_APP_PYTHON_ENDPOINT) {
  const uk = JSON.parse(localStorage.getItem(userKey));
  if (uk) {
    // console.log(uk.token);
    api.defaults.headers.common["Authorization"] = `Bearer ${uk.token}`;
  }
}

// Função para obter o token CSRF do cookie
function getCsrfToken() {
  let csrfToken = null;
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [name, value] = cookie.trim().split("=");
    if (name === "csrftoken") {
      csrfToken = value;
      break;
    }
  }
  return csrfToken;
}

// Adiciona um interceptor para incluir o token CSRF em todas as requisições
api.interceptors.request.use(
  (config) => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
