/**
 * App Config File
 */
export default {
  appLogo: process.env.BASE_URL + "/static/img/ROSSET GROUP_PRETO_outline.png", // App Logo,
  darkLogo: process.env.BASE_URL + "/static/img/site-dark-logo.png", // dark logo
  darkLogo2: process.env.BASE_URL + "/static/img/site-dark-logo.png", // dark logo
  appLogo2: process.env.BASE_URL + "/static/img/ROSSET GROUP_PRETO_outline.png", // App Logo 2 For Login & Signup Page
  login: process.env.BASE_URL + "/static/img/login-tecel.png",
  brand: process.env.BASE_URL + "Rosset", // Brand Name
  copyrightText: `Grupo Rosset © ${new Date().getFullYear()} Todos direitos reservados.`, // Copyright Text
  fotosFabrica: [
    process.env.BASE_URL + "/static/img/matriz.png",
    process.env.BASE_URL + "/static/img/rosset_cumbica.png",
    process.env.BASE_URL + "/static/img/salete.png",
    process.env.BASE_URL + "/static/img/morungaba.png",
    process.env.BASE_URL + "/static/img/friburgo.png",
  ],
};
