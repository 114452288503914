<template>
  <q-btn @click.stop="navigation('home')" flat round dense class="q-mx-sm">
    <q-tooltip
      anchor="bottom middle"
      self="center middle"
      :offset="[10, 10]"
      class="text-body2"
      >{{ $t("message.homePage") }}</q-tooltip
    >
    <q-icon large class="material-icons text-blue-9">home</q-icon></q-btn
  >

  <!-- <MessageAlert /> -->

  <q-btn
    @click.stop="navigation('releaseslista')"
    flat
    round
    dense
    class="q-mx-sm"
  >
    <q-tooltip
      anchor="bottom middle"
      self="center middle"
      :offset="[10, 10]"
      class="text-body2"
      >{{ $t("message.systemReleases") }}</q-tooltip
    >
    <q-icon large class="material-icons text-green-9">bookmark</q-icon></q-btn
  >

  <q-btn
    @click.stop="navigation('lgpddocumentos')"
    flat
    round
    dense
    class="q-mx-sm"
  >
    <q-tooltip
      anchor="bottom middle"
      self="center middle"
      :offset="[10, 10]"
      class="text-body2"
      >LGPD</q-tooltip
    >
    <q-icon large class="material-icons text-red-9" name="mdi-shield-account" />
  </q-btn>

  <Help />

  <q-btn @click.stop="logoutPowerBi()" flat round dense class="q-mx-sm">
    <q-tooltip
      anchor="bottom middle"
      self="center middle"
      :offset="[10, 10]"
      class="text-body2"
      >Sair do PowerBi</q-tooltip
    >
    <q-icon large class="material-icons text-yellow-9 sair-power-bi"
      >logout</q-icon
    ></q-btn
  >

  <q-btn @click="fullScreen()" flat round dense class="q-mx-sm">
    <q-tooltip
      anchor="bottom middle"
      self="center middle"
      :offset="[10, 10]"
      class="text-body2"
      >{{ $t("message.fullscreenMode") }}</q-tooltip
    >
    <q-icon
      v-if="$q.fullscreen.isActive"
      large
      class="material-icons text-grey-9"
      >fullscreen_exit
    </q-icon>

    <q-icon v-else large class="material-icons text-grey-9">fullscreen</q-icon>
  </q-btn>
</template>

<script>
import { mapActions } from "vuex";
// import MessageAlert from "Components/Areas/Admin/Mensagens/MessageAlert";
import Help from "Components/Areas/Admin/FAQ/Help";

export default {
  name: "Toolbar",
  components: { Help },

  methods: {
    ...mapActions("admin", ["setSideBarToggle", "setFullScreenToggle"]),

    fullScreen() {
      this.$q.fullscreen.toggle();
      this.setFullScreenToggle();
      this.setSideBarToggle();
    },
    navigation(path) {
      this.$router.push({ name: path }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
    logoutPowerBi() {
      const windowSignout = window.open(
        "https://app.powerbi.com/Signout",
        "_blank"
      );
      setTimeout(() => {
        windowSignout.close();
        location.reload();
      }, 2000);
    },
  },
};
</script>
