<template>
  <div class="q-pa-xs">
    <LojasSelecao :lojaId="cdLoja" @selected="onSelected($event)" />
  </div>
  <q-table
    ref="myTable"
    :rows="rows"
    :columns="columns"
    :rows-per-page-options="[0]"
    :no-data-label="$t('message.noDataFound')"
    hide-pagination
    class="q-mx-sm q-my-sm r7-sticky-column-table"
    :style="{ height: alturaTabela + 'px' }"
    dense
    :loading="loading"
    id="tableData"
  ></q-table>
</template>
<script>
import LojasSelecao from "./LojasSelecao.vue";
import Api from "Api";
import { showError } from "@/notifications/notify";
import { arrayMonths, sizeTotal } from "Helpers";
import { printTableMixin } from "Mixin/printTableMinx";

export default {
  components: { LojasSelecao },
  mixins: [printTableMixin],
  data() {
    return {
      loading: true,
      cdLoja: 200,
      rows: [],
      columns: [],
      alturaTabela: 0,
      // alturaTabela: sizeTotal() - 52, // 52 é o tamanho do componente antes da tabela
    };
  },
  methods: {
    initialize() {
      this.montaColunasTabela();
      this.listarPerformance();
      this.alturaTabela = sizeTotal() - 52;
    },
    onSelected(item) {
      this.rows = [];
      this.cdLoja = item.id;
      this.listarPerformance();
    },
    montaColunasTabela() {
      this.columns = [
        {
          label: "",
          name: "ano",
          field: "ano",
          sortable: false,
          align: "center",
          class: "bgYellow text-center",
        },
      ];

      for (const month of arrayMonths.months) {
        const colname = month.abrev.toLowerCase() + "Valor";
        const color = month.id % 2 == 0 ? "bgGreenLight" : "bgGreen";
        const col = {
          label: month.description,
          name: colname,
          field: colname,
          sortable: false,
          align: "right",
          classes: color,
          headerClasses: color,
          format: (v) => this.$filters.formatWithoutDecimal(v),
        };
        this.columns.push(col);
      }
      const colTotal = {
        label: "TOTAL",
        name: "total",
        field: "total",
        sortable: false,
        align: "right",
        classes: "bgSalmon",
        headerClasses: "bgSalmon",
        format: (v) => this.$filters.formatWithoutDecimal(v),
      };
      this.columns.push(colTotal);
    },
    listarPerformance() {
      this.loading = true;
      Api.get(`/v1/faturamento/varejo/performance/loja/${this.cdLoja}`)
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
