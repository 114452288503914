<template>
  <page-title-bar></page-title-bar>
  <app-section-loader :status="loader"></app-section-loader>
  <div class="q-pa-md">
    <q-file
      color="teal"
      filled
      label="Planilha Excel"
      accept=".xlsx"
      v-model="file"
      @update:model-value="validarPlanilha"
    >
      <template v-slot:prepend>
        <q-icon name="cloud_upload"></q-icon>
      </template>
    </q-file>

    <q-table
      :rows="rows"
      :columns="columns"
      table-header-class="table-header"
      :filter="filter"
      class="my-sticky-header-table q-mt-md"
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      virtual-scroll
    >
      <!-- v-if="rows.length > 0" -->
      <template v-slot:top-left>
        <q-btn color="teal" class="q-mr-md" :disable="!file" @click="processar">
          <q-icon left name="input"></q-icon>
          <div>Processar</div>
        </q-btn>
        <q-btn color="red" class="q-pr-md" @click="limpar" :disable="!file">
          <q-icon left name="clear"></q-icon>
          <div>Limpar</div>
        </q-btn>
      </template>

      <template v-slot:top-right>
        <q-input
          ref="filter"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:body-cell-valid="props">
        <q-td auto-width align="center">
          <tooltip-custom :label="props.row.message" v-if="props.row.message" />
          <q-icon
            size="md"
            :name="props.row.valid ? `check_circle` : `cancel`"
            :color="props.row.valid ? `green` : `red`"
          >
          </q-icon>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import api from "Api";
import { showError } from "@/notifications/notify";
import { useQuasar, QSpinnerGears } from "quasar";

export default {
  setup() {
    const $q = useQuasar();
    let dialog = null;

    function showWaitingMessage() {
      dialog = $q.dialog({
        title: "Aguarde, convertendo imagens...",
        dark: true,
        progress: {
          spinner: QSpinnerGears,
          color: "amber",
        },
        persistent: true,
        ok: false,
      });
    }

    function showSuccessMessage() {
      dialog.update({
        title: "Finalizado",
        message: "Imagens convertidas!",
        progress: false,
        ok: true,
      });
    }

    return { showWaitingMessage, showSuccessMessage };
  },
  data() {
    return {
      filter: null,
      file: null,
      loader: false,
      columns: [
        {
          label: this.$t("message.group"),
          field: "grupo",
          name: "grupo",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.family"),
          field: "familia",
          name: "familia",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.subGroup"),
          field: "subGrupo",
          name: "subGrupo",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.type"),
          field: "tipo",
          name: "tipo",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.serie"),
          field: "serie",
          name: "serie",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.color"),
          field: "cor",
          name: "cor",
          align: "left",
          sortable: true,
        },
        {
          label: "Ref+Taça+Cor",
          field: "refTacaCor",
          name: "refTacaCor",
          align: "left",
          sortable: true,
        },
        {
          label: "Ean",
          field: "ean",
          name: "ean",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.status"),
          field: "valid",
          name: "valid",
          align: "center",
          sortable: true,
        },
      ],
      rows: [],
    };
  },

  methods: {
    async validarPlanilha(file) {
      this.loader = true;
      const sheet = await readXlsxFile(file);
      api
        .post("/v1/comercial/ecommerce/imagensb2b/validarplanilha", { sheet })
        .then((res) => {
          if (res.data) {
            this.rows = res.data;
          }
        })
        .catch((error) => showError(error))
        .finally(() => (this.loader = false));
    },

    processar() {
      this.showWaitingMessage();
      const data = this.rows;
      api
        .post("/v1/comercial/ecommerce/imagensb2b/converterarquivos", data)
        .catch((error) => showError(error))
        .finally(() => this.showSuccessMessage());
    },

    limpar() {
      this.file = null;
      this.rows = [];
    },
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 600px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
