import ClienteLista from "Views/Clientes/ClienteLista";
import ClienteResumo from "Views/Clientes/ClienteResumo";
import ClientePerfil from "Views/Clientes/ClientePerfil";

const routes = [
  {
    path: "/clientelista",
    name: "clientelista",
    component: ClienteLista,
    meta: {
      requiresAuth: true,
      title: "message.customerList",
      breadcrumb: [
        "message.comercial",
        "message.customer",
        "message.customerList",
      ],
    },
  },
  {
    path: "/clienteresumo",
    name: "clienteresumo",
    component: ClienteResumo,
    meta: {
      requiresAuth: true,
      title: "message.customerSummary",
      breadcrumb: [
        "message.comercial",
        "message.customer",
        "message.customerSummary",
      ],
    },
  },
  {
    path: "/clienteperfil",
    name: "clienteperfil",
    component: ClientePerfil,
    meta: {
      requiresAuth: true,
      title: "message.customerProfile",
      breadcrumb: [
        "message.comercial",
        "message.customer",
        "message.customerProfile",
      ],
    },
  },
];

export default routes;
