<template>
  <q-menu
    fit
    anchor="top right"
    content-class="language-dropdown"
    transition="fade"
    class="q-pa-xs"
    auto-close
  >
    <q-list clickable>
      <q-item
        v-for="language in languages"
        :key="language.name"
        @click="changeLanguage(language)"
        clickable
      >
        <q-img
          class="img-responsive mr-3"
          width="20px"
          height="20px"
          :src="getImagePath(`/static/flag-icons/${language.icon}.png`)"
        />
        <span class="q-ml-sm">{{ language.name }}</span>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      icon: null,
      getImagePath(path) {
        return process.env.BASE_URL + path;
      },
    };
  },
  computed: {
    ...mapGetters(["selectedLocale", "languages"]),
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language.locale;
      this.$store.dispatch("changeLanguage", language);
    },
  },
};
</script>
