<template>
  <page-title-bar></page-title-bar>

  <div class="row q-pa-md">
    <div class="col-6 q-pr-lg">
      <SetoresQAListComponent ref="setoresQAListComponent" />
    </div>
    <q-separator inset vertical />
    <div class="col q-pl-lg">
      <SetoresQADetailComponent
        ref="setoresQADetailComponent"
        @atualizar="refreshList()"
      />
    </div>
  </div>
</template>

<script>
import SetoresQAListComponent from "Components/Areas/RH/QA/SetoresQAListComponent";
import SetoresQADetailComponent from "Components/Areas/RH/QA/SetoresQADetailComponent";

export default {
  name: "SetoresQAListDetailView",
  components: { SetoresQAListComponent, SetoresQADetailComponent },
  data() {
    return {};
  },
  methods: {
    refreshList() {
      this.$refs.setoresQAListComponent.listarSetores();
    },
  },
};
</script>
