<template>
  <page-title-bar />
  <div class="q-px-md">
    <OmniChannelDetalheComponent />
  </div>
</template>
<script>
import OmniChannelDetalheComponent from 'Components/Areas/Valisere/OmniChannelDetalheComponent';
export default {
  name: "OmniChannelDetalheView",
  components: {OmniChannelDetalheComponent},
  data() {
    return {
    
    }
  },
}
</script>
