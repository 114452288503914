<template>
  <div>
    <ModalCard
      ref="modalListaUsuariosPorNiveisAcesso"
      :title="$t('message.usersList')"
    >
      <template v-slot:body>
        <q-table
          :loading="loader"
          :rows="rows"
          :columns="columns"
          table-header-class="table-header"
          :rows-per-page-options="[0]"
          :noDataLabel="$t('message.noDataFound')"
          hide-pagination
          @row-click="selecionarLinha"
          class="q-mx-sm q-my-sm my-sticky-header-table"
          dense
          virtual-scroll
        >
        </q-table>
      </template>
    </ModalCard>
  </div>
</template>

<script>
import api from "Api";
import ModalCard from "Components/Widgets/ModalCard";
import { showError } from "@/notifications/notify";

export default {
  name: "UsuarioDeCadaNivelAcesso",
  components: { ModalCard },

  data() {
    return {
      loader: true,

      id: null,

      rows: [],
      columns: [
        {
          label: this.$t("message.userId"),
          field: "idusuario",
          name: "idusuario",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.name"),
          field: "nome",
          name: "nome",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    obterUsuariosNivelAcesso(id) {
      api
        .get(`/v1/nivelAcesso/listarusuarios/${id}`)
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    abrirModal(id) {
      this.$refs.modalListaUsuariosPorNiveisAcesso.abrir();
      this.obterUsuariosNivelAcesso(id);
    },

    selecionarLinha(evt, item) {
      this.$router.push({
        name: "usuariodetalhe",
        params: { id: item.idusuario },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 150px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
