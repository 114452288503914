<template>
  <q-page-sticky
    position="top-left"
    :offset="configuracoesData.offset"
    v-if="visivel"
  >
    <q-btn
      fab-mini
      :icon="configuracoesData.icon"
      @click.stop="alternarNavegacao()"
      :color="configuracoesData.color"
      :style="configuracoesData.estilo"
      :flat="configuracoesData.flat"
    >
      <q-tooltip
        anchor="bottom middle"
        self="center middle"
        :offset="[10, 10]"
        class="text-body2"
      >
        {{ this.configuracoesData.mensagem }}
      </q-tooltip>
    </q-btn>
  </q-page-sticky>
</template>

<script>
export default {
  name: "FloatingButton",
  props: ["configuracoes", "visivel"],
  emits: ["abrirFecharNavegacao"],
  data() {
    return {
      configuracoesData: null,
    };
  },
  watch: {
    configuracoes() {
      this.setarConfiguracoes();
    },
  },
  methods: {
    setarConfiguracoes() {
      this.configuracoesData = this.configuracoes;
    },
    alternarNavegacao() {
      this.$emit("abrirFecharNavegacao");
    },
  },
  mounted() {
    this.setarConfiguracoes();
  },
};
</script>
