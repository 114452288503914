import { userKey } from "@/global";
import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';;
import { chaveAleatoriaComponente } from "Helpers";

export default {
  onHandlerHighLevel(state) {
    const json = localStorage.getItem(userKey);
    const userData = JSON.parse(json);

    state.highLevel = userData?.highlevel === 1;
  },
  onHandleSideBarToggle(state) {
    state.fullScreenToggle = AppFullscreen.isActive;
    state.sideBarToggle = !state.sideBarToggle;
  },
  onHandleFullScreenToggle(state) {
    state.fullScreenToggle = !state.fullScreenToggle;
  },
  onHandleSideBarToggleHide(state) {
    state.sideBarToggle = false;
  },
  onHandleKeyMaster(state) {
    state.keyMaster = chaveAleatoriaComponente();
  },
  onHandleDisableInventory(state) {
    state.disableInventory = true;
  },
};
