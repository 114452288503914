<template>
  <div class="sidebar" :class="sidebarSelectedFilter.class">
    <div class="transparent navigation">
      <q-list>
        <q-item>
          <app-logo></app-logo>
        </q-item>

        <q-item>
          <user-block></user-block>
        </q-item>

        <q-item>
          <q-input
            class="r7-filtro-pesquisa"
            ref="filterRef"
            filled
            v-model="filter"
            label="Pesquisa"
            placeholder="Qual item do Menu você procura?"
            place
            standout="bg-grey-1 text-grey-10"
          >
            <template v-slot:append>
              <q-icon
                v-if="filter !== ''"
                name="clear"
                class="cursor-pointer"
                @click="limparFiltro"
              />
            </template>
          </q-input>
        </q-item>

        <q-item>
          <q-tree
            :nodes="treeData"
            node-key="id"
            label-key="descricao"
            :filter="filter"
            accordion
            :duration="200"
          >
            <template v-slot:default-header="prop">
              <div class="row items-center" @click="chamarItem(prop.node)">
                <q-icon
                  small
                  v-if="prop.node.children.length > 0"
                  :name="prop.expanded ? 'mdi-folder-open' : 'mdi-folder'"
                  class="q-mr-sm"
                />
                <q-icon
                  small
                  v-else
                  :name="prop.node.icone ? `mdi-${prop.node.icone}` : ''"
                  class="q-mr-sm"
                />

                <div class="title-item-menu">
                  {{ prop.node.descricao }}
                  <q-badge
                    align="middle"
                    transparent
                    color="danger"
                    v-if="prop.node.status === 1"
                  >
                    v1.0</q-badge
                  >
                </div>
              </div>
            </template>
          </q-tree>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppLogo from "Components/AppLogo/AppLogo";
import api from "Api";
import { showError } from "@/notifications/notify";
import UserBlock from "./UserBlock";

export default {
  name: "Sidebar",
  data() {
    return {
      filter: null,
      user: this.$store.getters["auth/getUser"],
      treeData: [],
    };
  },
  components: {
    UserBlock,
    AppLogo,
  },
  computed: {
    ...mapGetters(["sidebarSelectedFilter"]),
  },
  methods: {
    obterMenu() {
      api
        .get("v1/menu")
        .then((res) => {
          if (res.data) {
            this.treeData = res.data;
          }
        })
        .catch((err) => showError(err));
    },

    chamarItem(node) {
      if (node.reportid) {
        this.$router.push({
          name: "powerbirelatorio",
          params: { id: node.reportid },
        });
        return;
      }
      if (!node || !node.routename) return;
      const path =
        node.status === 1
          ? {
              name: node.routename,
              query: { url: node.externalLink, t: new Date().getTime() },
            }
          : { name: node.routename };
      const currentRoute = this.$router.currentRoute._value.name;
      if (currentRoute === path.name && path.query) {
        this.$router.push({ query: path.query });
      } else {
        this.$router
          .push(path)
          .then((failure) => {
            this.$store.dispatch("admin/setKeyMaster");
          })
          .catch((error) => {
            if (error.name !== "NavigationDuplicated") {
              showError(error);
            }
          });
      }
    },

    recarregarSidebar() {
      this.$emit("recarregarSidebar");
    },

    limparFiltro() {
      this.filter = "";
      this.$refs.filterRef.focus();
    },
  },

  mounted() {
    if (this.user && this.user.usuario !== this.user.usuarioPrincipal) {
      this.$store.commit("changeSidebarFilterHandlerAlternativeUser");
    } else {
      this.$store.commit("changeSidebarFilterHandlerDefault");
    }
    this.obterMenu();
  },
};
</script>
