<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="my-card">
      <q-img :src="image">
        <div class="absolute-bottom text-h6">
          Sua sessão está sendo finalizada...
        </div>
      </q-img>
    </q-card>
  </q-dialog>
</template>

<script>
import { auth } from "Helpers/authentication";
import AppConfig from "Constants/AppConfig";
import { deleteCookie } from "Helpers";
export default {
  name: "Logout",
  data() {
    return {
      dialog: true,
      image: AppConfig.appLogo,
    };
  },

  methods: {
    logout() {
      setTimeout(() => {
        deleteCookie("Token");
        auth.logout();
        // this.$router.push({
        //   name: "home",
        // })
      }, 3000);
    },
  },

  mounted() {
    this.logout();
  },
};
</script>

<style lang="sass" scoped>
.my-card
  width: 100vw
  max-width: 50vw
</style>
