<template>
  <div>
    <div class="row align-items-center search-wrap">
      <div cols="12" md="12" lg="12" class="col align-items-center pt-0">
        <q-card customClasses="q-mt-md q-mb-xl">
          <div class="row">
            <div cols="12" md="12" lg="12" class="col pb-0 ml-4">
              <div class="d-flex search-field-wrap">
                <div class="w-100">
                  <q-input
                    class="q-ma-sm"
                    hide-details
                    clearable
                    :label="label"
                    v-model="searchValue"
                    @keypress.enter="pesquisar"
                    @clear="limpar"
                    autofocus
                  >
                  </q-input>
                </div>
                <div>
                  <q-btn
                    color="primary"
                    class="q-ma-sm ml-0 mr-2"
                    medium
                    @click="pesquisar"
                    >{{ $t("message.search") }}</q-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    label: String,
  },
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    pesquisar() {
      this.$emit("pesquisar", this.searchValue);
    },
    limpar() {
      this.$emit("limpar");
    },
  },
};
</script>
