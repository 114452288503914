<template>
  <div>
    <page-title-bar></page-title-bar>
    <q-card class="q-ma-md" style="max-width: 100%" v-if="show">
      <q-tabs
        icons-and-text
        flat
        v-model="tab"
        dense
        class="text-grey-darken-3 bg-grey-2"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
        style="height: 80px"
      >
        <q-tab name="tab-1">
          <q-icon class="material-icons q-mb-sm" size="md"
            >assignment_ind</q-icon
          >
          {{ $t("message.registrationData") }}
        </q-tab>
        <q-tab name="tab-2">
          <q-icon class="material-icons q-mb-sm" size="md">call</q-icon>
          {{ $t("message.contacts") }}
        </q-tab>
        <q-tab name="tab-3">
          <q-icon class="material-icons q-mb-sm" size="md">map</q-icon>
          {{ $t("message.addresses") }}
        </q-tab>
        <q-tab name="tab-4">
          <q-icon class="material-icons q-mb-sm" size="md"
            >view_timeline</q-icon
          >
          {{ $t("message.history") }}
        </q-tab>
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="tab-1">
          <ClienteDadosCadastro
            :idCliente="clienteStorage.codigoCliente"
            @abrirPesquisa="abrirPesquisa()"
            :key="componentKey1"
          />
        </q-tab-panel>
        <q-tab-panel name="tab-2">
          <ClienteContato :idCliente="clienteStorage.codigoCliente" />
        </q-tab-panel>
        <q-tab-panel name="tab-3">
          <ClienteEndereco :idCliente="clienteStorage.codigoCliente" />
        </q-tab-panel>
        <q-tab-panel name="tab-4">
          <ClienteHistorico :idCliente="clienteStorage.codigoCliente" />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>

    <q-dialog v-model="dialog" class="q-pa-md">
      <keep-alive>
        <cliente-lista
          :popUp="true"
          ref="listaCliente"
          @clienteSelecionado="fecharPesquisa()"
          class="r7-dialog-medium"
        >
        </cliente-lista>
      </keep-alive>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref } from "vue";
import ClienteDadosCadastro from "Components/Areas/Clientes/ClienteDadosCadastro";
import ClienteContato from "Components/Areas/Clientes/ClienteContato";
import ClienteEndereco from "Components/Areas/Clientes/ClienteEndereco";
import ClienteHistorico from "Components/Areas/Clientes/ClienteHistorico";
import ClienteLista from "Views/Clientes/ClienteLista";

export default {
  name: "ClientePerfil",
  components: {
    ClienteDadosCadastro,
    ClienteContato,
    ClienteEndereco,
    ClienteHistorico,
    ClienteLista,
  },
  data() {
    return {
      show: false,
      dialog: false,
      //tab: null,
      clienteStorage: {},
      componentKey1: 0,
      tab: ref("tab-1"),
    };
  },
  methods: {
    ...mapGetters("clientes", ["getDadosDoCliente"]),
    abrirPesquisa() {
      this.dialog = true;
    },
    fecharPesquisa() {
      this.dialog = false;
      this.montarPesquisa();
      this.componentKey1 += 1;
    },

    montarPesquisa() {
      this.clienteStorage = this.getDadosDoCliente();
      if (!this.clienteStorage) {
        this.dialog = true;
        return;
      }
      this.show = true;
    },
  },
  mounted() {
    this.montarPesquisa();
  },
};
</script>
