<template>
  <div class="q-pa-md titleBar">
    <div class="q-gutter-y-md column">
      <q-toolbar class="bg-grey-3 shadow-2 rounded-borders">
        <q-toolbar-title :shrink="true" class="text-capitalize mb-0">
          {{ $t(title) }}
        </q-toolbar-title>
        <q-space />
        <q-breadcrumbs class="pa-6 pr-4" v-if="breadcrumbItems.length > 0">
          <template v-slot:separator>
            <q-icon
              size="1.2em"
              class="gt-sm noPrint"
              name="arrow_forward"
              color="blue"
            />
          </template>
          <q-breadcrumbs-el
            v-for="breadcrumb in breadcrumbItems"
            :key="breadcrumb"
            :label="$t(breadcrumb)"
            class="gt-sm noPrint"
          />
          <q-separator vertical inset class="noPrint" />
          <q-btn
            @click.stop="logoutPowerBi()"
            flat
            round
            dense
            class="q-mx-sm"
            v-if="bi"
          >
            <q-tooltip
              anchor="bottom middle"
              self="center middle"
              :offset="[10, 10]"
              class="text-body2"
              >Sair do PowerBi</q-tooltip
            >
            <q-icon large class="material-icons text-yellow-9 sair-power-bi"
              >logout</q-icon
            ></q-btn
          >
          <q-btn
            flat
            round
            dense
            size="md"
            class="text-grey-9 noPrint"
            icon="print"
            :title="$t('message.goBack')"
            @click.stop="print()"
            v-else
          />
          <q-btn
            flat
            round
            dense
            size="md"
            class="text-grey-9 noPrint"
            icon="mdi-arrow-u-left-top"
            :title="$t('message.goBack')"
            @click.stop="$router.go(-1)"
          />
        </q-breadcrumbs>
      </q-toolbar>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    bi: Boolean,
    default: false,
  },

  data() {
    return {
      breadcrumbItems: this.$route.meta?.breadcrumb,
      title: this.$route.meta?.title,
    };
  },
  methods: {
    ...mapActions("common", [
      "triggerRemoveStyleAndClass",
      "restoreStyleAndClass",
    ]),
    async print() {
      this.triggerRemoveStyleAndClass();
      setTimeout(() => {
        window.print();
        this.restoreStyleAndClass();
      }, 100);
    },
    logoutPowerBi() {
      const windowSignout = window.open(
        "https://app.powerbi.com/Signout",
        "_blank"
      );
      setTimeout(() => {
        windowSignout.close();
        location.reload();
      }, 2000);
    },
  },
};
</script>
