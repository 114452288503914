<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      ref="myTable"
      :loading="loader"
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      hide-pagination
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
    >
      <template v-slot:body-cell-situacao="props">
        <q-td auto-width>
          <q-badge
            size="xs"
            :color="props.row.situacao == 'INATIVO' ? 'red' : 'green'"
          >
            {{ props.row.situacao }}
          </q-badge>
        </q-td>
      </template>
    </q-table>
  </div>
</template>
<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import { printTableMixin } from "Mixin/printTableMinx";

export default {
  name: "ListaFranqueadosComponent",
  mixins: [printTableMixin],
  data() {
    return {
      loader: true,
      segmento: this.$route.params.segmento,

      rows: [],
      columns: [
        {
          label: "Razão",
          field: "dsRazao",
          name: "dsRazao",
          align: "left",
          sortable: true,
        },
        {
          label: "Fantasia",
          field: "dsFranquia",
          name: "dsFranquia",
          align: "left",
          sortable: true,
        },
        {
          label: "Situação",
          field: "situacao",
          name: "situacao",
          align: "left",
          sortable: true,
        },
        {
          label: "Bloqueio",
          field: "bloqueio",
          name: "bloqueio",
          align: "left",
          sortable: true,
        },
        {
          label: "Cobra Royalties?",
          field: "cobraroyalties",
          name: "cobraroyalties",
          align: "left",
          sortable: true,
        },
        {
          label: "Cobra Fundo de Promoção?",
          field: "cobrafdopromo",
          name: "cobrafdopromo",
          align: "left",
          sortable: true,
        },
        {
          label: "% Royalties",
          field: (f) => (f.pcroyalties > 0 ? `${f.pcroyalties}%` : "-"),
          name: "pcroyalties",
          align: "left",
          sortable: true,
        },
        {
          label: "% Fdo. Promo",
          field: (f) => (f.pcfdopromo > 0 ? `${f.pcfdopromo}%` : "-"),
          name: "pcfdopromo",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.segmento = to.params.segmento;
      this.listarContas();
    },
  },

  methods: {
    listarContas() {
      this.loader = true;
      api
        .get(`/v1/faturamento/franquias/listafranqueados/${this.segmento}`)
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.listarContas();
  },
};
</script>
