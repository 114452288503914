<template>
  <div class="row q-mt-sm" v-if="complemento">
    <div class="col">
      <pre class="notranslate">
                  <code>
                    {{ complemento }}
                  </code>
                </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionarioComplementoComponent",
  props: {
    complemento: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.flex-end {
  justify-items: flex-end;
}

pre {
  padding-left: 10px;
  overflow: auto;
  font-size: 85% !important;
  background-color: #fafafa !important;
  border-radius: 6px;
  word-wrap: normal;
  word-break: normal;
  white-space: pre;
  width: 50%;
}

code {
  color: #000 !important;
}
</style>
