export default {
  setDadosUsuariosSGT(context, payload) {
    context.commit("onChangeDadosUsuariosSGT", payload);
  },
  setFiltroEmpresaID(context, payload) {
    context.commit("onChangeFiltroEmpresaID", payload);
  },
  setAtualizar(context) {
    context.commit("onChangeAtualizar");
  },
};
