export default {
  onChangeGrupoEconomico(state, payload) {
    state.grupoEconomico = payload.ativo ? payload.grupoEconomico : null;
  },
  onChangeRegraNegocio(state, payload) {
    state.regraNegocio = payload;
  },
  onChangeDadosDoCliente(state, payload) {
    state.dadosDoCliente = payload;
  },
};
