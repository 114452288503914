import PageNotFound from "Views/Session/PageNotFound";
import QuestionarioQAView from "Views/RH/QA/QuestionarioQAView";

const routes = [
  {
    path: "/pagenotfound",
    name: "pagenotfound",
    component: PageNotFound,
    props: true,
    meta: {
      title: "message.pageNotFound",
      breadcrumb: null,
    },
  },
  {
    path: "/qaquestionario",
    name: "questionarioqaview",
    component: QuestionarioQAView,
    props: true,
    meta: {
      requiresAuth: true,
      title: "message.quiz",
      breadcrumb: null,
      qa: true,
    },
  },
];

export default routes;
