<template>
  <div class="app-card-title" v-if="heading" :class="[customHeaderClasses]">
    <h3 :class="[customHeaderClasses]">{{ heading }}</h3>
    <template v-if="!withTabs">
      <div class="app-contextual-link" v-if="reloadable || closeable">
        <q-menu transition="scale-transition" origin="right top" bottom left>
          <template v-slot:activator="{ on }">
            <q-btn class="ma-0" icon v-on="on">
              <q-icon>more_vert</q-icon>
            </q-btn>
          </template>
          <q-list class="app-card-action">
            <q-item @click="$emit('onReload')" v-if="reloadable">
              <i class="zmdi zmdi-refresh success--text mr-2 fs-14"></i>
              <span>{{ $t("message.reload") }}</span>
            </q-item>
            <q-item @click="$emit('onClose')" v-if="closeable">
              <i class="zmdi zmdi-close mr-2 error--text fs-14"></i>
              <span>{{ $t("message.close") }}</span>
            </q-item>
          </q-list>
        </q-menu>
      </div>
    </template>
    <template v-else>
      <div class="app-contextual-link">
        <ul class="custom-tab-wrap list-inline">
          <template v-for="(tab, key) in tabs" :key="key">
            <li @click="onChangeTab(key)">
              <a
                href="javascript:void(0)"
                class="fs-12 fw-normal px-2 py-1 rounded mx-1"
                :class="[
                  {
                    'primary white--text': activeTab === key,
                  },
                ]"
                >{{ tab }}</a
              >
            </li>
          </template>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: [
    "heading",
    "closeable",
    "reloadable",
    "customHeaderClasses",
    "withTabs",
    "tabs",
    "onCollapse",
    "onReload",
    "onClose",
    "onChangeTabCallback",
  ],
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    onChangeTab(value) {
      this.activeTab = value;
      this.$emit("onChangeTabCallback", value);
    },
  },
};
</script>
