<template>
  <q-footer reveal elevated class="bg-grey-8 text-white">
    <CookieConsent @hasKey="onConsent($event)" v-if="!isConsent" />
    <q-toolbar v-else>
      <q-toolbar-title class="gt-sm">
        <span v-if="user?.nivelAcessoId === 1" class="fs-12"
          ><q-icon
            color="primary"
            name="mdi-help-circle"
            size="xs"
            dark
          ></q-icon
          >&nbsp;{{ path }}
        </span>
      </q-toolbar-title>

      <span class="fs-12"
        >{{ copyright }} - {{ $t("message.version") }} &nbsp;</span
      >
      <span class="fs-12 fw-bold">{{ this.tag }}</span>
    </q-toolbar>
  </q-footer>
</template>

<script>
import api from "Api";
import AppConfig from "Constants/AppConfig";
import { showError } from "@/notifications/notify";
import CookieConsent from "Components/Widgets/CookieConsent";

export default {
  name: "Footer",
  components: { CookieConsent },
  data() {
    return {
      copyright: AppConfig.copyrightText,
      tag: null,
      isConsent: false,
      user: this.$store.getters["auth/getUser"],
    };
  },
  computed: {
    path() {
      return this.$route.matched[1].components.default.__file;
    },
  },
  methods: {
    onConsent(e) {
      this.isConsent = e;
    },
    setSystemVersion() {
      api
        .get("/v1/github/obtertag")
        .then((res) => {
          this.tag = res.data;
        })
        .catch((err) => {
          showError(err);
        });
    },
  },
  mounted() {
    this.setSystemVersion();
  },
};
</script>
