<template>
  <div>
    <page-title-bar />
    <app-section-loader :status="loader" />

    <div class="q-pa-md">
      <q-card class="q-pa-md">
        <q-form ref="form" autofocus @submit="salvar">
          <div class="row no-wrap justify-evenly">
            <div class="col-3" cols="12" sm="12" md="4" lg="4">
              <h6 class="text-primary">
                {{ $t("message.dashboardInformations") }}:
              </h6>
              <br />
              <q-input
                class="q-mt-sm"
                ref="descricao"
                rounded
                outlined
                dense
                v-model="dashboardData.descricao"
                :label="$t('message.description')"
                required
                :rules="descriptionRules"
              />
              <q-input
                rounded
                outlined
                dense
                v-model="dashboardData.pbigroupid"
                :label="$t('message.groupId')"
                required
                :rules="pbigroupidRules"
                hint="Modelo: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                hide-hint
              />
              <q-input
                class="q-mt-md"
                rounded
                outlined
                dense
                v-model="dashboardData.pbidashboardid"
                :label="$t('message.dashboardId')"
                required
                :rules="pbidashboardid"
                hint="Modelo: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                hide-hint
              />

              <q-toggle
                v-model="dashboardData.status"
                color="blue"
                :label="definirLegenda()"
                checked-icon="check"
                unchecked-icon="clear"
                class="q-mt-md"
                :true-value="1"
                :false-value="0"
                :toggle-indeterminate="false"
              />
              <br />

              <q-btn
                color="success"
                class="q-mr-sm q-mt-md"
                type="submit"
                size="md"
                rounded
              >
                {{ $t("message.save") }}
              </q-btn>

              <q-btn
                color="danger"
                class="q-mt-sm q-mt-md"
                size="md"
                type="sair"
                @click.prevent="$router.go(-1)"
                rounded
              >
                {{ $t("message.close") }}
              </q-btn>
            </div>
          </div>
        </q-form>
      </q-card>
    </div>
  </div>
</template>

<script>
import api from "Api";
import { clearObject } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "DashboardsDetalhe",
  data() {
    return {
      loader: true,
      valid: true,
      dashboardData: {
        descricao: null,
        pbigroupid: null,
        pbidashboardid: null,
        status: 0,
      },

      id: this.$route.params.id,

      descriptionRules: [
        (v) => !!v || this.$t("message.descriptionRequired"),
        (v) =>
          (v && v.length <= 100) ||
          `${this.$t("message.description")}
           ${this.$t("message.mustbelessthan")} 100
           ${this.$t("message.characters")}`,
      ],
      pbigroupidRules: [(v) => !!v || this.$t("message.codeRequired")],
      pbidashboardid: [(v) => !!v || this.$t("message.codeRequired")],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterDashboard();
    },
  },
  methods: {
    salvar() {
      let message;
      let method;
      let url;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/dashboards/${this.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/dashboards/";
      }

      this.loader = true;
      const item = this.dashboardData;

      api[method](url, item)
        .then(() => {
          if (!this.id) {
            clearObject(this.dashboardData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.$refs.descricao.focus();
        });
    },
    obterDashboard() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/dashboards/${this.id}`)
        .then((res) => {
          this.dashboardData = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    definirLegenda(v) {
      return this.dashboardData.status == 1
        ? this.$t("message.active")
        : this.$t("message.inactive");
    },
  },
  mounted() {
    this.obterDashboard();
    this.$refs.descricao.focus();
  },
};
</script>
