<template>
  <div>
    <ModalCard :style="style" ref="modalHelpPowerBi" :title="documento.title">
      <template v-slot:body>
        <span class="text-h5">{{ documento.text }}</span>
        <img
          :src="getImagePath(`/static/gifs/${documento.gif}`)"
          class="my-image"
        />
      </template>
    </ModalCard>
  </div>
</template>

<script>
import ModalCard from "Components/Widgets/ModalCard";
import { isMobile } from "Helpers";

export default {
  name: "HelpPowerBi",
  components: { ModalCard },
  data() {
    return {
      getImagePath(path) {
        return process.env.BASE_URL + path;
      },
      style: {
        width: "100vw",
        maxWidth: isMobile() ? "100vw" : "60vw",
      },
      tpDocto: {
        formaCorreta: {
          gif: "Forma-Correta.gif",
          title: "Como acessar os relatórios em B.I.",
          text: "Siga os passos abaixo para acessar os relatórios, caso tenha alguma dúvida entre em contato com o ADM de Vendas!",
        },
        formaErrada: {
          gif: "Forma-Errada.gif",
          title: "Caso apareça a mensagem REQUEST ACCESS",
          text: "Caso tenha feito a seleção do seu próprio usuário ou outra conta que não seja a que foi definida pela equipe de T.I. do Grupo Rosset, será apresentada a imagem de REQUEST ACCESS, para isso proceda conforme abaixo:",
        },
      },
      documento: {
        gif: null,
        title: null,
        text: null,
      },
    };
  },
  methods: {
    abrirModal(tipoDocumento) {
      this.documento = this.tpDocto[tipoDocumento];
      this.$refs.modalHelpPowerBi.abrir();
    },
  },
};
</script>

<style scoped>
.my-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 70%;
  margin: auto;
}
</style>
