<template>
  <div>
    <page-title-bar />
    <app-section-loader :status="loader" />

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        dense
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        hide-pagination
        class="my-sticky-header-table"
        virtual-scroll
      >
        <template v-slot:top>
          <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:body-cell-actionsInfo="props">
          <q-td auto-width>
            <q-btn flat dense unelevated round small>
              <q-tooltip>{{ $t("message.accessLevelList") }}</q-tooltip>
              <q-icon
                color="blue"
                class="mr-2 material-icons"
                @click.stop="exibirLista(props.row.id)"
                size="xs"
              >
                search
              </q-icon>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-status="props">
          <q-td auto-width>
            <span>{{ props.row.status == 1 ? "Ativo" : "Inativo" }}</span>
          </q-td>
        </template>

        <template v-slot:body-cell-actionsEdit="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="onUpdate(props.row.id)"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>
        <template v-slot:body-cell-actionsDelete="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click="deletarDashboards(props.row.id)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-td>
        </template>
      </q-table>
      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        @onConfirm="deleteItem"
      ></delete-confirmation-dialog>

      <NivelAcessoDeCadaDashboard ref="componenteListaNivelAcesso" />
    </div>
  </div>
</template>

<script>
import api from "Api";
import NivelAcessoDeCadaDashboard from "Components/Areas/Admin/Dashboards/NivelAcessoDeCadaDashboard";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "DashboardsLista",
  components: {
    NivelAcessoDeCadaDashboard,
  },
  data() {
    return {
      loader: true,
      selectItemToDelete: {},
      rows: [],
      columns: [
        {
          label: "",
          field: "actionsInfo",
          name: "actionsInfo",
          align: "center",
        },
        {
          label: "ID",
          field: "id",
          name: "id",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.dashboardStatus"),
          field: "status",
          name: "status",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.groupId"),
          field: "pbigroupid",
          name: "pbigroupid",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.dashboardId"),
          field: "pbidashboardid",
          name: "pbidashboardid",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
        },
      ],
    };
  },
  methods: {
    listarDashboards() {
      api
        .get("v1/dashboards/")
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    onUpdate(id) {
      this.$router.push({
        name: "dashboardsdetalhe",
        params: { id },
      });
    },

    onAdd() {
      this.$router.push({
        name: "dashboardsdetalhe",
        params: { id: 0 },
      });
    },

    deletarDashboards(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectItemToDelete = id;
    },
    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();

      await api
        .delete(`v1/dashboards/${this.selectItemToDelete}`)
        .then(() => {
          showSuccess(this.$t("message.successDeletedRecord"));
        })
        .catch((err) => showError(err));
      this.listarDashboards();
    },

    exibirLista(id) {
      this.$refs.componenteListaNivelAcesso.abrirModal(id);
    },
  },
  created() {
    this.listarDashboards();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 700px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
