<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        dense
        hide-pagination
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
        virtual-scroll
      >
        <template v-slot:top>
          <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
            {{ $t("message.add") }}
          </q-btn>
        </template>
        <template v-slot:body-cell-actionsEdit="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="onUpdate(props.row.id)"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>
        <template v-slot:body-cell-actionsDelete="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click="onDelete(props.row.id)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-td>
        </template>
      </q-table>
      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        @onConfirm="deleteItem"
      ></delete-confirmation-dialog>
    </div>
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "RelatoriosPowerBiLista",
  data() {
    return {
      loader: true,
      selectItemToDelete: 0,
      rows: [],
      columns: [
        {
          label: "ID",
          field: "id",
          name: "id",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.reportName"),
          field: "nomerelatorio",
          name: "nomerelatorio",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          sortable: false,
          align: "left",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  methods: {
    retrieveList() {
      api
        .get("v1/powerbi")
        .then((response) => {
          this.loader = false;
          this.rows = response.data;
        })
        .catch((err) => showError(err));
    },
    onUpdate(id) {
      this.$router.push({
        name: "relatoriospowerbidetalhe",
        params: { id },
      });
    },
    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectItemToDelete = id;
    },

    onAdd() {
      this.$router.push({
        name: "relatoriospowerbidetalhe",
        params: { id: 0 },
      });
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      this.loader = true;
      await api
        .delete(`v1/powerbi/${this.selectItemToDelete}`)
        .then(() => {
          showSuccess(this.$t("message.successDeletedRecord"));
          this.reload = false;
        })
        .catch((err) => showError(err));
      this.retrieveList();
    },
  },
  created() {
    this.retrieveList();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
