<template>
  <q-input
    outlined
    dense
    :mask="mask"
    fill-mask="0"
    reverse-fill-mask
    :label="label"
    v-model="text"
    debounce="1500"
  >
    <template v-slot:prepend>
      <span class="text-caption q-mr-xs">{{ prependLabel }}</span>
      <q-separator inset vertical />
    </template>
    <template v-slot:append>
      <q-icon
        name="cancel"
        @click.stop.prevent="text = null"
        class="cursor-pointer"
        color="red-2"
      />
    </template>
  </q-input>
</template>

<script>
export default {
  name: "InputCalculadora",
  props: {
    label: {
      type: String,
      default: null,
    },
    prependLabel: {
      type: String,
      default: null,
    },
    mask: {
      type: String,
      default: "#.##",
    },
  },
  data() {
    return {
      text: null,
    };
  },
};
</script>
