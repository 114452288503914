<template>
  <q-table
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    hide-pagination
    class="q-mx-sm q-my-sm sticky-column-table"
    virtual-scroll
    dense
    row-key="name"
    :loading="loading"
    id="tableData"
    ref="tabelaDeFAturamento"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th :colspan="2" auto-width />
        <q-th
          v-for="(label, idx) in labelCabec"
          :class="['labelRosset', bgColors[idx % 2]]"
          :key="idx"
          :colspan="3"
          >{{ label }}</q-th
        >
      </q-tr>
      <q-tr :props="props">
        <q-td class="bgYellow sticky-column-1" auto-width />
        <q-td
          v-for="(col, idx) in props.cols"
          :key="idx"
          :class="[col.bgColor, idx == 0 ? 'sticky-column-2' : '']"
          class="labelRosset"
          auto-width
        >
          {{ col.label }}
        </q-td>
      </q-tr>
    </template>

    <template v-slot:body="props">
      <q-tr
        :props="props"
        :class="props.row.cdSegmento == 99 ? 'totalizador' : ''"
      >
        <q-td auto-width class="bgYellow sticky-column-1">
          <q-btn
            size="xs"
            color="accent"
            round
            dense
            @click="expandData(props.row)"
            :icon="props.row.expand ? 'remove' : 'add'"
            :loading="!lojasProntas"
            v-if="props.row.cdSegmento !== 99"
          />
        </q-td>

        <q-td
          v-for="(col, idx) in props.cols"
          :key="col.name"
          :class="[
            col.bgColor,
            `text-${col.align}`,
            idx == 0 ? 'sticky-column-2' : '',
          ]"
        >
          {{ col.value }}
        </q-td>
      </q-tr>

      <q-tr
        v-show="props.row.expand"
        v-for="(row, idx) in props.row.lojas"
        :key="idx"
        :props="props"
      >
        <q-td auto-width class="bgYellow sticky-column-1" />

        <q-td
          v-for="(col, idx) in props.cols"
          :key="idx"
          :class="[
            col.bgColor,
            `text-${col.align}`,
            idx == 0 ? 'sticky-column-2' : '',
          ]"
        >
          <span v-if="idx == 0">
            {{ row.dsFranquia }}
          </span>
          <span v-else>
            {{ formatValue(row[col.field]) }}
          </span>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>
<script>
export default {
  name: "Tabela",
  props: ["labelCabec", "loading", "lojasProntas"],
  data() {
    return {
      bgColors: ["bgGreenLight", "bgGreen"],
    };
  },

  watch: {
    loading() {
      setTimeout(() => {
        this.calculateLeftForStickyColumn2();
      }, 100);
    },
  },

  methods: {
    formatValue(v) {
      return this.$filters.formatWithoutDecimal(v);
    },

    expandData(row) {
      row.expand = !row.expand;
      setTimeout(() => {
        this.calculateLeftForStickyColumn2();
      }, 100);
    },

    calculateLeftForStickyColumn2() {
      const columnWidth1 =
        document.querySelector(".sticky-column-1").offsetWidth;

      const stickyColumn2 = document.querySelectorAll(".sticky-column-2");
      if (stickyColumn2.length > 0) {
        stickyColumn2.forEach(
          (node) => (node.style.left = `${parseInt(columnWidth1)}px`)
        );
      }
    },
  },

  mounted() {
    this.calculateLeftForStickyColumn2();
    window.addEventListener("resize", this.calculateLeftForStickyColumn2);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateLeftForStickyColumn2);
  },
};
</script>
<style lang="sass" scoped>
.sticky-column-table
  max-width: 100%

  thead tr:first-child th:first-child
    background-color: #ffffcc !important

  th:first-child
    position: sticky
    left: 0

.sticky-column-1
    left: 0
    position: sticky !important
    z-index: 1

.sticky-column-2
    position: sticky !important
    z-index: 2

.labelRosset
    color: #0066cc
    font-weight: bold
    text-align: center
</style>
