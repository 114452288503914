<template>
  <q-table
    grid
    :loading="loader"
    :rows="rows"
    :columns="columns"
    dense
    hide-pagination
    table-header-class="table-header"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
  >
    <template v-slot:top>
      <div class="q-toolbar row no-wrap items-center">
        <div class="doc-card-title">{{ $t("message.answers") }}</div>
      </div>

      <q-btn
        dark
        size="md"
        class="q-mb-xm"
        color="primary"
        @click="onAdd"
        v-if="pergunta"
      >
        {{ $t("message.add") }}
      </q-btn>

      <div class="q-field--with-bottom">
        <div class="q-field__control relative-position" tabindex="-1"></div>
      </div>
    </template>

    <template v-slot:item="props">
      <div
        class="q-pa-xs col-xs-12 col-sm-6 col-md-4 col-lg-3 grid-style-transition"
        :style="props.selected ? 'transform: scale(0.95);' : ''"
      >
        <q-card :class="props.selected ? 'bg-grey-2' : ''">
          <q-card-section>
            <q-icon
              size="xs"
              :color="props.row.iscorreto === 1 ? 'teal' : 'red'"
              class="material-icons"
            >
              {{ props.row.iscorreto === 1 ? "check" : "close" }}
            </q-icon>
            <span>Resposta</span>
          </q-card-section>
          <q-separator />
          <q-list
            dense
            :class="props.row.iscorreto === 1 ? 'bg-green-3' : 'bg-red-3'"
          >
            <q-item>
              <q-item-section>
                <q-item-label>{{ props.row.resposta }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
          <q-card-actions align="right">
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="onUpdate(props.row.idResposta)"
              >
                edit
              </q-icon>
            </q-btn>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click="onDelete(props.row.idResposta)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-card-actions>
        </q-card>
      </div>
    </template>
  </q-table>
  <delete-confirmation-dialog
    ref="deleteConfirmationDialog"
    @onConfirm="deleteItem"
  ></delete-confirmation-dialog>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import { mapGetters } from "vuex";

export default {
  name: "RespostasQAListComponent",
  data() {
    return {
      loader: false,
      selectedItemToDelete: null,
      rows: [],
      pergunta: this.$store.state.rh.pergunta,
      columns: [
        {
          label: "",
          field: "idResposta",
          name: "idResposta",
          align: "left",
          sortable: true,
        },
        {
          label: "Resposta",
          field: "resposta",
          name: "resposta",
          align: "left",
          sortable: true,
        },
        {
          label: "Correto?",
          field: (f) => (f["iscorreto"] === 1 ? "Sim" : "Não"),

          name: "iscorreto",
          align: "center",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("rh", ["getPergunta"]),
  },
  watch: {
    getPergunta(v) {
      if (v) {
        this.pergunta = v;
        this.listarRespostasDaPergunta();
      }
    },
  },
  methods: {
    listarRespostasDaPergunta() {
      this.loader = true;
      api
        .get(`/v1/rh/qa/respostas/pergunta/${this.pergunta.idPergunta}`)
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    onAdd() {
      this.$router.push({
        name: "respostasqadetailview",
        params: { id: 0 },
      });
    },

    onUpdate(id) {
      this.$router.push({
        name: "respostasqadetailview",
        params: { id },
      });
    },

    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectedItemToDelete = id;
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      await api
        .delete(`v1/rh/qa/resposta/${this.selectedItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarRespostasDaPergunta());
    },
  },
  mounted() {
    if (this.pergunta) {
      this.listarRespostasDaPergunta();
    }
  },
};
</script>

<style scoped>
.q-table__grid-content {
  display: flex !important;
  justify-content: space-between;
}
/* .my-sticky-header-table{
  height: 700px;
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
} */
</style>
