<template>
  <div class="q-pa-md">
    <div class="row justify-center">
      <q-img :src="imagem" class="imagem" />
    </div>
    <div class="row justify-center q-pa-sm q-my-lg">
      <div class="col">
        <q-banner class="text-center bg-primary text-white" rounded>
          <h3>GERENCIAL GRUPO ROSSET</h3>
        </q-banner>
      </div>
    </div>
    <div class="row justify-center q-pa-xs">
      <q-responsive
        :ratio="1.9776"
        class="col"
        style="width: 880px; max-width: 100%; height: 446px"
      >
        <q-carousel
          animated
          v-model="slide"
          navigation
          infinite
          height="15rem"
          :autoplay="autoplay"
          transition-prev="slide-right"
          transition-next="slide-left"
          @mouseenter="autoplay = false"
          @mouseleave="autoplay = true"
          class="carousel"
        >
          <q-carousel-slide
            style="overflow: hidden"
            v-for="(foto, index) in fotos"
            :key="index"
            :name="index"
            class="column no-wrap"
          >
            <div
              class="row fit justify-center no-wrap q-col-gutter q-gutter-xs items-center"
            >
              <q-img class="rounded-borders col-6" width="57rem" :src="foto" />
            </div>
          </q-carousel-slide>
        </q-carousel>
      </q-responsive>
    </div>
  </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
import { userKey } from "@/global";
export default {
  name: "Home",
  data() {
    return {
      imagem: AppConfig.appLogo2,
      fotos: AppConfig.fotosFabrica,
      slide: 1,
      autoplay: true,
    };
  },
  methods: {},
  mounted() {
    const json = localStorage.getItem(userKey);
    const userData = JSON.parse(json);
    if (userData.nivelAcessoId == 99) {
      this.$q.notify({
        progress: true,
        timeout: 10000,
        message:
          "ATENÇÃO: Esse é o seu primeiro acesso, deverá solicitar ao T.I. a inclusão do seu usuário em um grupo para ter acesso ao menu!",
        color: "danger",
        multiLine: true,
        avatar: process.env.BASE_URL + "/static/gifs/warning.gif",
        actions: [
          {
            label: "Dismiss",
            color: "yellow",
            handler: () => {
              /* ... */
            },
          },
        ],
      });
    }
  },
};
</script>

<style scoped>
.carousel {
  border-radius: 5px;
  max-height: 20rem;
  min-height: 1rem;
}
.imagem {
  width: 30%;
}
</style>
