<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <ConsoleOutputComponent />
  </div>
</template>

<script>
import ConsoleOutputComponent from "Areas/Admin/InventarioTI/ConsoleOutputComponent.vue";

export default {
  name: "ConsoleOutputView",
  components: { ConsoleOutputComponent },
};
</script>
