<template>
  <MateriaPrimaMobile
    @pesquisarTecido="pesquisarTecido"
    @pesquisarRenda="pesquisarRenda"
    :tecido="tecido"
    :renda="renda"
    v-if="isMobile"
  />
  <MateriaPrimaDesktop
    @pesquisarTecido="pesquisarTecido"
    @pesquisarRenda="pesquisarRenda"
    :tecido="tecido"
    :renda="renda"
    v-else
  />
  <ModalListaReferencias
    ref="pesquisarReferencia"
    @selecionado="selecionado($event)"
  />
</template>

<script>
import MateriaPrimaDesktop from "./MateriaPrimaDesktop";
import MateriaPrimaMobile from "./MateriaPrimaMobile";
import ModalListaReferencias from "Components/Areas/Produtos/Referencias/ModalListaReferencias";
import { isMobile } from "Helpers";

export default {
  name: "MateriaPrima",
  components: {
    MateriaPrimaDesktop,
    MateriaPrimaMobile,
    ModalListaReferencias,
  },
  data() {
    return {
      tipoPesquisa: null,
      tecido: null,
      renda: null,
    };
  },
  computed: {
    isMobile() {
      return isMobile();
    },
  },
  methods: {
    selecionado(referencia) {
      this[this.tipoPesquisa] = referencia;
    },
    pesquisarTecido() {
      this.tipoPesquisa = "tecido";
      this.$refs.pesquisarReferencia.abrirModal({ tipo: [1, 2] });
    },
    pesquisarRenda() {
      this.tipoPesquisa = "renda";
      this.$refs.pesquisarReferencia.abrirModal({ tipo: 3 });
    },
  },
};
</script>
