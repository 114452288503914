<template>
  <LoginTemplate
    @inputUsername="username = $event"
    @inputPassword="password = $event"
    @submit="submit()"
  >
    <template v-slot:botao>
      <q-btn style="min-width: 384px" @click="submit" block color="primary"
        >LOGIN</q-btn
      >
    </template>
    <template v-slot:languages>
      <div
        class="col-auto flex-center q-mx-md q-px-xs q-mt-md"
        v-for="language in languages"
        :key="language.name"
      >
        <q-btn flat small dense>
          <img
            :src="getImagePath(`/static/flag-icons/${language.icon}.png`)"
            @click="changeLanguage(language)"
            class="q-mx-md q-mx-lg"
          />
        </q-btn>
      </div>
    </template>
  </LoginTemplate>
</template>

<script>
import { mapGetters } from "vuex";
import LoginTemplate from "./LoginTemplate.vue";
import { auth } from "Helpers/authentication";

export default {
  name: "Login",
  components: { LoginTemplate },
  data() {
    return {
      username: null,
      password: null,
      getImagePath(path) {
        return process.env.BASE_URL + path;
      },
    };
  },
  computed: {
    ...mapGetters(["languages"]),
  },

  methods: {
    async submit() {
      const redirect = this.$route.query.redirect;
      const user = {
        user: this.username,
        password: this.password,
        redirect,
      };
      await auth.login(user);
    },

    changeLanguage(language) {
      this.$i18n.locale = language.locale;
      this.$store.dispatch("changeLanguage", language);
    },
  },
};
</script>
