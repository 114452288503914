<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      ref="myTable"
      :loading="loader"
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      hide-pagination
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
    >
      <template v-slot:top-left>
        <q-btn
          class="q-mb-xm noPrint"
          color="red darken-2"
          @click="onFinish"
          :disabled="protocolos.length == 0"
        >
          {{ $t("message.finish") }}
        </q-btn>
      </template>

      <template v-slot:body-cell-actionsFinish="props">
        <q-td auto-width>
          <q-checkbox
            v-model="protocolos"
            :val="props.row.protocolo"
            class="noPrint"
          />
        </q-td>
      </template>
    </q-table>
  </div>
  <delete-confirmation-dialog
    ref="finishConfirmationDialog"
    heading="Confirmar finalização?"
    message="Tem certeza de que deseja finalizar esse(s) protocolo(s)?"
    @onConfirm="confirmedFinish"
  ></delete-confirmation-dialog>
</template>
<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import { printTableMixin } from "Mixin/printTableMinx";

export default {
  name: "OffPremiumListaComponent",
  mixins: [printTableMixin],
  data() {
    return {
      loader: true,
      protocolos: [],
      rows: [],
      columns: [
        {
          label: "",
          field: "actionsFinish",
          name: "actionsFinish",
          align: "center",
        },
        {
          label: "Protocolo",
          field: "protocolo",
          name: "protocolo",
          align: "right",
          sortable: true,
        },
        {
          label: "Pedido",
          field: "pedido",
          name: "pedido",
          align: "left",
          sortable: true,
        },
        {
          label: "Nota",
          field: "nota",
          name: "nota",
          align: "right",
          sortable: true,
        },
        {
          label: "Cliente",
          field: "nomecliente",
          name: "nomecliente",
          align: "left",
          sortable: true,
        },
        {
          label: "Email",
          field: "email",
          name: "email",
          align: "left",
          sortable: true,
        },
        {
          label: "Dt. Protocolo",
          field: "dtcriacao",
          name: "dtcriacao",
          align: "left",
          sortable: true,
        },
        {
          label: "Valor",
          field: "valor",
          format: (val) => this.$filters.formatDecimal(val),
          name: "valor",
          align: "right",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    listarProtocolos() {
      this.loader = true;
      api
        .get("/v1/valisere/offpremium")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onFinish() {
      this.$refs.finishConfirmationDialog.open();
    },
    confirmedFinish() {
      this.loader = true;
      this.$refs.finishConfirmationDialog.close();

      api
        .post("/v1/valisere/offpremium", { protocolos: this.protocolos })
        .then(() => {
          showSuccess("Protocolo(s) encerrado(s) com sucesso");
          this.listarProtocolos();
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.protocolos = [];
          this.loader = false;
        });
    },
  },
  mounted() {
    this.listarProtocolos();
  },
};
</script>
