export default {
  getDadosUsuariosSGT: (state) => {
    return state.dadosUsuariosSGT;
  },
  getFiltroEmpresaID: (state) => {
    return state.filtroEmpresaID;
  },
  getAtualizar: (state) => {
    return state.atualizar;
  },
};
