<template>
  <SelectWithFilter
    v-if="listaMeses.length > 0"
    :id="mesId"
    :list="listaMeses"
    :required="required"
    :disabled="disabled"
    :prependIcon="prependIcon"
    :label="$t('message.chooseMonth')"
    :rounded="false"
    :filled="true"
    :dense="false"
    @selected="itemSelected($event)"
    :rules="mesesRules"
  />
</template>

<script>
import SelectWithFilter from "Components/Widgets/SelectWithFilter";
import { arrayMonths } from "Helpers";

export default {
  name: "MonthSelector",
  components: { SelectWithFilter },
  emits: ["selected"],

  props: {
    mesId: Number,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      listaMeses: [],
      mesesRules: [
        (v) => v !== null || !this.required || this.$t("message.monthRequired"),
      ],
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
  },
  mounted() {
    this.listaMeses = arrayMonths.months;
  },
};
</script>
