<template>
  <q-list bordered class="rounded-borders">
    <template v-for="(item, index) in documentsList" :key="index">
      <LgpdLista
        :icon="item.icon"
        :description="item.description"
        :pdf="item.pdf"
      />
      <q-separator v-if="index + 1 !== documentsList.length" spaced />
    </template>
  </q-list>
</template>

<script>
import LgpdLista from "./LgpdLista";
export default {
  name: "LgpdListaDocumentos",
  components: { LgpdLista },
  data() {
    return {
      documentsList: [
        {
          description: "Categoria ANEEL",
          pdf: "anell.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Compliance",
          pdf: "compliance.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Contabilidade",
          pdf: "contabilidade.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Contratos",
          pdf: "contratos.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Crédito",
          pdf: "crédito.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Documentos Corporativos",
          pdf: "documentos corporativos.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Previdenciário",
          pdf: "previdenciário.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Jurídico",
          pdf: "jurídico.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Regulatório",
          pdf: "regulatório.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Saúde",
          pdf: "saúde.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Seguros",
          pdf: "seguros.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Trabalhista",
          pdf: "trabalhista.pdf",
          icon: "mdi-target",
        },
        {
          description: "Categoria Outros",
          pdf: "outros.pdf",
          icon: "mdi-target",
        },
      ],
    };
  },
  methods: {
    openDocument(document) {},
  },
};
</script>
