<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        dense
        hide-pagination
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
        virtual-scroll
      >
        <template v-slot:top-right v-if="showFilter">
          <q-input
            borderless
            dense
            debounce="300"
            v-model="filter"
            :placeholder="$t('message.search')"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>

        <template v-slot:top v-if="exibir">
          <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:body-cell-actionsEdit="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="onUpdate(props.row.id)"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>
        <template v-slot:body-cell-actionsDelete="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click="deletarMensagem(props.row.id)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-td>
        </template>
      </q-table>
      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        @onConfirm="deleteItem"
      ></delete-confirmation-dialog>
    </div>
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "MensagemLista",
  props: { exibir: { type: Boolean, default: true } },
  data() {
    return {
      loader: true,
      showFilter: true,
      filter: null,
      selectItemToDelete: {},
      rows: [],
      columns: [
        {
          label: "Id",
          field: "id",
          name: "id",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.title"),
          field: "titulo",
          name: "titulo",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.category"),
          field: "categoriaDesc",
          name: "categoriaDesc",
          align: "left",
          sortable: true,
        },

        {
          label: this.$t("message.route"),
          field: "rota",
          name: "rota",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          sortable: false,
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  methods: {
    listarMensagens() {
      api
        .get("v1/mensagens/listarmensagensheader")
        .then((response) => {
          this.loader = false;
          this.rows = response.data;
        })
        .catch((err) => showError(err));
    },

    onAdd() {
      this.$router.push({ name: "mensagemdetalhe", params: { id: 0 } });
    },

    onUpdate(id) {
      this.$router.push({ name: "mensagemdetalhe", params: { id } });
    },

    deletarMensagem(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectItemToDelete = id;
    },
    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();

      await api
        .delete(`v1/mensagens/excluirmensagemheader/${this.selectItemToDelete}`)
        .then(() => {
          showSuccess(this.$t("message.successDeletedRecord"));
        })
        .catch((err) => showError(err));

      this.listarMensagens();
    },
  },
  created() {
    this.listarMensagens();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
