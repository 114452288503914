<template>
  <app-section-loader :status="loader" />

  <q-card class="q-pa-md">
    <q-form ref="form" autofocus @submit="onSave">
      <div class="row no-wrap justify-evenly">
        <div class="col-4" cols="12" sm="12" md="4" lg="4">
          <h6 class="text-primary">
            {{ $t("message.questionInformations") }}:
          </h6>
          <br />

          <TemaQASelectionComponent
            :temaId="perguntasData.idTema"
            @selected="selectedTema($event)"
            :required="true"
            :rounded="true"
            :filled="false"
          />

          <q-input
            class="q-mt-sm"
            ref="pergunta"
            rounded
            outlined
            dense
            autogrow
            v-model="perguntasData.pergunta"
            :label="$t('message.question')"
            required
            :rules="questionRules"
          />

          <q-input
            class="q-mt-sm"
            ref="complemento"
            rounded
            outlined
            dense
            autogrow
            v-model="perguntasData.complemento"
            :label="$t('message.complement')"
          />

          <div class="row q-mb-sm">
            <div class="col">
              <q-toggle
                v-model="perguntasData.multiplaEscolha"
                color="blue"
                :label="$t('message.multipleChoice')"
                checked-icon="check"
                unchecked-icon="clear"
              ></q-toggle>
            </div>
          </div>

          <br />
          <div class="text-center">
            <q-btn
              color="success"
              class="q-mr-sm q-mt-md"
              type="submit"
              size="md"
              rounded
            >
              {{ $t("message.save") }}
            </q-btn>

            <q-btn
              color="danger"
              class="q-mt-sm q-mt-md"
              size="md"
              type="sair"
              @click.prevent="$router.go(-1)"
              rounded
            >
              {{ $t("message.close") }}
            </q-btn>
          </div>
        </div>
      </div>
    </q-form>
  </q-card>
</template>

<script>
import api from "Api";
import { clearObject } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";
import TemaQASelectionComponent from "Components/Areas/RH/QA/Selecao/TemaQASelectionComponent";

export default {
  name: "PerguntasQADetailComponent",
  components: { TemaQASelectionComponent },
  data() {
    return {
      loader: true,
      valid: true,
      perguntasData: {
        idPergunta: null,
        pergunta: null,
        complemento: null,
        multiplaEscolha: true,
        idTema: null,
      },

      id: this.$route.params.id,

      questionRules: [(v) => !!v || this.$t("message.questionRequired")],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterPergunta();
    },
  },
  methods: {
    onSave() {
      let message;
      let method;
      let url;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/rh/qa/pergunta/${this.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/rh/qa/pergunta/";
      }

      this.loader = true;
      const item = this.perguntasData;

      api[method](url, item)
        .then(() => {
          if (!this.id) {
            clearObject(this.perguntasData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.$refs.pergunta.focus();
        });
    },
    obterPergunta() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/rh/qa/pergunta/${this.id}`)
        .then((res) => {
          if (res.data) {
            this.perguntasData = res.data;
            this.perguntasData.multiplaEscolha = res.data.multiplaEscolha == 1;
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    selectedTema(selected) {
      this.perguntasData.idTema = selected.id;
    },
  },
  mounted() {
    this.obterPergunta();
    this.$refs.pergunta.focus();
  },
};
</script>
