<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>
    <div class="q-px-md">
      <LicencasUsuariosSGT />
    </div>

    <div class="q-px-md">
      <q-card>
        <TabsUsuariosSGT />
      </q-card>
    </div>
  </div>
</template>

<script>
import LicencasUsuariosSGT from "Components/Areas/TI/LicencasUsuariosSGT";
import TabsUsuariosSGT from "Components/Areas/TI/TabsUsuariosSGT";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ConsultaAcessoUsuariosSGTLista",
  components: {
    LicencasUsuariosSGT,
    TabsUsuariosSGT,
  },

  data() {
    return {
      user: this.$store.getters["auth/getUser"],
      loader: true,
    };
  },
  computed: {
    ...mapGetters("common", ["getLoader"]),
  },

  watch: {
    getLoader(value) {
      this.loader = value;
    },
  },

  methods: {
    ...mapActions("ti", ["setFiltroEmpresaID"]),
  },
  mounted() {
    this.setFiltroEmpresaID(this.user.idCompany);
  },
};
</script>
