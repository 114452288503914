<template>
  <q-btn label="menu" size="lg" color="primary">
    <q-menu>
      <q-list style="min-width: 100px">
        <q-item
          clickable
          v-close-popup
          @click.stop="onSelectedItem('novo')"
          :disable="disable"
        >
          <q-item-section>{{ $t("message.newGoal") }}</q-item-section>
        </q-item>
        <!-- <q-item
          clickable
          v-close-popup
          @click.stop="selecionarItem('edit')"
          :disable="disable"
        >
          <q-item-section>{{ $t("message.editGoal") }}</q-item-section>
        </q-item>
        <q-item
          clickable
          v-close-popup
          @click.stop="selecionarItem('revise')"
          :disable="disable"
        >
          <q-item-section>{{ $t("message.reviseGoal") }}</q-item-section>
        </q-item> -->

        <q-item
          clickable
          v-close-popup
          @click.stop="selecionarItem('delete')"
          :disable="disable"
        >
          <q-item-section>{{ $t("message.deleteGoal") }}</q-item-section>
        </q-item>

        <q-separator />

        <q-item
          clickable
          v-close-popup
          @click.stop="gravarDados"
          :disable="desabilitarGravar"
        >
          <q-item-section>{{ $t("message.saveData") }}</q-item-section>
        </q-item>

        <!-- :disable="desabilitarGravar" -->
        <q-item
          clickable
          v-close-popup
          @click.stop="redistribuirMeta"
          :disable="desabilitarGravar"
        >
          <q-item-section>{{ $t("message.redistributeGoal") }}</q-item-section>
        </q-item>

        <q-separator />

        <q-item
          clickable
          v-close-popup
          @click.stop="subirXlsx()"
          :disable="disable"
        >
          <q-item-section>{{ $t("message.uploadGoal") }}</q-item-section>
        </q-item>

        <q-item clickable v-close-popup :disable="disable">
          <a download="modeloMetas.xlsx" :href="linkDownload">
            <q-item-section>{{ $t("message.downloadModel") }}</q-item-section>
          </a>
        </q-item>

        <q-separator />

        <q-item clickable v-close-popup @click.stop="sairMenu()">
          <q-item-section>{{ $t("message.exit") }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>

  <confirmation-dialog ref="confirmationDialog" @onConfirm="sair">
  </confirmation-dialog>

  <SeletorMeta ref="modalFormSeletorMeta" />

  <SubirMetaExcel ref="modalFormSubirMetaExcel" />

  <RedistribuirMeta
    ref="modalFormRedistribuirMeta"
    @saveData="dadosRedistribuicao = $event"
  />
</template>

<script>
import { Loading, QSpinnerGears } from "quasar";
import SeletorMeta from "Components/Areas/Comercial/Metas/SeletorMeta";
import SubirMetaExcel from "Components/Areas/Comercial/Metas/SubirMetaExcel";
import RedistribuirMeta from "Components/Areas/Comercial/Metas/RedistribuirMeta";
import api from "Api";
import { mapActions, mapGetters } from "vuex";
import { showSuccess, showError } from "@/notifications/notify";

export default {
  name: "MenuPrincipalMetas",
  components: { SeletorMeta, SubirMetaExcel, RedistribuirMeta },
  data() {
    return {
      disable: this.$store.state.metas.desabilitarMenu,
      linkDownload: null,
      validaOk: true,
      desabilitarGravar: true,
      dadosRedistribuicao: null,
    };
  },
  computed: {
    ...mapGetters("metas", ["getDesabilitarMenu", "getExibir"]),
  },
  watch: {
    getDesabilitarMenu(v) {
      this.disable = v;
    },
    getExibir(v) {
      if (v == true) {
        this.desabilitarGravar = false;
      } else {
        this.desabilitarGravar = true;
      }
    },
  },

  methods: {
    ...mapActions("metas", [
      "setNullMetas",
      "setAcao",
      "setExibir",
      "setDesabilitarMenu",
    ]),
    onSelectedItem(acao) {
      if (acao == "novo") {
        this.setExibir(true);
        this.setDesabilitarMenu(true);
      }
      this.setAcao(acao);
    },

    selecionarItem(acao) {
      const tituloModal = this.$t("message." + acao + "Goal");
      const textoModal = this.$t("message.selectGoalTo" + acao);
      const tituloBotao = this.$t("message." + acao);
      let acaoModal = null;
      switch (acao) {
        case "edit":
          acaoModal = "editar";
          break;
        case "revise":
          acaoModal = "revisar";
          break;
        case "delete":
          acaoModal = "deletar";
          break;

        default:
          break;
      }
      this.onSelectedItem(acaoModal);

      this.infoModal = {
        tituloModal,
        textoModal,
        tituloBotao,
        acaoModal,
      };
      this.$refs.modalFormSeletorMeta.aoAbrir(this.infoModal);
    },

    redistribuirMeta() {
      this.$refs.modalFormRedistribuirMeta.aoAbrir();
    },

    subirXlsx() {
      this.$refs.modalFormSubirMetaExcel.aoAbrir();
    },
    sairMenu() {
      this.$refs.confirmationDialog.open();
    },
    sair() {
      this.$refs.confirmationDialog.close();
      this.setNullMetas();
    },
    downloadModelo() {
      let type =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let base64;

      api
        .get("v1/comercial/metas/modelo")
        .then((res) => {
          base64 = res.data.modelo;
          this.linkDownload = type + base64;
        })
        .catch((err) => showError(err));
    },
    gravarDados() {
      Loading.show({
        message: this.$t("message.savingData"),
        spinner: QSpinnerGears,
      });
      const stateMetas = this.$store.state.metas;

      if (!stateMetas || stateMetas.meta.length == 0) {
        showError(this.$t("message.noDataFound"));
        return;
      }

      const data = {
        ano: stateMetas.cabecalho.ano,
        descricao: stateMetas.cabecalho.descricao,
        acao: stateMetas.acao,
        metas: null,
        redistribuirMeta: this.dadosRedistribuicao,
      };

      this.validaOk = true;
      data.metas = stateMetas.meta.map((e) => {
        if (e.registroInvalido) {
          this.validaOk = false;
        }
        return {
          grupo: e.grupoId,
          mes: e.mesId,
          representante: e.representanteId,
          valor: e.valor,
          quilos: e.quilos,
        };
      });

      if (!this.validaOk) {
        showError(this.$t("message.invalidDataForSave"));
        Loading.hide();
        return;
      }

      api
        .post("v1/comercial/metas", data)
        .then(() => {
          showSuccess(this.$t("message.successAddedRecord"));
          this.setNullMetas();
        })
        .catch((err) => showError(err))
        .finally(() => Loading.hide());
    },
  },
  mounted() {
    this.downloadModelo();
  },
};
</script>

<style scoped>
a {
  color: black !important;
}
</style>
