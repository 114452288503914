<template>
  <q-table
    :loading="loader"
    :rows="rows"
    :columns="columns"
    dense
    hide-pagination
    table-header-class="table-header"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
  >
    <template v-slot:top>
      <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
        {{ $t("message.add") }}
      </q-btn>
    </template>

    <template v-slot:body-cell-actionsPermissions="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="blue darken-2"
            class="material-icons"
            @click="editarPermissoes(props.row)"
          >
            search
          </q-icon>
        </q-btn>
      </q-td>
    </template>

    <template v-slot:body-cell-actionsEdit="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="blue darken-2"
            class="material-icons"
            @click="onUpdate(props.row.id)"
          >
            edit
          </q-icon>
        </q-btn>
      </q-td>
    </template>

    <template v-slot:body-cell-actionsDelete="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="red darken-2"
            class="material-icons"
            @click="onDelete(props.row.id)"
          >
            delete
          </q-icon>
        </q-btn>
      </q-td>
    </template>
  </q-table>
  <FormularioCadastroDocumentos
    ref="modalFormCadastroDocumentos"
    @atualizarTabela="atualizarRegistros($event)"
  />

  <TipoDeAcessoDocumentos ref="modalTipoDeAcessoDocumentos" />

  <delete-confirmation-dialog
    ref="deleteConfirmationDialog"
    @onConfirm="deleteItem"
  ></delete-confirmation-dialog>
</template>

<script>
import FormularioCadastroDocumentos from "Components/Areas/Repositorio/FormularioCadastroDocumentos";
import TipoDeAcessoDocumentos from "Components/Areas/Repositorio/TipoDeAcessoDocumentos";

import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "ListaDocumentosRepositorio",
  components: { FormularioCadastroDocumentos, TipoDeAcessoDocumentos },
  data() {
    return {
      loader: true,
      selectedItemToDelete: null,
      rows: [],
      columns: [
        {
          label: "",
          field: "actionsPermissions",
          name: "actionsPermissions",
          sortable: false,
          align: "center",
        },
        {
          label: "ID",
          field: "id",
          name: "id",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.fileName"),
          field: "nomeArquivo",
          name: "nomeArquivo",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.modificationDate"),
          field: "alteradoEm",
          name: "alteradoEm",
          align: "center",
          sortable: true,
        },
        {
          label: this.$t("message.modificatedBy"),
          field: "alteradoPor",
          name: "alteradoPor",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          sortable: false,
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  methods: {
    listarDocumentos() {
      this.loader = true;
      api
        .get("v1/repositorios/documentos")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    atualizarRegistros() {
      this.listarDocumentos();
    },
    onAdd() {
      this.$refs.modalFormCadastroDocumentos.aoAbrir();
    },

    editarPermissoes(documento) {
      this.$refs.modalTipoDeAcessoDocumentos.abrir(documento);
    },

    onUpdate(id) {
      this.$refs.modalFormCadastroDocumentos.aoAbrir(id);
    },

    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectedItemToDelete = id;
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      await api
        .delete(`v1/repositorios/documento/${this.selectedItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarDocumentos());
    },
  },
  created() {
    this.listarDocumentos();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
