<template>
  <page-title-bar></page-title-bar>
  <app-section-loader :status="loader"></app-section-loader>
  <div class="q-pa-md">
    <q-card>
      <q-card-section align="center">
        <ListaTabelasDePrecos
          label="Selecione a lista de preços ATUAL"
          type="ATUAL"
          @selected="priceSelected($event)"
          :key="keyPriceList"
        />
      </q-card-section>
      <q-card-section>
        <ResumoTabelaSelecionada
          typeTable="Atual"
          :styleClass="['bg-red-10']"
          :data="listas.atual"
        />
      </q-card-section>
      <div class="row q-pa-lg">
        <div class="col-12 text-center">
          <q-icon class="material-icons" size="4rem">south</q-icon>
        </div>
      </div>
      <q-card-section align="center">
        <ListaTabelasDePrecos
          label="Selecione a NOVA lista de preços"
          type="NOVA"
          @selected="priceSelected($event)"
          :key="keyPriceList"
        />
      </q-card-section>
      <q-card-section>
        <ResumoTabelaSelecionada
          typeTable="Nova"
          :styleClass="['bg-green-10']"
          :data="listas.nova"
        />
      </q-card-section>
      <q-card-actions class="q-pa-md" align="center">
        <q-btn
          class="glossy"
          rounded
          color="blue-10"
          :disabled="disableUpdateButton"
          @click="onUpdate"
          >Atualizar</q-btn
        >
      </q-card-actions>
    </q-card>
    <confirmation-dialog ref="confirmationDialog" @onConfirm="updateList" />
  </div>
</template>

<script>
import ListaTabelasDePrecos from "Components/Areas/Comercial/TabelaDePrecos/ListaTabelasDePrecos.vue";
import ResumoTabelaSelecionada from "Components/Areas/Comercial/TabelaDePrecos/ResumoTabelaSelecionada.vue";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
export default {
  name: "AtualizacaoTabelaPreco",
  components: { ListaTabelasDePrecos, ResumoTabelaSelecionada },

  data() {
    return {
      title: "Atualização Tabela de Preços",
      loader: false,
      listas: {
        atual: null,
        nova: null,
      },
      disableUpdateButton: true,
      keyPriceList: 0,
    };
  },
  watch: {
    listas: {
      handler: function () {
        if (this.listas.atual && this.listas.nova) {
          this.disableUpdateButton = false;
        } else {
          this.disableUpdateButton = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    priceSelected(item) {
      if (item.type == "ATUAL") {
        this.listas.atual = item.data;
      } else {
        this.listas.nova = item.data;
      }
    },
    onUpdate() {
      this.$refs.confirmationDialog.open();
    },
    updateList() {
      this.loader = true;
      const data = {
        atual: this.listas.atual.tabelapreco,
        nova: this.listas.nova.tabelapreco,
      };
      api
        .patch("v1/comercial/tabelapreco", data)
        .then((res) => {
          showSuccess(this.$t("message.successUpdatedRecord"));
          this.keyPriceList += 1;
          this.listas.atual = null;
          this.listas.nova = null;
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.$refs.confirmationDialog.close();
          this.loader = false;
        });
    },
  },
};
</script>
