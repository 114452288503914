<template>
  <q-btn-group push>
    <q-btn
      v-for="empresa in nomeEmpresa"
      :key="empresa.cdEmpresa"
      :color="empresaSelecionada == empresa.cdEmpresa ? 'green' : 'secondary'"
      @click.stop="setFilter(empresa)"
      :label="empresa.label"
      push
    />
  </q-btn-group>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CompanyFilterButton",
  emits: ["selecionarEmpresa"],
  props: {
    nomeEmpresa: Array,
    color: String,
    idEmpresaInicial: Number,
  },

  data() {
    return {
      empresaSelecionada: null,
    };
  },
  methods: {
    ...mapActions("ti", ["setFiltroEmpresaID"]),

    setFilter(v) {
      this.empresaSelecionada = v.cdEmpresa;
      this.setFiltroEmpresaID(v.cdEmpresa);
    },
  },
  mounted() {
    this.empresaSelecionada = this.idEmpresaInicial;
  },
};
</script>
