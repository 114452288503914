// messages.js
export default {
  general: "Général",
  home: "Home",
  overview: "Aperçu",
  customizer: "Personnalisateur",
  components: "Composants",
  applications: "Applications",
  features: "Fonctionnalités",
  salesAndVisitStats: "Ventes et statistiques de visites",
  orderAndProjectStats: "Stats de commandes et de projets",
  fitnessStats: "Stats de remise en forme",
  supportAndUsefulWidgets: "Support et Widgets utiles",
  dashboard: "Tableau de bord",
  dashboardOverview: "Présentation du tableau de bord",
  dashboardv1: "Tableau de bord V1",
  dashboardv2: "Tableau de bord V2",
  widgets: "Widgets",
  horizontalMenu: "Horizontal Menu",
  pages: "Pages",
  gallery: "Galerie",
  pricing: "Prix",
  pricing1: "prix 1",
  pricing2: "prix 2",
  blank: "Blanc",
  session: "Session",
  signUp1: "S'inscrire 1",
  signUp2: "S'inscrire 2",
  login1: "S'identifier 1",
  login2: "S'identifier 2",
  lockScreen: "Écran verrouillé",
  uiElements: "Éléments d'interface utilisateur",
  buttons: "Boutons",
  cards: "Cartes",
  checkbox: "Case à cocher",
  carousel: "Carrousel",
  chips: "chips",
  datepicker: "Sélecteur de date",
  dialog: "Dialogue",
  grid: "la grille",
  input: "Contribution",
  list: "liste",
  menu: "Menu",
  progress: "Le progrès",
  radio: "Radio",
  select: "Sélectionner",
  slider: "Slider",
  snackbar: "Snackbar",
  tabs: "onglets",
  toolbar: "Barre d'outils",
  tooltip: "info-bulle",
  timepicker: "Timepicker",
  forms: "Formes",
  formValidation: "Validation de formulaire",
  stepper: "Stepper",
  charts: "Graphiques",
  vueChartjs: "Vue Chartjs",
  vueEcharts: "Vue Echarts",
  icons: "Icônes",
  themify: "Thémifier",
  material: "Matériel",
  tables: "les tables",
  standard: "la norme",
  slots: "Slots",
  selectable: "Sélectionnable",
  searchRow: "Rechercher une ligne",
  maps: "Plans",
  googleMaps: "Google Maps",
  leafletMaps: "Cartes de brochure",
  jvectorMap: "Carte Jvector",
  inbox: "Boîte de réception",
  users: "Utilisateurs",
  user: "Utilisateur",
  userProfile: "Profil de l'utilisateur",
  usersList: "Liste des utilisateurs",
  calendar: "Calendrier",
  editor: "Éditeur",
  quillEditor: "Éditeur de piquants",
  wYSIWYG: "WYSIWYG",
  vue2Dragula: "Vue2 Dragula",
  dragAndDrop: "Glisser déposer",
  vueDraggable: "Vue Draggable",
  draggableResizeable: "Draggable redimensionnable",
  chat: "Bavarder",
  todo: "Todo",
  modules: "Modules",
  miscellaneous: "divers",
  promo: "Promo",
  connections: "Les liaisons",
  follow: "Suivre",
  unfollow: "Ne pas",
  basicValidation: "Validation de base",
  validationWithSubmitAndclear: "Validation avec soumettre et effacer",
  submit: "Soumettre",
  clear: "Clair",
  step1: "Étape 1",
  step2: "Étape 2",
  step3: "Étape 3",
  continue: "Continuer",
  cancel: "Annuler",
  totalEarnings: "Total des gains",
  onlineRevenue: "Revenu en ligne",
  offlineRevenue: "Revenus hors ligne",
  marketingExpenses: "Les frais de commercialisation",
  newCustomers: "nouveaux clients",
  visitAndSalesStatistics: "Statistiques de visite et de vente",
  collapse: "Effondrer",
  reload: "Recharger",
  close: "Fermer",
  bandwidthUsage: "Utilisation de la bande passante",
  shoppingCart: "Chariot",
  totalDownloads: "Total des téléchargements",
  productSales: "Ventes de produits",
  projectStatus: "L'état du projet",
  averageSteps: "Étapes moyennes",
  todaysDistance: "Distance d'aujourd'hui",
  todaysStep: "L'étape du jour",
  todaysGoal: "L'objectif d'aujourd'hui",
  calories: "Calories",
  weeklySummary: "Résumé hebdomadaire",
  supportTickets: "Billets de soutien",
  todoList: "Liste de choses à faire",
  newsletterCampaign: "Campagne de newsletter",
  currentVisitors: "Visiteurs actuels",
  newMembers: "Nouveaux membres",
  addNewBlog: "Ajouter un nouveau blog",
  add: "Ajouter",
  logOut: "Connectez - Out",
  totalAppMemory: "Mémoire totale de l'application",
  totalMemoryUsed: "Mémoire totale utilisée",
  unreadMail: "12 Courrier non lu",
  feedback: "Retour d'information",
  flatButton: "Bouton plat",
  raisedButton: "Bouton surélevé",
  gradientButton: "Bouton de dégradé",
  buttonDropdownVariants: "Bouton Dropdown Variantes",
  buttonToggle: "Bouton bascule",
  icon: "Icône",
  normal: "Ordinaire",
  disabled: "désactivé",
  floating: "Flottant",
  loaders: "Chargeurs",
  sizing: "Dimensionnement",
  outline: "Contour",
  round: "Rond",
  block: "Bloc",
  search: "Chercher",
  checkboxesBoolean: "Cases à cocher Boolean",
  checkboxesArray: "Cases à cocher Array",
  checkboxesStates: "Cases à cocher - États",
  checkboxesColors: "Cases à cocher - Couleurs",
  switches: "Commutateurs",
  default: "Défaut",
  customTransition: "Transition personnalisée",
  customDelimiter: "Délimiteur personnalisé",
  hideControls: "Masquer les contrôles",
  hideDelimiters: "Masquer les délimiteurs",
  colored: "Coloré",
  label: "Étiquette",
  closeable: "Fermable",
  datePickerHorizontal: "Sélecteur de date Horizontal",
  datePickerVertical: "Sélecteur de date vertical",
  datePickersInDialogAndMenu: "Date Pickers- Dans le dialogue et le menu",
  datePickersWithModal: "Sélecteurs de date avec modal",
  datePickersFormattingDate: "Date Pickers - Date de formatage",
  simpleDialogs: "Dialogues simples",
  withoutActivator: "Sans activateur",
  scrollable: "Défilement",
  listOfAllMaterialIcons: "Liste de toutes les icônes de matériel",
  arrowsAndDirectionsIcons: "FLÈCHES ET ORIENTATIONS DE DIRECTION",
  webAppIcons: "ICONS DE L'APPLICATION WEB",
  CONTROLICONS: "Icônes de contrôle",
  TEXTEDITOR: "ÉDITEUR DE TEXTE",
  LAYOUTICONS: "ICONES DE LAYOUT",
  BRANDICONS: "ICÔNES DE MARQUE",
  newClients: "Nouveaux clients",
  recurringClients: "Clients récurrents",
  bounceRate: "Taux de rebond",
  pageViews: "Pages vues",
  usefulWidgets: "Widgets utiles",
  sale: "Vente",
  sales: "Ventes",
  invoices: "Factures",
  referrals: "Renvois",
  serverLoad: "Charge du serveur",
  recentSale: "Vente récente",
  supportRequest: "Demande de soutien",
  productSale: "Vente de produits",
  viewAll: "VOIR TOUT",
  barChart: "Diagramme à bandes",
  polarAreaChart: "Carte de zone polaire",
  lineChart: "Graphique en ligne",
  radarChart: "Graphique radar",
  doughnutChart: "Tableau de beignets",
  bubbleChart: "Graphique à bulles",
  gradientLineChart: "Diagramme de ligne de dégradé",
  pieChart: "Camembert",
  funnelChart: "Entonnoir",
  loginNow: "Connecte-toi maintenant",
  createAccount: "Créer un compte",
  termsOfService: "Conditions d'utilisation",
  bySigningUpYouAgreeTo: "En vous inscrivant, vous acceptez",
  loginToAdmin: "Connectez-vous à Admin",
  enterUsernameAndPasswordToAccessControlPanelOf:
    "Entrez un nom d'utilisateur et un mot de passe",
  // "Entrez un nom d'utilisateur et un mot de passe pour accéder au panneau de configuration de",
  havingAnAccount: "Avoir un compte?",
  quickLinks: "Liens rapides",
  plans: "Des plans",
  chooseThePlanThatWorksForYou: "Choisissez le plan qui fonctionne pour vous.",
  monthly: "Mensuel",
  yearlyGet2MonthExtra: "Annuel (obtenez 2 mois supplémentaires)",
  basic: "De base",
  pro: "Pro",
  advanced: "Avancée",
  basicFree: "Basic Gratuit",
  startToBasic: "Début à la base",
  upgradeToPro: "Passer à Pro.",
  upgradeToAdvance: "Mettre à niveau à l'avance",
  comparePlans: "Comparer les plans",
  frequentlyAskedQuestions: "Questions fréquemment posées",
  defaultInput: "Entrée par défaut",
  activator: "Activateur",
  hover: "Flotter",
  menus: "Menus",
  indeterminate: "Indéterminé",
  sizeAndWidth: "Taille et largeur",
  rotate: "Tourner",
  determinate: "Déterminé",
  buffer: "Tampon",
  radiosDefault: "Radios par défaut",
  radiosDirectionRow: "Radios Direction Row",
  radiosDirectionColumn: "Radios Direction Colonne",
  radiosColors: "Radios - Couleurs",
  switchesColors: "Commutateurs - Couleurs",
  continuous: "Continu",
  discrete: "Discret",
  customColors: "Couleurs personnalisées",
  editableNumericValue: "Valeur numérique modifiable",
  contextualSnackbar: "Snackbar contextuel",
  showSnackbar: "Afficher Snackbar",
  centeredTabs: "Onglets centrés",
  toolbarTabs: "Onglets de la barre d'outils",
  theDefaultColorToolbar: "La barre d'outils couleur par défaut",
  appBar: "App Bar",
  appBarWithExtension: "Barre d'applications avec extension",
  visibility: "Visibilité",
  top: "Haut",
  right: "Droite",
  bottom: "Bas",
  left: "La gauche",
  toggle: "Basculer",
  timePicker: "Time Picker",
  timePickerInDialogAndMenu: "Time Picker - Dans le dialogue et le menu",
  itemsAndHeaders: "Articles et en-têtes",
  selectableRows: "Lignes sélectionnables",
  headerCell: "Cellule d'en-tête",
  expand: "Développer",
  recentChat: "Chat récent",
  previousChats: "Conversations précédentes",
  addNewItems: "Ajouter de nouveaux éléments",
  addToDo: "Ajouter à faire",
  yes: "Oui",
  ok: "D'accord",
  activeUsers: "Utilisateurs actifs",
  addNew: "Ajouter un nouveau",
  readMore: "Lire la suite",
  assignNow: "Assigner maintenant",
  totalRequest: "Demande totale",
  new: "Nouveau",
  pending: "en attendant",
  update: "mettre à jour",
  updated10MinAgo: "Mis à jour il y a 10 minutes",
  addNewCustomer: "Ajouter un nouveau client",
  name: "prénom",
  email: "Email",
  editMember: "Modifier le membre",
  scheduleDate: "Date de programmation",
  title: "Titre",
  newEmails: "Nouveaux Emails",
  newEmail: "nouveau courriel",
  employeePayroll: "Employee Payroll",
  forMostOfTheUsers: "Pour la plupart des utilisateurs",
  choosePlan: "Choisissez Plan",
  mega: "méga",
  master: "Maîtriser",
  forDeveloper: "pour développeur",
  forLargeEnterprises: "Pour les grandes entreprises",
  composeEmail: "Écrire un email",
  mailboxes: "Boîtes aux lettres",
  folders: "Dossiers",
  settings: "Paramètres",
  inbox5: "Boîte de réception (5)",
  draft: "Brouillon",
  starred: "Marqué",
  sentMessages: "Messages envoyés",
  spam: "Spam",
  delete: "Effacer",
  work: "Travail",
  personal: "Personnel",
  manageFolders: "Gérer les dossiers",
  contacts: "Contacts",
  themeOptions: "Options du thème",
  darkMode: "Mode sombre",
  collapseSidebar: "Réduire la barre latérale",
  boxLayout: "Box Layout",
  rtlLayout: "Rtl Layout",
  backgroundImage: "Image de fond",
  sidebarFilters: "Filtres de barre latérale",
  danger: "Rose",
  primary: "Primaire",
  warning: "Attention",
  success: "Succès",
  info: "Violet",
  dailySales: "Ventes quotidiennes",
  trafficChannel: "Canal de trafic",
  campaignPerformance: "Performance de la campagne",
  goToCampaign: "Aller à la campagne",
  fullScreen: "Plein écran",
  ecommerce: "Commerce électronique",
  shopWithAlgolia: "Magasinez avec Algolia",
  cart: "Chariot",
  total: "Total",
  totalPrice: "prix total",
  viewCart: "Voir le panier",
  checkout: "Check-out",
  apply: "Appliquer",
  noItemsFound: "Aucun élément trouvé",
  billingDetails: "Détails de la facturation",
  placeOrder: "Passer la commande",
  addNewCard: "Ajouter une nouvelle carte",
  devicesShare: "Partage de périphériques",
  customerOverview: "Aperçu du client",
  reviews: "Avis",
  weeklySales: "Ventes hebdomadaires",
  recentOrders: "Dernières commandes",
  categorySales: "Catégorie Ventes",
  topSelling: "Meilleures ventes",
  social: "Social",
  newPost: "Nouveau message",
  publish: "Publier",
  webAnalytics: "analyses d'audience Internet",
  magazine: "Magazine",
  editUser: "Modifier l'utilisateur",
  addNewUser: "Ajouter un nouvel utilisateur",
  dark: "Foncé",
  light: "Lumière",
  recentUsers: "Utilisateurs récents",
  activityAroundWorld: "Activité autour du monde",
  adsIncome: "Revenus publicitaires",
  recentComments: "Commentaires récents",
  recentNotifications: "Notifications récentes",
  messages: "messages",
  edit: "modifier",
  topAuthors: "Top Auteurs",
  marketingCampaign: "Campagne de marketing",
  article: "Article",
  websiteTraffic: "Trafic du site",
  agency: "Agence",
  recent: "Récent",
  trending: "Tendance",
  totalPageViews: "Nombre total de pages vues",
  impressions: "Impressions",
  deviceSeparations: "Séparations de périphériques",
  browserStatics: "Statistiques du navigateur",
  totalDownloading: "Téléchargement total",
  notifications: "Notifications",
  totalBookmarked: "Total mis en signet",
  itemsDownloads: "Articles Téléchargements",
  itemsUploaded: "Articles téléchargés",
  totalAnalytics: "Total Analytics",
  hotKeywords: "Mots-clés chauds",
  MaleUsers: "Utilisateurs masculins",
  serverStatus: "Statut du serveur",
  purchaseVuely: "Acheter Vuely",
  letsGetInTouch: "Permet de se mettre en contact",
  maleUsers: "Utilisateurs masculins",
  femaleUsers: "Utilisateurs féminins",
  androidUsers: "Utilisateurs Android",
  iOSUsers: "Utilisateurs iOS",
  advance: "Avance",
  routerAnimation: "Animation du routeur",
  salesAndEarning: "Ventes et gains",
  netProfit: "Bénéfice net",
  totalExpences: "Dépenses totales",
  photos: "Photos",
  adCampaignPerfomance: "Performance de la campagne publicitaire",
  profitShare: "Une part des bénéfices",
  topSellingTheme: "Thème le plus vendu",
  newRequest: "Nouvelle requête",
  followers: "Suiveurs",
  mailbox: "Boites aux lettres",
  sent: "Envoyé",
  birthdays: "Anniversaires",
  events: "Événements",
  newsletter: "Bulletin",
  lastMonth: "Le mois dernier",
  allTime: "Tout le temps",
  composeNewEmail: "Composer un nouveau courriel",
  activity: "Activité",
  message: "Message",
  selectTheme: "Sélectionne un thème",
  continueShopping: "Continuer vos achats",
  proceedToCheckout: "Passer à la caisse",
  headerFilters: 'Filtres d"en-tête',
  blog: "Blog",
  signUp: "S'inscrire",
  login: "S'identifier",
  news: "Actualités",
  topHeadlines: "Top titres",
  visitors: "visiteurs",
  subscribers: "Les abonnés",
  twitterFeeds: "Flux Twitter",
  extensions: "Les extensions",
  imageCropper: "Cropper d'image",
  videoPlayer: "Lecteur vidéo",
  dropzone: "Zone de largage",
  baseConfig: "Base Config",
  audioTrackAndPlaysInline: "Piste audio et jeux en ligne",
  hlsLive: "HLS Live",
  forgotPassword: "Mot de passe oublié",
  resetPassword: "réinitialiser le mot de passe",
  backToSignIn: "Retour à la connexion",
  enterYourEmailToSendYouAResetLink:
    "Entrez votre email pour vous envoyer un lien de réinitialisation",
  pleaseEnterYourPasswordToReset:
    "Veuillez entrer votre mot de passe pour réinitialiser",
  password: "Mot de Passe",
  remember: "Souviens",
  saas: "SAAS",
  overallTrafficStatus: "État général du trafic",
  projectManagement: "Gestion de projet",
  projectTaskManagement: "Gestion des tâches du projet",
  totalSales: "Ventes totales",
  simple: "Simple",
  transitions: "Transitions",
  tax: "Impôt",
  expenses: "Les dépenses",
  images: "Images",
  ratings: "Évaluations",
  containAndCover: "Contenir et couvrir",
  height: "la taille",
  fixedRatio: "Ratio fixe",
  gradients: "Les dégradés",
  sizeVariants: "Variantes de taille",
  newIcons: "Nouvelles icônes",
  webApplication: "Application Web",
  person: "La personne",
  file: "Fichier",
  comment: "Commentaire",
  form: "Forme",
  hardware: "Matériel",
  directional: "Directionnel",
  mapAliases: "Carte (Alias)",
  dateAndTime: 'Date et l "heure"',
  groups: "Groupes",
  buttonGroups: "Groupes de boutons",
  itemGroups: "Groupes d'articles",
  windows: "les fenêtres",
  onBoarding: "À l'embarquement",
  accountCreation: "Création de compte",
  treeview: "Treeview",
  customSelectableIcons: "Icônes sélectionnables personnalisées",
  timelines: "Les délais",
  usage: "Usage",
  scopedSlots: "Scoped Slots",
  asyncItems: "Articles asynchrones",
  smallDots: "Petits points",
  iconDots: "Points d'icône",
  coloredDots: "Points de couleur",
  oppositeSlot: "Slot opposé",
  denseAlert: "Alerte dense",
  fixedTabs: "Onglets fixes",
  rightAlignedTabs: "Onglets alignés à droite",
  content: "Contenu",
  withSearch: "Avec recherche",
  iconsAndText: "Icônes Et Texte",
  grow: "Croître",
  pagination: "Pagination",
  customIcons: "Icônes Personnalisées",
  courses: "Cours",
  coursesList: "Liste des cours",
  courseDetail: "Détail des cours",
  signIn: "Se connecter",
  payment: "Paiement",
  LearnWithYourConvenience: "Apprendre avec votre commodité",
  management: "La gestion",
  design: "Conception",
  development: "Développement",
  bestseller: "Best-seller",
  addToCart: "Ajouter au panier",
  popularInstructors: "Instructeurs populaires",
  moreCoursesFromJamesColt: "Plus de cours de James Colt",
  whatYoWillLearn: "Ce que vous apprendrez",
  description: "La description",
  aboutInstructor: "A propos de l'instructeur",
  userSignIn: "Connexion utilisateur",
  guestCheckout: "Commander des invités",
  continueAsGuest: "Continuer en tant qu'invité",
  paymentOptions: "Options de paiement",
  offerCode: "Code d'offre",
  enterCardDetails: "Entrer les détails de la carte",
  makePayment: "Effectuer le paiement",
  withContent: "Avec contenu",
  customHeight: "Hauteur personnalisée",
  viewer: "Téléspectateur",
  trade: "Commerce",
  upcomingEvents: "évènements à venir",
  ongoingProjects: "Projets en cours",
  viewAllNotifications: "Afficher toutes les notifications",
  learnMore: "Apprendre encore plus",
  payments: "Paiements",
  taxRates: "Les taux d'imposition",
  addTickets: "Ajouter des billets",
  projects: "Projets",
  clients: "Les clients",
  reports: "Rapports",
  officeManagement: "Office Management",
  hotelManagement: "Hotel Management",
  projectGrid: "Grille de projet",
  projectData: "Données du projet",
  videoCallingApp: "Video Calling App",
  hospitalAdministration: "Hospital Administration",
  revenue: "Revenu",
  deals: "Offres",
  transactionList: "Liste de transaction",
  transferReport: "Rapport de transfert",
  expenseCategory: "Catégorie de dépenses",
  allClients: "Tous les clients",
  recentlyAdded: "Récemment ajouté",
  favourite: "Préférée",
  tradeHistory: "Histoire du commerce",
  safeTrade: "Commerce sécurisé",
  exchangeStatistics: "Statistiques d'échange",
  quickTrade: "Commerce rapide",
  recentTrades: "Métiers récents",
  exchangeRate: "Exchange Rate",
  coinsList: "Coins List",
  currenciesAvailable: "Devises disponibles",
  receivedAmount: "Montant reçu",
  sentAmount: "Montant envoyé",
  totalAmount: "Montant total",
  walletAddress: "Adresse du portefeuille",
  withdraw: "Se désister",
  deposit: "Dépôt",
  bankDetails: "Coordonnées bancaires",
  phone: "Téléphone",
  account: "Compte",
  buyCryptocurrency: "Acheter une crypto-monnaie",
  expenditureStats: "Statistiques de dépenses ",
  sellCryptocurrency: "Vendre une crypto-monnaie",
  transferCryptocurrency: "Transférer une crypto-monnaie",
  buyOrSell: "Acheter / Vendre",
  crm: "CRM",
  crypto: "Crypto",
  supervisor: "Superviseuse",
  duration: "Durée",
  netWorth: "Valeur nette",
  liveChatSupport: "Support de chat en direct",
  marketCap: "Capitalisation boursière",
  wallet: "Portefeuille",

  budget: "Budget",
  department: "département",
  projectManager: "Chef de projet",
  team: "Équipe",
  status: "Statut",
  deadline: "Date limite",
  filesUploaded: "Fichiers téléchargés",
  statistics: "Statistiques",
  projectGallery: "Galerie de projet",
  projectDetails: "détails du projet",
  client: "Client",
  projectList: "Liste de projets",

  shop: "Boutique",
  productDetail: "Détail du produit",
  addProduct: "Ajouter un produit",
  editProduct: "Modifier le produit",
  editDetail: "Modifier le détail",

  fileInput: "Entrée de fichier",
  colorPickers: "Sélecteurs de couleurs",
  size: "Taille",
  validation: "Validation",
  playground: "Cour de récréation",
  chipGroups: "Groupes de jetons",
  overlays: "Superpositions",
  slideGroups: "Groupes de diapositives",
  simpleTable: "Tableau simple",
  listItemGroups: "Liste des groupes d'éléments",
  banners: "Bannières",
  appBars: "Barres d'application",

  // Novos
  emailRequired: "Email requis",
  userRequired: "Utilisateur requis",
  passwordRequired: "Mot de passe requis",

  required: "Obligatoire",
  maxOccursExceeded: "Nombre maximal d'occurrences dépassé",
  confirm: "Confirmer",
  areYouSureYouWantToDelete: "Etes-vous sûr que vous voulez supprimer?",
  areYouSureYouWantToDeleteThisRecord:
    "Voulez-vous vraiment supprimer définitivement cet enregistrement ?",
  descriptionRequired: "La description est obligatoire",
  successAddedRecord: "Inscription incluse avec Success!",
  successUpdatedRecord: "Inscription mise à jour avec succès !",
  successDeletedRecord: "Enregistrement supprimé avec succès !",
  nameRequired: "Le nom est requis!",
  emailMustBeValid: "L'email doit être valide",
  accessLevel: "Niveau d'accès",
  save: "Conserver",
  accessLevelRequired: "Niveau d'accès requis",
  mustbelessthan: "doit comporter moins de ",
  characters: "caractères",
  requestSent: "Demande envoyée",
  confirmPassword: "Confirmez le mot de passe",
  newPassword: "Nouveau mot de passe",
  messagepPasswordNotMatch: "Les mots de passe ne correspondent pas",
  patternPassword:
    "Le mot de passe doit comporter au moins 8 caractères et contenir des chiffres, des majuscules, des minuscules et des caractères de ponctuation.",
  passwordConfirmed: "Mot de passe confirmé",
  passwordLastSet: "Dernier mot de passe défini",
  badPasswordCount: "Tentatives de connexion non valides",
  lastBadPassword: "Dernier mot de passe incorrect",
  admin: "Administration",
  menuList: "Liste des menus",
  multiLanguage: "Multi-langue",
  route: "Route",
  order: "Ordre",
  orders: "Ordres",
  sort: "Sorte",
  active: "Active",
  sortRequired: "Tri obligatoire",
  pageNotFound: "PAGE NON TROUVÉE",
  goToHomePage: "ALLER À LA PAGE D'ACCUEIL",
  accessLevelList: "Liste des niveaux d'accès",
  language: "Langue",
  customer: "Client",
  city: "Ville",
  state: "Etat",
  comercial: "Commercial",
  customerList: "Liste de clients",
  typeInformationAboutCustomer:
    "Tapez les informations sur le client que vous souhaitez rechercher",
  customerSelected: "Client sélectionné",
  customerProfile: "Profil client",
  seller: "Vendeur",
  manager: "Directeur",
  address: "Adresse",
  billingLast3Years: "Facturation des 3 dernières années",
  amount: "Valeur",
  kilos: "Kilos",
  alternativeUser: "Utilisateur alternatif",
  ordersSummary: "Résumé des commandes",
  billing: "Facturation",
  billingHistory: "Historique de facturation",
  reportsDetails: "Détails des rapports",
  reportName: "Nom du rapport",
  tradingName: "Nom Commercial",
  lastOrder: "Dernière commande",
  customerSummary: "Résumé client",
  registrationData: "Données d`inscription",
  addresses: "Adresses",
  history: "Historique",
  mainAddress: "Adresse principale",
  billingAddress: "Adresse de facturation",
  deliveryAddress: "Adresse de livraison",
  district: "Arrondissement",
  zipCode: "Code postal",
  customerType: "Type de client",
  joinOrders: "Joindre des commandes",
  shippingCompany: "Transporteur",
  recordType: "Type d`enregistrement",
  goBack: "Retourner",
  orderDate: "Date de commande",
  releaseDate: "Date de sortie",
  promiseDate: "Date promise",
  DeliveryDate: "Date de livraison",
  customerOrder: "Commande client",
  productId: "Identifiant du produit",
  productCode: "Code produit",
  colorCode: "Code couleur",
  designCode: "Code de conception",
  preInvoice: "Préfacture",
  statusPreInvoice: "Statut Pré Facture",
  invoiceDocument: "Document de facture",
  invoiceIssueDate: "Date d'émission de la facture",
  invoiceReleaseDate: "Date de sortie de la facture",
  orderList: "Liste des commandes",
  typeOrder: "Ordre de type",
  immediateDelivery: "Livraison Rapide",
  quantity: "Quantité",
  quantityKg: "Quantité(KG)",

  balance: "Solde",
  orderType: "Type de Demande",
  reservedQuantityKg: "Réservé(KG)",
  reservedQuantityQtd: "Réservé(QTD)",
  reservedQuantityFat: "Réservé(FAT)",
  partialDelivery: "Livraison Partielle",
  combinedOrders: "Demandes Conjugué",
  requirements: "Exigences",
  economicGroup: "Groupe économique",
  billOfSale: "Acte de vente",
  carrier: "Transporteur",
  pickupNumber: "Numéro de prise en charge",
  pickupDate: "Date de ramassage",
  lockReason: "Verrouiller la raison",
  lockDate: "Dt. Verrouillage",
  grossWeight: "Poids Brut",
  netWeight: "Poids Net",
  volume: "Le Volume",
  blockedPreInvoices: "Pré-Factures Bloquées",
  scheduledDeliveryDate: "Date de Livraison Prévue",
  businessRules: "Règles Opérationnelles",
  salesOrder: "Bon de Commande",
  deliveryWeek: "Semaine de Livraison",
  color: "Couler",
  tradingRules: "Règle Commerciale",
  rawArticleCode: "Code Article Brut",
  rawArticleItem: "Article Brut",
  financialInformation: "L`information Financière",
  creditLimit: "Limite de Crédit",
  totalValueOrders: "Ordres de Valeur Totale",
  totalValueOfBonds: "Valeur Totale des Obligations (Ouvertes)",
  overdueAmount: "Montant en Souffrance",
  creditBalance: "Solde de Crédit",
  openDuplicates: "Ouvrir des Doublons",
  situation: "Situation",
  value: "Valeur",
  issueDate: "Date D´émission",
  dueDate: "Date D´échéance",
  lastDueDate: "Dernière Date D´échéance",
  epirationDays: "Jours D´expiration",
  filial: "Branche",
  billingType: "Type de Facturation",
  thirdPartyCustomer: "Client Tiers",
  billingBlock: "Blocage de la Facturation",
  paymentTerms: "Modalités de Paiement",
  commission: "Commission",
  salesChannel: "Canal de Vente",
  federativeUnit: "Unité Fédérative",
  triangularCustomer: "Client Triangulaire",
  viaTransport: "Par les Transports",
  clientOrderNumber: "Numéro de Commande Client",
  complete: "Achevée",
  anticipate: "Anticiper",
  askedFor: "Demandé Pour",
  preOrder: "Pré-Commander",
  conjugate: "Conjuguer",
  typist: "Dactylographe",
  billingStatus: "Statut de Facturation",
  orderValue: "Valeur Commandée",
  approver: "Approbateur",
  lots: "Beaucoup",
  historyReport: "Historique/Rapport",
  orderInquiry: "Demmande de Commande",

  reference: "Référence",
  print: "Imprimer",
  finishing: "Finitions",
  unitPrice: "Prix ​​Unitaire",
  unit: "Unité",
  ordered: "Ordre",
  reserved: "Réservé",
  weighted: "Lourd",
  billed: "Facturé",
  ofItem: "OF",
  canceled: "Annulé",
  productSituation: "État du Produit",
  quality: "Qualité",
  discount: "Réduction",
  noDataFound: "Données Non Trouvées",

  orderItemList: "Liste D`articles de Commande",

  productTagId: "Identifiant de L`Étiquette du Produit",
  price: "Le Prix",

  movideskUserList: "Liste des Utilisateurs Movidesk",
  userId: "Identifiant d´Utilisateur",

  orderData: "Données de Commande",
  clientsData: "Les Données du Client",
  businessData: "Données Commerciales",
  generalData: "Données Generales",

  releaseNotes: "Release Notes",

  condition: "État",

  sellerSummary: "Résumé Représentatif",
  sellerOrderList: "Liste de Commande du Représentant",
  sellerList: "Liste des Vendeurs",
  typeInformationAboutSeller:
    "Entrez les informations sur le vendeur que vous souhaitez rechercher",
  sellerSelected: "Vendeur Sélectionné",

  sellerClients: "Clients Vendeurs",
  version: "Version",

  moreOptions: "Plus d`Options",

  externalLink: "Lien Externe",
  systemVersion: "Version du Système",

  movideskTickets: "Movidesk Billet",

  widgetsList: "Widgets Liste",
  widget: "Widget",
  component: "Composant",
  id: "Id",

  widgetInformations: "Information Provenant de Widget",
  acessLevels: "Niveaux D`Accès",

  movideskList: "Liste des Billets Movidesk",
  movideskDetail: "Détail du Ticket Movidesk",
  movidesk: "Movidesk",

  fullscreenMode: "Mode Plein Écran",
  homePage: "Page D`Accueil",
  systemReleases: "Versions Du Système",

  notificationsSetup: "Configuration des Notifications",
  markMessagesAsRead: "Marquer Les Messages Comme Lus",
  markRead: "Marquer Lu",
  deleteMessage: "Supprimer le Message",

  changeEmailFrequency: "Modifier la Fréquence des E-mails",
  neverSend: "Ne Jamais Envoyer",
  sendEveryHour: "Envoyer Toutes les Heures",
  sendInstantly: "Envoyer Instantanément",
  desktopNotification: "Notification Desktop",
  doNotNotify: "Ne Pas Notifier",
  notify: "Notifier",

  messageRegistration: "Enregistrement des Messages",
  systemMessageList: "Liste de Messages Système",
  categoryId: "Identifiant de Catégorie",

  titleRequired: "Le Titre Est Obligatoire!",
  successMarkAsRead: "Message Marqué Comme Lu!",
  successMarkAllAsRead: "Messages Marqués Comme Lus!",
  successUpdateMessageConfigurations:
    "La Configuration des Messages a Été Mise à Jour Avec Succès !",
  noMessagesAvailable: "Aucun Message Disponible",

  category: "Catégorie",

  ticketNumber: "Numéro de Billet",
  subject: "Sujet",
  origin: "Origine",
  type: "Taper",
  createdBy: "Créé par",
  createdDate: "Date de Création",
  lastUpdate: "Dernière Mise à Jour",

  sampleDepositsList: "Liste de Dépôts D`Échantillons",
  sampleDeposits: "Dépôts D`Échantillons",
  depositCode: "Code de Dépôt",
  depositDescription: "Descriptif du Dépôt",
  updateDate: "Date de Mise à Jour",
  updateUser: "Mise à Jour de L`Utilisateur",
  changeSituation: "Modifier le Statut",
  registerSampleDeposit: "Enregistrer le Dépôt D`Échantillon",
  insertDepositCode: "Insérer le Code de Dépôt",
  insertDepositDescription: "Insérer la Description du Dépôt",
  codeRequired: "Le Code Est Requis",
  code: "Code",
  mustBe: "doit être de",
  successSubmitedDeposit: "Dépôt enregistré avec succès!",

  production: "Production",
  registers: "Enregistrements",

  send: "Envoyer",
  copy: "Copie",
  registerPriceList: "Enregistrer la Liste de Prix",
  insertTableName: "Insérer le Nom du Tableau",
  chooseTableStatus: "Choisir la Situation de Table",
  chooseEmissionDate: "Choisir la Date D`Émission",
  chooseValidityDate: "Choisissez la Date de Validité",
  inactive: "Inactif",
  saletePriceList: "Salete Liste de Prix",
  purchases: "Achats",

  copyPriceList: "Copier la Liste de Prix",
  referenceTable: "Tableau de Référence",

  saletePriceListItem: "Élément de Liste de Prix de Salete",
  saletePriceItem: "Prix ​​de L'Élément Salete",
  table: "Tableau",
  validity: "Durée de Conservation",
  baseTable: "Table Basse",
  familyStock: "Famille de Stock",
  familyStockDescription: "Description Famille de Stock",
  physicalFinish: "Finition Physique",
  physicalFinishDescription: "Description Finition Physique",
  hasPrint: "Timbré?",
  variantOf: "Variante De",
  variantUntil: "Variante Jusqu`à",
  quantityOf: "Quantité de",
  quantityUntil: "Quantité Jusqu`à",
  tableNumber: "Numéro de Table",
  item: "Objet",
  productInfo: "Information Produit",
  typeProductId: "Entrez le code du produit que vous souhaitez rechercher",
  validityDate: "Date Effective",
  isTableActive: "Actif?",
  emissionDate: "Date d`Émission",
  stock: "Stock",
  stockPosition: "Position des stocks",
  ti: "T.I.",
  helpdeskAnalysis: "Help Desk Analysis",
  billingvsBudget: "Billing vs Budget",
};
