<template>
  <app-section-loader :status="loader" size="xl" />

  <q-page>
    <iframe class="fullScreen q-pa-sm" :srcdoc="html"> </iframe>
  </q-page>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "Container",
  data() {
    return {
      html: null,
      loader: true,
    };
  },
  watch: {
    "$route.query": {
      handler: function (n, o) {
        if (n.url) this.callLink(this.$route.query.url);
      },
      deep: true,
    },
  },
  methods: {
    listener(e) {
      if (
        e.data &&
        typeof e.data === "string" &&
        e.data?.indexOf("eventChildToParent") >= 0
      ) {
        const obj = JSON.parse(e.data);
        if (obj.destination) {
          this.callLink(obj.destination);
        }
      }
    },
    callLink(url) {
      this.loader = true;
      api
        .post("/v1/callback/gerencial", { url })
        .then((res) => (this.html = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    //    window.addEventListener("message", this.listener);
    this.callLink(this.$route.query.url);
  },
  unmounted() {
    //  window.removeEventListener("message", this.listener);
  },
};
</script>

<style scoped>
.fullScreen {
  display: block;
  width: 100%;
  border: none;
  min-height: inherit !important;
}
</style>
