<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <AcessosSenior />
  </div>
</template>

<script>
import AcessosSenior from "Components/Areas/TI/AcessosSenior";
export default {
  name: "ListaAcessosSenior",
  components: { AcessosSenior },
  data() {
    return {};
  },
};
</script>
