<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <SimularUsuarioComponent />
  </div>
</template>

<script>
import SimularUsuarioComponent from "Areas/Admin/Usuarios/SimularUsuarioComponent";

export default {
  name: "SimularUsuariosView",
  components: {
    SimularUsuarioComponent,
  },
};
</script>
