<template>
  <q-card class="my-card">
    <q-card-section class="q-pa-sm">
      <q-list>
        <q-item>
          <q-item-section class="text-h6 text-bold">
            {{ produto?.descricao }}
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section side>
            <q-badge :color="color">
              <span class="q-pa-xs fs-16 text-bold"
                >Variação: {{ variacao.percentual }}% (R$
                {{ variacao.valor }})</span
              >
            </q-badge>
          </q-item-section>
        </q-item>
      </q-list>
      <q-img class="rounded-borders" :src="produto?.imagem" />

      <CalculaComposicaoProdutoConfeccao
        :id="produto?.id"
        @carregarVariacao="variacao = $event"
      />

      <q-card-section class="border q-mt-xs">
        <div class="row">
          <div class="col"></div>
          <div class="col text-bold">Rosset</div>
          <div class="col text-bold">Outros</div>
          <div class="col text-bold">Variação</div>
        </div>
        <div class="row">
          <div class="col text-bold">Custo Peça</div>
          <div class="col">R$ {{ variacao.custoTotalRosset }}</div>
          <div class="col">R$ {{ variacao.custoTotalConcorrente }}</div>
          <div class="col text-bold" :class="color">
            {{ variacao.percentual }}% ({{ variacao.valor }})
          </div>
        </div>
      </q-card-section>

      <q-card-actions>
        <q-btn
          color="negative"
          size="xs"
          label="Remover"
          @click.prevent.stop="removerItem"
        />
      </q-card-actions>
    </q-card-section>
  </q-card>
</template>

<script>
import CalculaComposicaoProdutoConfeccao from "./CalculaComposicaoProdutoConfeccao";
export default {
  name: "ProdutosConfeccaoMobile",
  components: { CalculaComposicaoProdutoConfeccao },
  emits: ["removerItem"],
  props: {
    produto: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      variacao: {
        percentual: 0,
        valor: 0,
      },
    };
  },
  computed: {
    color() {
      return this.variacao.percentual > 0 ? "positive" : "red";
    },
  },
  methods: {
    removerItem() {
      this.$emit("removerItem", this.produto.id);
    },
  },
};
</script>

<style scoped>
.composicao {
  height: 2.5vh !important;
}
</style>
