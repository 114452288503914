<template>
  <q-input
    :filled="filled"
    :label="labelComponent"
    :required="required"
    :rules="rulesValue"
    :autofocus="autofocus"
    :rounded="rounded"
    :outlined="outlined"
    :hint="$t('message.maxValueElevenDigits')"
    hide-hint
    v-money="config"
    :dense="dense"
  >
    <slot name="append"> </slot>
  </q-input>
</template>

<script>
import { VMoney } from "v-money";
import { convertToFloat } from "Helpers";

export default {
  name: "InputQuantity",
  props: {
    filled: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rulesLabel: {
      type: String,
    },
    label: {
      type: String,
    },
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: 2,
    },
    decimal: {
      type: String,
      default: ",",
    },
    thousands: {
      type: String,
      default: ".",
    },
  },
  data() {
    return {
      config: {
        decimal: this.decimal,
        thousands: this.thousands,
        prefix: this.prefix,
        suffix: this.suffix,
        precision: this.precision,
        masked: false,
      },
      labelComponent: this.label || this.$t("message.insertValue"),
      rulesValue: [
        (v) =>
          (convertToFloat(v) != 0 && v.length < 15) ||
          !this.required ||
          this.rulesLabel,
      ],
    };
  },
  directives: { config: VMoney },
};
</script>
