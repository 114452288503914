<template>
  <ModalForm
    ref="modalFormListaPrecos"
    :title="$t('message.registerPriceList')"
  >
    <template v-slot:body>
      <q-form ref="form" v-model="valid" autofocus @submit="aoSalvar">
        <div class="row q-mb-xl q-mt-md">
          <div class="col-6">
            <q-input
              filled
              v-model="nomeTabela"
              :label="$t('message.insertTableName')"
              required
              autofocus
              cleareble
              class="q-mx-sm"
              :rules="nameRules"
            />
          </div>
          <div class="col-6">
            <q-select
              filled
              required
              option-label="label"
              option-value="value"
              v-model="situacaoTabela"
              :options="opcoesSituacao"
              map-options
              emit-value
              :label="$t('message.chooseTableStatus')"
              class="q-mx-sm"
              :rules="situacaoRules"
            />
          </div>
        </div>

        <div class="row q-mt-xl q-mb-md">
          <div class="col-6">
            <q-input
              name="dataEmissao"
              filled
              v-model="dataEmissao"
              :label="$t('message.chooseEmissionDate')"
              required
              class="q-mx-sm"
              :rules="emissaoRules"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qEmissionDateProxy">
                    <q-date
                      v-model="dataEmissao"
                      minimal
                      mask="DD/MM/YYYY"
                      @click="() => $refs.qEmissionDateProxy.hide()"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="col-6">
            <q-input
              name="dataVigencia"
              filled
              v-model="dataVigencia"
              :label="$t('message.chooseValidityDate')"
              required
              cleareble
              class="q-mx-sm"
              :rules="vigenciaRules"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qValidityDateProxy">
                    <q-date
                      v-model="dataVigencia"
                      minimal
                      mask="DD/MM/YYYY"
                      @click="() => $refs.qValidityDateProxy.hide()"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <q-separator />
        <q-btn
          v-if="!nrTabela"
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-ml-sm q-mt-md"
        />

        <q-btn
          v-else
          :label="$t('message.update')"
          @click.prevent="aoAtualizar()"
          class="bg-green text-white q-ml-sm q-mt-md"
        />

        <q-btn
          :label="$t('message.close')"
          @click.prevent="aoFechar()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
</template>

<script>
import api from "Api";
import ModalForm from "Components/Widgets/ModalForm";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "InsereAtualizaListaDePrecosHeader",
  components: { ModalForm },

  data() {
    return {
      loader: true,
      valid: true,

      nrTabela: null,

      nomeTabela: null,
      dataEmissao: null,
      dataVigencia: null,
      situacaoTabela: null,

      nameRules: [
        (v) => !!v || this.$t("message.nameRequired"),
        (v) =>
          (v && v.length >= 6) ||
          `${this.$t("message.name")}
           ${this.$t("message.mustbegreaterthan")} 6
           ${this.$t("message.characters")}`,
      ],
      situacaoRules: [(v) => v !== null || this.$t("message.statusRequired")],
      emissaoRules: [(v) => !!v || this.$t("message.dateRequired")],
      vigenciaRules: [(v) => !!v || this.$t("message.dateRequired")],

      opcoesSituacao: [
        {
          label: this.$t("message.inactive"),
          value: 0,
        },
        {
          label: this.$t("message.active"),
          value: 1,
        },
      ],
    };
  },
  methods: {
    adicionarListaPreco() {
      this.$refs.modalFormListaPrecos.abrir();
    },
    aoFechar() {
      this.$refs.modalFormListaPrecos.fechar();
      this.nrTabela = null;
      this.nomeTabela = null;
      this.situacaoTabela = null;
      this.dataEmissao = null;
      this.dataVigencia = null;
    },

    editarInformacoes(nrTabela) {
      this.$refs.modalFormListaPrecos.abrir();
      this.obterPrecoHeader(nrTabela);
    },

    obterPrecoHeader(nrTabela) {
      api
        .get(`v1/compras/salete/obterprecoheader/${nrTabela}`)
        .then((res) => {
          this.listaPreco = res.data;

          this.nrTabela = this.listaPreco.nrTabela;
          this.nomeTabela = this.listaPreco.nome;
          this.dataEmissao = this.listaPreco.dtEmissao;
          this.dataVigencia = this.listaPreco.dtVigencia;
          this.situacaoTabela = this.listaPreco.status;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    aoSalvar() {
      api
        .post("v1/compras/salete/inserirprecoheader", {
          nome: this.nomeTabela,
          dtEmissao: this.dataEmissao,
          dtVigencia: this.dataVigencia,
          situacao: this.situacaoTabela,
        })
        .then(() => {
          showSuccess(this.$t("message.successAddedRecord"));
          this.$emit("atualizarLista");
        })
        .catch((err) => showError(err))
        .finally(() => this.aoFechar());
    },

    aoAtualizar() {
      api
        .put(`v1/compras/salete/alterarprecoheader/${this.nrTabela}`, {
          nome: this.nomeTabela,
          dtEmissao: this.dataEmissao,
          dtVigencia: this.dataVigencia,
          situacao: this.situacaoTabela,
        })
        .then(() => {
          showSuccess(this.$t("message.successUpdatedRecord"));
          this.$emit("atualizarLista");
        })
        .catch((err) => showError(err))
        .finally(() => this.aoFechar());
    },
  },
};
</script>
