<template>
  <q-table
    :loading="loader"
    :rows="rows"
    :columns="columns"
    dense
    hide-pagination
    table-header-class="table-header"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
  >
    <template v-slot:top>
      <div class="q-toolbar row no-wrap items-center">
        <div class="doc-card-title">{{ $t("message.questions") }}</div>
      </div>
      <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
        {{ $t("message.add") }}
      </q-btn>

      <q-space />
      <TemaQASelectionComponent
        @selected="filtrarPorTema($event)"
        :required="false"
        :clearable="true"
      />
    </template>

    <template v-slot:body="props">
      <q-tr
        class="cursor-pointer"
        :class="classRow[props.rowIndex]"
        :props="props"
        @click="selectedItem(props.rowIndex, props.row)"
      >
        <q-td
          auto-width
          v-for="col in props.cols"
          :key="col.name"
          :props="props"
        >
          <template v-if="col.field == 'actions'">
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                :color="col.color"
                class="material-icons"
                @click="col.click(props.row.idPergunta)"
              >
                {{ col.icon }}
              </q-icon>
            </q-btn>
          </template>
          <template v-else>
            {{ col.value }}
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <delete-confirmation-dialog
    ref="deleteConfirmationDialog"
    @onConfirm="deleteItem"
  ></delete-confirmation-dialog>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import TemaQASelectionComponent from "Components/Areas/RH/QA/Selecao/TemaQASelectionComponent";
import { mapActions } from "vuex";
export default {
  name: "PerguntasQAListComponent",
  components: { TemaQASelectionComponent },
  data() {
    return {
      loader: true,
      selectedItemToDelete: null,
      rows: [],
      classRow: [],
      columns: [
        {
          label: "ID",
          field: "idPergunta",
          name: "idPergunta",
          align: "right",
          sortable: true,
        },
        {
          label: "Tema",
          field: "descricaoTema",
          name: "descricaoTema",
          align: "left",
          sortable: true,
        },
        {
          label: "Pergunta",
          field: "pergunta",
          name: "pergunta",
          align: "left",
          sortable: true,
        },
        {
          label: "Complemento",
          field: "complemento",
          name: "complemento",
          align: "left",
          sortable: true,
        },
        {
          label: "Multipla Escolha?",
          field: (f) => (f["multiplaEscolha"] === 1 ? "Sim" : "Não"),
          name: "multiplaEscolha",
          align: "center",
          sortable: true,
        },
        {
          label: "",
          field: "actions",
          name: "actionsEdit",
          align: "center",
          color: "blue darken-2",
          icon: "edit",
          click: (id) => this.onUpdate(id),
        },
        {
          label: "",
          field: "actions",
          name: "actionsDelete",
          align: "center",
          color: "red darken-2",
          icon: "delete",
          click: (id) => this.onDelete(id),
        },
      ],
    };
  },
  methods: {
    ...mapActions("rh", ["setPergunta"]),

    listarPerguntas() {
      this.loader = true;
      api
        .get("/v1/rh/qa/perguntas")
        .then((res) => {
          this.rows = res.data;
          const pergunta = this.$store.state.rh.pergunta;
          if (pergunta && res.data.length > 0) {
            const index = this.rows.findIndex(
              (f) => f.idPergunta === pergunta.idPergunta
            );
            this.classRow[index] = "bg-selected text-white";
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    filtrarPorTema(selected) {
      if (!selected) {
        this.listarPerguntas();
        return;
      }
      this.loader = true;
      api
        .get(`/v1/rh/qa/perguntas/tema/${selected.id}`)
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    onAdd() {
      this.$router.push({
        name: "perguntasqadetailview",
        params: { id: 0 },
      });
    },

    onUpdate(id) {
      this.$router.push({
        name: "perguntasqadetailview",
        params: { id },
      });
    },

    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectedItemToDelete = id;
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      await api
        .delete(`v1/rh/qa/pergunta/${this.selectedItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarPerguntas());
    },

    selectedItem(index, item) {
      this.classRow = [];
      this.classRow[index] = "bg-selected text-white";
      this.setPergunta(item);
    },
  },
  mounted() {
    this.listarPerguntas();
  },
};
</script>

<style scoped>
.bg-selected {
  background: rgba(0, 0, 0, 0.2);
}
.q-table tbody td:before {
  background: rgba(0, 0, 0, 0.1);
}

/* .my-sticky-header-table{
  height: 700px;
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
} */
</style>
