<template>
    <div>
        <ListaItensPedido
            :data="dadosPedSimples"
            :loader="loader"
            :title="$t('message.orderItemList')"
            v-show="dadosPedSimples.length > 0"
        />

        <ListaItensPedido
            :data="dadosPedConjugados"
            :loader="loader"
            :title="$t('message.orderItemListConjugate')"
            v-show="dadosPedConjugados.length > 0"
        />
    </div>
</template>

<script>
import ListaItensPedido from 'Components/Areas/Comercial/ListaItensPedido';
import api from 'Api';
import { showError } from '@/notifications/notify';

export default {
    name: 'ItensPedido',
    props: ['idPedido'],
    components: { ListaItensPedido },
    data() {
        return {
            loader: true,
            dadosPedConjugados: [],
            dadosPedSimples: [],
            dadosTags: [],
        };
    },
    methods: {
        listarItensPedidos(conjugado) {
            this.loader = true;
            api.post('v1/comercial/pedidos/listaritenspedidos', {
                idPedido: this.idPedido,
                conjugado,
            })
                .then(res => {
                    if (conjugado) {
                        this.dadosPedConjugados = res.data;
                    } else {
                        this.dadosPedSimples = res.data;
                    }
                })
                .catch(err => showError(err))
                .finally(() => {
                    this.loader = false;
                });
        },
    },
    mounted() {
        this.listarItensPedidos(false);
        this.listarItensPedidos(true);
    },
};
</script>
