<template>
  <div>
    <title-bar :title="$t('message.blockedPreInvoices')">
      <template v-slot:funcoes>
        <q-space />
        <q-btn flat round size="small">
          <q-tooltip
            anchor="bottom middle"
            self="center middle"
            :offset="[10, 10]"
            class="text-body2"
            >{{ $t("message.filter") }}</q-tooltip
          >
          <q-icon
            @click="showFilter = !showFilter"
            class="material-icons text-grey-9"
          >
            filter_alt
          </q-icon>
        </q-btn>

        <exportar-excel
          v-if="rows"
          :data="rows"
          name="listaoprenotasbloqueadas"
          class="q-mr-sm q-mt-xs"
        />
      </template>
    </title-bar>

    <app-section-loader :status="loader" />

    <q-grid
      ref="table"
      :data="rows"
      :columns="columns"
      :dense="true"
      :columns_filter="showFilter"
      :totalizador="true"
      :subtotalizador="true"
    >
      <template v-slot:pedido="props">
        <router-link
          :to="{
            name: 'pedidoUnico',
            params: { idPedido: props.value },
          }"
        >
          <q-tooltip anchor="bottom right" self="top right" class="text-body2">
            {{ $t("message.salesOrder") }}
          </q-tooltip>
          {{ props.value }}
        </router-link>
      </template>
    </q-grid>
  </div>
</template>

<script>
import api from "Api";
import { mapGetters } from "vuex";
import { showError } from "@/notifications/notify";
import { filtrarPedido } from "Helpers/FiltrosComerciais";
import { aguardar } from "Helpers";

export default {
  name: "ListaPreNotasBloqueadas",
  props: ["idCliente", "idRepresentante"],
  data() {
    return {
      dadosOriginal: [],
      rows: [],
      grupoEconomico: null,
      loader: true,
      showFilter: false,
      filter: null,
      columns: [
        {
          label: this.$t("message.preInvoice"),
          field: "preNota",
          name: "preNota",
          sortable: true,
          titleTotal: true,
        },

        {
          label: this.$t("message.customer"),
          field: "dsCliente",
          name: "dsCliente",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.seller"),
          field: "dsRepresentante",
          name: "dsRepresentante",
          align: "left",
        },
        {
          label: "CFOP",
          field: "cdCfop",
          name: "cdCfop",
          align: "left",
        },
        {
          label: this.$t("message.order"),
          field: "pedido",
          name: "pedido",
        },
        {
          label: this.$t("message.block"),
          field: "bloqueioMacro",
          name: "bloqueioMacro",
          align: "left",
        },
        {
          label: this.$t("message.amount"),
          field: "valorOf",
          name: "valorOf",
          total: true,
          format: "decimal",
        },
        {
          label: this.$t("message.grossWeight"),
          field: "pesoBruto",
          name: "pesoBruto",
          total: true,
          format: "decimal",
        },
        {
          label: this.$t("message.netWeight"),
          field: "pesoLiquido",
          name: "pesoLiquido",
          total: true,
          format: "decimal",
        },
        {
          label: this.$t("message.volume"),
          field: "volume",
          name: "volume",
          total: true,
        },
        {
          label: this.$t("message.user"),
          field: "usuario",
          name: "usuario",
          align: "left",
        },
        {
          label: this.$t("message.lockDate"),
          field: "dataBloqueio",
          name: "dataBloqueio",
          sortable: true,
          align: "center",
        },
        {
          label: this.$t("message.lockReason"),
          field: "motivoBloqueio",
          name: "motivoBloqueio",
          align: "left",
        },
        {
          label: this.$t("message.releaseDate"),
          field: "dataUltLib",
          name: "dataUltLib",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("clientes", ["getGrupoEconomico"]),
    ...mapGetters({ computedGetPedido: "comercial/getPedido" }),
  },
  watch: {
    getGrupoEconomico(val) {
      this.grupoEconomico = val;
      this.listaOfsBloqueadas();
    },
    computedGetPedido: "filtrarExibirPedido",
  },
  methods: {
    listaOfsBloqueadas() {
      this.loader = true;
      api
        .post("/v1/faturamento/prenotas/ofsbloqueadas", {
          idCliente: this.idCliente,
          idRepresentante: this.idRepresentante,
          grupoeconomico: this.grupoEconomico,
        })
        .then((res) => {
          if (res.data) {
            this.rows = res.data;
            this.dadosOriginal = this.rows;
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    filtrarExibirPedido(pedido) {
      if (!this.loader) {
        this.rows = filtrarPedido(this.dadosOriginal, pedido);
      } else {
        aguardar(pedido, this.filtrarExibirPedido, 1000);
      }
    },
  },
  created() {
    this.listaOfsBloqueadas();
  },
};
</script>
