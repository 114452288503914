const pesquisaPedido = (pedido, pedidocliente, filtro) => {
  if (filtro) {
    return (
      pedido === parseInt(filtro, 10) || pedidocliente?.trim() === filtro.trim()
    );
  }
  return pedido > 0;
};

const pesquisaStatus = (filtro, status) => {
  if (filtro.length === 0) {
    return true;
  }
  return filtro.filter((v) => v === status).length > 0;
};

const filtrarSituacao = (dados, filtro) => {
  return !dados || dados.length === 0
    ? null
    : dados.filter((v) => pesquisaStatus(filtro, v.cdResumo));
};

const filtrarPedido = (dados, pedido) => {
  return !dados || dados.length === 0
    ? null
    : dados.filter((v) => pesquisaPedido(v.pedido, v.pedidocliente, pedido));
};

module.exports = {
  filtrarSituacao,
  filtrarPedido,
};
