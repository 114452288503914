<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-form ref="form" v-model="valid" autofocus @submit="salvar">
        <div class="row">
          <div class="col" cols="12" sm="12" md="4" lg="4">
            <q-input
              rounded
              outlined
              dense
              v-model="menuData.id"
              label="Id"
              disable
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
          <div class="col" cols="12" sm="12" md="4" lg="4">
            <q-input
              ref="icone"
              rounded
              outlined
              dense
              class="q-ml-sm"
              v-model="menuData.icone"
              :label="$t('message.icon')"
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>

          <div class="col" cols="12" sm="12" md="4" lg="4">
            <q-input
              rounded
              outlined
              dense
              class="q-ml-sm"
              v-model="menuData.descricao"
              :rules="descriptionRules"
              :label="$t('message.description')"
              required
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>

        <div class="row">
          <div class="col" cols="12" sm="12" md="4" lg="4">
            <q-input
              rounded
              outlined
              dense
              v-model="menuData.descricaoMultLang"
              :label="$t('message.multiLanguage')"
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
          <div class="col" cols="12" sm="12" md="4" lg="4">
            <MenuParentSelecao
              class="q-ml-sm"
              :prepend-icon="icon"
              @selected="obterMenuParent($event)"
              :parentId="menuData.parentId"
            ></MenuParentSelecao>
          </div>
          <div class="col" cols="12" sm="12" md="4" lg="4">
            <q-input
              class="q-ml-sm"
              rounded
              outlined
              dense
              v-model="menuData.routeName"
              :label="$t('message.route')"
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>

        <div class="row q-mt-md">
          <div class="col" cols="12" sm="12" md="4" lg="4">
            <PowerBiSelecao
              class="q-ml-sm"
              :prepend-icon="icon"
              @selected="obterRelatorio($event)"
              :reportId="menuData.reportid"
            ></PowerBiSelecao>
          </div>
          <div class="col" cols="12" sm="12" md="4" lg="4">
            <q-input
              rounded
              outlined
              dense
              class="q-ml-sm"
              type="number"
              v-model="menuData.ordem"
              :rules="sortRules"
              :label="$t('message.sort')"
              required
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>

          <div class="col" cols="12" sm="12" md="4" lg="4">
            <q-select
              rounded
              outlined
              dense
              class="q-ml-sm"
              :options="options"
              option-label="label"
              option-value="value"
              v-model="menuData.status"
              required
              :label="$t('message.systemVersion')"
              :prepend-icon="icon"
              map-options
              emit-value
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-select>
          </div>
        </div>

        <div class="col" cols="12" sm="12" md="4" lg="4">
          <q-toggle
            class="q-ml-sm q-mb-lg"
            v-model="menuData.ativo"
            color="blue"
            :label="$t('message.active')"
            checked-icon="check"
            unchecked-icon="clear"
          ></q-toggle>
        </div>

        <q-btn
          color="success"
          class="q-mr-sm q-mt-sm"
          type="submit"
          size="md"
          rounded
        >
          {{ $t("message.save") }}
        </q-btn>

        <q-btn
          color="danger"
          class="q-mt-sm"
          size="md"
          type="sair"
          @click.prevent="$router.go(-1)"
          rounded
        >
          {{ $t("message.close") }}
        </q-btn>
      </q-form>

      <hr />
      <MenuLista
        v-if="id"
        :parentId="id.toString()"
        :exibir="false"
        :classe="''"
        :key="id"
      ></MenuLista>
    </div>
  </div>
</template>

<script>
import api from "Api";
import PowerBiSelecao from "Components/Areas/Admin/PowerBi/PowerBiSelecao";
import MenuParentSelecao from "Components/Areas/Admin/Menu/MenuParentSelecao";
import MenuLista from "Views/Admin/Menu/MenuLista";
import { showError, showSuccess } from "@/notifications/notify";
import { clearObject } from "Helpers";

export default {
  name: "MenuDetalhe",
  components: { PowerBiSelecao, MenuParentSelecao, MenuLista },
  data() {
    return {
      loader: true,
      valid: true,
      options: [
        {
          value: 0,
          label: "3.0",
        },
        {
          value: 1,
          label: "1.0",
        },
      ],
      id: this.$route.params.id,
      menuData: {
        descricao: null,
        descricaoMultLang: null,
        routeName: undefined,
        parentId: null,
        ordem: null,
        ativo: null,
        parentDesc: null,
        icone: null,
        externalLink: null,
        status: null,
        reportId: null,
      },
      parentDescricao: null,
      descriptionRules: [
        (v) => !!v || this.$t("message.descriptionRequired"),
        (v) =>
          (v && v.length <= 100) ||
          `${this.$t("message.description")}
           ${this.$t("message.mustbelessthan")} 100
           ${this.$t("message.characters")}`,
      ],
      sortRules: [(v) => !!v || this.$t("message.sortRequired")],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterMenu();
    },
    "menuData.routeName": function (newVal, oldVal) {
      if (oldVal === undefined) {
        return;
      }
      if (newVal) {
        this.menuData.reportId = null;
      }
    },
  },
  methods: {
    salvar() {
      let message;
      let method;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
      } else {
        message = "message.successAddedRecord";
        method = "post";
      }

      this.loader = true;
      const item = this.menuData;

      api[method](`/v1/menu/${this.id}`, item)
        .then(() => {
          if (!this.id) {
            clearObject(this.menuData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    obterMenu() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/menu/${this.id}`)
        .then((response) => {
          this.menuData = response.data[0];
          this.menuData.ativo = this.menuData.ativo === 1;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    obterMenuParent(e) {
      this.menuData.parentId = e.id;
      this.parentDescricao = e.descricao;
    },
    obterRelatorio(e) {
      this.menuData.routeName = "";
      this.menuData.reportId = e.id;
    },
  },
  mounted() {
    this.obterMenu();
    this.$refs.icone.focus();
  },
};
</script>
