<template>
  <!-- Breakpoints acima de "small" -->

  <div class="row gt-sm" no-gutters>
    <div class="col" id="imagemBanner" style="height: 100vh">
      <div class="containerImagem-r7">
        <q-img :src="imagem" class="imagem-r7" />
      </div>
    </div>

    <div class="col" items-center id="formularioLogin">
      <div class="row flex-center q-my-xl q-pt-xl" style="min-width: 384px">
        <q-img :src="appLogo" fit width="384px" style="min-width: 384px" />
      </div>
      <br />
      <div class="row flex-center" no-gutters>
        <h5 style="max-width: 384px" class="q-mb-md">
          {{ $t("message.confirmPassword") }}
        </h5>
      </div>
      <div class="row flex-center q-my-md" no-gutters>
        <p class="text-body" style="max-width: 384px">
          Bem Vindo ao CRM do Grupo Rosset, digite abaixo a sua senha e confirme
          para ter acesso ao sistema
        </p>
      </div>

      <div class="row flex-center q-mt-lg" no-gutters>
        <div clas="col ">
          <q-form class="5" ref="form">
            <q-input
              ref="password"
              filled
              autocomplete="on"
              :label="messageNewPassword"
              v-model="newPassword"
              :rules="passwordRules"
              :type="isPwd ? 'password' : 'text'"
              required
              style="min-width: 384px"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
            <q-input
              ref="confirmPassword"
              filled
              :label="messageConfirmPassword"
              v-model="confirmPassword"
              type="password"
              :rules="confirmPasswordRules"
              required
              style="min-width: 384px"
            ></q-input>
            <q-btn
              class="q-btn-login q-mt-md"
              @click="confirmarSenha"
              block
              color="primary"
              style="min-width: 384px"
            >
              {{ $t("message.confirmPassword") }}
            </q-btn>
          </q-form>
        </div>
      </div>
      <div class="row flex-center q-mt-sm" no-gutters>
        <div
          class="col-auto flex-center q-mx-md q-px-xs q-mt-md"
          v-for="language in languages"
          :key="language.name"
        >
          <q-btn flat small dense>
            <img
              :src="getImagePath(`/static/flag-icons/${language.icon}.png`)"
              @click="changeLanguage(language)"
              class="q-mx-md q-mx-lg"
            />
          </q-btn>
        </div>
      </div>
    </div>
  </div>

  <!-- Breakpoints abaixo de "medium" -->

  <div class="row lt-md" no-gutters>
    <div class="col" items-center id="formularioLogin">
      <div class="row flex-center q-my-xl q-pt-xl" style="min-width: 384px">
        <q-img :src="appLogo" fit width="384px" style="min-width: 384px" />
      </div>
      <br />
      <div class="row flex-center" no-gutters>
        <h5 style="max-width: 384px" class="q-mb-md">
          {{ $t("message.confirmPassword") }}
        </h5>
      </div>
      <div class="row flex-center q-my-md" no-gutters>
        <p class="text-body" style="max-width: 384px">
          Bem Vindo ao CRM do Grupo Rosset, digite abaixo a sua senha e confirme
          para ter acesso ao sistema
        </p>
      </div>

      <div class="row flex-center q-mt-lg" no-gutters>
        <div clas="col ">
          <q-form class="5" ref="form">
            <q-input
              ref="password"
              filled
              autocomplete="on"
              :label="messageNewPassword"
              v-model="newPassword"
              :rules="passwordRules"
              :type="isPwd ? 'password' : 'text'"
              required
              style="min-width: 384px"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
            <q-input
              ref="confirmPassword"
              filled
              :label="messageConfirmPassword"
              v-model="confirmPassword"
              type="password"
              :rules="confirmPasswordRules"
              required
              style="min-width: 384px"
            ></q-input>
            <q-btn
              class="q-btn-login q-mt-md"
              @click="confirmarSenha"
              block
              color="primary"
              style="min-width: 384px"
            >
              {{ $t("message.confirmPassword") }}
            </q-btn>
          </q-form>
        </div>
      </div>
      <div class="row flex-center q-mt-sm" no-gutters>
        <div
          class="col-auto flex-center q-mx-md q-px-xs q-mt-md"
          v-for="language in languages"
          :key="language.name"
        >
          <q-btn flat small dense>
            <img
              :src="getImagePath(`/static/flag-icons/${language.icon}.png`)"
              @click="changeLanguage(language)"
              class="q-mx-md q-mx-lg"
            />
          </q-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "Api";
import AppConfig from "Constants/AppConfig";
import { showSuccess, showError } from "@/notifications/notify";

export default {
  data() {
    return {
      appLogo: AppConfig.appLogo2,
      newPassword: "",
      confirmPassword: "",
      token: "",
      isPwd: true,
      passwordRules: [
        (v) => !!v || this.$t("message.passwordRequired"),
        (v) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return pattern.test(v) || this.$t("message.patternPassword");
        },
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$t("message.passwordRequired"),
        (v) =>
          this.newPassword === this.confirmPassword ||
          this.$t("message.messagepPasswordNotMatch"),
      ],
      messageNewPassword: this.$t("message.newPassword"),
      messageConfirmPassword: this.$t("message.confirmPassword"),
      messagepPasswordNotMatch: this.$t("message.messagepPasswordNotMatch"),
      getImagePath(path) {
        return process.env.BASE_URL + path;
      },
    };
  },

  methods: {
    confirmarSenha() {
      const validate =
        this.$refs.password.validate() && this.$refs.confirmPassword.validate();
      if (!validate) return;
      api
        .post("/auth/forgotPasswordConfirm", {
          token: this.token,
          password: this.newPassword,
          confirmPassword: this.confirmPassword,
        })
        .then(() => {
          showSuccess(this.$t("message.passwordConfirmed"));
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 2000);
        })
        .catch((err) => showError(err));
    },
    validateToken() {
      this.token = this.$route.params.token;
      if (!this.token) {
        showError("Token não informado!");
        this.$router.push({ name: "login" });
        return;
      }
      api.get(`/auth/forgotPasswordValidate/${this.token}`).catch((err) => {
        showError(err);
        this.$router.push({ name: "login" });
      });
    },
  },
  created() {
    this.validateToken();
  },
};
</script>
