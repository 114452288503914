<template>
  <app-section-loader :status="loader" />

  <q-card class="q-pa-md">
    <q-form ref="form" autofocus @submit="onSave">
      <div class="row no-wrap justify-evenly">
        <div class="col-4" cols="12" sm="12" md="4" lg="4">
          <h6 class="text-primary">{{ $t("message.answerInformations") }}:</h6>
          <br />

          <q-input
            class="q-mt-sm"
            ref="pergunta"
            rounded
            outlined
            dense
            autogrow
            v-model="respostaData.pergunta"
            :label="$t('message.question')"
            :disable="true"
          />

          <q-input
            class="q-mt-sm"
            ref="complemento"
            rounded
            outlined
            dense
            v-model="respostaData.complemento"
            :label="$t('message.complement')"
            :disable="true"
          />

          <q-input
            class="q-mt-sm"
            ref="resposta"
            rounded
            outlined
            dense
            autogrow
            v-model="respostaData.resposta"
            :label="$t('message.answer')"
            required
            :rules="answerRules"
          />

          <div class="row q-mb-sm">
            <div class="col">
              <q-toggle
                v-model="respostaData.isCorreto"
                color="green"
                :label="$t('message.isCorrect')"
                checked-icon="check"
                unchecked-icon="clear"
              ></q-toggle>
            </div>
          </div>

          <br />
          <div class="text-center">
            <q-btn
              color="success"
              class="q-mr-sm q-mt-md"
              type="submit"
              size="md"
              rounded
            >
              {{ $t("message.save") }}
            </q-btn>

            <q-btn
              color="danger"
              class="q-mt-sm q-mt-md"
              size="md"
              type="sair"
              @click.prevent="$router.go(-1)"
              rounded
            >
              {{ $t("message.close") }}
            </q-btn>
          </div>
        </div>
      </div>
    </q-form>
  </q-card>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "RespostasQADetailComponent",
  data() {
    return {
      loader: true,
      valid: true,
      respostaData: {
        idResposta: null,
        pergunta: null,
        complemento: null,
        resposta: null,
        isCorreto: false,
      },

      id: this.$route.params.id,

      answerRules: [(v) => !!v || this.$t("message.answerRequired")],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterResposta();
    },
  },
  methods: {
    onSave() {
      let message;
      let method;
      let url;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/rh/qa/resposta/${this.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/rh/qa/resposta/";
      }

      this.loader = true;
      const item = this.respostaData;

      api[method](url, item)
        .then(() => {
          if (!this.id) {
            this.respostaData.resposta = null;
            this.respostaData.isCorreto = false;
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.$refs.resposta.focus();
        });
    },
    obterResposta() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        this.obterPergunta();
        return;
      }
      api
        .get(`/v1/rh/qa/resposta/${this.id}`)
        .then((res) => {
          if (res.data) {
            this.respostaData = res.data;
            this.respostaData.isCorreto = res.data.iscorreto == 1;
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    obterPergunta() {
      const idPergunta = this.$store.state.rh.pergunta?.idPergunta;
      if (!idPergunta) {
        showError("Pergunta não selecionada!");
      } else {
        api
          .get(`/v1/rh/qa/pergunta/${idPergunta}`)
          .then((res) => {
            if (res.data) {
              this.respostaData.idPergunta = res.data.idPergunta;
              this.respostaData.pergunta = res.data.pergunta;
              this.respostaData.complemento = res.data.complemento;
            }
          })
          .catch((err) => showError(err))
          .finally(() => (this.loader = false));
      }
    },
  },
  mounted() {
    this.obterResposta();
    this.$refs.resposta.focus();
  },
};
</script>
