<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-form
        ref="form"
        v-model="valid"
        class="q-gutter-md"
        autofocus
        @submit="salvar"
      >
        <div class="row">
          <div class="col">
            <q-input
              rounded
              outlined
              dense
              v-model="relatorioData.id"
              label="Id"
              disable
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>

        <div class="row q-mt-md">
          <div class="col">
            <q-input
              ref="descricao"
              rounded
              outlined
              dense
              v-model="relatorioData.descricao"
              :rules="descriptionRules"
              :label="$t('message.description')"
              required
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
          <div class="col">
            <q-input
              rounded
              outlined
              dense
              class="q-ml-sm"
              v-model="nomeRelatorio"
              :label="$t('message.reportName')"
              required
              readonly
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <q-input
              rounded
              outlined
              dense
              v-model="relatorioData.groupid"
              :rules="groupIdRules"
              label="Group ID"
              required
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>

          <div class="col">
            <q-input
              rounded
              outlined
              dense
              class="q-ml-sm"
              v-model="relatorioData.reportid"
              :rules="reportIdRules"
              label="Report ID"
              required
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <q-input
              rounded
              outlined
              dense
              v-model="relatorioData.embedcontent"
              :rules="embedContentRules"
              label="Embed Content"
              required
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>

        <q-btn
          color="success"
          class="q-mr-sm q-mt-sm"
          type="submit"
          size="md"
          rounded
        >
          {{ $t("message.save") }}
        </q-btn>

        <q-btn
          color="danger"
          class="q-mt-sm"
          size="md"
          type="sair"
          @click.prevent="$router.go(-1)"
          rounded
        >
          {{ $t("message.close") }}
        </q-btn>

        <q-separator />
        <ListaDeFiltrosPorRelatorio :idRelatorio="id" />
      </q-form>
    </div>
  </div>
</template>

<script>
import api from "Api";
import ListaDeFiltrosPorRelatorio from "Components/Areas/Admin/PowerBi/ListaDeFiltrosPorRelatorio";
import { camelCase } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";
import { clearObject } from "Helpers";

export default {
  name: "RelatoriosPowerBiDetalhe",
  components: { ListaDeFiltrosPorRelatorio },
  data() {
    return {
      loader: true,
      valid: true,
      id: this.$route.params.id,
      relatorioData: {
        nomerelatorio: null,
        descricao: null,
        reportid: null,
        groupid: null,
        embedcontent: null,
      },
      descriptionRules: [
        (v) => !!v || this.$t("message.descriptionRequired"),
        (v) =>
          (v && v.length <= 100) ||
          `${this.$t("message.description")} 
           ${this.$t("message.mustbelessthan")} 100 
           ${this.$t("message.characters")}`,
      ],
      groupIdRules: [
        (v) => !!v || `Group ID ${this.$t("message.required")}`,
        (v) =>
          (v && v.length <= 255) ||
          `Group ID 
           ${this.$t("message.mustbelessthan")} 255
           ${this.$t("message.characters")}`,
      ],
      reportIdRules: [
        (v) => !!v || `Report ID ${this.$t("message.required")}`,
        (v) =>
          (v && v.length <= 100) ||
          `Report ID
           ${this.$t("message.mustbelessthan")} 255
           ${this.$t("message.characters")}`,
      ],
      embedContentRules: [
        (v) => !!v || `Embed Content ${this.$t("message.required")}`,
        (v) =>
          (v && v.length <= 512) ||
          `Embed Content
           ${this.$t("message.mustbelessthan")} 512
           ${this.$t("message.characters")}`,
      ],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
    nomeRelatorio() {
      return camelCase(this.relatorioData.descricao);
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id;
      this.obterRelatorio();
    },
  },
  methods: {
    salvar() {
      if (!this.$refs.form.validate()) return;
      let message;
      let method;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
      } else {
        message = "message.successAddedRecord";
        method = "post";
      }

      this.relatorioData.nomerelatorio = this.nomeRelatorio;

      this.loader = true;
      api[method](`/v1/powerbi/${this.id}`, this.relatorioData)
        .then(() => {
          if (!this.id) {
            clearObject(this.relatorioData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
    obterRelatorio() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }

      api
        .get(`/v1/powerbi/${this.id}`)
        .then((response) => {
          this.relatorioData = response.data;
          this.loader = false;
        })
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.obterRelatorio();
    this.$refs.descricao.focus();
  },
};
</script>
