// messages.js
export default {
  general: "Geral",
  home: "Home",
  overview: "Visão Geral",
  customizer: "Customizado",
  applications: "Aplicação",
  features: "Novidades",
  components: "Componentes",
  salesAndVisitStats: "Sales & Visits Stats",
  orderAndProjectStats: "Order & Projects Stats",
  fitnessStats: "Fitness Stats",
  supportAndUsefulWidgets: "Support & Useful Widgets",
  dashboard: "Painel de Controle",
  dashboardOverview: "Dashboard Overview",
  dashboardv1: "Dashboard V1",
  dashboardv2: "Dashboard V2",
  widgets: "Widgets",
  horizontalMenu: "Horizontal Menu",
  pages: "Páginas",
  blank: "Branco",
  session: "Sessão",
  signUp1: "Sign Up 1",
  signUp2: "Sign Up 2",
  login1: "Conectar 1",
  Conectar2: "Conectar 2",
  lockScreen: "Bloqueio de Tela",
  uiElements: "UI Elements",
  buttons: "Botões",
  cards: "Cartões",
  checkbox: "Checkbox",
  carousel: "Carrossel",
  chips: "Chips",
  datepicker: "Datepicker",
  dialog: "Diálogo",
  grid: "Grid",
  input: "Input",
  list: "List",
  menu: "Menu",
  progress: "Progress",
  radio: "Radio",
  select: "Select",
  slider: "Slider",
  snackbar: "Snackbar",
  tabs: "Tabs",
  toolbar: "Toolbar",
  tooltip: "Dicas",
  timepicker: "Timepicker",
  forms: "Forms",
  formValidation: "Form Validation",
  stepper: "Stepper",
  charts: "Charts",
  vueChartjs: "Vue Chartjs",
  vueEcharts: "Vue Echarts",
  icons: "Icons",
  themify: "Themify",
  material: "Material",
  tables: "Tables",
  standard: "Standard",
  slots: "Slots",
  selectable: "Selectable",
  searchRow: "Search Row",
  maps: "Maps",
  googleMaps: "Google Maps",
  leafletMaps: "Leaflet Maps",
  jvectorMap: "Jvector Map",
  inbox: "Inbox",
  users: "Usuários",
  userProfile: "Perfil do Usuário",
  usersList: "Lista de Usuários",
  calendar: "Calendário",
  editor: "Editor",
  quillEditor: "Quill Editor",
  wYSIWYG: "WYSIWYG",
  vue2Dragula: "Vue2 Dragula",
  dragAndDrop: "Drag And Drop",
  vueDraggable: "Vue Draggable",
  draggableResizeable: "Draggable Resizeable",
  chat: "Chat",
  todo: "Todo",
  modules: "Modules",
  user: "Usuário",
  miscellaneous: "Miscellaneous",
  promo: "Promo",
  connections: "Connections",
  follow: "Follow",
  unfollow: "Unfollow",
  basicValidation: "Basic Validation",
  validationWithSubmitAndclear: "Validation with submit & clear",
  submit: "Enviar",
  clear: "Limpar",
  step1: "Step 1",
  step2: "Step 2",
  step3: "Step 3",
  continue: "Continuar",
  cancel: "Cancelar",
  totalEarnings: "Total Earnings",
  onlineRevenue: "Online Revenue",
  offlineRevenue: "Offline Revenue",
  marketingExpenses: "Marketing Expenses",
  newCustomers: "Novo Cliente",
  visitAndSalesStatistics: "Visit & Sales Statistics",
  collapse: "Colapsar",
  reload: "Recarregar",
  close: "Fechar",
  bandwidthUsage: "Bandwidth Usage",
  shoppingCart: "Shopping Cart",
  totalDownloads: "Total Downloads",
  productSales: "Product Sales",
  projectStatus: "Project Status",
  averageSteps: "Average Steps",
  todaysDistance: "Today's Distance",
  todaysStep: "Today's Step",
  todaysGoal: "Today's Goal",
  calories: "Calories",
  weeklySummary: "Weekly Summary",
  supportTickets: "Support Tickets",
  todoList: "To Do List",
  newsletterCampaign: "Newsletter Campaign",
  currentVisitors: "Current Visitors",
  newMembers: "Novo Membro",
  addNewBlog: "Add New Blog",
  add: "Adicionar",
  logOut: "Sair",
  totalAppMemory: "Total App Memory",
  totalMemoryUsed: "Total Memory Used",
  unreadMail: "12 Unread Mail",
  feedback: "Feedback",
  flatButton: "Flat Button",
  raisedButton: "Raised Button",
  gradientButton: "Gradient Button",
  buttonDropdownVariants: "Button Dropdown Variants",
  buttonToggle: "Button Toggle",
  icon: "Ícone",
  normal: "Normal",
  disabled: "Disabled",
  floating: "Floating",
  loaders: "Loaders",
  sizing: "Sizing",
  outline: "Outline",
  round: "Round",
  block: "Bloqueio",
  search: "Pesquisar",
  searching: "Pesquisando",
  checkboxesBoolean: "Checkboxes Boolean",
  checkboxesArray: "Checkboxes Array",
  checkboxesStates: "Checkboxes - States",
  checkboxesColors: "Checkboxes - Colors",
  switches: "Switches",
  default: "Padrão",
  customTransition: "Custom Transition",
  customDelimiter: "Custom Delimiter",
  hideControls: "Hide Controls",
  hideDelimiters: "Hide Delimiters",
  colored: "Colored",
  label: "Label",
  closeable: "Closeable",
  datePickerHorizontal: "Date Picker Horizontal",
  datePickerVertical: "Date Picker Vertical",
  datePickersInDialogAndMenu: "Date Pickers- In Dialog And Menu",
  datePickersWithModal: "Date Pickers With Modal",
  datePickersFormattingDate: "Date Pickers - Formatting Date",
  withoutActivator: "Without Activator",
  scrollable: "Scrollable",
  listOfAllMaterialIcons: "List Of All Material Icons",
  arrowsAndDirectionsIcons: "ARROWS & DIRECTION ICONS",
  webAppIcons: "WEB APP ICONS",
  CONTROLICONS: "CONTROL ICONS",
  TEXTEDITOR: "TEXT EDITOR",
  LAYOUTICONS: "LAYOUT ICONS",
  BRANDICONS: "BRAND ICONS",
  newClients: "New Clients",
  recurringClients: "Recurring Clients",
  bounceRate: "Bounce Rate",
  pageViews: "Page Views",
  usefulWidgets: "Useful Widgets",
  sale: "Sale",
  sales: "Sales",
  invoices: "Invoices",
  referrals: "Referrals",
  serverLoad: "Server Load",
  recentSale: "Recent Sale",
  supportRequest: "Support Request",
  productSale: "Product Sale",
  viewAll: "VIEW ALL",
  barChart: "Bar Chart",
  polarAreaChart: "Polar Area Chart",
  lineChart: "Line Chart",
  radarChart: "Radar Chart",
  doughnutChart: "Doughnut Chart",
  bubbleChart: "Bubble Chart",
  gradientLineChart: "Gradient Line Chart",
  pieChart: "Pie Chart",
  funnelChart: "Funnel Chart",
  loginNow: "Conectar",
  createAccount: "Create Account",
  termsOfService: "Terms of Service",
  bySigningUpYouAgreeTo: "By signing up you agree to",
  loginToAdmin: "Conectar como Admin",
  enterUsernameAndPasswordToAccessControlPanelOf: "Digite o usuário e senha",
  // "Digite o usuário e senha para acessar o painel de controle",
  havingAnAccount: "Tem uma conta?",
  quickLinks: "Quick Links",
  plans: "Plans",
  chooseThePlanThatWorksForYou: "Choose the plan that works for you.",
  monthly: "Monthly",
  yearlyGet2MonthExtra: "Yearly ( get 2 month extra)",
  basic: "Basic",
  pro: "Pro",
  advanced: "Advanced",
  basicFree: "Basic (Free)",
  startToBasic: "Start To Basic",
  upgradeToPro: "Upgrade To Pro.",
  upgradeToAdvance: "Upgrade To Advance",
  comparePlans: "Compare Plans",
  frequentlyAskedQuestions: "Frequently Asked Questions",
  defaultInput: "Default Input",
  activator: "Activator",
  hover: "Hover",
  menus: "Menus",
  indeterminate: "Indeterminate",
  sizeAndWidth: "Size & Width",
  rotate: "Rotate",
  determinate: "Determinate",
  buffer: "Buffer",
  radiosDefault: "Radios Default",
  radiosDirectionRow: "Radios Direction Row",
  radiosDirectionColumn: "Radios Direction Column",
  radiosColors: "Radios - Colors",
  switchesColors: "Switches - Colors",
  continuous: "Continuous",
  discrete: "Discrete",
  customColors: "Custom Colors",
  editableNumericValue: "Editable numeric value",
  contextualSnackbar: "Contextual snackbar",
  showSnackbar: "Show Snackbar",
  centeredTabs: "Centered Tabs",
  toolbarTabs: "Toolbar Tabs",
  theDefaultColorToolbar: "The Default Color Toolbar",
  appBar: "App Bar",
  appBarWithExtension: "App Bar With Extension",
  visibility: "Visibility",
  top: "Top",
  right: "Right",
  bottom: "Bottom",
  left: "Left",
  toggle: "Toggle",
  timePicker: "Time Picker",
  timePickerInDialogAndMenu: "Time Picker - In Dialog And Menu",
  itemsAndHeaders: "Items And Headers",
  selectableRows: "Selectable Rows",
  headerCell: "Header Cell",
  expand: "Expand",
  recentChat: "Recent Chat",
  previousChats: "Previous chats",
  addNewItems: "Add New Items",
  addToDo: "Add To Do",
  yes: "Sim",
  ok: "Ok",
  activeUsers: "Usuários ativos",
  addNew: "Adicionar novo",
  readMore: "Leia mais",
  assignNow: "Assign Now",
  totalRequest: "Total Request",
  new: "Novo",
  pending: "Pending",
  update: "Atualizar",
  updated10MinAgo: "Updated 10 min ago",
  addNewCustomer: "Adicionar novo cliente",
  name: "Nome",
  email: "Email",
  editMember: "Editar membro",
  scheduleDate: "Schedule Date",
  title: "Título",
  newEmails: "Novos Emails",
  newEmail: "Novo Email",
  employeePayroll: "Employee Payroll",
  forMostOfTheUsers: "For most of the users",
  choosePlan: "Choose Plan",
  mega: "Mega",
  master: "Master",
  forDeveloper: "For developer",
  forLargeEnterprises: "For large enterprises",
  composeEmail: "Compose Email",
  mailboxes: "Mailboxes",
  folders: "Folders",
  inbox5: "Inbox (5)",
  draft: "Draft",
  starred: "Starred",
  sentMessages: "Sent Messages",
  spam: "Spam",
  delete: "Excluir",
  work: "Work",
  personal: "Personal",
  manageFolders: "Manage Folders",
  contacts: "Contatos",
  settings: "Configurações",
  themeOptions: "Theme Options",
  darkMode: "Dark Mode",
  collapseSidebar: "Collapse Sidebar",
  boxLayout: "Box Layout",
  rtlLayout: "Rtl Layout",
  backgroundImage: "Background Image",
  sidebarFilters: "Sidebar Filters",
  danger: "Danger",
  primary: "Primary",
  warning: "Warning",
  success: "Successo",
  info: "Info",
  dailySales: "Daily Sales",
  trafficChannel: "Traffic Channel",
  campaignPerformance: "Campaign Performance",
  goToCampaign: "Go To Campaign",
  fullScreen: "Full Screen",
  ecommerce: "E-Commerce",
  shopWithAlgolia: "Shop With Algolia",
  cart: "Cart",
  total: "Total",
  totalPrice: "Total Price",
  viewCart: "View Cart",
  checkout: "Checkout",
  apply: "Aplicar",
  noItemsFound: "No Items Found",
  billingDetails: "Billing Details",
  placeOrder: "Place Order",
  addNewCard: "Add New Card",
  devicesShare: "Devices Share",
  customerOverview: "Customer Overview",
  reviews: "Reviews",
  weeklySales: "Weekly Sales",
  recentOrders: "Recent Orders",
  categorySales: "Category Sales",
  webAnalytics: "Web Analytics",
  topSelling: "Top Selling",
  social: "Social",
  newPost: "New Post",
  publish: "Publish",
  magazine: "Magazine",
  editUser: "Editar usuário",
  addNewUser: "Adicionar novo usuário",
  dark: "Dark",
  light: "Light",
  recentUsers: "Recentes usuários",
  activityAroundWorld: "Activity Around World",
  adsIncome: "Ads Income",
  recentComments: "Recent Comments",
  recentNotifications: "Recent Notifications",
  messages: "Mensagens",
  edit: "Editar",
  topAuthors: "Top Authors",
  marketingCampaign: "Marketing Campaign",
  article: "Artigo",
  websiteTraffic: "Website Traffic",
  agency: "Agency",
  recent: "Recente",
  trending: "Trending",
  totalPageViews: "Total Page Views",
  impressions: "Impressions",
  deviceSeparations: "Device Separations",
  browserStatics: "Browser Statics",
  totalDownloading: "Total Downloading",
  notifications: "Notificações",
  totalBookmarked: "Total Bookmarked",
  itemsDownloads: "Items Downloads",
  itemsUploaded: "Items Uploaded",
  totalAnalytics: "Total Analytics",
  hotKeywords: "Hot Keywords",
  MaleUsers: "Male Users",
  serverStatus: "Server Status",
  purchaseVuely: "Purchase Vuely",
  letsGetInTouch: "Lets Get In Touch",
  maleUsers: "Male Users",
  femaleUsers: "Female Users",
  androidUsers: "Android Users",
  iOSUsers: "iOS Users",
  advance: "Advance",
  routerAnimation: "Router Animation",
  salesAndEarning: "Sales And Earning",
  netProfit: "Net Profit",
  totalExpences: "Total Expences",
  photos: "Photos",
  adCampaignPerfomance: "Ad Campaign Perfomance",
  profitShare: "Profit Share",
  topSellingTheme: "Top Selling Theme",
  newRequest: "New Request",
  followers: "Followers",
  mailbox: "Mailbox",
  sent: "Enviado",
  birthdays: "Birthdays",
  events: "Events",
  newsletter: "Newsletter",
  lastMonth: "Last Month",
  allTime: "All Time",
  composeNewEmail: "Compose New Email",
  activity: "Activity",
  message: "Mensagem",
  selectTheme: "Selecione o tema",
  continueShopping: "Continue Shopping",
  proceedToCheckout: "Proceed To Checkout",
  headerFilters: "Header Filters",
  signUp: "Registrar",
  login: "Conectar",
  news: "Novidades",
  topHeadlines: "Top Headlines",
  visitors: "Visitors",
  subscribers: "Subscribers",
  twitterFeeds: "Twitter Feeds",
  extensions: "Extensions",
  imageCropper: "Image Cropper",
  videoPlayer: "Video Player",
  dropzone: "Dropzone",
  baseConfig: "Base Config",
  audioTrackAndPlaysInline: "Audio Track And Plays Inline",
  hlsLive: "HLS Live",
  password: "Senha",
  remember: "Lembrar",
  forgotPassword: "Esqueceu a senha",
  resetPassword: "Redefinir a senha",
  generatePassword: "Gerar Senha",

  backToSignIn: "Retorne para autenticar",
  enterYourEmailToSendYouAResetLink:
    "Enter your email to send you a reset link",
  pleaseEnterYourPasswordToReset: "Please Enter Your Password To Reset",
  saas: "SAAS",
  overallTrafficStatus: "Overall Traffic Status",
  projectManagement: "Project Management",
  projectTaskManagement: "Project Task Management",
  totalSales: "Total Sales",
  simple: "Simple",
  transitions: "Transitions",
  tax: "Tax",
  expenses: "Expenses",
  images: "Images",
  ratings: "Ratings",
  containAndCover: "Contain And Cover",
  height: "Height",
  fixedRatio: "Fixed Ratio",
  gradients: "Gradients",
  sizeVariants: "Size Variants",
  newIcons: "New Icons",
  webApplication: "Web Application",
  person: "Person",
  file: "Arquivo",
  comment: "Comment",
  form: "Form",
  hardware: "Hardware",
  directional: "Directional",
  mapAliases: "Map (Aliases)",
  dateAndTime: "Data/Hora",
  groups: "Groups",
  buttonGroups: "Button Groups",
  itemGroups: "Item Groups",
  windows: "Windows",
  onBoarding: "On Boarding",
  accountCreation: "Account Creation",
  treeview: "Treeview",
  customSelectableIcons: "Custom Selectable Icons",
  timelines: "Timelines",
  usage: "Usage",
  scopedSlots: "Scoped Slots",
  asyncItems: "Async Items",
  smallDots: "Small Dots",
  iconDots: "Icon Dots",
  coloredDots: "Colored Dots",
  oppositeSlot: "Opposite Slot",
  denseAlert: "Dense Alert",
  fixedTabs: "Fixed Tabs",
  rightAlignedTabs: "Right Aligned Tabs",
  content: "Conteúdo",
  withSearch: "With Search",
  iconsAndText: "Icons And Text",
  grow: "Grow",
  pagination: "Pagination",
  customIcons: "Custom Icons",
  courses: "Courses",
  coursesList: "Courses List",
  courseDetail: "Courses Detail",
  signIn: "Entrar",
  payment: "Payment",
  LearnWithYourConvenience: "Learn With Your Convenience",
  management: "Management",
  design: "Design",
  development: "Development",
  bestseller: "BestSeller",
  addToCart: "Add To Cart",
  popularInstructors: "Popular Instructors",
  moreCoursesFromJamesColt: "More Courses From James Colt",
  whatYoWillLearn: "What you Will learn",
  description: "Descrição",
  aboutInstructor: "About Instructor",
  userSignIn: "Login do usuário",
  guestCheckout: "Guest Checkout",
  continueAsGuest: "Continue as Guest",
  paymentOptions: "Payment Options",
  offerCode: "Offer Code",
  enterCardDetails: "Enter Card Details",
  makePayment: "Make Payment",
  withContent: "With Content",
  customHeight: "Custom Height",
  viewer: "Viewer",
  trade: "Trade",
  upcomingEvents: "Upcoming Events",
  ongoingProjects: "Ongoing Projects",
  viewAllNotifications: "View All Notifications",
  learnMore: "Learn More",
  payments: "Payments",
  taxRates: "Tax Rates",
  addTickets: "Add Tickets",
  projects: "Projects",
  clients: "Clientes",
  reports: "Relatórios",
  officeManagement: "Office Management",
  hotelManagement: "Hotel Management",
  projectGrid: "Project Grid",
  projectList: "Project List",
  projectData: "Project Data",
  videoCallingApp: "Video Calling App",
  hospitalAdministration: "Hospital Administration",
  revenue: "Revenue",
  deals: "Deals",
  transactionList: "Transaction List",
  transferReport: "Transfer Report",
  expenseCategory: "Expense Category",
  allClients: "All Clients",
  recentlyAdded: "Recently Added",
  favourite: "Favourite",
  tradeHistory: "Trade History",
  safeTrade: "Safe Trade",
  exchangeStatistics: "Exchange Statistics",
  quickTrade: "Quick Trade",
  recentTrades: "Recent Trades",
  exchangeRate: "Exchange Rate",
  coinsList: "Coins List",
  currenciesAvailable: "Currencies Available",
  receivedAmount: "Received Amount",
  sentAmount: "Sent Amount",
  totalAmount: "Total Amount",
  walletAddress: "Wallet Address",
  withdraw: "Withdraw",
  deposit: "Deposit",
  bankDetails: "Bank Details",
  phone: "Telefone",
  account: "Conta",
  buyCryptocurrency: "Buy Cryptocurrency",
  expenditureStats: "Expenditure Stats",
  sellCryptocurrency: "Sell Cryptocurrency",
  transferCryptocurrency: "Transfer Cryptocurrency",
  buyOrSell: "Buy / Sell",
  crm: "CRM",
  crypto: "Crypto",
  supervisor: "Supervisor",
  duration: "Duration",
  netWorth: "Net Worth",
  liveChatSupport: "Live Chat Support",
  marketCap: "Market Cap",
  wallet: "Wallet",

  budget: "Budget",
  department: "Department",
  projectManager: "Project Manager",
  team: "Team",
  status: "Situação",
  deadline: "Deadline",
  filesUploaded: "Files Uploaded",
  statistics: "Estatísticas",
  projectGallery: "Project Gallery",
  projectDetails: "Project Details",
  client: "Cliente",

  shop: "Shop",
  productDetail: "Product Detail",
  addProduct: "Add Product",
  editProduct: "Edit Product",
  editDetail: "Edit Detail",

  fileInput: "File Input",
  colorPickers: "Color Pickers",
  size: "Size",
  validation: "Validação",
  playground: "Playground",
  chipGroups: "Chip Groups",
  overlays: "Overlays",
  slideGroups: "Slide Groups",
  simpleTable: "Simple Table",
  listItemGroups: "List Item Groups",
  banners: "Banners",
  appBars: "App Bars",

  // Novos
  emailRequired: "Email obrigatório",
  userRequired: "Usuário obrigatório",
  passwordRequired: "Senha obrigatória",

  required: "Obrigatório",
  maxOccursExceeded: "Máximo de ocorrências excedida",
  confirm: "Confirmar",
  areYouSureYouWantToDelete: "Tem certeza de que deseja excluir?",
  areYouSureYouWantToDeleteThisRecord:
    "Tem certeza de que deseja excluir este registro permanentemente?",
  descriptionRequired: "Descrição é Obrigatória",
  successAddedRecord: "Registro incluído com Sucesso!",
  successUpdatedRecord: "Registro atualizado com Sucesso!",
  successDeletedRecord: "Registro excluído com Sucesso!",
  nameRequired: "Nome é obrigatório!",
  emailMustBeValid: "E-mail deve ser válido",
  accessLevel: "Nível de acesso",
  save: "Salvar",
  accessLevelRequired: "Nível de acesso necessário",
  mustbelessthan: "deve ter menos de",
  characters: "caracteres",
  requestSent: "Solicitação enviada",
  confirmPassword: "Confirme a senha",
  newPassword: "Nova senha",
  messagepPasswordNotMatch: "Senhas não conferem",
  patternPassword:
    "A senha deve ter pelo menos 8 caracteres e conter números, letras maiúsculas e minúsculas e caracteres de pontuação.",
  passwordConfirmed: "Senha confirmada",
  passwordLastSet: "Última definição de senha",
  badPasswordCount: "Tentativas de login inválidas",
  lastBadPassword: "Última tentativa de login inválida",
  admin: "Administração",
  menuList: "Lista Menu",
  multiLanguage: "Multi Idiomas",
  route: "Rota",
  order: "Pedido",
  orders: "Pedidos",
  sort: "Ordem",
  active: "Ativo",
  sortRequired: "Ordenação obrigatória",
  pageNotFound: "PÁGINA NÃO ENCONTRADA",
  goToHomePage: "IR PARA A PÁGINA INICIAL",
  accessLevelList: "Lista de Nível de Acesso",
  language: "Idioma",
  invoice: "Faturamento",
  customer: "Cliente",
  city: "Cidade",
  state: "Estado",
  comercial: "Comercial",
  customerList: "Lista de Clientes",
  typeInformationAboutCustomer:
    "Digite as informações sobre o cliente que você deseja pesquisar",
  customerSelected: "Cliente selecionado",
  customerProfile: "Perfil do cliente",
  seller: "Representante",
  manager: "Gerente",
  address: "Endereço",
  billingLast3Years: "Faturamento nos últimos 3 anos",
  amount: "Valor",
  kilos: "Quilos",
  alternativeUser: "Usuário alternativo",
  ordersSummary: "Resumo de pedidos",
  billing: "Faturamento",
  billingHistory: "Histórico de Faturamento",
  reportsDetails: "Detalhes de Relatórios",
  reportName: "Nome do Relatório",
  tradingName: "Nome Fantasia",
  lastOrder: "Último Pedido",
  customerSummary: "Resumo do Cliente",
  registrationData: "Dados Cadastrais",
  addresses: "Endereços",
  history: "Histórico",
  mainAddress: "Endereço Principal",
  billingAddress: "Endereço de cobrança",
  deliveryAddress: "Endereço de entrega",
  district: "Bairro",
  zipCode: "CEP",
  customerType: "Tipo de Cliente",
  joinOrders: "Juntar Pedidos",
  shippingCompany: "Transportadora",
  recordType: "Tipo de registro",
  goBack: "Voltar",
  orderDate: "Data do pedido",
  releaseDate: "Data de liberação",
  promisedDate: "Data Prometida",
  deliveryDate: "Data de entrega",
  customerOrder: "Pedido do cliente",
  productId: "Reduzido",
  productCode: "Artigo",
  colorCode: "Cor",
  designCode: "Desenho",
  preInvoice: "Pré-Nota",
  statusPreInvoice: "Status Pré-Nota",
  invoiceDocument: "Fatura",
  invoiceIssueDate: "Data de Emissão da Nota",
  invoiceReleaseDate: "Data de Expedição da Nota",
  ordersList: "Lista de Pedidos",
  typeOrder: "Digite o Pedido",
  immediateDelivery: "Pronta Entrega",
  quantity: "Quantidade",
  quantityKg: "Quantidade(KG)",
  balance: "Saldo",
  orderType: "Tipo de Pedido",
  reservedQuantityKg: "Reservado(KG)",
  reservedQuantityQtd: "Reservado(QTD)",
  reservedQuantityFat: "Reservado(FAT)",
  partialDelivery: "Parcial",
  combinedOrders: "Pedidos Conjugados",
  requirements: "Exigências",
  economicGroup: "Grupo Econômico",
  billOfSale: "Nota",
  carrier: "Transportadora",
  pickupNumber: "Coleta",
  pickupDate: "Dt. Coleta",
  lockReason: "Motivo Bloqueio",
  lockDate: "Dt. Bloqueio",
  grossWeight: "Peso Bruto",
  netWeight: "Peso Líquido",
  volume: "Volume",
  blockedPreInvoices: "Pré-Notas Bloqueadas",
  scheduledDeliveryDate: "Previsão de Entrega",
  businessRules: "Regras de Negócio",
  salesOrder: "Pedido de Venda",
  deliveryWeek: "Semana de Entrega",
  color: "Cor",
  tradingRules: "Regras de Negócio",
  rawArticleCode: "Código de Artigo Cru",
  rawArticleItem: "Item de Artigo Cru",
  financialInformation: "Informação Financeira",
  creditLimit: "Limite de Crédito",
  totalValueOrders: "Valor Total Pedidos",
  totalValueOfBonds: "Valor Total Títulos (Em aberto)",
  overdueAmount: "Valor em Atraso",
  creditBalance: "Saldo Crédito",
  openDuplicates: "Duplicatas em Aberto",
  situation: "Situação",
  value: "Valor",
  issueDate: "Data de Emissão",
  dueDate: "Data de Vencimento",
  lastDueDate: "Data do Último Vencimento",
  expirationDays: "Dias Vencimento",
  filial: "Filial",
  billingType: "Tipo de Faturamento",
  thirdPartyCustomer: "Cliente Terceiro",
  billingBlock: "Bloqueio Faturamento",
  paymentTerms: "Condição de Pagamento",
  commission: "Comissão",
  salesChannel: "Canal de Venda",
  federativeUnit: "UF",
  triangularCustomer: "Cliente Triangular",
  viaTransport: "Via Transporte",
  clientOrderNumber: "Nº Pedido Cliente",
  complete: "Completo",
  anticipate: "Antecipa",
  askedFor: "Solicitado Para",
  preOrder: "Pré-Pedido",
  conjugate: "Conjugado",
  typist: "Digitador",
  billingStatus: "Situação do Faturamento",
  orderValue: "Valor Pedido",
  approver: "Aprovador",
  lots: "Lotes",
  historyReport: "Histórico/Laudo",
  orderInquiry: "Consulta Pedido",

  reference: "Ref.",
  print: "Estampa",
  finishing: "Acabamentos",
  unitPrice: "Pr. Unitário",
  unit: "Un.",
  ordered: "Pedida",
  reserved: "Reservada",
  weighted: "Romaneado",
  billed: "Faturada",
  canceled: "Cancelada",
  ofItem: "OF",
  productSituation: "Sit.",
  quality: "Ql.",
  discount: "Desc.",
  noDataFound: "Nenhum Dado Encontrado",
  orderItemList: "Lista de Itens do Pedido",
  orderItemListConjugate: "Lista de Itens do Pedido Conjugado",

  productTagId: "Reduzido",
  price: "Preço",

  movideskUserList: "Lista de Usuário Movidesk",
  userId: "Id do Usuário",

  orderData: "Dados do Pedido",
  clientsData: "Dados do Cliente",
  businessData: "Dados Financeiros",
  generalData: "Dados Gerais",

  releaseNotes: "Release Notes",

  condition: "Condição",
  sellerSummary: "Resumo do Representante",
  sellerOrderList: "Lista de Pedidos do Representante",
  sellerList: "Lista de Representantes",
  typeInformationAboutSeller:
    "Digite as informações sobre o representante que você deseja pesquisar",
  sellerSelected: "Representante Selecionado",

  sellerClients: "Clientes do Representante",
  version: "Versão",

  moreOptions: "Mais Opções",

  externalLink: "Link Externo",
  systemVersion: "Versão do Sistema",

  movideskTickets: "Tickets Movidesk",

  widgetsList: "Lista de Widgets",
  widget: "Widget",
  component: "Componente",
  id: "Id",

  widgetInformations: "Dados do Widget",
  acessLevels: "Níveis Acesso",

  movideskList: "Lista de Tickets Movidesk",
  movideskDetail: "Detalhe do Ticket Movidesk",
  movidesk: "Movidesk",

  fullscreenMode: "Modo Tela Cheia",
  homePage: "Página Inicial",
  systemReleases: "Releases do Sistema",

  notificationsSetup: "Configurações de Notificações",
  markMessagesAsRead: "Marcar Mensagens Como Lidas",
  markRead: "Marcar como Lida",
  deleteMessage: "Excluir Mensagem",

  changeEmailFrequency: "Alterar Frequência de E-mail",
  neverSend: "Nunca Enviar",
  sendEveryHour: "Enviar a Cada Hora",
  sendInstantly: "Enviar Instantaneamente",
  desktopNotification: "Notificação Desktop",
  doNotNotify: "Não Notificar",
  notify: "Notificar",

  messageRegistration: "Cadastro de Mensagens",
  systemMessageList: "Lista de Mensagens do Sistema",
  categoryId: "Id da Categoria",

  titleRequired: "Título é Obrigatório",
  successMarkAsRead: "Mensagem Marcada Como Lida!",
  successMarkAllAsRead: "Mensagens Marcadas Como Lidas!",
  successUpdateMessageConfigurations:
    "Configuração de Mensagens Atualizada com Sucesso!",
  noMessagesAvailable: "Nenhuma Mensagem Disponível",

  category: "Categoria",

  ticketNumber: "Nº do Ticket",
  subject: "Assunto",
  origin: "Origem",
  type: "Tipo",
  createdBy: "Criado por",
  createdDate: "Data de Criação",
  lastUpdate: "Última Atualização",

  sampleDepositsList: "Lista de Depósitos Amostra",
  sampleDeposits: "Depósitos de Amostra",
  depositCode: "Código do Depósito",
  depositDescription: "Descrição do Depósito",
  updateDate: "Data de Atualização",
  updateUser: "Usuário Atualização",
  activeDeposit: "Depósito Ativo",
  inactiveDeposit: "Depósito Inativo",
  allDeposit: "Todos",
  changeSituation: "Alterar Situação",
  registerSampleDeposit: "Cadastrar Depósito de Amostra",
  insertDepositCode: "Inserir Código do Depósito",
  insertDepositDescription: "Inserir Descrição do Depósito",
  codeRequired: "Código é Obrigatório",
  code: "Código",
  mustBe: "deve ser de",
  successSubmitedDeposit: "Depósito cadastrado com Sucesso!",

  production: "Produção",
  registers: "Cadastros",

  send: "Enviar",
  copy: "Copiar",
  registerPriceList: "Registrar Lista de Preços",
  insertTableName: "Inserir Nome da Tabela",
  chooseTableStatus: "Escolher Situação da Tabela",
  chooseEmissionDate: "Escolher Data de Emissão",
  chooseValidityDate: "Escolher Data de Vagência",
  inactive: "Inativo",
  saletePriceList: "Lista de Preços Salete",
  purchases: "Compras",

  copyPriceList: "Copiar Tabela de Preços",
  referenceTable: "Tabela de Referência",

  saletePriceListItem: "Item da Lista de Preço Salete",
  saletePriceItem: "Preço do Item Salete",
  table: "Tabela",
  validity: "Validade",
  baseTable: "Tabela Base",
  familyStock: "Família Estoque",
  familyStockDescription: "Descrição Família Estoque",
  physicalFinish: "Acab. Físico",
  physicalFinishDescription: "Descrição Acab. Físico",
  hasPrint: "Estampado?",
  variantOf: "Variante De",
  variantUntil: "Variante Até",
  quantityOf: "Qtde. De",
  quantityUntil: "Qtde. Até",
  tableNumber: "Nº Tabela",
  item: "Item",
  productInfo: "Informações do Reduzido",
  typeProductId: "Digite o código do reduzido que você deseja pesquisar",
  emissionDate: "Dt. Emissão",
  validityDate: "Dt. Vigência",
  isTableActive: "Ativa?",
  stock: "Estoque",
  stockPosition: "Posição do Estoque",

  statusRequired: "Situação é obrigatório!",
  dateRequired: "Data é obrigatório!",
  reduceInfo: "Info. Reduzido",

  industrialCode: "Código Industrial",
  varRef: "Var. Ref.",
  dimension: "Dimensão",
  print6: "Desenho(6)",
  varDes: "Var. Des.",
  physicalFinish: "Acab. Físico",
  chemicalFinish: "Acab. Químico",
  reference2: "Referência",
  pleaseWait: "Por favor aguarde...",

  mustbegreaterthan: "deve ter maior que",
  ti: "T.I.",
  helpdeskAnalysis: "Análise dos Chamados",

  excelExport: "Exportar Excel",
  filter: "Filtrar",

  dashboards: "Dashboards",
  dashboard: "Dashboard",
  dashboardsList: "Lista de Dashboards",
  dashboardStatus: "Situação do Dashboard",
  groupId: "Grupo Id",
  dashboardId: "Dashboard Id",
  dashboardInformations: "Dados do Dashboard",
  defaultDashboard: "Dashboard Padrão",

  permission: "Permissão",
  allowed: "Permitido",
  notAllowed: "Não Permitido",

  SGTUsersAcessList: "Consulta Acessos Usuários SGT",
  company: "Empresa",
  sector: "Setor",
  collaboratorsNumber: "Nº de Colaboradores",
  currentAccess: "Acessos Atuais",
  allowedAccess: "Acessos Permitidos",
  temporaryAccess: "Acessos Temporários",
  available: "Disponíveis",
  chooseCompany: "Escolha a Empresa",
  chooseBrand: "Escolha a Marca",
  chooseSubGroup: "Escolha o Sub Grupo",
  chooseLicenses: "Escolha a Licença",
  all: "Todas",
  rosset: "Rosset",
  salete: "Salete",
  allAvailable: "Disponíveis",
  allUnavailable: "Indisponíveis",
  daysOfAccess: "Dias de Acesso",
  actualize: "Atualizar",

  updateAvailableLicenses: "Atualizar Licenças Disponíveis",

  userSO: "Usuário do S.O.",
  terminal: "Terminal",
  logonDate: "Data Logon",
  releaseSession: "Liberar Sessão",

  itemsPerPage: "Itens por página",
  unavailable: "Indisponíveis",
  rosset: "Rosset",

  totalOfLicenses: "Total de Licenças",
  usedLicenses: "Usadas",
  availableLicenses: "Disponíveis",
  reservedLicenses: "Licenças Reservadas",

  effect: "Efetivar",
  billingvsBudget: "Comparativo Faturamento vs Orçamento",

  online: "Online",
  offline: "Offline",
  vip: "Vip",
  removeConnection: "Remover Conexão",
  licenseOwner: "Dono da Licença",
  sharedLicense: "Compartilhando Licença",
  notSharedLicense: "Não Compartilhando Licença",
  successRemovedConnection: "Conexão Removida com Sucesso!",

  sharedLicenses: "Licenças Compartilhadas",

  sharedLicenseUser: "Usuário para Compartilhar Licença",

  sectors: "Setores",
  allUsers: "Todos os Usuários",
  numberOfVips: "Nº de Vips",
  confirmAction: "Confirme sua ação",
  areYouSureYouWantToConfirmThisActions:
    "Tem certeza que deseja confirmar esta ação?",
  companyFilter: "Filtrar Empresa",
  userName: "Nome do Usuário",

  officeRole: "Cargo",
  inactive: "Inativo",

  employeesTotal: "Total de Colaboradores",
  employeesTotalWithoutLicense: "Total de Colaboradores s/ Licença",
  updateVipLicenses: "Atualizar Licenças Vips",

  nickname: "Apelido",
  updateNickname: "Atualizar Apelido",
  allStatus: "Todos",

  closeNavBar: "Fechar Barra de Navegação",
  openNavBar: "Abrir Barra de Navegação",

  goalsRegistration: "Cadastro de Metas",

  group: "Grupo",
  month: "Mês",
  newRecord: "Novo Registro",
  addRecord: "Adicionar Registro",
  insertGroup: "Inserir Grupo",
  insertMonth: "Inserir Mês",
  chooseSeller: "Escolher Representante",
  insertValue: "Inserir Valor",
  insertKilos: "Inserir Quilos",
  groupRequired: "Grupo é Obrigatório",
  monthRequired: "Mês é Obrigatório",
  sellerRequired: "Representante é Obrigatório",
  valueRequired: "Valor é Obrigatório",
  kiloRequired: "Quilos é Obrigatório",
  newGoal: "Nova Meta",
  editingGoal: "Editando Meta",
  selectGoalToedit: "Selecione a Meta para Editar",
  chooseGoal: "Escolha a Meta",
  editGoal: "Editar Meta",
  chooseGroup: "Escolha o Grupo",
  chooseMonth: "Escolha o Mês",
  january: "JANEIRO",
  february: "FEVEREIRO",
  march: "MARÇO",
  april: "ABRIL",
  may: "MAIO",
  june: "JUNHO",
  july: "JULHO",
  august: "AGOSTO",
  september: "SETEMBRO",
  october: "OUTUBRO",
  november: "NOVEMBRO",
  december: "DEZEMBRO",
  uploadGoal: "Subir Meta - Excel",
  uploadImage: "Subir Imagem",
  downloadModel: "Baixar Modelo",
  exit: "Sair",
  saveData: "Gravar Dados",
  goalDescription: "Descrição da Meta",
  period: "Período",
  year: "Ano",

  selectXlsxWithGoals: "Selecione o Arquivo XLSX com as Metas",
  load: "Carregar",
  yearRequired: "Ano é Obrigatório",
  periodRequired: "Período é Obrigatório",

  line: "Linha",

  reviseGoal: "Revisar Meta",
  deleteGoal: "Deletar Meta",

  amountR$: "Valor (R$)",
  kilosKG: "Quilos (Kg)",
  reviseGoal: "Revisar Meta",
  selectGoalTorevise: "Selecione a Meta para Revisar",
  unavailableGoal: "Meta Indisponível",
  revisingGoal: "Revisando Meta",

  revise: "Revisar",
  selectGoalTodelete: "Selecione a Meta para Excluir",

  maxValueElevenDigits: "Valor Máximo: 11 dígitos",

  modificationDate: "Data Alteração",
  modificatedBy: "Alterado Por",
  documentsQueryRepository: "Cadastro de Documentos - Repositório",
  addDocument: "Adicionar Documento",
  documentDescription: "Descrição do Documento",
  area: "Área",
  typeOfAccess: "Tipo de Acesso",
  access: "Acesso",
  document: "Documento",
  chooseArea: "Escolha a Área",
  areaRequired: "Área é Obrigatória",
  repository: "Repositório",
  areaRegistration: "Cadastro de Área",
  areaRegistrationForm: "Formulário de Cadastro de Área",
  path: "Caminho",
  pathRequired: "Caminho é Obrigatório",
  areaDescription: "Descrição da Área",
  updateDescription: "Atualizar Descrição",
  goalAlreadyExistForTheSelectedYear:
    "Meta já existente para o ano selecionado!",

  invalidDataForSave: "Dados Inválidos para Gravação!",
  invalidRegisterPleaseEdit: "Registro Inválido, Favor Revisar!",
  loadingData: "Carregando Dados...",
  goalRequired: "Meta é Obrigatória",
  savingData: "Salvando Dados...",

  selectAction: "Selecione uma Ação",
  action: "Ação",
  actionRequired: "Ação é Obrigatória!",

  addAccess: "Adicionar Acesso",
  documentAccess: "Acesso ao Documento",
  addUser: "Adicionar Usuário",
  addAccessLevel: "Adicionar Nível de Acesso",
  editingDocument: "Editando Documento",
  chooseUser: "Escolha o Usuário",

  editDocument: "Editar Documento",
  documentQueries: "Consulta de Documentos",
  documentsList: "Lista de Documentos",
  fileDescription: "Descrição do Arquivo",
  fileName: "Nome do Arquivo",

  workplace: "Setor da Vaga",
  testTheme: "Tema do Teste",
  questions: "Perguntas",
  answers: "Respostas",
  question: "Pergunta",
  answer: "Resposta",
  candidates: "Candidatos",
  candidateResult: "Resultado do Candidato",
  humanResources: "Recursos Humanos",
  questionsAnswers: "Perguntas e Respostas",
  qa: "Q.A.",
  hr: "R.H.",

  workplaceDescription: "Descrição do Setor",
  chooseWorkplace: "Escolha o Setor",
  workplaceRequired: "Setor é Obrigatório",
  workplaceRegistration: "Cadastro de Setores",
  workplaceRegistrationForm: "Formulário de Cadastro de Setores",

  themeInformations: "Dados do Tema",
  chooseTheme: "Escolha o Tema",
  themeRequired: "Tema é Obrigatório",

  quiz: "Questionário",
  template: "Gabarito",
  questionRequired: "Pergunta é obrigatória",
  answerRequired: "Resposta é obrigatória",
  complement: "Complemento",
  multipleChoice: "Múltipla Escolha",
  questionInformations: "Dados da Pergunta",
  answerInformations: "Dados da Resposta",
  isCorrect: "Está correto ?",

  candidateInformations: "Dados do Candidato",
  job: "Vaga",
  jobRequired: "Vaga é obrigatória",
  next: "Próxima",
  start: "Iniciar",
  loginInformations: "Dados do Login",
  redistributeGoal: "Redistribuir Meta",
  showNotificationLgpd: "Exibir notificação da LGPD",
  fullTableMode: "Modo Tabela Inteira",
  exitFullTableMode: "Sair do Modo Tabela Inteira",

  costCalculator: "Calculadora de Custos",
  products: "Produtos",
  confectionProductRegistration: "Cadastro de Produtos Confecção",
  compositionTypes: "Tipos de Composição",
  compositionList: "Lista de Composição",
  measureUnity: "Unidade de Medida",
  amountUsed: "Quantidade de Consumo",
  unitCost: "Custo Unitário",

  priceListUpdate: "Atualização Tabela de Preços",

  seniorAccessList: "Lista Acessos Senior",

  program: "Programa",
  schemaName: "Banco de Dados",
  logonTime: "Data/Hora da Entrada",
  previousExecuteStart: "Data/Hora do Último Comando",
  waitTimeInSeconds: "Tempo de Espera em Segundos",

  purchasingGroup: "Grupo de Compras",
  controlsBudget: "Controla Orçamento",
  controlsBudgetConfec: "Controla Orcto. Confec.",
  controlsBudgetBrand: "Controla Orcto. Marca",
  imageConvert: "Conversor de Imagem",
  family: "Família",
  subGroup: "Sub Grupo",
  serie: "Série",

  priceListStatus: "Status da Lista de Preço",
  manufacturing: "Confecção",
  cadTables: "Cadastro de Tabelas",
  logView: "Visualizar Log",
  logTitle: "Título do Log",
  tableName: "Nome da Tabela",
  fixedExpensesByBrand: "Despesas Fixas por Marca",
  brand: "Marca",
  franchiseRevenue: "Faturamento Franquias",
  franchise: "Franquias",
  performanceRetail: "Performance Varejo",
  store: "Loja",
  omniChannel: "OmniChannel",
  offPremium: "OffPremium",
  finish: "Finalizar",
  groupsReferences: "Grupos Referências",
  LDAP: "LDAP",
  userSimulate: "Simular Usuário",

  inventory: "Inventário",
  inventoryEntireNetwork: "Inventariar toda rede",
  inventorySpecificIP: "Inventariar IP específico",
  lastInventoryLog: "Log Último Inventário",
  consoleOutput: "Console",
  run: "Executar",

  listOpenInvoices: "Listar Duplicatas em Aberto",
  listOutstandingDuplicates120plus: "Duplicatas Vencidas +120 dias",
  duplicatesDetails: "Duplicatas - Detalhes",

  financial: "Financeiro",
  backupAutomation: "Automação de Backup",
  chooseFile: "Escolha o Arquivo",
  chooseSourceDataBase: "Escolha o Banco de dados de Origem",
  chooseTheDatabaseOfDestination: "Escolha o Banco de Dados de Destino",
  approvalPurchasingLimits: "Aprovação de Limites de Compras",
  approvalAutomaticPurchaseLimits: "Aprovação de Limites de Compra Automática",
  pagSeguro: "PagSeguro",
  panelToValidateApprovals: "Painel para Validar Aprovações",
  validations: "Validações",
};
