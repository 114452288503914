<template>
  <q-card bordered class="my-card">
    <q-card-section class="q-pt-md bg-red-1">
      <div class="row">
        <div class="col text-h6 text-bold">Matéria Prima</div>
      </div>
      <div class="row items-center q-pt-sm">
        <div class="col-2 fs-16 text-bold">Tecido:</div>
        <div class="col-10 text-left">
          <q-btn
            size="md"
            flat
            round
            icon="search"
            color="primary"
            @click="pesquisarTecido"
          />
          <span class="fs-16 text-weight-medium text-primary">{{
            calculadora.referenciaTecido
          }}</span>
        </div>
      </div>
      <div class="row items-center">
        <div class="col fs-16 text-bold">Largura:</div>
        <div class="col fs-16 text-weight-medium text-primary">
          {{ tecido?.largura }}
        </div>
        <div class="col fs-16 text-bold">Gramatura:</div>
        <div class="col fs-16 text-weight-medium text-primary fs-16">
          {{ tecido?.gramatura }}
        </div>
      </div>
      <div class="row items-center q-pt-sm">
        <div class="col-2 fs-16 text-bold">Renda:</div>
        <div class="col-10 text-left">
          <q-btn
            size="md"
            flat
            round
            icon="search"
            color="primary"
            @click="pesquisarRenda"
          />
          <span class="fs-16 text-weight-medium text-primary">{{
            calculadora.referenciaRenda
          }}</span>
        </div>
      </div>
      <div class="row items-center">
        <div class="col-2 fs-16 text-bold">Largura:</div>
        <div class="col-4 fs-16 text-weight-medium text-primary">
          {{ renda?.largura }}
        </div>
      </div>
      <div class="row items-center">
        <div class="col-4 fs-16 text-weight-medium text-primary">
          <q-checkbox
            dense
            label="Exportação"
            color="red"
            v-model="calculadora.isExportacao"
          />
        </div>
        <div class="col-4" v-if="calculadora.isExportacao">
          <input-calculadora
            label="Câmbio"
            prependLabel="R$/kg"
            v-model="calculadora.cambio"
          />
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-pt-md bg-green-1">
      <div class="row">
        <div class="col text-h6 text-bold">Custos Rosset</div>
      </div>
      <div class="row q-pa-sm">
        <div class="col q-pr-md">
          <input-calculadora
            label="ICMS"
            prependLabel="%"
            mask="##"
            :disable="calculadora.isExportacao"
            v-model="calculadora.custosRosset.icms"
          />
        </div>
        <div class="col">
          <input-calculadora
            label="Gramatura"
            prependLabel="g/m²"
            mask="###"
            v-model="calculadora.custosRosset.gramaturaTecido"
          />
        </div>
      </div>
      <div class="row q-pa-sm">
        <div class="col q-pr-md">
          <input-calculadora
            label="Preço Tecido"
            :prependLabel="`${moeda}/kg`"
            v-model="calculadora.custosRosset.precoTecido"
          />
        </div>
        <div class="col">
          <input-calculadora
            label="Preço Renda"
            :prependLabel="`${moeda}/ml`"
            v-model="calculadora.custosRosset.precoRenda"
          />
        </div>
      </div>
    </q-card-section>

    <q-card-section class="bg-blue-1">
      <div class="row">
        <div class="col text-h6 text-bold">Custos Concorrente</div>
      </div>
      <div class="row q-pa-sm">
        <div class="col q-pr-md">
          <input-calculadora
            label="ICMS"
            prependLabel="%"
            mask="##"
            :disable="calculadora.isExportacao"
            v-model="calculadora.custosConcorrente.icms"
          />
        </div>
        <div class="col">
          <input-calculadora
            label="Gramatura"
            prependLabel="g/m²"
            mask="###"
            v-model="calculadora.custosConcorrente.gramaturaTecido"
          />
        </div>
      </div>
      <div class="row q-pa-sm">
        <div class="col q-pr-md">
          <input-calculadora
            label="Preço Tecido"
            :prependLabel="`${moeda}/kg`"
            v-model="calculadora.custosConcorrente.precoTecido"
          />
        </div>
        <div class="col">
          <input-calculadora
            label="Preço Renda"
            :prependLabel="`${moeda}/ml`"
            v-model="calculadora.custosConcorrente.precoRenda"
          />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import InputCalculadora from "./InputCalculadora";
export default {
  name: "MateriaPrimaDesktop",
  components: { InputCalculadora },
  emits: ["pesquisarTecido", "pesquisarRenda"],
  props: {
    tecido: {
      type: Object,
      default: () => null,
    },
    renda: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      calculadora: {
        cambio: null,
        isExportacao: false,
        referenciaTecido: null,
        referenciaRenda: null,
        custosRosset: {
          icms: 0,
          precoTecido: 0,
          precoRenda: 0,
          gramaturaTecido: 0,
        },
        custosConcorrente: {
          icms: 0,
          precoTecido: 0,
          precoRenda: 0,
          gramaturaTecido: 0,
        },
      },
    };
  },
  watch: {
    tecido: {
      handler(val) {
        this.calculadora.referenciaTecido = val.referencia;
        this.calculadora.custosRosset.gramaturaTecido = val.gramatura;
      },
    },
    renda: {
      handler(val) {
        this.calculadora.referenciaRenda = val.referencia;
      },
    },
    calculadora: {
      handler(val) {
        if (val.isExportacao) {
          val.custosRosset.icms = 0;
          val.custosConcorrente.icms = 0;
        }
        this.$store.dispatch("comercial/setCalculadora", val);
      },
      deep: true,
    },
  },
  computed: {
    moeda() {
      return this.calculadora.isExportacao ? "US$" : "R$";
    },
  },
  methods: {
    pesquisarTecido() {
      this.$emit("pesquisarTecido");
    },
    pesquisarRenda() {
      this.$emit("pesquisarRenda");
    },
  },
};
</script>

<style scoped>
.my-card {
  height: auto;
}
</style>
