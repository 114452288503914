<template>
  <!-- dialog -->
  <q-dialog v-model="aberto" max-width="2000px">
    <q-card class="pa-4" style="min-width: 600px">
      <q-card-section class="headline px-0 pt-0">{{ title }}</q-card-section>
      <q-card-section class="px-0 pt-0">
        <slot name="body"></slot>
      </q-card-section>
      <q-card-actions class="pa-0">
        <q-separator />
        <slot name="buttons" v-if="$slots.buttons"></slot>
        <div v-else>
          <q-btn
            color="success"
            class="q-mx-sm q-mt-sm"
            @click="$emit('salvarForm')"
          >
            {{ $t("message.save") }}
          </q-btn>
          <q-btn color="danger" class="q-ml-sm q-mt-sm" @click="fechar()">
            {{ $t("message.close") }}
          </q-btn>
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["title", "salvarForm"],
  data() {
    return {
      aberto: false,
    };
  },
  methods: {
    abrirDialogo() {
      this.aberto = true;
    },
    fechar() {
      this.aberto = false;
    },
  },
};
</script>
