<template>
  <div>
    <page-title-bar v-if="!popUp"></page-title-bar>

    <div :class="classeLista">
      <div class="contact-wrapper">
        <Search
          :label="$t('message.typeInformationAboutSeller')"
          @pesquisar="pesquisar($event)"
          @limpar="limpar"
        ></Search>

        <q-table
          :rows="rows"
          :columns="columns"
          :loading="carregandoDados"
          v-show="exibirDados"
          @row-click="selecionar"
          class="r7-cursor-pointer q-mt-md my-sticky-header-table"
          table-header-class="table-header"
          :rows-per-page-options="[0]"
          :noDataLabel="$t('message.noDataFound')"
          dense
          :filter="filter"
          hide-pagination
          virtual-scroll
        >
          <template v-slot:body-cell-situacao="props">
            <q-td auto-width class="text-center" v-if="getHighLevel">
              <q-toggle
                v-model="props.row.situacao"
                color="blue"
                :disable="atualizandoSituacao"
                @update:model-value="atualizarSituacao({ ...props.row })"
                checked-icon="check"
                unchecked-icon="clear"
                true-value="ATIVO"
                false-value="INATIVO"
              >
              </q-toggle>
            </q-td>
            <q-td auto-width class="text-center" v-else>
              <q-chip :color="definirCor(props.row.situacao)" dark>
                {{ props.row.situacao }}
              </q-chip>
            </q-td>
          </template>

          <template v-slot:body-cell-apelido="props">
            <q-td
              key="apelido"
              :props="props"
              class="r7-cursor-pointer"
              @click.stop
              v-if="getHighLevel"
            >
              {{ props.row.apelido }}
              <q-icon class="material-icons q-pl-sm" color="blue" size="xs"
                >edit</q-icon
              >
              <q-popup-edit
                v-model="props.row.apelido"
                :title="$t('message.updateNickname')"
                buttons
                v-slot="scope"
                :label-set="$t('message.confirm')"
                :label-cancel="$t('message.cancel')"
                @update:model-value="atualizarApelido({ ...props.row })"
              >
                <q-input v-model="scope.value" dense autofocus />
              </q-popup-edit>
            </q-td>
            <q-td class="r7-cursor-pointer" v-else>
              {{ props.row.apelido }}
            </q-td>
          </template>

          <template v-slot:top-right>
            <div class="r7-switch-toggle">
              <input
                id="on"
                name="state-d"
                type="radio"
                checked=""
                @click.stop="acao = 'ativo'"
              />
              <label for="on">{{ $t("message.active") }}</label>

              <input
                id="na"
                name="state-d"
                type="radio"
                checked="checked"
                @click.stop="acao = 'todos'"
              />
              <label for="na">{{ $t("message.allStatus") }}</label>
              <input
                id="off"
                name="state-d"
                type="radio"
                @click.stop="acao = 'inativo'"
              />
              <label for="off">{{ $t("message.inactive") }}</label>
            </div>

            <q-input
              class="q-ml-sm"
              borderless
              dense
              debounce="300"
              v-model="filter"
              :placeholder="$t('message.search')"
              clearable
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
            <exportar-excel
              v-if="rows"
              :data="rows"
              name="listaderepresentantes"
              class="q-mx-sm"
            />
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "Components/Widgets/Search";
import api from "Api";
import apic from "Api/cobol";
import { mapActions, mapGetters } from "vuex";
import {
  showSuccess,
  showError,
  showSelectedSuccess,
} from "@/notifications/notify";

export default {
  name: "RepresentanteLista",
  components: { Search },
  props: {
    popUp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: null,
      carregandoDados: false,
      atualizandoSituacao: false,
      exibirDados: false,
      acao: "todos",
      dadosOri: null,
      rows: [],
      columns: [
        {
          label: "Id",
          field: "idrepresentante",
          name: "idrepresentante",
          sortable: true,
          align: "right",
        },
        {
          label: this.$t("message.name"),
          field: "nomeRepresentante",
          name: "nomeRepresentante",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.nickname"),
          field: "apelido",
          name: "apelido",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.state"),
          field: "uf",
          name: "uf",
          sortable: true,
          align: "center",
        },
        {
          label: this.$t("message.city"),
          field: "cidade",
          name: "cidade",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.situation"),
          field: "situacao",
          name: "situacao",
          sortable: true,
          align: "center",
        },
      ],
    };
  },
  computed: {
    classeLista() {
      return this.popUp ? "" : "q-pa-md";
    },
    ...mapGetters("admin", ["getHighLevel"]),
  },

  watch: {
    acao() {
      this.filtrarSituacao();
    },
  },
  methods: {
    ...mapActions("representantes", ["setDadosDoRepresentante"]),
    pesquisar(value) {
      this.rows = [];
      this.exibirDados = true;
      this.carregandoDados = true;

      api
        .post("/v1/representantes/listarrepresentantepesquisado", {
          pesquisa: value,
        })
        .then((res) => {
          this.rows = res.data;
          this.dadosOri = res.data;
          this.carregandoDados = false;
        });
    },
    limpar() {
      this.exibirDados = false;
      this.representantes = [];
    },
    selecionar(evt, item) {
      this.setDadosDoRepresentante(item);

      const params = new URLSearchParams();
      params.append("cdrep", item.idrepresentante);
      apic.post("/cgi-bin/vnd240.exe", params);

      if (this.popUp) {
        this.$emit("representanteSelecionado");
      } else {
        showSelectedSuccess(this.$t("message.sellerSelected"));
      }
    },

    definirCor(p) {
      return p === "ATIVO" ? "green" : "red";
    },

    atualizarApelido(linha) {
      api
        .patch(`/v1/representantes/${linha.idrepresentante}/nickname`, {
          nickname: linha.apelido,
        })
        .then(() => showSuccess(this.$t("message.successUpdatedRecord")))
        .catch((err) => showError(err));
    },

    async filtrarSituacao() {
      this.rows = this.dadosOri;
      let dados = this.dadosOri;
      if (this.acao === "ativo") {
        dados = await dados.filter((v) => {
          return v.situacao == "ATIVO";
        });
      }
      if (this.acao === "inativo") {
        dados = await dados.filter((v) => {
          return v.situacao == "INATIVO";
        });
      }
      this.rows = dados;
    },

    atualizarSituacao(linha) {
      this.atualizandoSituacao = true;

      let situacao;

      if (linha.situacao == "ATIVO") {
        situacao = 0;
      } else {
        situacao = 1;
      }

      api
        .patch(`/v1/representantes/${linha.idrepresentante}/trocarsituacao`, {
          ativo: situacao,
        })
        .then(() => showSuccess(this.$t("message.successUpdatedRecord")))
        .catch((err) => showError(err))
        .finally(() => {
          this.atualizandoSituacao = false;
        });
    },
  },
  mounted() {
    this.pesquisar("");
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 570px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
