<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        dense
        hide-pagination
        virtual-scroll
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        @row-click="selecionarLinha"
        class="my-sticky-header-table"
      >
        <template v-slot:top-left v-if="exibir">
          <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:top-right>
          <q-input
            class="q-ml-sm"
            borderless
            dense
            debounce="300"
            v-model="filter"
            :placeholder="$t('message.search')"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <exportar-excel
            v-if="rows"
            :data="rows"
            name="listadeporecossalete"
            class="q-mx-sm"
          />
        </template>

        <template v-slot:body-cell-status="props">
          <q-td auto-width>
            {{ props.row.status === 1 ? "ATIVO" : "INATIVO" }}
          </q-td>
        </template>

        <template v-slot:body-cell-actionsEdit="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-tooltip>{{ $t("message.edit") }}</q-tooltip>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click.stop="onUpdate(props.row.nrTabela)"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-actionsDelete="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-tooltip>{{ $t("message.delete") }}</q-tooltip>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click.stop="deletarLista(props.row.nrTabela)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-actionsCopy="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-tooltip>{{ $t("message.copy") }}</q-tooltip>
              <q-icon
                size="xs"
                color="yellow-9"
                class="material-icons"
                @click.stop="aoCopiar(props.row.nrTabela)"
              >
                copy_all
              </q-icon>
            </q-btn>
          </q-td>
        </template>
      </q-table>

      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        @onConfirm="deleteItem"
      ></delete-confirmation-dialog>

      <InsereAtualizaListaDePrecosHeader
        ref="formularioInserir"
        @atualizarLista="listarPrecosHeader()"
      />

      <CopiaListaDePrecosHeader
        ref="formularioCopiar"
        @atualizarLista="listarPrecosHeader()"
      />
    </div>
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

import { mapActions } from "vuex";
import InsereAtualizaListaDePrecosHeader from "Components/Areas/Compras/Salete/ListaDePrecosHeader/InsereAtualizaListaDePrecosHeader";
import CopiaListaDePrecosHeader from "Components/Areas/Compras/Salete/ListaDePrecosHeader/CopiaListaDePrecosHeader";

export default {
  name: "ListadePrecosHeader",
  components: {
    InsereAtualizaListaDePrecosHeader,
    CopiaListaDePrecosHeader,
  },
  props: {
    exibir: { type: Boolean, default: true },
  },

  data() {
    return {
      loader: true,
      filter: null,

      itemSelecionadoParaDelecao: {},

      rows: [],
      columns: [
        {
          label: this.$t("message.table"),
          field: "nrTabela",
          name: "nrTabela",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "nome",
          name: "nome",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.issueDate"),
          field: "dtEmissao",
          name: "dtEmissao",
          align: "center",
          sortable: true,
        },
        {
          label: this.$t("message.validity"),
          field: "dtVigencia",
          name: "dtVigencia",
          align: "center",
          sortable: true,
        },
        {
          label: this.$t("message.situation"),
          field: "status",
          name: "status",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.baseTable"),
          field: "nrTabelaBase",
          name: "nrTabelaBase",
          align: "right",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
          sortable: false,
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
          sortable: false,
        },
        {
          label: "",
          field: "actionsCopy",
          name: "actionsCopy",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions("compras", ["setDadosListaPrecoSalete"]),

    listarPrecosHeader() {
      api
        .get("v1/compras/salete/listarprecosheader")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    onAdd() {
      this.$refs.formularioInserir.adicionarListaPreco();
    },

    onUpdate(nrTabela) {
      this.$refs.formularioInserir.editarInformacoes(nrTabela);
    },

    aoCopiar(nrTabela) {
      this.$refs.formularioCopiar.abrirModalCopiar(nrTabela);
    },

    deletarLista(nrTabela) {
      this.$refs.deleteConfirmationDialog.open();
      this.itemSelecionadoParaDelecao = nrTabela;
    },
    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();

      await api
        .delete(
          `v1/compras/salete/excluirprecoheader/${this.itemSelecionadoParaDelecao}`
        )
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarPrecosHeader());
    },

    definirCor(p) {
      return p === 1 ? "green" : "red";
    },

    selecionarLinha(evt, item) {
      this.setDadosListaPrecoSalete(item);
      this.$router.push({
        path: "/listadeprecositem",
      });
    },
  },
  mounted() {
    this.listarPrecosHeader();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
