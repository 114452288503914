<template>
  <page-title-bar></page-title-bar>

  <div class="row q-pa-md">
    <div class="col-6 q-pr-lg">
      <ListaAreas />
    </div>
    <q-separator inset vertical />
    <div class="col q-pl-lg">
      <FormularioCadastroArea />
    </div>
  </div>
</template>

<script>
import ListaAreas from "Components/Areas/Repositorio/ListaAreas";
import FormularioCadastroArea from "Components/Areas/Repositorio/FormularioCadastroArea";

export default {
  name: "CadastroDeAreas",
  components: { ListaAreas, FormularioCadastroArea },
  data() {
    return {};
  },
};
</script>
