export default {
  onChangeAcao: (state, payload) => (state.acao = payload),
  onChangeCabecalho: (state, payload) => (state.cabecalho = payload),
  OnChangeRevisaoXlsx: (state, payload) => (state.revisaoXlsx = payload),
  onChangeMeta: (state, obj) => state.meta.push(obj),
  onChangeExibir: (state, payload) => (state.exibir = payload),
  OnChangeDesabilitarMenu: (state, payload) =>
    (state.desabilitarMenu = payload),

  onHandlerMeta: (state) => {
    state.cabecalho.ano = null;
    state.cabecalho.descricao = null;
    state.cabecalho.periodo = null;
    state.revisaoXlsx = false;
    state.meta = [];
    state.exibir = false;
    state.desabilitarMenu = false;
    state.acao = null;
  },

  onChangeEditarMeta: (state, obj) => {
    state.meta.find((f) => {
      if (f.linha == obj.linha) {
        f.grupoId = obj.grupoId;
        f.grupoDescription = obj.grupoDescription;
        f.mesId = obj.mesId;
        f.mesDescription = obj.mesDescription;
        f.representanteId = obj.representanteId;
        f.representanteDescription = obj.representanteDescription;
        f.valor = obj.valor;
        f.quilos = obj.quilos;
      }
    });
  },

  onChangeDeletarRegistro: (state, payload) => state.meta.splice(payload, 1),
};
