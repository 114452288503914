<template>
  <div class="q-pa-xs">
    <q-bar class="bg-white">
      <q-btn
        :outline="!indicadores.stValor"
        label="Valor"
        color="primary"
        class="q-mr-sm"
        @click="onClick('stValor')"
      />
      <q-btn
        :outline="!indicadores.stQtde"
        label="Quantidade"
        color="primary"
        @click="onClick('stQtde')"
      />
    </q-bar>
  </div>
</template>
<script>
export default {
  name: "Indicadores",
  emits: ["onFilter"],
  data() {
    return {
      status: false,
      indicadores: {
        stValor: true,
        stQtde: false,
      },
    };
  },
  methods: {
    onClick(indicador) {
      this.indicadores[indicador] = !this.indicadores[indicador];

      if (indicador == "stValor")
        this.indicadores.stQtde = !this.indicadores.stValor;
      if (indicador == "stQtde")
        this.indicadores.stValor = !this.indicadores.stQtde;

      this.$emit("onFilter", this.indicadores);
    },
  },
};
</script>
