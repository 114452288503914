<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        dense
        hide-pagination
        virtual-scroll
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
      >
        <template v-slot:top-left v-if="exibir">
          <q-btn
            dark
            size="md"
            class="q-mb-xm"
            color="primary"
            @click="adicionarDeposito"
          >
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:top-right>
          <div class="r7-switch-toggle switch-3 switch-candy">
            <input id="on" name="state-d" type="radio" checked="" />
            <label for="on" @click="filtrarDepositos('ativos')">{{
              $t("message.activeDeposit")
            }}</label>

            <input id="na" name="state-d" type="radio" checked="checked" />
            <label for="na" @click="filtrarDepositos('todos')">{{
              $t("message.allDeposit")
            }}</label>
            <input id="off" name="state-d" type="radio" />
            <label for="off" @click="filtrarDepositos('inativos')">{{
              $t("message.inactiveDeposit")
            }}</label>
          </div>

          <q-input
            class="q-ml-sm"
            borderless
            dense
            debounce="300"
            v-model="filter"
            :placeholder="$t('message.search')"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <exportar-excel
            v-if="rows"
            :data="rows"
            name="listadepositosamostra"
            class="q-mx-sm"
          />
        </template>

        <template v-slot:body-cell-decricaoSituacao="props">
          <q-td auto-width>
            <q-chip :color="definirCor(props.row.decricaoSituacao)" dark>
              {{ props.row.decricaoSituacao }}
            </q-chip>
          </q-td>
        </template>

        <template v-slot:body-cell-situacao="props">
          <q-td auto-width>
            <q-toggle
              v-model="props.row.situacao"
              color="blue"
              :disable="atualizandoDeposito"
              @update:model-value="atualizarDeposito({ ...props.row })"
              checked-icon="check"
              unchecked-icon="clear"
              :true-value="1"
              :false-value="0"
            >
            </q-toggle>
          </q-td>
        </template>
      </q-table>

      <ModalForm
        ref="modalFormDeposito"
        :title="$t('message.registerSampleDeposit')"
      >
        <template v-slot:body>
          <q-form
            ref="form"
            v-model="valid"
            autofocus
            @submit="aoSubmeter"
            greedy
          >
            <q-input
              name="inputCodigo"
              filled
              v-model="codigoDeposito"
              :label="$t('message.insertDepositCode')"
              :rules="codeRules"
              class="q-mt-md"
              required
              autofocus
              cleareble
            />
            <q-btn
              :label="$t('message.send')"
              @click.prevent="aoSubmeter()"
              type="submit"
              class="bg-green text-white q-mt-md"
            />
            <q-btn
              :label="$t('message.close')"
              @click.prevent="aoFechar()"
              class="bg-red text-white q-ml-md q-mt-md"
            />
          </q-form>
        </template>

        <template v-slot:button> </template>
      </ModalForm>
    </div>
  </div>
</template>

<script>
import api from "Api";
import ModalForm from "Components/Widgets/ModalForm";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "DepositosAmostraLista",
  components: { ModalForm },
  props: {
    exibir: { type: Boolean, default: true },
  },
  data() {
    return {
      loader: true,
      valid: true,

      filter: null,
      atualizandoDeposito: false,
      dadosOri: [],
      codigoDeposito: null,

      codeRules: [
        (v) => !!v || this.$t("message.codeRequired"),
        (v) =>
          (v && v.length <= 6) ||
          `${this.$t("message.code")}
           ${this.$t("message.mustBe")} 6
           ${this.$t("message.characters")}`,
      ],

      rows: [],
      columns: [
        {
          label: this.$t("message.depositCode"),
          field: "cdDeposito",
          name: "cdDeposito",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.depositDescription"),
          field: "descricaoDeposito",
          name: "descricaoDeposito",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.updateDate"),
          field: "dtAtualizacao",
          name: "dtAtualizacao",
          align: "center",
          sortable: true,
        },
        {
          label: this.$t("message.updateUser"),
          field: "usuario",
          name: "usuario",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.situation"),
          field: "decricaoSituacao",
          name: "decricaoSituacao",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.changeSituation"),
          field: "situacao",
          name: "situacao",
          align: "center",
        },
      ],
    };
  },
  methods: {
    listarDepositosAmostras() {
      api
        .get("/v1/produtos/depositos/listardepositosamostra")
        .then((res) => {
          this.loader = false;
          this.rows = res.data;
          this.dadosOri = this.rows;
        })
        .catch((err) => showError(err));
    },

    async filtrarDepositos(acao) {
      this.rows = this.dadosOri;
      let dados = this.dadosOri;

      if (acao === "ativos") {
        dados = await this.rows.filter((v) => v.decricaoSituacao === "ATIVO");
      }
      if (acao === "inativos") {
        dados = await this.rows.filter((v) => v.decricaoSituacao === "INATIVO");
      }

      this.rows = dados;
    },

    atualizarDeposito(item) {
      this.atualizandoDeposito = true;

      api
        .put(
          `/v1/produtos/depositos/atualizardepositoamostra/${item.cdDeposito}`,
          {
            situacao: item.situacao,
          }
        )
        .catch((err) => showError(err))
        .finally(() => {
          this.atualizandoDeposito = false;
          this.listarDepositosAmostras();
        });
    },

    adicionarDeposito() {
      this.$refs.modalFormDeposito.abrir();
    },

    aoSubmeter(codigoDeposito) {
      api
        .post("/v1/produtos/depositos/inserirdepositoamostra", {
          cdDeposito: codigoDeposito,
        })
        .then(() => showSuccess(this.$t("message.successSubmitedDeposit")))
        .catch((err) => showError(err))
        .finally(() => {
          this.listarDepositosAmostras();
          this.$refs.modalFormDeposito.fechar();
          this.codigoDeposito = "";
        });
    },
    aoFechar() {
      this.$refs.modalFormDeposito.fechar();
    },

    definirCor(p) {
      return p === "ATIVO" ? "green" : "red";
    },
  },
  created() {
    this.listarDepositosAmostras();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
