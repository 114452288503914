<template>
  <app-section-loader :status="loader"></app-section-loader>

  <SelectWithFilter
    v-if="!loader"
    :id="fornecedorId"
    :list="listaFornecedores"
    :required="required"
    :disabled="disabled"
    :prependIcon="prependIcon"
    label="Escolha o Fornecedor"
    :rounded="false"
    :filled="true"
    :dense="false"
    @selected="itemSelected($event)"
    :autofocus="autofocus"
    :clearable="true"
  />
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "FornecedoresSelectComponent",
  components: { SelectWithFilter },
  emits: ["selected"],

  props: {
    fornecedorId: Number,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loader: true,
      listaFornecedores: null,
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("/v1/compras/fornecedores")
        .then((res) => {
          if (res.data) {
            this.listaFornecedores = res.data.map((element) => {
              return {
                id: element.codval,
                description: element.nome,
              };
            });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
