export default {
  setAtualizar(context) {
    context.commit("onChangeAtualizar");
  },
  setLoader(context, payload) {
    context.commit("onChangeLoader", payload);
  },
  triggerRemoveStyleAndClass({ commit }) {
    commit("setRemoveStyleAndClass", true);
  },
  restoreStyleAndClass({ commit }) {
    commit("setRemoveStyleAndClass", false);
  },
};
