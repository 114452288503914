<template>
  <q-tabs
    v-model="tab"
    dense
    class="text-grey"
    active-color="primary"
    indicator-color="primary"
    align="left"
    narrow-indicator
  >
    <q-tab name="estatistica" class="tirarPaddingTab no-padding">
      <q-btn
        class="material-icon r7-background-BI text-white"
        icon="bar_chart"
        all-pointer-events
        @click.stop=""
      >
        <q-tooltip
          anchor="bottom middle"
          self="center middle"
          :offset="[10, 10]"
          class="text-body2"
        >
          {{ $t("message.statistics") }}
        </q-tooltip>
      </q-btn>
    </q-tab>
    <q-tab name="setores" :label="$t('message.sectors')" />
    <q-tab
      name="todosUsuarios"
      :label="$t('message.allUsers')"
      @click.stop="ativarAbaTodosUsuarios"
    />

    <template v-for="setor in dados" :key="setor.chaveDoSetor">
      <q-tab :name="setor.chaveDoSetor" align="right" v-if="setor.exibir">
        <div class="row">
          <div class="col">
            <span>{{ textTruncate(setor.descricaoSetor) }}</span>
          </div>
          <div class="col">
            <q-btn
              all-pointer-events
              size="xs"
              round
              flat
              @click.stop="fecharAba(setor)"
              class="q-mb-xs q-ml-sm"
            >
              <q-icon class="material-icons">close</q-icon>
            </q-btn>
          </div>
        </div>
      </q-tab>
    </template>
  </q-tabs>

  <q-separator />

  <q-tab-panels v-model="tab" animated keep-alive>
    <q-tab-panel name="setores" style="z-index: -1">
      <ListaSetoresUsuariosSGT @abrirAba="abrirAba($event)" />
    </q-tab-panel>
    <q-tab-panel
      name="todosUsuarios"
      style="z-index: -1"
      v-if="carregarTodosUsuarios"
    >
      <AcessosAtuaisUsuariosSGT :empresa="empresaId" />
    </q-tab-panel>

    <template v-for="setor in dados" :key="setor.chaveDoSetor">
      <q-tab-panel
        :name="setor.chaveDoSetor"
        v-if="setor.exibir"
        style="z-index: -1"
      >
        <AcessosAtuaisUsuariosSGT :setor="setor" />
      </q-tab-panel>
    </template>
  </q-tab-panels>
</template>

<script>
import { ref } from "vue";
import AcessosAtuaisUsuariosSGT from "Components/Areas/TI/AcessosAtuaisUsuariosSGT";
import ListaSetoresUsuariosSGT from "Components/Areas/TI/ListaSetoresUsuariosSGT";
import { mapGetters } from "vuex";
import { textTruncate } from "Helpers";

export default {
  name: "TabsUsuariosSGT",
  components: {
    AcessosAtuaisUsuariosSGT,
    ListaSetoresUsuariosSGT,
  },

  data() {
    return {
      tab: ref("setores"),

      dados: [],
      carregarTodosUsuarios: false,
    };
  },
  computed: {
    obterDados() {
      return this.obterDadosUsuariosSGT(this.getDadosUsuariosSGT());
    },
    ...mapGetters("ti", ["getDadosUsuariosSGT"]),
    ...mapGetters("ti", ["getFiltroEmpresaID"]),
  },
  watch: {
    getDadosUsuariosSGT: "obterDadosUsuariosSGT",
    getFiltroEmpresaID(value) {
      this.empresaId = value;
    },
  },
  methods: {
    obterDadosUsuariosSGT(array) {
      this.dados = array;
    },
    abrirAba(setor) {
      setor.exibir = true;
      this.tab = ref(setor.chaveDoSetor);
    },
    ativarAbaTodosUsuarios() {
      this.carregarTodosUsuarios = true;
    },

    fecharAba(setor) {
      setor.exibir = false;
      this.tab = ref("setores");
    },

    textTruncate(text) {
      return textTruncate(text, 10);
    },
  },
};
</script>

<style lang="sass" scoped>
.tirarPaddingTab
  .q-tab__content
    padding: 0 !important
</style>
