export default {
  setHighLevel(context) {
    context.commit("onHandlerHighLevel");
  },
  setSideBarToggle(context) {
    context.commit("onHandleSideBarToggle");
  },
  setSideBarToggleHide(context) {
    context.commit("onHandleSideBarToggleHide");
  },
  setFullScreenToggle(context) {
    context.commit("onHandleFullScreenToggle");
  },
  setKeyMaster(context) {
    context.commit("onHandleKeyMaster");
  },
  setDisableInventory(context) {
    context.commit("onHandleDisableInventory");
  },
};
