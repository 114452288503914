import DuplicatasEmAbertoListaView from "Views/Financeiro/DuplicatasEmAbertoListaView";
import ListarDuplicatasDetalhesView from "Views/Financeiro/ListarDuplicatasDetalhesView";

const routes = [
  {
    path: "/listaduplicatasemaberto",
    name: "listaduplicatasemaberto",
    component: DuplicatasEmAbertoListaView,
    meta: {
      requiresAuth: true,
      title: "message.listOpenInvoices",
      breadcrumb: ["message.financial"],
    },
  },
  {
    path: "/listaduplicatasdetalhes",
    name: "listaduplicatasdetalhes",
    component: ListarDuplicatasDetalhesView,
    meta: {
      requiresAuth: true,
      title: "message.duplicatesDetails",
      breadcrumb: ["message.financial"],
    },
  },
];

export default routes;
