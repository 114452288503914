<template>
  <div>
    <br />

    <title-bar :title="title">
      <template v-slot:funcoes>
        <q-space />
        <exportar-excel
          v-if="data"
          :data="data"
          name="listaitenspedido"
          class="q-mx-sm"
        >
          <q-tooltip
            anchor="bottom middle"
            self="center middle"
            :offset="[10, 10]"
            class="text-body2"
            >{{ $t("message.excelExport") }}</q-tooltip
          >
        </exportar-excel>
      </template>
    </title-bar>

    <q-table
      :rows="data"
      :columns="colunas"
      row-key="reduzido"
      dense
      :rows-per-page-options="[0]"
      hide-pagination
      table-header-class="table-header"
      :loading="loader"
      :noDataLabel="$t('message.noDataFound')"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th />
          <template v-for="col in props.cols" :key="col.name" :props="props">
            <q-th :class="col.headerClasses" v-if="!col.detail">
              {{ col.label }}
            </q-th>
          </template>
        </q-tr>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td auto-width>
            <q-btn
              size="sm"
              color="accent"
              round
              dense
              @click="props.expand = !props.expand"
              :icon="props.expand ? 'remove' : 'add'"
            />
          </q-td>

          <template v-for="col in props.cols" :key="col.name" :props="props">
            <q-td v-if="col.name === 'tag' && col.value === 1" auto-width>
              <q-btn
                flat
                dense
                round
                small
                @click="listarTagsItemPedido(props.row)"
              >
                <q-icon size="small" color="red" class="material-icons"
                  >sell</q-icon
                >
              </q-btn>
            </q-td>

            <q-td
              :class="col.classes"
              v-else-if="col.name === 'tag' && col.value === 0"
              auto-width
            >
              {{ "" }}
            </q-td>

            <q-td :class="col.classes" v-else-if="!col.detail" auto-width>
              {{ col.value }}
            </q-td>
          </template>
        </q-tr>

        <q-tr v-show="props.expand" :props="props">
          <q-td colspan="100%" auto-width>
            <template v-for="col in props.cols" :key="col.name" :props="props">
              <div class="row" v-if="col.detail">
                <div class="col">
                  <span class="fw-bold"> {{ col.label }}: </span>
                  <span>
                    {{ col.value }}
                  </span>
                </div>
              </div>
            </template>
          </q-td>
        </q-tr>
      </template>

      <template v-slot:bottom-row>
        <q-tr class="totalizador">
          <q-td auto-width></q-td>
          <Totalizador :dados="data" :colunas="colunas" />
        </q-tr>
      </template>
    </q-table>

    <ModalCard ref="modalTagItemPedido" :title="titulo">
      <template v-slot:body>
        <div
          class="row"
          no-gutters
          v-for="(coluna, indice) in camposTags"
          :key="indice"
        >
          <div cols="12" sm="4" md="4" lg="4" class="col q-pa-xs fw-bold">
            {{ coluna.label }}:
          </div>
          <div cols="12" sm="8" md="8" lg="8" class="col q-pa-xs">
            {{ dadosTags[coluna.field] }}
          </div>
        </div>
      </template>
    </ModalCard>
  </div>
</template>

<script>
import ModalCard from "Components/Widgets/ModalCard";
import api from "Api";
import { textTruncate } from "Helpers";
import { showError } from "@/notifications/notify";
import Totalizador from "Components/Table/Totalizador";

export default {
  name: "ListaItensPedido",
  components: { ModalCard, Totalizador },
  props: ["data", "loader", "title", "idPedido"],
  data() {
    return {
      search: false,
      dadosTags: {},
      titulo: null,
      colunas: [
        {
          label: this.$t("message.productId"),
          field: "reduzido",
          name: "reduzido",
          classes: "text-center",
        },
        {
          label: "",
          field: "tag",
          name: "tag",
          align: "center",
        },
        {
          label: this.$t("message.reference"),
          field: "cdArtigo",
          name: "cdArtigo",
          classes: "text-center",
        },
        {
          label: this.$t("message.productSituation"),
          field: "situacao",
          name: "situacao",
          classes: "text-center",
        },
        {
          label: this.$t("message.color"),
          field: "cdCor",
          name: "cdCor",
          classes: "text-center",
        },
        {
          label: "TPC",
          field: "tipoCor",
          name: "tipoCor",
          classes: "text-center",
        },
        {
          label: this.$t("message.print"),
          field: "cdDesenho",
          name: "cdDesenho",
          align: "center",
          classes: "text-center",
        },
        {
          label: this.$t("message.quality"),
          field: "qualidadeSintetica",
          name: "qualidadeSintetica",
          classes: "text-center",
        },
        {
          label: this.$t("message.deliveryDate"),
          field: "dtEntregaItem",
          name: "dtEntregaItem",
          classes: "fw-bold text-center",
        },
        {
          label: this.$t("message.finishing"),
          field: "cdAcabamentos",
          name: "cdAcabamentos",
          classes: "text-center",
        },
        {
          label: this.$t("message.unitPrice"),
          field: "precoUnitario",
          name: "precoUnitario",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "text-right",
        },
        {
          label: this.$t("message.discount"),
          field: "desconto",
          name: "desconto",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "text-right",
        },
        {
          label: this.$t("message.unit"),
          field: "unidmed",
          name: "unidmed",
          titleTotal: "Total (KG)",
          classes: "text-center",
        },
        {
          label: this.$t("message.ordered"),
          field: "qtPedidaItemOri",
          name: "qtPedidaItemOri",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "r7-text-color-warning-light-1 fw-bold text-right",
          total: true,
        },
        {
          label: this.$t("message.reserved"),
          field: "qtReservaItemOri",
          name: "qtReservaItemOri",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "r7-text-color-blue-light-grey fw-bold text-right",
          total: true,
        },
        {
          label: this.$t("message.weighted"),
          field: "qtRomaItemOri",
          name: "qtRomaItemOri",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "r7-text-color-blue-light-grey fw-bold text-right",
          total: true,
        },
        {
          label: this.$t("message.ofItem"),
          field: "qtOfItemOri",
          name: "qtOfItemOri",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "r7-text-color-success-light fw-bold text-right",
          total: true,
        },
        {
          label: this.$t("message.billed"),
          field: "qtFaturaItemOri",
          name: "qtFaturaItemOri",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "r7-text-color-success-light fw-bold text-right",
          total: true,
        },
        {
          label: this.$t("message.canceled"),
          field: "qtCancelada",
          name: "qtCancelada",
          format: (val) => this.$filters.formatDecimal(val),
          classes: "r7-text-color-purple-light fw-bold text-right",
          total: true,
        },
        {
          label: this.$t("message.balance"),
          field: "qtSaldoItemOri",
          name: "qtSaldoItemOri",
          format: (val) => this.$filters.formatDecimal(val),
          total: true,
          classes: "text-right",
        },
        {
          label: this.$t("message.situation"),
          field: "descSituacaoItem",
          name: "descSituacaoItem",
          classes: "fw-bold",
          align: "left",
        },
        {
          label: this.$t("message.order"),
          field: "pedido",
          name: "pedido",
          classes: "text-center",
        },
        {
          label: this.$t("message.article"),
          field: "dsArtigo",
          name: "dsArtigo",
          detail: true,
          align: "center",
        },
        {
          label: this.$t("message.color"),
          field: "dsCor",
          name: "dsCor",
          detail: true,
          align: "center",
        },
        {
          label: this.$t("message.designCode"),
          field: "dsDesenho",
          name: "dsDesenho",
          detail: true,
          align: "center",
        },
        {
          label: this.$t("message.deliveryDate"),
          field: "dsDtEntregaItem",
          name: "dsDtEntregaItem",
          detail: true,
          align: "center",
        },
        {
          label: this.$t("message.finishing"),
          field: "dsAcabamentos",
          name: "dsAcabamentos",
          detail: true,
          align: "center",
        },
        {
          label: this.$t("message.situation"),
          field: "descTituloSituacao",
          name: "descTituloSituacao",
          detail: true,
          align: "center",
        },
      ],

      camposTags: [
        {
          label: this.$t("message.productTagId"),
          field: "reduzidoTag",
        },
        {
          label: this.$t("message.description"),
          field: "descricao",
        },
        {
          label: this.$t("message.quantity"),
          field: "quantidade",
        },
        {
          label: this.$t("message.unit"),
          field: "dssigla",
        },
        {
          label: this.$t("message.price"),
          field: "precounitario",
        },
        {
          label: this.$t("message.situation"),
          field: "descricaoSituacao",
        },
      ],
    };
  },
  methods: {
    textTruncate(text) {
      return textTruncate(text, 12);
    },
    listarTagsItemPedido(row) {
      api
        .post("v1/comercial/pedidos/listartagsitempedido", {
          idPedido: row.pedido,
          itempedido: row.itempedido,
          reduzido: row.reduzido,
        })
        .then((res) => {
          this.dadosTags = res.data;
          this.$refs.modalTagItemPedido.abrir();
          this.titulo = `TAG do Reduzido: ${row.reduzido}`;
        })
        .catch((err) => showError(err));
    },
  },
};
</script>
