<template lang="">
  <page-title-bar />
  <app-section-loader :status="!showComponents"></app-section-loader>
  <div class="q-px-md">
    <template v-if="showComponents">
      <LogBackupComponent :tasks="tasks" v-if="showConsoleOutput" />
      <AutomacaoBackupComponent @refreshBackup="init" v-else />
    </template>
  </div>
</template>
<script>
import AutomacaoBackupComponent from "Areas/TI/AutomacaoBackupComponent";
import LogBackupComponent from "Areas/TI/LogBackupComponent";
import apy from "Api/python";
import { showError } from "@/notifications/notify";

export default {
  name: "AutomacaoBackupView",
  components: { AutomacaoBackupComponent, LogBackupComponent },
  data() {
    return {
      showComponents: false,
      showConsoleOutput: true,
      tasks: null,
      intervalId: null,
      poolingFrequency: 5000,
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.checarBackup();
        this.startPooling();
      }, 1000);
    },
    startPooling() {
      this.intervalId = setInterval(() => {
        this.checarBackup();
      }, this.poolingFrequency);
    },
    stopPooling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    async checarBackup() {
      await apy
        .get("dbora/checarbackup")
        .then((res) => {
          if (res.data.result) {
            this.tasks = res.data.result;
            this.showConsoleOutput = true;
          } else {
            this.stopPooling();
            this.showConsoleOutput = false;
          }
          this.showComponents = true;
        })
        .catch((err) => {
          if (err.code == "ERR_NETWORK") {
            showError("Backend(Python) offline");
            this.showComponents = false;
          } else {
            showError(err);
          }
          this.stopPooling();
        });
    },
  },
  mounted() {
    this.init();
  },
  beforeUnmount() {
    this.stopPooling();
  },
};
</script>
