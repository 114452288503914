<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      :loading="loader"
      :rows="rows"
      :columns="columns"
      :filter="filter"
      dense
      table-header-class="table-header"
      :rows-per-page-options="[20]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
      :style="style"
    >
      <template v-slot:top-right>
        <q-input
          class="q-ml-sm"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template v-slot:body-cell-status="props">
        <q-td auto-width>
          <q-badge size="xs" :color="colorBadge(props.row.status)">
            {{ props.row.status }}
          </q-badge>
        </q-td>
      </template>

      <template v-slot:body-cell-consoleOutputIcon="props">
        <q-td auto-width>
          <q-btn
            class="q-mb-xm"
            flat
            round
            size="sm"
            color="black"
            @click="onConsoleOutput()"
            icon="preview"
          >
            <!-- :disable="props.row.status !== 'RUNNING'" -->
            <q-tooltip>Console do Processo</q-tooltip>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-stopProcessIcon="props">
        <q-td auto-width>
          <q-btn
            class="q-mb-xm"
            flat
            round
            size="sm"
            color="red darken-2"
            @click="onStopProcess()"
            icon="cancel"
            :disable="props.row.status !== 'RUNNING'"
          >
            <q-tooltip>Parar Processo</q-tooltip>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </div>
</template>
<script>
import { sizeTotal } from "Helpers";
import Api from "Api";
import { showError } from "@/notifications/notify";

export default {
  data() {
    return {
      loader: true,
      filter: null,
      title: null,
      rows: [],
      columns: [
        {
          label: "Id",
          name: "id",
          field: "id",
          sortable: true,
          align: "right",
        },
        {
          label: "Data Início",
          name: "startdate",
          field: "startdate",
          sortable: true,
          align: "center",
        },
        {
          label: "Data Término",
          name: "enddate",
          field: "enddate",
          sortable: true,
          align: "center",
        },
        {
          label: "Usuário",
          name: "username",
          field: "username",
          sortable: true,
          align: "left",
        },
        {
          label: "Ação",
          name: "action",
          field: "action",
          sortable: true,
          align: "left",
        },
        {
          label: "Status",
          name: "status",
          field: "status",
          sortable: true,
          align: "left",
        },
        {
          label: "",
          name: "consoleOutputIcon",
          field: "consoleOutputIcon",
          sortable: false,
          align: "center",
        },
        {
          label: "",
          name: "stopProcessIcon",
          field: "stopProcessIcon",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    style() {
      const height = (this.loader ? sizeTotal(70) : sizeTotal(70)) + "px";
      return {
        height,
      };
    },
  },

  methods: {
    colorBadge(status) {
      switch (status) {
        case "COMPLETE":
          return "green darken-2";
        case "RUNNING":
          return "orange darken-2";
        case "STOPPED":
          return "red darken-2";
        default:
          break;
      }
    },
    refreshList() {
      this.getList();
    },
    initialize() {
      this.getList();
    },

    getList() {
      this.loader = true;
      Api.get("/v1/inventarioti")
        .then((res) => {
          this.rows = res.data;

          const running = this.rows.find((f) => f.status == "RUNNING");
          if (running) {
            this.$store.dispatch("admin/setDisableInventory");
          }
        })
        .catch((err) => showError(err))
        .finally((_) => (this.loader = false));
    },

    stopProcess() {},

    onStopProcess() {},

    onConsoleOutput() {
      Api.post("/v1/inventarioti/consoleoutput")
        .then(() => this.$router.push("consoleoutputinventarioti"))
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
