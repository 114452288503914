<template>
  <div>
    <page-title-bar></page-title-bar>
    <div class="q-pa-md">
      <UsuariosLDAPListaComponent />
    </div>
  </div>
</template>
<script>
import UsuariosLDAPListaComponent from "Areas/Admin/LDAP/UsuariosLDAPListaComponent";
export default {
  name: "UsuariosLDAPListaView",
  components: { UsuariosLDAPListaComponent },
};
</script>
