<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <ListaDocumentosRepositorio />
  </div>
</template>

<script>
import ListaDocumentosRepositorio from "Components/Areas/Repositorio/ListaDocumentosRepositorio";
export default {
  name: "CadastroDeDocumentos",
  components: { ListaDocumentosRepositorio },
};
</script>
