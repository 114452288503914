export default {
  setAtualizarRegistros(context) {
    context.commit("onChangeAtualizarRegistros");
  },
  setAcessoUsuarios(context, payload) {
    context.commit("onChangeAcessoUsuarios", payload);
  },
  setRemoverUsuario(context, payload) {
    context.commit("onChangeRemoverUsuario", payload);
  },
  setAcessoNivelAcesso(context, payload) {
    context.commit("onChangeAcessoNivelAcesso", payload);
  },
  setRemoverNivelAcesso(context, payload) {
    context.commit("onChangeRemoverNivelAcesso", payload);
  },

  setNullState(context) {
    context.commit("onHandlerState");
  },
};
