<template>
  <div>
    <page-title-bar v-if="exibir"></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md" :class="classe">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        :rowClass="row_classes"
        dense
        hide-pagination
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
        virtual-scroll
      >
        <template v-slot:body-cell-descricao="props">
          <q-td :style="{ 'text-indent': props.row.indent }">
            <span>
              {{ props.row.descricao }}
            </span>
          </q-td>
        </template>

        <template v-slot:body-cell-status="props">
          <q-td auto-width>
            <q-chip
              text-color="white"
              :color="definirStatus(props.row.status)"
              class="fw-bold"
            >
              {{ props.row.status === 1 ? "v1.0" : "v3.0" }}
            </q-chip>
          </q-td>
        </template>

        <template v-slot:body-cell-ativo="props">
          <q-td auto-width>
            <q-chip text-color="white" :color="getColor(props.row.ativo)">
              {{ props.row.ativo === 1 ? "Sim" : "Não" }}
            </q-chip>
          </q-td>
        </template>

        <template v-slot:top v-if="exibir">
          <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:body-cell-actionsEdit="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="onUpdate(props.row.id)"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>
        <template v-slot:body-cell-actionsDelete="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click="deletarMenu(props.row.id)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-td>
        </template>
      </q-table>
      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        @onConfirm="deleteItem"
      ></delete-confirmation-dialog>
    </div>
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import { menuHierarchy } from "Helpers";

export default {
  name: "MenuLista",
  props: {
    parentId: String,
    exibir: { type: Boolean, default: true },
    classe: { type: String, default: "grid-list-xl pt-0 mt-n3" },
  },
  data() {
    return {
      loader: true,
      selectItemToDelete: {},
      rows: [],
      columns: [
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
        },
        {
          label: this.$t("message.multiLanguage"),
          field: "descricaoMultLang",
          name: "descricaoMultLang",
          align: "left",
        },
        {
          label: this.$t("message.route"),
          field: "routeName",
          name: "routeName",
          align: "left",
        },

        {
          label: this.$t("message.reportName"),
          field: "nomerelatorio",
          name: "nomerelatorio",
          align: "left",
        },

        {
          label: this.$t("message.status"),
          field: "status",
          name: "status",
          align: "center",
        },

        {
          label: this.$t("message.active"),
          field: "ativo",
          name: "ativo",
          align: "center",
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          sortable: false,
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          sortable: false,
          align: "center",
        },
      ],
    };
  },

  methods: {
    row_classes(item) {
      if (!item.parentId) return "";
      return "row-child";
    },
    getColor(id) {
      return id === 1 ? "success" : "danger";
    },

    definirStatus(id) {
      return id === 1 ? "orange" : "primary";
    },

    retrieveList() {
      const url = this.parentId
        ? `v1/menu/children/${this.parentId}`
        : "v1/menu/all";

      api
        .get(url)
        .then((response) => {
          this.rows = response.data.map((elem, idx, array) => {
            const indent = menuHierarchy(elem.id, array);
            elem.indent = indent + "%";
            return elem;
          });
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onUpdate(id) {
      this.$router.push({ name: "menudetalhe", params: { id } });
    },
    deletarMenu(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectItemToDelete = id;
    },
    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();

      await api
        .delete(`v1/menu/${this.selectItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err));
      this.retrieveList();
    },

    onAdd() {
      this.$router.push({ name: "menudetalhe", params: { id: 0 } });
    },
  },
  created() {
    this.retrieveList();
  },
};
</script>

<style lang="sass" scoped>
.row-child td:first-child
  font-weight: bold
  text-indent: 1.5em
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
