<template>
  <div>
    <br />
    <p class="text-gray-light ml-3 mt-4 h3">
      {{ nome }}
      <slot name="icone"></slot>
    </p>
    <div class="text-left ml-3 mt-1 fs-14">
      <q-circular-progress
        indeterminate
        color="primary"
        v-if="loader"
      ></q-circular-progress>
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        dense
        hide-pagination
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
        virtual-scroll
      />
    </div>
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ClienteHistorico",
  props: ["idCliente"],
  data() {
    return {
      dialog: false,
      nome: null,
      loader: true,
      rows: [],
      columns: [
        {
          label: this.$t("message.user"),
          field: "usualteracao",
          name: "usualteracao",
          align: "left",
        },
        {
          label: this.$t("message.dateAndTime"),
          field: "datalteracao",
          name: "datalteracao",
          align: "center",
        },
        {
          label: this.$t("message.history"),
          field: "historico",
          name: "historico",
          align: "left",
        },
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
        },
      ],
    };
  },
  methods: {
    listarHistoricoCliente() {
      this.loader = true;
      api
        .post("/v1/clientes/listarhistoricocliente", {
          id: this.idCliente,
        })
        .then((res) => {
          if (res.data) {
            this.rows = res.data;
          }
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.listarHistoricoCliente();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 540px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
