import { maskNumberInteger } from "Helpers";
import moment from "moment";

const formatDecimal = (value) => maskNumberInteger(value, true);
const formatWithoutDecimal = (value) => maskNumberInteger(value, false);
const formatCurrency = (value) => `R$ ${maskNumberInteger(value, true)}`;
const formatDate = (value) => moment(value).format("DD/MM/YYYY");
const formatDateAndHour = (value) =>
  moment(value).format("DD/MM/YYYY HH:mm:ss");
const cnpj = (value) =>
  value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");

export default {
  formatDecimal,
  formatWithoutDecimal,
  formatCurrency,
  formatDate,
  formatDateAndHour,
  cnpj,
};
