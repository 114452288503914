<template>
  <div>
    <page-title-bar></page-title-bar>

    <div class="r7-container-header" v-if="show">
      <div class="row">
        <div class="col q-mx-lg">
          <transition name="flip">
            <q-card style="height: 410px" :key="troca" class="q-my-xs">
              <ResumoDadosRepresentante
                v-show="!flipped"
                :key="componentKeyDadosRepresentante"
                :idRepresentante="representanteStorage.idrepresentante"
              >
                <template v-slot:icone>
                  <q-btn
                    flat
                    round
                    dense
                    color="primary"
                    size="md"
                    icon="mdi-account-search"
                    @click="abrirPesquisa"
                  >
                    <q-tooltip
                      anchor="center left"
                      self="center right"
                      :offset="[10, 10]"
                      >{{ $t(`message.search`) }}
                    </q-tooltip>
                  </q-btn>

                  <q-btn
                    flat
                    round
                    dense
                    color="primary"
                    size="md"
                    class="q-mx-sm"
                    icon="mdi-clipboard-list-outline"
                    @click="listarClientesDoRepresentante"
                  >
                    <q-tooltip
                      anchor="center left"
                      self="center right"
                      :offset="[10, 10]"
                      >{{ $t(`message.sellerClients`) }}
                    </q-tooltip>
                  </q-btn>
                </template>
              </ResumoDadosRepresentante>

              <ListaClientesDoRepresentante
                v-if="flipped"
                ref="listaClientesDoRepresentante"
                :key="componentKeyListaClientesDoRepresentante"
                :idRepresentante="representanteStorage.idrepresentante"
              >
                <template v-slot:nome>
                  {{ representanteStorage.nomeRepresentante }}
                </template>

                <template v-slot:icone>
                  <q-btn
                    flat
                    round
                    dense
                    color="primary"
                    size="md"
                    class="q-ml-xs"
                    icon="mdi-arrow-u-left-top"
                    @click="listarClientesDoRepresentante"
                  />
                </template>
              </ListaClientesDoRepresentante>
            </q-card>
          </transition>
        </div>
        <div class="col q-px-md q-py-xs">
          <q-card style="height: 410px" v-if="show">
            <ResumoSituacaoPedidos
              :key="componentKeyResumoSituacaoPedidos"
              :idRepresentante="representanteStorage.idrepresentante"
              @listaPedidos="listarPedidos($event)"
              @limparListaPedidos="limparListaPedidos()"
            ></ResumoSituacaoPedidos>
          </q-card>
        </div>
      </div>

      <q-card class="q-ma-md" v-if="show">
        <q-tabs
          icons-and-text
          flat
          v-model="tab"
          dense
          class="text-grey-darken-3 bg-grey-2"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
          style="height: 60px"
        >
          <q-tab name="tab-1">
            <q-icon class="material-icons q-mb-sm" size="sm">list_alt </q-icon>
            {{ $t("message.ordersList") }}
          </q-tab>

          <q-tab name="tab-2">
            <q-icon class="material-icons q-mb-sm" size="sm">block </q-icon>
            {{ $t("message.blockedPreInvoices") }}
          </q-tab>

          <q-tab name="tab-3" v-if="getHighLevel">
            <q-icon class="material-icons q-mb-sm" size="sm">file_open </q-icon>
            {{ $t("message.openDuplicates") }}
          </q-tab>

          <q-tab name="tab-4">
            <q-icon class="material-icons q-mb-sm" size="sm"
              >local_activity
            </q-icon>
            {{ $t("message.movideskTickets") }}
          </q-tab>
        </q-tabs>

        <q-tab-panels v-model="tab" animated keep-alive>
          <q-tab-panel name="tab-1">
            <ListaPedidosSituacao
              ref="listaPedidosSituacao"
              :idRepresentante="representanteStorage.idrepresentante"
              :key="componentKeyListaPedidosSituacao"
            />
          </q-tab-panel>

          <q-tab-panel name="tab-2">
            <ListaPreNotasBloqueadas
              ref="listaPreNotasBloqueadas"
              :idRepresentante="representanteStorage.idrepresentante"
              :key="componentKeyListaPreNotasBloqueadas"
            />
          </q-tab-panel>

          <q-tab-panel name="tab-3" v-if="getHighLevel">
            <ListaDuplicatasEmAberto
              ref="listaDuplicatasEmAberto"
              :key="componentKeyListaDuplicatasEmAberto"
              :idRepresentante="representanteStorage.idrepresentante"
            />
          </q-tab-panel>

          <q-tab-panel name="tab-4" v-if="getHighLevel">
            <h1>Hello World</h1>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>

    <q-dialog v-model="dialog" class="q-pa-md">
      <RepresentanteLista
        :popUp="true"
        ref="listaRepresentante"
        @representanteSelecionado="fecharPesquisa()"
        class="r7-dialog-medium"
      >
      </RepresentanteLista>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref } from "vue";
import { chaveAleatoriaComponente } from "Helpers";
import RepresentanteLista from "Views/Representantes/RepresentanteLista";
import ResumoDadosRepresentante from "Components/Areas/Representantes/ResumoDadosRepresentante";
import ListaClientesDoRepresentante from "Components/Areas/Representantes/ListaClientesDoRepresentante";
import ResumoSituacaoPedidos from "Components/Areas/Comercial/ResumoSituacaoPedidos";
import ListaPedidosSituacao from "Components/Areas/Comercial/ListaPedidosSituacao";
import ListaPreNotasBloqueadas from "Components/Areas/Faturamento/ListaPreNotasBloqueadas";
import ListaDuplicatasEmAberto from "Components/Areas/Financeiro/ListaDuplicatasEmAberto";

export default {
  name: "RepresentanteResumo",
  components: {
    ResumoDadosRepresentante,
    ResumoSituacaoPedidos,
    ListaPedidosSituacao,
    ListaPreNotasBloqueadas,
    ListaDuplicatasEmAberto,
    RepresentanteLista,
    ListaClientesDoRepresentante,
  },
  data() {
    return {
      dialog: false,
      show: false,
      representante: {},
      representanteStorage: {},
      flipped: false,
      troca: false,
      tab: ref("tab-1"),

      componentKeyDadosRepresentante: chaveAleatoriaComponente(),
      componentKeyResumoSituacaoPedidos: chaveAleatoriaComponente(),
      componentKeyListaPedidosSituacao: chaveAleatoriaComponente(),
      componentKeyListaPreNotasBloqueadas: chaveAleatoriaComponente(),
      componentKeyListaDuplicatasEmAberto: chaveAleatoriaComponente(),
      componentKeyListaClientesDoRepresentante: chaveAleatoriaComponente(),
    };
  },
  computed: {
    ...mapGetters("admin", ["getHighLevel"]),
  },
  methods: {
    ...mapGetters("representantes", ["getDadosDoRepresentante"]),

    abrirPesquisa() {
      this.dialog = true;
    },
    fecharPesquisa() {
      this.dialog = false;
      this.montarTodos();
    },

    montarTodos() {
      this.representanteStorage = this.getDadosDoRepresentante();
      if (!this.representanteStorage) {
        this.dialog = true;
        return;
      }
      this.show = true;

      if (this.representante != this.representanteStorage) {
        this.representante = this.representanteStorage;
        this.componentKeyDadosRepresentante += chaveAleatoriaComponente();
        this.componentKeyListaClientesDoRepresentante +=
          chaveAleatoriaComponente();
        this.montarResumos();
      }
    },
    listarClientesDoRepresentante() {
      this.flipped = !this.flipped;
      this.troca = !this.troca;
    },
    montarResumos() {
      this.componentKeyResumoSituacaoPedidos += chaveAleatoriaComponente();
      this.componentKeyListaPedidosSituacao += chaveAleatoriaComponente();
      this.componentKeyListaPreNotasBloqueadas += chaveAleatoriaComponente();
      this.componentKeyListaDuplicatasEmAberto += chaveAleatoriaComponente();
    },
    listarPedidos(item) {
      this.$refs.listaPedidosSituacao.filtrarExibirSituacaoPedido(item);
      this.$refs.listaPreNotasBloqueadas.filtrarPrenotas(item);
    },
    limparListaPedidos() {
      this.$refs.listaPedidosSituacao.limparLista();
      this.$refs.listaPreNotasBloqueadas.limparLista();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.montarTodos();
    });
  },
};
</script>
