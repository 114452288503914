<template>
  <div>
    <page-title-bar></page-title-bar>

    <div class="q-pa-md">
      <div class="contact-wrapper">
        <Search
          :label="$t('message.typeOrder')"
          @pesquisar="pesquisar($event)"
        ></Search>

        <HeaderPedido
          :idPedido="idPedido"
          v-if="idPedido"
          :key="componentKeyHeaderPedido"
        />

        <ItensPedido
          :idPedido="idPedido"
          v-if="idPedido"
          :key="componentKeyItensPedido"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderPedido from "Components/Areas/Comercial/HeaderPedido";
import ItensPedido from "Components/Areas/Comercial/ItensPedido";
import Search from "Components/Widgets/Search";
import { chaveAleatoriaComponente } from "Helpers";
import { mapGetters } from "vuex";

export default {
  name: "PedidoUnico",
  components: {
    HeaderPedido,
    Search,
    ItensPedido,
  },
  data() {
    return {
      componentKeyHeaderPedido: chaveAleatoriaComponente(),
      componentKeyItensPedido: chaveAleatoriaComponente(),
      idPedido: this.$route.params.idPedido,
    };
  },
  computed: {
    ...mapGetters("common", ["getAtualizar"]),
  },
  watch: {
    $route(to) {
      this.idPedido = to.params.idPedido === 0 ? null : to.params.idPedido;
    },
    getAtualizar() {
      this.pesquisar(this.idPedido);
    },
  },
  methods: {
    pesquisar(valor) {
      this.idPedido = valor;
      this.componentKeyHeaderPedido += chaveAleatoriaComponente();
      this.componentKeyItensPedido += chaveAleatoriaComponente();
    },
  },
};
</script>
