import ConversorImagemB2B from "Views/Comercial/Ecommerce/ConversorImagemB2B.vue";

const routes = [
  {
    path: "/conversorimagemb2b",
    name: "conversorimagemb2b",
    component: ConversorImagemB2B,
    meta: {
      requiresAuth: true,
      title: "message.imageConvert",
      breadcrumb: [
        "message.comercial",
        "message.ecommerce",
        "message.imageConvert",
      ],
    },
  },
];

export default routes;
