<template>
  <div class="q-pa-md">
    <app-section-loader :status="loader"></app-section-loader>

    <q-table
      :loading="loader"
      :rows="rows"
      :columns="columns"
      :filter="filter"
      dense
      hide-pagination
      table-header-class="table-header"
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
    >
      <template v-slot:top-right>
        <q-input
          class="q-ml-sm"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:body-cell-controlaOrctoMarca="props">
        <q-td align="center" auto-width>
          <q-checkbox
            v-model="props.row.controlaOrctoMarca"
            :true-value="1"
            :false-value="0"
          >
          </q-checkbox>
        </q-td>
      </template>
      <template v-slot:body-cell-alertaConf="props">
        <q-td align="center" auto-width>
          <q-checkbox
            keep-color
            color="orange"
            v-model="props.row.alertaConf"
            :true-value="1"
            :false-value="0"
          >
          </q-checkbox>
        </q-td>
      </template>
      <template v-slot:body-cell-bloqueioConf="props">
        <q-td align="center" auto-width>
          <q-checkbox
            keep-color
            color="orange"
            v-model="props.row.bloqueioConf"
            :true-value="1"
            :false-value="0"
          >
          </q-checkbox>
        </q-td>
      </template>
      <template v-slot:body-cell-alertaTextil="props">
        <q-td align="center" auto-width>
          <q-checkbox
            keep-color
            color="teal"
            v-model="props.row.alertaTextil"
            :true-value="1"
            :false-value="0"
          >
          </q-checkbox>
        </q-td>
      </template>
      <template v-slot:body-cell-bloqueioTextil="props">
        <q-td align="center" auto-width>
          <q-checkbox
            keep-color
            color="teal"
            v-model="props.row.bloqueioTextil"
            :true-value="1"
            :false-value="0"
          >
          </q-checkbox>
        </q-td>
      </template>
      <template v-slot:bottom>
        <q-btn
          color="green-8 material-icons-outlined "
          size="sm"
          @click="save"
          class="q-my-xs q-py-xs"
          icon="save"
          :label="$t('message.save')"
          :disable="loader"
        />
      </template>
    </q-table>
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "ListaGruposCompras",
  data() {
    return {
      loader: true,
      rows: [],
      filter: null,
      columns: [
        {
          label: this.$t("message.group"),
          field: "cdGrupo",
          name: "cdGrupo",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "dsGrupo",
          name: "dsGrupo",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.controlsBudgetBrand"),
          field: "controlaOrctoMarca",
          name: "controlaOrctoMarca",
          align: "center",
        },
        {
          label: "Alerta Confecção",
          field: "alertaConf",
          name: "alertaConf",
          align: "center",
        },
        {
          label: "Bloqueio Confecção",
          field: "bloqueioConf",
          name: "bloqueioConf",
          align: "center",
        },
        {
          label: "Alerta Têxtil",
          field: "alertaTextil",
          name: "alertaTextil",
          align: "center",
        },
        {
          label: "Bloqueio Têxtil",
          field: "bloqueioTextil",
          name: "bloqueioTextil",
          align: "center",
        },
      ],
    };
  },
  methods: {
    save() {
      this.loader = true;
      const data = { grupos: this.rows };
      api
        .patch("/v1/compras/gruposcompras/alterar", data)
        .then(() => showSuccess(this.$t("message.successUpdatedRecord")))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    retrieveList() {
      this.loader = true;
      api
        .get("/v1/compras/gruposcompras/listar")
        .then((response) => {
          this.loader = false;
          this.rows = response.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  created() {
    this.retrieveList();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 70vh
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
