<template>
  <q-select
    rounded
    outlined
    dense
    :options="items"
    option-label="descricao"
    option-value="id"
    v-model="id"
    label="Parent"
    @update:model-value="itemSelected"
    :disable="disabled"
    map-options
  >
    <template v-slot:prepend>
      <q-icon :name="prependIcon" />
    </template>
  </q-select>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "MenuParentSelecao",
  props: {
    parentId: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: this.parentId,
      items: [],
    };
  },
  watch: {
    parentId() {
      this.id = this.parentId;
    },
  },
  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("v1/menu/parents")
        .then((res) => {
          this.items = res.data;
        })
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
