import Full from "Container/Full";
import Home from "Container/Home";
import Playground from "Views/Admin/Playground";
import AdminRoutes from "./admin";
import ClientesRoutes from "./clientes";
import ComercialRoutes from "./comercial";
import RepresentantesRoutes from "./representantes";
import ProdutosRoutes from "./produtos";
import ComprasRoutes from "./compras";
import EstoquesRoutes from "./estoques";
import TiRoutes from "./ti";
import FaturamentoRoutes from "./faturamento";
import RepositorioRoutes from "./repositorio";
import EcommerceRoutes from "./ecommerce";
import QARoutes from "./qa";
import ValisereRoutes from "./valisere";
import FinanceiroRoutes from "./financeiro";

export default {
  path: "/",
  component: Full,
  redirect: "/home",
  children: [
    ...AdminRoutes,
    ...ComercialRoutes,
    ...ClientesRoutes,
    ...RepresentantesRoutes,
    ...ProdutosRoutes,
    ...ComprasRoutes,
    ...EstoquesRoutes,
    ...TiRoutes,
    ...FaturamentoRoutes,
    ...RepositorioRoutes,
    ...QARoutes,
    ...EcommerceRoutes,
    ...ValisereRoutes,
    ...FinanceiroRoutes,
    {
      name: "home",
      path: "/home",
      component: Home,
      meta: {
        requiresAuth: true,
        title: "message.home",
        breadcrumb: null,
      },
    },
    {
      path: "/playground",
      name: "playground",
      component: Playground,
      meta: {
        requiresAuth: true,
        title: "message.playground",
        breadcrumb: ["message.admin"],
      },
    },
  ],
};
