<template>
  <ModalForm
    ref="modalFormRegistroMetas"
    :title="$t('message.addRecord')"
    :customClass="estilo"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="aoSalvar()">
        <div class="row q-mb-xl q-mt-md">
          <div class="col-4 q-pr-xs">
            <GrupoDeVendasSelecao
              :grupoId="registroData.grupoId"
              @selected="selectedGrupo($event)"
              :autofocus="true"
            />
          </div>
          <div class="col-4 q-px-xs">
            <MonthSelector
              :mesId="registroData.mesId"
              @selected="selectedMes($event)"
              :required="true"
            />
          </div>
          <div class="col-4 q-pl-xs">
            <RepresentanteSelecao
              :representanteId="registroData.representanteId"
              @selected="selectedRepresentante($event)"
            />
          </div>
        </div>

        <div class="row q-mt-xl q-mb-md">
          <div class="col-4 q-pr-xs">
            <InputQuantity
              v-model.lazy="registroData.valor"
              :filled="true"
              :required="false"
              :label="$t('message.insertValue')"
            />
          </div>

          <div class="col-4">
            <InputQuantity
              v-model.lazy="registroData.quilos"
              :filled="true"
              :required="false"
              :label="$t('message.insertKilos')"
              class="q-ml-xs"
            />
          </div>
        </div>
        <q-separator />
        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-mt-md"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="aoFechar()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
</template>

<script>
import GrupoDeVendasSelecao from "Components/Areas/Comercial/GrupoDeVendasSelecao";
import MonthSelector from "Components/Widgets/MonthSelector";
import RepresentanteSelecao from "Components/Areas/Representantes/RepresentanteSelecao";
import InputQuantity from "Components/Widgets/InputQuantity";
import ModalForm from "Components/Widgets/ModalForm";
import { clearObject } from "Helpers";
import { mapActions } from "vuex";

export default {
  name: "NovoRegistroMetas",
  components: {
    GrupoDeVendasSelecao,
    MonthSelector,
    RepresentanteSelecao,
    InputQuantity,
    ModalForm,
  },
  data() {
    return {
      estilo: {
        width: "1100px",
        height: "400px",
      },
      registroData: {
        linha: null,
        grupoId: null,
        grupoDescription: null,
        mesId: null,
        mesDescription: null,
        representanteId: null,
        representanteDescription: null,
        valor: null,
        quilos: null,
      },
    };
  },

  methods: {
    ...mapActions("metas", ["setMeta", "setEditarMeta"]),

    aoAbrir(linha) {
      if (linha) {
        this.registroData = { ...linha };
      }
      this.$refs.modalFormRegistroMetas.abrir();
    },

    aoFechar() {
      clearObject(this.registroData, this.$refs.form);
      this.$refs.modalFormRegistroMetas.fechar();
    },

    selectedGrupo(item) {
      this.registroData.grupoId = item.id;
      this.registroData.grupoDescription = item.description;
    },

    selectedMes(item) {
      this.registroData.mesId = item.id;
      this.registroData.mesDescription = item.description;
    },

    selectedRepresentante(item) {
      this.registroData.representanteId = item.id;
      this.registroData.representanteDescription = item.description;
    },

    aoSalvar() {
      if (this.registroData.linha) {
        this.setEditarMeta({ ...this.registroData });
        this.aoFechar();
      } else {
        this.setMeta({ ...this.registroData });
        clearObject(this.registroData, this.$refs.form);
      }
    },
  },
};
</script>
