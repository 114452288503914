<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <div class="row justify-between">
      <div class="col-auto">
        <MenuInventario
          ref="menuInventario"
          @refreshListInventario="refreshList()"
        />
      </div>
    </div>
  </div>
  <div class="q-pa-md">
    <ListaInventariosComponent ref="listaInventarios" />
  </div>
</template>

<script>
import MenuInventario from "Areas/Admin/InventarioTI/MenuInventario.vue";
import ListaInventariosComponent from "Areas/Admin/InventarioTI/ListaInventariosComponent.vue";

export default {
  name: "InventarioTIView",
  components: { MenuInventario, ListaInventariosComponent },
  data() {
    return {};
  },
  methods: {
    refreshList() {
      this.$refs.listaInventarios.refreshList();
    },
  },
};
</script>
