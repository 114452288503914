<template lang="">
  <div class="q-pa-sm row items-start q-gutter-md">
    <q-card class="my-card" flat bordered>
      <q-item>
        <q-item-section avatar>
          <q-avatar>
            <q-icon color="green darken-2" size="lg" class="material-icons">
              check_circle
            </q-icon>
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h5 col-12 text-weight-bold"
            >Console Output</q-item-label
          >
        </q-item-section>
      </q-item>
      <q-separator />
      <q-card-section class="bg-grey-3">
        <pre>
          <div class="tasks">
            <div class="taskInfo">
               <span class="text-weight-bold">Solicitante: </span>
               <span>{{tasks.USUARIO}}</span>&nbsp;&nbsp;&nbsp;
               <span class="text-weight-bold">Data Solicitação: </span>
               <span>{{tasks.DTLOGBACKUP}}</span>
            </div>   
            <template v-for="(item, index) in tasks.RESUMETASK" :key="index">
              <span class="task text-weight-bold" >
                {{item.label}}:
              </span>
              <span class="task">
                 {{item.value}}
              </span>
            </template>
          </div>
          <q-separator />
          <div class="taskInfo">
              <span class="text-weight-bold">Tarefa Atual: </span>
              <span>{{tasks.TASKLOG}}</span>&nbsp;&nbsp;&nbsp;
              <span class="text-weight-bold">Data Início: </span>
              <span>{{tasks.DTLOGTAREFA}}</span>
          </div>
          <div class="taskInfo" v-if="tasks.DATAPUMPSESSION">
              <span class="text-weight-bold">Datapump execução atual: </span>
              <span>{{tasks.DATAPUMPSESSION.SQL_TEXT}}</span>&nbsp;&nbsp;&nbsp;
          </div>
          <div v-if="tasks.TASKLOG == 'Copia em andamento' ||  tasks.DATAPUMPSESSION">
            <q-linear-progress size="25px" :value="progress1" color="accent">
              <div class="absolute-full flex flex-center">
                <q-badge color="white" text-color="accent" :label="progressLabel1" />
              </div>
            </q-linear-progress>              
          </div>
          <div v-if="tasks.TASKLOG == 'Importação em andamento'">
            {{ tasks.CONTENTFILE }}
          </div>
        </pre>
      </q-card-section>
    </q-card>
  </div>
</template>
<script>
export default {
  props: ["tasks"],
  data() {
    return {
      messages: [],
      messageString: null,
      progress1: 0,
      progressLabel1: null,
    };
  },
  watch: {
    tasks(n, o) {
      this.mountProgress();
    },
  },
  methods: {
    mountProgress() {
      if (this.tasks.TASKLOG == "Copia em andamento") {
        const content = this.tasks.CONTENTFILE.split("- ");
        this.progressLabel1 = content[0];
        this.progress1 = content[1];
      } else {
        if (this.tasks.TASKLOG == "Importação em andamento") {
          if (this.tasks.DATAPUMPSESSION) {
            this.progressLabel1 = `Tempo restante: ${this.tasks.DATAPUMPSESSION.TIME_REMAINING}s`;
            this.progress1 = this.tasks.DATAPUMPSESSION.DONE / 100;
          }
        }
      }
      this.messageString = this.tasks;
    },
  },
  mounted() {
    this.mountProgress();
  },
};
</script>
<style scoped>
.my-card {
  width: 100vw;
  max-width: 100vw;
}
.tasks {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.task {
  line-height: 1;
}
.taskInfo {
  display: flex;
  flex-direction: row;
}
pre {
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  color: #14141f;
  font-family: ui-monospace, sfmono-regular, sf mono, jetbrainsmono, consolas,
    monospace;
  font-weight: 500;
  font-size: 0.95em;
  line-height: 1.66;
  margin: 0 0 1.75rem;
  padding: 0.8rem 1rem;
  white-space: pre-line;
  word-wrap: break-word;
}
</style>
