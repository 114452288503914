<template>
  <q-card>
    <div class="row">
      <div class="col-auto q-ml-md q-my-sm">
        <span class="text-bold text-subtitle2">
          {{ $t("message.tableNumber") }}:
        </span>
      </div>
      <div class="col-3 q-ml-sm q-my-sm">
        <span class="text-subtitle2">
          {{ this.dadosLista.nrTabela }}
        </span>
      </div>

      <div class="col-auto q-my-sm q-ml-sm q-pl-xs">
        <span class="text-bold text-subtitle2"
          >{{ $t("message.description") }}:
        </span>
      </div>

      <div class="col-4 q-ml-sm q-my-sm">
        <span class="text-subtitle2"> {{ this.dadosLista.nome }}</span>
      </div>

      <div class="col-auto q-my-sm">
        <span class="text-bold text-subtitle2"
          >{{ $t("message.isTableActive") }}:
        </span>
      </div>

      <div class="col-auto q-ml-sm q-my-sm">
        <span class="text-subtitle2">{{ analisarStatus() }}</span>
      </div>
    </div>

    <div class="row">
      <div class="col-auto q-ml-md q-my-sm">
        <span class="text-bold text-subtitle2">
          {{ $t("message.emissionDate") }}:
        </span>
      </div>

      <div class="col-3 q-ml-sm q-my-sm">
        <span class="text-subtitle2">
          {{ this.dadosLista.dtEmissao }}
        </span>
      </div>

      <div class="col-auto q-my-sm">
        <span class="text-bold text-subtitle2">
          {{ $t("message.validityDate") }}:
        </span>
      </div>

      <div class="col-auto q-ml-sm q-my-sm">
        <span class="text-subtitle2">
          {{ this.dadosLista.dtVigencia }}
        </span>
      </div>
    </div>
  </q-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderTabelaListadePrecosItem",

  data() {
    return {
      dadosLista: {},
    };
  },
  methods: {
    ...mapGetters("compras", ["getDadosListaPrecoSalete"]),

    obterDadosTabela() {
      this.dadosLista = this.getDadosListaPrecoSalete();
    },
    analisarStatus() {
      return this.dadosLista.status == 0 ? "Não" : "Sim";
    },
  },
  created() {
    this.obterDadosTabela();
  },
};
</script>
