<template>
  <q-toolbar class="bg-grey-8 text-white">
    <q-btn flat round dense icon="mdi-cookie-lock" />
    <q-toolbar-title>
      Grupo Rosset e os cookies: usamos cookies para melhorar a sua experiência
      no site. Ao continuar navegando, você concorda com a nossa
      <q-btn flat @click="openDocument">Política de Privacidade</q-btn>
    </q-toolbar-title>
    <q-btn push color="primary" label="Concordar" @click="onAccept" />
  </q-toolbar>

  <LgpdVisualizarDocumento ref="modalDocument" />
</template>

<script>
import { cookieConsent } from "@/global";
import LgpdVisualizarDocumento from "Components/Areas/Admin/LGPD/LgpdVisualizarDocumento";
export default {
  name: "CookieConsent",
  emits: ["hasKey"],
  components: { LgpdVisualizarDocumento },
  data() {
    return {
      documentPdf: null,
    };
  },
  methods: {
    onAccept() {
      localStorage.setItem(cookieConsent, true);
      this.$emit("hasKey", true);
    },
    openDocument() {
      this.$refs.modalDocument.openDocument("aviso interno de privacidade.pdf");
    },
  },
  mounted() {
    const key = localStorage.getItem(cookieConsent);
    this.$emit("hasKey", key);
  },
};
</script>
