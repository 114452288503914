<template>
  <ModalForm
    ref="modalFormPesquisa"
    :title="tituloModal"
    :customClass="estilo"
    :persistent="false"
  >
    <template v-slot:body>
      <div class="row">
        <div class="col">
          <q-table
            :rows="rows"
            :columns="columns"
            dense
            hide-pagination
            table-header-class="table-header"
            :rows-per-page-options="[0]"
            :noDataLabel="$t('message.noDataFound')"
            class="my-sticky-header-table"
            virtual-scroll
            :style="{ height: '42vh' }"
          >
            <template v-slot:top-left>
              <q-input
                borderless
                dense
                debounce="300"
                v-model="referencia"
                placeholder="Pesquisar Referência"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
          </q-table>
        </div>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from "Components/Widgets/ModalForm";

export default {
  name: "PesquisarGruposAtravesDaReferencia",
  components: { ModalForm },
  props: ["relacionamentos"],

  data() {
    return {
      estilo: {
        width: "60vw",
        height: "60vh",
      },
      referencia: null,
      dataRelacionados: null,
      alturaTabela: 0,

      rows: [],
      columns: [
        {
          label: "Referência",
          field: "cdReferencia",
          name: "cdReferencia",
          align: "center",
          sortable: true,
        },
        {
          label: "Grupo",
          field: "cdGrupo",
          name: "cdGrupo",
          align: "center",
          sortable: true,
        },
        {
          label: "Descrição",
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
      ],
      tituloModal: "Pesquisar Grupos Através da Referência",
    };
  },

  watch: {
    referencia() {
      this.filterRows();
    },
  },

  methods: {
    abrir() {
      this.$refs.modalFormPesquisa.abrir();
      this.filterRows();
    },

    aoFechar() {
      this.$refs.modalFormPesquisa.fechar();
    },

    filterRows() {
      const filterGroup = (row) => {
        if (!this.referencia) return true;
        return row.cdReferencia.indexOf(this.referencia) >= 0;
      };

      this.rows = this.relacionamentos.filter((f) => filterGroup(f));
    },
  },
};
</script>
