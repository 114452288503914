<template lang="">
  <ModalForm
    ref="modalFormCadastro"
    title="Aprovação de Limites de Compras"
    :customClass="estilo"
    :persistent="true"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="aoSalvar()">
        <div class="row q-mb-xl q-mt-md">
          <div class="col-4 q-pr-xs">
            <GruposComprasSelectComponent
              @selected="selectedGrupo($event)"
              :grupoId="registroData.cdGrupo"
              :autofocus="true"
              :required="true"
            />
          </div>
          <div class="col-4 q-px-xs">
            <CentrosCustoSelectComponent
              @selected="selectedCentro($event)"
              :centroId="registroData.cdCentro"
            />
          </div>
          <div class="col-4 q-pl-xs"></div>
        </div>

        <div class="row q-mt-xl q-mb-md">
          <div class="col-4 q-pr-xs">
            <InputQuantity
              v-model.lazy="registroData.vlFaixa1"
              :filled="true"
              :required="false"
              label="Valor Faixa 1"
            />
          </div>

          <div class="col-4">
            <InputQuantity
              v-model.lazy="registroData.vlFaixa2"
              :filled="true"
              :required="false"
              label="Valor Faixa 2"
              class="q-ml-xs"
            />
          </div>
          <div class="col-4">
            <InputQuantity
              v-model.lazy="registroData.vlFaixa3"
              :filled="true"
              :required="false"
              label="Valor Faixa 3"
              class="q-ml-xs"
            />
          </div>
        </div>
        <q-separator />
        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-mt-md"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="fechar()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
</template>
<script>
import ModalForm from "Components/Widgets/ModalForm";
import InputQuantity from "Components/Widgets/InputQuantity";
import GruposComprasSelectComponent from "Areas/Compras/GruposCompras/GruposComprasSelectComponent";
import CentrosCustoSelectComponent from "Areas/Compras/CentrosCusto/CentrosCustoSelectComponent";
export default {
  name: "FormLimitesAprovacaoComprasComponent",
  emits: ["save"],
  components: {
    ModalForm,
    InputQuantity,
    GruposComprasSelectComponent,
    CentrosCustoSelectComponent,
  },
  data() {
    return {
      estilo: {
        width: "60vw",
        height: "60vh",
      },

      registroDataEmpty: {
        cdGrupo: 0,
        cdCentro: 0,
        vlFaixa1: 0,
        vlFaixa2: 0,
        vlFaixa3: 0,
      },
      registroData: {
        cdGrupo: 0,
        cdCentro: 0,
        vlFaixa1: 0,
        vlFaixa2: 0,
        vlFaixa3: 0,
      },
    };
  },
  methods: {
    selectedGrupo(item) {
      this.registroData.cdGrupo = item?.id;
    },
    selectedCentro(item) {
      this.registroData.cdCentro = item?.id;
    },
    abrir(reg) {
      if (reg) {
        this.registroData = { ...reg };
        this.registroData.vlFaixa1 *= 100;
        this.registroData.vlFaixa2 *= 100;
        this.registroData.vlFaixa3 *= 100;
      } else {
        this.clearModal();
      }
      this.$refs.modalFormCadastro.abrir();
    },
    fechar() {
      this.$refs.modalFormCadastro.fechar();
    },
    clearModal() {
      this.registroData = { ...this.registroDataEmpty };
    },
    aoSalvar() {
      this.$emit("save", this.registroData);
    },
  },
};
</script>
