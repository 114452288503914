import RepresentanteResumo from "Views/Representantes/RepresentanteResumo";
import RepresentanteLista from "Views/Representantes/RepresentanteLista";

const routes = [
  {
    path: "/representanteresumo",
    name: "representanteresumo",
    component: RepresentanteResumo,
    meta: {
      requiresAuth: true,
      title: "message.sellerSummary",
      breadcrumb: ["message.seller", "message.sellerSummary"],
    },
  },
  {
    path: "/representantelista",
    name: "representantelista",
    component: RepresentanteLista,
    meta: {
      requiresAuth: true,
      title: "message.sellerList",
      breadcrumb: ["message.seller", "message.sellerList"],
    },
  },
];

export default routes;
