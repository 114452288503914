<template>
  <q-table
    ref="table"
    :id="uuid"
    :loading="loading"
    :rows="getFilteredValuesData"
    :columns="final_column"
    row-key="name"
    :class="classes"
    :visible-columns="visible_columns"
    :pagination="pagination"
    :separator="separator"
    :dense="dense"
    :dark="dark"
    :flat="flat"
    :bordered="bordered"
    :square="square"
    :selection="selection_prop"
    v-model:selected="selected_prop"
    :filter="filter"
    :noDataLabel="$t('message.noDataFound')"
    rows-per-page-label="Itens por página"
    :rows-per-page-options="[0]"
    class="my-sticky-header-column-table"
    :totalizador="totalizador"
    :subtotalizador="subtotalizador"
    virtual-scroll
    v-model:pagination="paginacao"
  >
    <template v-slot:header="props" class="tableHeader">
      <q-tr :props="props" v-show="!hasHeaderSlot">
        <q-th
          auto-width
          class="ignore-elements tableHeader"
          v-if="selection_prop != 'none'"
        >
          <q-checkbox
            v-if="selection_prop == 'multiple'"
            v-model="props.selected"
            indeterminate-value="some"
          />
        </q-th>
        <q-th
          :props="props"
          @hover.stop
          v-for="col in props.cols"
          :key="col.name"
          class="tableHeader"
          auto-width
        >
          <div class="row inline">
            <div class="column">
              {{ col.label }}
            </div>
            <div class="column">
              <q-btn
                flat
                dense
                size="sm"
                icon="fa fa-filter"
                class="q-ml-xs"
                @click.stop=""
                v-if="header_filter"
              >
                <q-icon
                  name="fas fa-asterisk"
                  color="red"
                  style="font-size: 7px"
                  v-if="column_options_selected[col.field].length > 0"
                ></q-icon>
                <q-menu>
                  <q-space />

                  <q-btn
                    dense
                    class="float-right q-ma-sm bg-red text-white"
                    round
                    size="sm"
                    v-close-popup
                    flat
                    icon="close"
                  />

                  <div class="q-pa-sm q-mt-md">
                    <q-select
                      map-options
                      multiple
                      emit-value
                      filled
                      v-model="column_options_selected[col.field]"
                      :options="getColumnOptions(col.field)"
                      style="width: 150px !important"
                    >
                      <template v-slot:before-options>
                        <q-item class="sticky-top">
                          <q-item-section avatar>
                            <q-checkbox
                              @update:model-value="
                                getColumnOptions(col.field).length ==
                                column_options_selected[col.field].length
                                  ? (column_options_selected[col.field] = [])
                                  : (column_options_selected[col.field] =
                                      getColumnOptions(col.field).map(
                                        (item) => item.value
                                      ))
                              "
                              :model-value="
                                getColumnOptions(col.field).length ==
                                column_options_selected[col.field].length
                                  ? true
                                  : column_options_selected[col.field].length ==
                                    0
                                  ? false
                                  : null
                              "
                              color="teal"
                            />
                          </q-item-section>
                          <q-item-section>
                            <q-item-label v-html="'Select All'"></q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>
                      <template v-slot:option="scope">
                        <q-item
                          v-bind="scope.itemProps"
                          v-on="scope.itemEvents"
                        >
                          <q-item-section avatar>
                            <q-checkbox
                              v-model="column_options_selected[col.field]"
                              :val="scope.opt.value"
                              color="teal"
                            />
                          </q-item-section>
                          <q-item-section>
                            <q-item-label
                              class="text-black"
                              v-html="scope.opt.label"
                            ></q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </div>
                  <q-btn
                    color="primary"
                    class="float-right q-mr-sm q-mb-sm text-capitalize"
                    size="sm"
                    v-close-popup
                    @click="column_options_selected[col.field] = []"
                    label="Clear"
                  />
                </q-menu>
              </q-btn>
            </div>
          </div>
        </q-th>
      </q-tr>
      <slot name="header" v-bind:cols="props.cols" v-if="hasHeaderSlot"> </slot>
      <q-tr :props="props" class="ignore-elements" v-if="columns_filter">
        <q-th auto-width v-if="selection_prop != 'none'"> </q-th>
        <q-th
          :key="col.name"
          v-for="col in props.cols"
          style="padding: 0px 0px 0px 0px"
        >
          <q-input
            v-if="
              !col.hasOwnProperty('filter_type') || col.filter_type == 'text'
            "
            dense
            color="teal"
            class="q-pa-xs"
            filled
            v-model="filter_data[col.field]"
          >
            <template v-if="filter_data[col.field]" v-slot:append>
              <q-icon
                name="cancel"
                @click.stop="filter_data[col.field] = ''"
                class="cursor-pointer"
              />
            </template>
          </q-input>

          <q-select
            v-if="
              col.hasOwnProperty('filter_type') && col.filter_type == 'select'
            "
            map-options
            multiple
            emit-value
            filled
            v-model="column_options_selected[col.field]"
            :options="getColumnOptions(col.field)"
            dense
          >
            <template v-slot:append>
              <q-icon
                v-if="column_options_selected[col.field].length > 0"
                name="close"
                @click.stop="column_options_selected[col.field] = []"
                class="cursor-pointer"
              />
            </template>
            <template v-slot:before-options>
              <q-item class="sticky-top">
                <q-item-section avatar>
                  <q-checkbox
                    @update:model-value="
                      getColumnOptions(col.field).length ==
                      column_options_selected[col.field].length
                        ? (column_options_selected[col.field] = [])
                        : (column_options_selected[col.field] =
                            getColumnOptions(col.field).map(
                              (item) => item.value
                            ))
                    "
                    :model-value="
                      getColumnOptions(col.field).length ==
                      column_options_selected[col.field].length
                        ? true
                        : column_options_selected[col.field].length == 0
                        ? false
                        : null
                    "
                    color="teal"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label v-html="'Select All'"></q-item-label>
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                <q-item-section avatar>
                  <q-checkbox
                    v-model="column_options_selected[col.field]"
                    :val="scope.opt.value"
                    color="teal"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label v-html="scope.opt.label"></q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>

          <q-input
            v-if="
              col.hasOwnProperty('filter_type') && col.filter_type == 'date'
            "
            dense
            color="teal"
            class="q-pl-xs q-pr-xs"
            filled
            :model-value="
              filter_data[col.field].from +
              (filter_data[col.field].from ? '-' : '') +
              filter_data[col.field].to
            "
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="filter_data[col.field]" range>
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        class="text-capitalize"
                        color="primary"
                        flat
                      ></q-btn>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
              <q-icon
                name="cancel"
                v-if="filter_data[col.field].from != ''"
                @click.stop="filter_data[col.field] = { from: '', to: '' }"
                class="cursor-pointer"
              />
            </template>
          </q-input>
        </q-th>
      </q-tr>
    </template>

    <template
      v-slot:top-right="props"
      v-if="excel_download || csv_download || fullscreen || global_search"
    >
      <q-input
        filled
        v-if="global_search"
        borderless
        dense
        debounce="300"
        v-model="filter"
        class="q-mr-md"
        placeholder="Search"
      >
        <template v-slot:append>
          <q-icon class="material-icons" name="search">search</q-icon>
        </template>
      </q-input>

      <q-btn
        class="bg-grey-2 q-mr-sm"
        icon="fas fa-file-excel"
        no-caps
        v-if="excel_download"
        @click="exportTable('xlsx')"
      />

      <q-btn
        class="bg-primary text-white"
        icon="fas fa-file-csv"
        no-caps
        v-if="csv_download"
        @click="exportTable('csv')"
      />

      <q-select
        class="q-mr-sm q-ml-sm"
        outlined
        dense
        v-model="selected_group_by_filed"
        v-if="groupby_filter"
        :options="gorupby_option"
        style="width: 150px"
      ></q-select>

      <q-btn
        v-if="fullscreen"
        flat
        round
        class="q-ml-sm"
        dense
        :icon="props.inFullscreen ? 'fullscreen_exit' : 'fullscreen'"
        @click="props.toggleFullscreen"
      >
        <q-tooltip :disable="$q.platform.is.mobile" v-close-popup>{{
          props.inFullscreen ? "Exit Fullscreen" : "Toggle Fullscreen"
        }}</q-tooltip>
      </q-btn>
    </template>

    <template v-slot:body="props">
      <q-tr :props="props" v-if="!hasDefaultSlot">
        <q-td v-if="selection_prop != 'none'">
          <q-checkbox color="primary" v-model="props.selected" />
        </q-td>
        <q-td
          v-for="(col, col_index) in props.cols"
          :key="col.name"
          :props="props"
        >
          <q-btn
            size="sm"
            color="accent"
            round
            dense
            @click="props.expand = !props.expand"
            class="q-mr-sm"
            :icon="props.expand ? 'remove' : 'add'"
            v-if="
              groupby_filter &&
              selected_group_by_filed.value != '' &&
              col_index == 0
            "
          />
          <slot :value="props.row[col.field]" :name="col.field">{{
            getFormatValue(col.format, props.row[col.field])
          }}</slot>
        </q-td>
      </q-tr>
      <q-tr
        v-if="groupby_filter && selected_group_by_filed.value != ''"
        v-show="props.expand"
        :props="props"
      >
        <q-td :colspan="2">
          <q-table
            :rows="sub_grouped_data[props.row.name]"
            :columns="columns"
            row-key="name"
            :pagination="group_pagination"
            hide-bottom
          >
            <template
              v-slot:header="props"
              v-if="col.field != selected_group_by_filed"
            >
              <q-tr>
                <q-th v-for="col in props.cols" :key="col.name" :props="props">
                  {{ col.label }}
                </q-th>
              </q-tr>
            </template>
            <template
              v-slot:body="props"
              v-if="col.field != selected_group_by_filed"
            >
              <q-tr :props="props">
                <q-td :key="col.name" v-for="col in props.cols" :props="props">
                  {{ props.row[col.field] }}
                </q-td>
              </q-tr>
            </template>
          </q-table>
        </q-td>
      </q-tr>
      <slot name="body" v-bind:row="props.row" v-if="hasDefaultSlot"> </slot>
    </template>

    <template v-slot:loading v-if="$slots['loading']">
      <slot name="loading"></slot>
    </template>

    <template v-slot:bottom-row>
      <q-tr class="totalizador" v-if="subtotalizador">
        <Totalizador
          :dados="$refs.table.computedRows"
          :colunas="columns"
          v-if="$refs.table"
          label="SubTotal: "
        />
      </q-tr>

      <q-tr class="totalizador" v-if="totalizador">
        <Totalizador
          :dados="data"
          :colunas="columns"
          label="Total: "
          v-if="$refs.table"
        />
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import { defineComponent, ref } from "vue";
import Sortable from "sortablejs";
import * as moment from "moment";

import { uid } from "quasar";
import { exportFile } from "quasar";
import Totalizador from "Components/Table/Totalizador";

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val;
  formatted =
    formatted === void 0 || formatted === null ? "" : String(formatted);
  formatted = formatted.split('"').join('""');

  return `"${formatted}"`;
}

export default defineComponent({
  name: "QGrid",
  components: { Totalizador },

  props: [
    "data",
    "columns",
    "file_name",
    "csv_download",
    "excel_download",
    "columns_filter",
    "header_filter",
    "draggable",
    "draggable_columns",
    "classes",
    "separator",
    "dense",
    "dark",
    "flat",
    "bordered",
    "square",
    "selection",
    "selected",
    "fullscreen",
    "global_search",
    "groupby_filter",
    "visible_columns",
    "pagination",
    "loading",
    "totalizador",
    "subtotalizador",
  ],

  setup() {
    return {
      paginacao: ref({
        rowsPerPage: 0,
      }),
      filter_data: ref({}),
      uuid: ref(""),
      column_options: ref({}),
      column_options_selected: ref({}),
      filter_flags: ref({}),
      selection_prop: ref(""),
      name: ref(""),
      selected_prop: ref([]),
      filter: ref(""),
      gorupby_option: ref([]),
      group_pagination: {
        rowsPerPage: 0,
      },
      grouped_column: [
        {
          name: "Grouped",
          required: true,
          label: "Grouped Column Values",
          align: "left",
          field: "name",
          sortable: true,
        },
      ],
      sub_grouped_data: ref({}),
      sub_grouped_columns: ref([]),
      selected_group_by_filed: ref({ label: "Group By Field", value: "" }),
      final_column: ref([]),
    };
  },
  mounted() {
    this.Sorting();
  },
  computed: {
    getFilteredData() {
      let self = this;
      let table_columns = self.final_column.map(function (item) {
        return item.field;
      });
      let table_Data = self.data.filter(function (item) {
        let i = "";
        for (i = 0; i < table_columns.length; i++) {
          if (self.filter_data[table_columns[i]] == "") continue;
          if (
            table_columns[i] in self.filter_data &&
            item[table_columns[i]] == null
          ) {
            return false;
          }

          if (
            !self.final_column[i].hasOwnProperty("filter_type") ||
            self.final_column[i].filter_type == "text"
          ) {
            if (
              table_columns[i] in self.filter_data &&
              item[table_columns[i]]
                .toString()
                .toLowerCase()
                .indexOf(self.filter_data[table_columns[i]].toLowerCase()) == -1
            ) {
              return false;
            }
          }
          if (
            self.final_column[i].hasOwnProperty("filter_type") &&
            self.final_column[i].filter_type == "date"
          ) {
            let compareDate = moment(
              item[table_columns[i]],
              self.final_column[i].format
            );
            let startDate = moment(
              self.filter_data[table_columns[i]].from,
              "DD/MM/YYYY"
            );
            let endDate = moment(
              self.filter_data[table_columns[i]].to,
              "DD/MM/YYYY"
            );
            if (
              table_columns[i] in self.filter_data &&
              self.filter_data[table_columns[i]].to &&
              self.filter_data[table_columns[i]].from &&
              !(startDate <= compareDate && compareDate <= endDate)
            ) {
              return false;
            }
          }
        }
        return true;
      });
      return table_Data;
    },
    getFilteredValuesData() {
      let self = this;
      this.columnOptionsSelectected(this.column_options_selected);

      let table_Data = this.getFilteredData.filter(function (item) {
        let i = "";
        for (i = 0; i < self.columns.length; i++) {
          if (self.column_options_selected[self.columns[i].field].length == 0)
            continue;
          if (
            self.column_options_selected[self.columns[i].field].indexOf(
              item[self.columns[i].field].toString().toLowerCase()
            ) == -1
          ) {
            return false;
          }
        }
        return true;
      });
      if (this.groupby_filter && this.selected_group_by_filed.value != "") {
        let grouped_data = this.groupBy(
          table_Data,
          this.selected_group_by_filed.value
        );
        table_Data = [];
        Object.keys(grouped_data).filter(function (item) {
          table_Data.push({ name: item });
          return item;
        });
        this.subGroupedData(grouped_data);
      }
      return table_Data;
    },
    hasDefaultSlot() {
      return this.$slots.hasOwnProperty("body");
    },
    hasHeaderSlot() {
      return this.$slots.hasOwnProperty("header");
    },
  },
  created() {
    this.uuid = uid();
    if (this.selection === undefined) {
      this.selection_prop = "none";
    } else {
      this.selection_prop = this.selection;
    }
    if (this.file_name === undefined) {
      this.name = "Download";
    } else {
      this.name = this.file_name;
    }
    if (this.selected === undefined) {
      this.selected_prop = [];
    } else {
      this.selected_prop = this.selected;
    }
    this.gorupby_option = [{ label: "Group By Field", value: "" }];
    this.setColumnsDefinition();
  },
  methods: {
    columnOptionsSelectected(options) {
      return (this.column_options_selected = Object.assign({}, options));
    },
    subGroupedData(grouped_data) {
      return (this.sub_grouped_data = grouped_data);
    },

    getFormatValue(format, value) {
      let convertedValue;
      switch (format) {
        case "decimal":
          convertedValue = this.$filters.formatDecimal(value);
          break;
        case "date":
          convertedValue = this.$filters.formatDate(value);
          break;
        case "currency":
          convertedValue = this.$filters.formatCurrency(value);
          break;
        case "integer":
          convertedValue = this.$filters.formatWithoutDecimal(value);
          break;
        case "dateHour":
          convertedValue = this.$filters.formatDateAndHour(value);
          break;
        case "cnpj":
          convertedValue = this.$filters.cnpj(value);
          break;
        default:
          convertedValue = value;
      }

      return convertedValue;
    },
    setColumnsDefinition() {
      let self = this;
      self.column_options = {};
      self.columns.filter(function (item) {
        self.column_options[item.field] = [];
        self.column_options_selected[item.field] = [];
        self.filter_flags[item.field] = false;
        if (item.hasOwnProperty("grouping") && item.grouping) {
          self.gorupby_option.push({ label: item.label, value: item.field });
        }
        return item;
      });
      self.data.filter(function (item) {
        self.columns.filter(function (column) {
          if (item[column.field] != null) {
            self.column_options[column.field].push({
              label: item[column.field].toString(),
              value: item[column.field]
                .toString()
                .toLowerCase()
                .replace(/_/g, "_"),
            });
          }
        });
      });
      self.columns.filter(function (column) {
        if (
          column.hasOwnProperty("filter_type") &&
          column.filter_type == "date"
        ) {
          self.filter_data[column.field] = { from: "", to: "" };
        }
        self.column_options[column.field] = [
          ...new Map(
            self.column_options[column.field].map((item) => [
              item["value"],
              item,
            ])
          ).values(),
        ];
      });
      this.final_column =
        this.selected_group_by_filed.value != ""
          ? this.grouped_column
          : this.columns;
    },
    getColumnOptions(column) {
      let column_option_simple = [
        ...new Set(this.data.map((item) => item[column])),
      ];
      let column_option = [];

      column_option_simple.filter(function (col) {
        column_option.push({
          label: col.toString(),
          value: col.toString().toLowerCase().replace(/_/g, "_"),
        });
        return col;
      });
      return column_option;
    },
    exportTable(type) {
      // naive encoding to csv format
      const content = [this.columns.map((col) => wrapCsvValue(col.label))]
        .concat(
          this.data.map((row) =>
            this.columns
              .map((col) =>
                wrapCsvValue(
                  typeof col.field === "function"
                    ? col.field(row)
                    : row[col.field === void 0 ? col.name : col.field],
                  col.format
                )
              )
              .join(",")
          )
        )
        .join("\r\n");
      const status = exportFile(
        this.file_name + "." + type,
        content,
        "text/" + type
      );
      if (status !== true) {
        this.$q.notify({
          message: "Browser denied file download...",
          color: "negative",
          icon: "warning",
        });
      }
    },
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    Sorting() {
      let dom = document.getElementById(this.uuid);
      const element = dom.querySelector("table tbody");
      const element2 = dom.querySelector("table thead tr:nth-of-type(1)");
      let self = this;
      const sortable = Sortable.create(element, {
        // filter:'.ignore-elements',
        // preventOnFilter: true,
        disabled: !this.draggable,
        onEnd(event) {
          // if (event.newIndex != 0) {
          let tmp = self.data[event.oldIndex];
          self.data[event.oldIndex] = self.data[event.newIndex];
          self.data[event.newIndex] = tmp;
          self.$emit("dragged_row", {
            dragged_row: self.data[event.oldIndex],
            old_index: event.oldIndex,
            new_index: event.newIndex,
          });
          // }
        },
        onMove: function (/**Event*/ evt, /**Event*/ originalEvent) {
          if (evt.related.className == "ignore-elements q-tr") {
            return false;
          }
        },
      });
      const sortable2 = Sortable.create(element2, {
        disabled: !this.draggable_columns,
        onEnd(event) {
          let old_index, new_index;
          if (self.selection) {
            old_index = event.oldIndex - 1;
            new_index = event.newIndex - 1;
          } else {
            old_index = event.oldIndex;
            new_index = event.newIndex;
          }
          let tmp = self.final_column[old_index];
          self.final_column[old_index] = self.final_column[new_index];
          self.final_column[new_index] = tmp;
          self.$emit("dragged_column", {
            dragged_column: self.final_column[old_index],
            old_index: old_index,
            new_index: new_index,
          });
        },
        onMove: function (evt, originalEvent) {
          if (
            evt.related.className == "q-table--col-auto-width ignore-elements"
          ) {
            return false;
          }
        },
      });
    },
  },
  watch: {
    selected_group_by_filed: function () {
      this.final_column =
        this.groupby_filter && this.selected_group_by_filed.value != ""
          ? this.grouped_column
          : this.columns;
    },
    selected_prop: function () {
      this.$emit("selected-val", this.selected_prop);
    },
    columns: function () {
      this.setColumnsDefinition();
    },
  },
  emits: ["selected-val", "dragged_column"],
});
</script>

<style scoped>
.tableHeader {
  color: white !important;
  background-color: #5d92f4 !important;
  border-color: #5d92f4 !important;
}
</style>

<style lang="sass" scoped>
.my-sticky-header-column-table
  height: 310px
  td:first-child
    background-color: #FFFFFF !important
  tr th
    position: sticky
    z-index: 2
  thead tr:last-child th
    z-index: 3
  thead tr:first-child th
    top: 0
    z-index: 1
  tr:first-child th:first-child
    z-index: 3
  td:first-child
    z-index: 1
  td:first-child, th:first-child
    position: sticky
    left: 0
</style>
