<template>
  <q-table
    :loading="loader"
    :rows="rows"
    :columns="columns"
    dense
    hide-pagination
    table-header-class="table-header"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
  >
    <template v-slot:body-cell-descricao="props">
      <q-td
        key="descricao"
        :props="props"
        class="r7-cursor-pointer"
        @click.stop
      >
        {{ props.row.descricao }}

        <q-icon class="material-icons q-pl-sm" color="blue" size="xs">
          edit
          <tooltip-custom :label="$t('message.edit')"></tooltip-custom>
        </q-icon>
        <q-popup-edit
          v-model="props.row.descricao"
          :title="$t('message.updateDescription')"
          buttons
          v-slot="scope"
          :label-set="$t('message.confirm')"
          :label-cancel="$t('message.cancel')"
          @update:model-value="onUpdate({ ...props.row })"
        >
          <q-input v-model="scope.value" dense autofocus />
        </q-popup-edit>
      </q-td>
    </template>

    <template v-slot:body-cell-actionsDelete="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="red darken-2"
            class="material-icons"
            @click="onDelete(props.row.id)"
          >
            delete
            <tooltip-custom :label="$t('message.delete')"></tooltip-custom>
          </q-icon>
        </q-btn>
      </q-td>
    </template>
  </q-table>
  <delete-confirmation-dialog
    ref="deleteConfirmationDialog"
    @onConfirm="deleteItem"
  ></delete-confirmation-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "ListaAreas",
  data() {
    return {
      loader: true,
      selectedItemToDelete: null,
      rows: [],
      columns: [
        {
          label: "ID",
          field: "id",
          name: "id",
          align: "right",
          sortable: true,
        },
        {
          label: "Descrição",
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: "Caminho",
          field: "caminho",
          name: "caminho",
          align: "left",
          sortable: true,
        },

        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("repositorio", ["getAtualizarRegistros"]),
  },
  watch: {
    getAtualizarRegistros() {
      this.listarAreas();
    },
  },
  methods: {
    listarAreas() {
      this.loader = true;
      api
        .get("/v1/repositorios/areas")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onUpdate(linha) {
      api
        .put(`/v1/repositorios/area/${linha.id}`, {
          descricao: linha.descricao,
        })
        .then(() => showSuccess(this.$t("message.successUpdatedRecord")))
        .catch((err) => showError(err));
    },
    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectedItemToDelete = id;
    },
    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      await api
        .delete(`v1/repositorios/area/${this.selectedItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarAreas());
    },
  },
  mounted() {
    this.listarAreas();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 800px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
