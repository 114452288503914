<template>
  <div></div>
</template>
<script>
import { Loading, QSpinnerGears } from "quasar";
export default {
  props: {
    status: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "lg",
    },
  },
  watch: {
    status(v) {
      this.loader(v);
    },
  },
  methods: {
    loader(v) {
      if (v) {
        Loading.show({
          message: this.$t("message.pleaseWait"),
          spinner: QSpinnerGears,
        });
      } else {
        Loading.hide();
      }
    },
  },
  mounted() {
    this.loader(this.status);
  },
};
</script>
