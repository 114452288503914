<template>
  <app-section-loader :status="loader" />

  <q-card class="q-pa-md">
    <q-form ref="form" autofocus @submit="onSave">
      <div class="row no-wrap justify-evenly">
        <div class="col-4" cols="12" sm="12" md="4" lg="4">
          <q-input
            class="q-mt-sm"
            ref="conta"
            rounded
            outlined
            dense
            autogrow
            v-model="contaData.conta"
            :label="$t('message.account')"
            required
            :rules="contaRules"
            :readonly="contaRO"
          />

          <q-input
            class="q-mt-sm"
            ref="loja"
            rounded
            outlined
            dense
            autogrow
            v-model="contaData.loja"
            :label="$t('message.store')"
            :rules="lojaRules"
          />

          <div class="col">
            <q-toggle
              v-model="contaData.situacao"
              color="blue"
              :label="$t('message.status')"
              checked-icon="check"
              unchecked-icon="clear"
            ></q-toggle>
          </div>

          <br />
          <div class="text-center">
            <q-btn
              color="success"
              class="q-mr-sm q-mt-md"
              type="submit"
              size="md"
              rounded
            >
              {{ $t("message.save") }}
            </q-btn>

            <q-btn
              color="danger"
              class="q-mt-sm q-mt-md"
              size="md"
              type="sair"
              @click.prevent="$router.go(-1)"
              rounded
            >
              {{ $t("message.close") }}
            </q-btn>
          </div>
        </div>
      </div>
    </q-form>
  </q-card>
  <delete-confirmation-dialog
    ref="deleteProtocolosDialog"
    heading="Excluir Protocolos?"
    message="Foram encontrados protocolos com essa conta, deseja excluir esses protocolos?"
    @onConfirm="confirmDelete"
  ></delete-confirmation-dialog>
</template>

<script>
import api from "Api";
import { clearObject } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "OmniChannelDetalheComponent",
  data() {
    return {
      loader: true,
      valid: true,
      contaRO: true,
      contaData: {
        conta: null,
        loja: null,
        situacao: true,
      },
      conta: this.$route.params.conta,
      contaRules: [(v) => !!v || "Conta Obrigatória"],
      lojaRules: [(v) => !!v || "Loja Obrigatória"],
    };
  },
  computed: {
    icon() {
      return this.conta ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.conta = to.params.conta;
      this.obterConta();
    },
  },
  methods: {
    onSave() {
      let message;
      let method;
      let url;

      if (this.conta) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/valisere/omnichannel/${this.conta}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/valisere/omnichannel/";
      }

      this.loader = true;
      const item = this.contaData;

      api[method](url, item)
        .then((res) => {
          this.loader = false;
          if (res.data?.count != "0") {
            this.$refs.deleteProtocolosDialog.open();
          } else {
            if (!this.conta) {
              clearObject(this.contaData, this.$refs.form);
            }
            showSuccess(this.$t(message));
          }
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.$refs.conta.focus();
        });
    },
    confirmDelete() {
      this.loader = true;
      this.$refs.deleteProtocolosDialog.close();

      api
        .delete(
          `/v1/valisere/omnichannel/protocols/account/${this.contaData.conta}`
        )
        .then(() => {
          showSuccess("Protocolo(s) deletado(s) com sucesso");
          if (!this.conta) {
            clearObject(this.contaData, this.$refs.form);
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    obterConta() {
      if (!this.conta) {
        this.conta = "";
        this.loader = false;
        this.contaRO = false;
        return;
      }
      api
        .get(`/v1/valisere/omnichannel/${this.conta}`)
        .then((res) => {
          if (res.data) {
            this.contaData = res.data;
            this.contaData.situacao = res.data.situacao == 1;
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.obterConta();
    this.$refs.conta.focus();
  },
};
</script>
