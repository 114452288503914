<template>
  <page-title-bar />
  <div class="q-px-md">
    <OffPremiumListaComponent />
  </div>
</template>
<script>
import OffPremiumListaComponent from 'Components/Areas/Valisere/OffPremiumListaComponent';
export default {
  name: "OffPremiumListaView",
  components: {OffPremiumListaComponent},
  data() {
    return {
    
    }
  },
}
</script>
