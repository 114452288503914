<template>
  <div class="q-pa-md">
    <app-section-loader :status="loader"></app-section-loader>
    <q-table
      ref="myTable"
      :loading="loader"
      :rows="rows"
      :columns="columns"
      :filter="filter"
      dense
      hide-pagination
      table-header-class="table-header"
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      :style="style"
      virtual-scroll
    >
      <template v-slot:top>
        <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
          {{ $t("message.add") }}
          <q-tooltip>Adiciona registro</q-tooltip>
        </q-btn>
        <q-space />
        <q-input
          class="q-ml-sm"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:body-cell-situacao="props">
        <q-td auto-width>
          <q-badge size="xs" :color="props.row.situacao == 0 ? 'red' : 'green'">
            {{ props.row.situacao == 0 ? "Inativo" : "Ativo" }}
          </q-badge>
        </q-td>
      </template>

      <template v-slot:body-cell-actionsEdit="props">
        <q-td auto-width>
          <q-btn flat dense round small>
            <q-icon
              size="xs"
              color="blue darken-2"
              class="material-icons noPrint"
              @click="onEdit(props.row)"
            >
              edit
            </q-icon>
            <q-tooltip>Edita registro</q-tooltip>
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-actionsChangeStatus="props">
        <q-td auto-width>
          <q-btn flat dense round small>
            <q-icon
              size="xs"
              color="red darken-2"
              class="material-icons noPrint"
              @click="onChangeStatus(props.row)"
            >
              published_with_changes
            </q-icon>
            <q-tooltip>Altera situação do registro</q-tooltip>
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <form-limites-aprovacao-compras-component
      ref="modalFormAprov"
      @save="save($event)"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import { printTableMixin } from "Mixin/printTableMinx";
import { sizeTotal } from "Helpers";
import FormLimitesAprovacaoComprasComponent from "./FormLimitesAprovacaoComprasComponent.vue";

export default {
  name: "LimitesAprovacaoComprasComponent",
  mixins: [printTableMixin],
  components: { FormLimitesAprovacaoComprasComponent },
  data() {
    return {
      loader: true,
      rows: [],
      filter: null,
      columns: [
        {
          label: this.$t("message.group"),
          field: "cdGrupo",
          name: "cdGrupo",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "dsGrupo",
          name: "dsGrupo",
          align: "left",
          sortable: true,
        },
        {
          label: "Centro de Custo",
          field: (d) => (d.cdCentro ? `${d.cdCentro} - ${d.dsCentro}` : null),
          name: "cdCentro",
          align: "right",
        },
        {
          label: "Dt. Cadastro",
          field: "dtCadastro",
          name: "dtCadastro",
          align: "center",
        },
        {
          label: "Cadastrado Por",
          field: "usuCadastro",
          name: "usuCadastro",
          align: "right",
        },
        {
          label: "Dt. Alteração",
          field: "dtAlteracao",
          name: "dtAlteracao",
          align: "center",
        },
        {
          label: "Alterado Por",
          field: "usuAlteracao",
          name: "usuAlteracao",
          align: "right",
        },
        {
          label: "Valor Faixa 1",
          field: "vlFaixa1",
          name: "vlFaixa1",
          align: "left",
          format: (v) => this.$filters.formatCurrency(v),
        },
        {
          label: "Valor Faixa 2",
          field: "vlFaixa2",
          name: "vlFaixa2",
          align: "left",
          format: (v) => this.$filters.formatCurrency(v),
        },
        {
          label: "Valor Faixa 3",
          field: "vlFaixa3",
          name: "vlFaixa3",
          align: "left",
          format: (v) => this.$filters.formatCurrency(v),
        },
        {
          label: "Situação",
          field: "situacao",
          name: "situacao",
          align: "center",
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
        },
        {
          label: "",
          field: "actionsChangeStatus",
          name: "actionsChangeStatus",
          align: "center",
        },
      ],
    };
  },
  computed: {
    style() {
      const height = (this.loader ? sizeTotal() : sizeTotal()) + "px";
      return {
        height,
      };
    },
  },
  methods: {
    retrieveList() {
      this.loader = true;
      api
        .get("/v1/compras/limites/aprovacao")
        .then((response) => {
          this.loader = false;
          this.rows = response.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onAdd() {
      this.$refs.modalFormAprov.abrir();
    },
    onChangeStatus(row) {
      let url = `/v1/compras/limites/aprovacao/${row.id}`;
      api
        .patch(url, { situacao: row.situacao == 1 ? 0 : 1 })
        .then((_) => {
          showSuccess(this.$t("message.successUpdatedRecord"));
          this.retrieveList();
        })
        .catch((err) => showError(err));
    },
    onEdit(row) {
      this.$refs.modalFormAprov.abrir(row);
    },
    save(item) {
      let url = "/v1/compras/limites/aprovacao";
      let method;
      if (item.id) {
        url += `/${item.id}`;
        method = "put";
      } else {
        method = "post";
      }

      api[method](url, item)
        .then((_) => {
          if (item.id) {
            showSuccess(this.$t("message.successUpdatedRecord"));
            this.$refs.modalFormAprov.fechar();
          } else {
            showSuccess(this.$t("message.successAddedRecord"));
            this.$refs.modalFormAprov.clearModal();
          }
          this.retrieveList();
        })
        .catch((err) => showError(err));
    },
  },
  created() {
    this.retrieveList();
  },
};
</script>
