<template>
  <div>
    <q-card
      v-for="(endereco, ind) in enderecos"
      :key="ind"
      class="mx-auto mb-30"
    >
      <q-card-section class="bg-primary text-white fw-bold">
        {{ headers[ind] }}
      </q-card-section>

      <q-card-section class="mt-2">
        <div class="row">
          <div class="col" cols="12" sm="12" md="2">
            <span class="fw-bold text-primary">
              {{ $t("message.address") }}:
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="10">
            <span class="fs-14"> {{ endereco.endereco }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col" cols="12" sm="12" md="2">
            <span class="fw-bold text-primary">
              {{ $t("message.district") }}:
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fs-14">{{ endereco.bairro }}</span>
          </div>
          <div class="col" cols="12" sm="12" md="2">
            <span class="fw-bold text-primary">
              {{ $t("message.city") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fs-14">{{ endereco.cidade }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col" cols="12" sm="12" md="2">
            <span class="fw-bold text-primary">
              {{ $t("message.zipCode") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fs-14">{{ endereco.cep }}</span>
          </div>
          <div class="col" cols="12" sm="12" md="2">
            <span class="fw-bold text-primary"> UF : </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fs-14">{{ endereco.uf }}</span>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ClienteEndereco",
  props: ["idCliente", "showHeader"],
  data() {
    return {
      headers: [
        this.$t("message.mainAddress"),
        this.$t("message.billingAddress"),
        this.$t("message.deliveryAddress"),
      ],
      enderecos: null,
    };
  },
  methods: {
    listarEnderecosCliente() {
      api
        .post("/v1/clientes/listarenderecoscliente", {
          id: this.idCliente,
        })
        .then((res) => {
          if (res.data) {
            this.enderecos = res.data;
          }
        })
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.listarEnderecosCliente();
  },
};
</script>
