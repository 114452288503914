import { createApp } from "vue";
import { loadFonts } from "./plugins/webfontloader";
import { createI18n } from "vue-i18n";
import { userKey } from "./global";
import Quasar from 'quasar/src/vue-plugin.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;
import money from "v-money";

import router from "./router";
import store from "./store";
import registerComponent from "./globalComponents";
import "./lib";
import messages from "./lang";
import quasarUserOptions from "./plugins/quasar";
import filters from "./globalFilters";

import App from "./App";
import Notifications from "vue-toastification";
import Nprogress from "nprogress";
import VueLoading from "vue-loading-overlay";

import VueTour from "v3-tour";

const app = createApp(App);

if (
  window.location.host.indexOf("localhost") !== -1 ||
  window.location.host.indexOf("gruporosset") !== -1
) {
  router.beforeEach(async (to, from, next) => {
    Nprogress.start();
    if (!to.matched.length) next("/pagenotfound");
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem(userKey) === null) {
        next({
          path: "/session/login",
          query: { redirect: to.fullPath },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
}
// navigation guard after each
router.afterEach(() => {
  Nprogress.done();
  setTimeout(() => {
    const contentWrapper = document.querySelector(".v-content__wrap");
    if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0;
    }
  }, 200);
});

const i18n = createI18n({
  locale: store.getters.selectedLocale.locale, // set locale
  messages, // set locale messages
});

loadFonts();

app.config.globalProperties.$filters = filters;

registerComponent(app);

app
  .use(
    Quasar,
    { plugins: { AppFullscreen, Dialog, Notify, Loading } },
    quasarUserOptions
  )
  .use(money, { precision: 4 })
  .use(router)
  .use(store)
  .use(i18n)
  .use(Notifications)
  .use(VueLoading)
  .use(VueTour)
  .mount("#app");
