<template>
  <div>
    <page-title-bar></page-title-bar>

    <app-section-loader :status="loader"></app-section-loader>
    <div class="q-pa-md">
      <PowerBiContainer
        v-if="!loader"
        :reportId="reportId"
        :groupId="groupId"
        :key="componentKey"
        className="fullHeight"
      ></PowerBiContainer>
    </div>
  </div>
</template>

<script>
import PowerBiContainer from "Components/Areas/Admin/PowerBi/PowerBiContainer";
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "PosicaoDoEstoque",
  components: { PowerBiContainer },

  data() {
    return {
      loader: true,
      componentKey: 0,
      reportName: "consultaDeEstoqueGeral",
      reportId: null,
      groupId: null,
    };
  },
  methods: {
    filtrarCliente() {
      api
        .get(`/v1/powerbi/nomeRelatorio/${this.reportName}`)
        .then((res) => {
          this.reportId = res.data.reportid;
          this.groupId = res.data.groupid;
          this.componentKey += 1;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    dadoSelecionado(event) {
      // const ano = event.detail.dataPoints[0].identity[0].equals;
    },
  },
  mounted() {
    this.filtrarCliente();
  },
};
</script>
