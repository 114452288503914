<template>
  <q-dialog
    v-model="dialog"
    persistent
    :maximized="maximizedToggle"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="bg-primary text-white">
      <q-bar>
        <q-space />

        <q-btn
          dense
          flat
          icon="minimize"
          @click="maximizedToggle = false"
          :disable="!maximizedToggle"
        >
          <q-tooltip v-if="maximizedToggle" class="bg-white text-primary"
            >Minimize</q-tooltip
          >
        </q-btn>
        <q-btn
          dense
          flat
          icon="crop_square"
          @click="maximizedToggle = true"
          :disable="maximizedToggle"
        >
          <q-tooltip v-if="!maximizedToggle" class="bg-white text-primary"
            >Maximize</q-tooltip
          >
        </q-btn>
        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip class="bg-white text-primary">Close</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section>
        <div class="text-h6">Documento: {{ title }}</div>
      </q-card-section>

      <q-card-section
        class="q-pt-none scroll"
        style="max-height: 85vh"
        align="center"
      >
        <VuePdfEmbed :source="documentPdf" width="1024" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "LgpdVisualizarDocumento",

  components: { VuePdfEmbed },

  data() {
    return {
      maximizedToggle: true,
      dialog: false,
      title: null,
      documentPdf: null,
    };
  },
  methods: {
    openDocument(document) {
      this.dialog = true;
      this.title = document;
      api
        .get(`/v1/lgpd/${document}`)
        .then((res) => {
          this.documentPdf = `data:application/pdf;base64,${res.data}`;
        })
        .catch((err) => showError(err));
    },
  },
};
</script>
