<template>
  <div>
    <page-title-bar></page-title-bar>
    <LimitesAprovacaoComprasAutomaticaComponent></LimitesAprovacaoComprasAutomaticaComponent>
  </div>
</template>

<script>
import LimitesAprovacaoComprasAutomaticaComponent from "Components/Areas/Compras/Limites/LimitesAprovacaoComprasAutomaticaComponent";
export default {
  name: "LimitesAprovacaoAutomaticaView",
  components: { LimitesAprovacaoComprasAutomaticaComponent },
};
</script>
