<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <div class="row justify-between">
      <div class="col-auto">
        <MenuPrincipalMetas />
      </div>
      <div class="col-auto">
        <HeaderMeta />
      </div>
    </div>
  </div>
  <div class="q-pa-md">
    <TabelaMetas />
  </div>
</template>

<script>
import MenuPrincipalMetas from "Components/Areas/Comercial/Metas/MenuPrincipalMetas";
import HeaderMeta from "Components/Areas/Comercial/Metas/HeaderMeta";
import TabelaMetas from "Components/Areas/Comercial/Metas/TabelaMetas";
export default {
  name: "CadastroDeMetas",
  components: { MenuPrincipalMetas, HeaderMeta, TabelaMetas },
};
</script>
