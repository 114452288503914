<template>
  <Indicadores @onFilter="filterData($event)" class="noPrint" />

  <div class="q-pa-xs">
    <Tabela
      ref="myTable"
      :labelCabec="labelCabec"
      :columns="columns"
      :rows="tbFaturamentoSegmentos"
      :visibleColumns="visibleColumns"
      :loading="loading"
      :lojasProntas="lojasProntas"
      :style="{ height: alturaTabela + 'px' }"
    />
  </div>

  <div class="q-pa-md text-center row noPrint">
    <div class="col col-6">
      <q-btn color="primary" icon="arrow_left" @click="trocarAno(-1)"
        >Ano Anterior</q-btn
      >
    </div>
    <div class="col col-6">
      <q-btn color="primary" icon-right="arrow_right" @click="trocarAno(1)"
        >Ano Posterior</q-btn
      >
    </div>
  </div>
</template>
<script>
import Indicadores from "./Indicadores.vue";
import Tabela from "./Tabela.vue";
import Api from "Api";
import { sizeTotal } from "Helpers";
import { printTableMixin } from "Mixin/printTableMinx";

export default {
  name: "FaturamentoComponent",
  components: { Indicadores, Tabela },
  mixins: [printTableMixin],
  data() {
    return {
      loading: true,
      ano: new Date().getFullYear(),
      labelCabec: [],
      lojasProntas: false,

      indicadores: ["Valor", "Qtde"],
      bgColors: ["bgGreen", "bgGreenLight"],
      filtros: { stValor: true, stQtde: false },

      tbFaturamentoSegmentos: [],
      tbFaturamentoLojas: [],
      columns: [],
      visibleColumnsOri: [],
      visibleColumns: [],
      alturaTabela: 0,

      columnsPrincipal: [
        {
          label: "",
          name: "lojas",
          field: "lojas",
        },
        {
          label: "",
          name: "dsSegmento",
          field: "dsSegmento",
          sortable: false,
          align: "left",
          bgColor: "bgYellow",
        },
      ],
    };
  },
  methods: {
    filterData(filtros) {
      let visibleColumns = [...this.visibleColumnsOri];

      const removeColumns = (filtro) => {
        return visibleColumns.filter((f) => f.indexOf(filtro) == -1);
      };

      this.filtros = filtros || this.filtros;

      if (!this.filtros.stValor) {
        visibleColumns = removeColumns("Valor");
      }
      if (!this.filtros.stQtde) {
        visibleColumns = removeColumns("Qtde");
      }

      this.visibleColumns = visibleColumns;
    },

    initialize() {
      this.columns = [];
      this.visibleColumns = [];
      this.visibleColumnsOri = [];
      this.tbFaturamentoSegmentos = [];
      this.tbFaturamentoLojas = [];
      this.labelCabec = [this.ano - 1, this.ano];
      this.carregarFaturamentoSegmentos();
      this.alturaTabela = sizeTotal() - 115;
    },

    async gerarColunas() {
      this.columns = [...this.columnsPrincipal];
      this.visibleColumns.push("dsSegmento");

      for (let index1 = 1; index1 < 3; index1++) {
        for (let index = 0; index < 2; index++) {
          const indicador = this.indicadores[index];

          const labelFrq = `Fat. Franquias`;
          const labelFilo = `Fat. Filó`;
          const labelDif = `Dif.`;

          const fieldFrq = `ano${index1}Frq${indicador}`;
          const nameFrq = `FatFrq${indicador}ano${index1}`;
          const fieldFilo = `ano${index1}Filo${indicador}`;
          const nameFilo = `FatFilo${indicador}ano${index1}`;
          const fieldDif = `ano${index1}Dif${indicador}`;
          const nameDif = `FatDif${indicador}ano${index1}`;

          const bgColor = this.bgColors[index1 % 2];

          const columnFrq = {
            name: nameFrq,
            label: labelFrq,
            field: fieldFrq,
            align: "right",
            sortable: false,
            format: (v) => this.$filters.formatWithoutDecimal(v),
            bgColor,
          };

          const columnFilo = {
            name: nameFilo,
            label: labelFilo,
            field: fieldFilo,
            align: "right",
            sortable: false,
            format: (v) => this.$filters.formatWithoutDecimal(v),
            bgColor,
          };

          const columnDif = {
            name: nameDif,
            label: labelDif,
            field: fieldDif,
            align: "right",
            sortable: false,
            format: (v) => this.$filters.formatWithoutDecimal(v),
            bgColor,
          };

          this.visibleColumns.push(nameFrq);
          this.visibleColumns.push(nameFilo);
          this.visibleColumns.push(nameDif);

          this.columns.push(columnFrq);
          this.columns.push(columnFilo);
          this.columns.push(columnDif);
        }
      }
      this.visibleColumnsOri = [...this.visibleColumns];
      this.filterData();
    },

    carregarFaturamentoSegmentos() {
      this.loading = true;
      this.lojasProntas = false;
      this.gerarColunas();
      Api.get(`/v1/faturamento/franquias/segmentos/anual/${this.ano}`)
        .then((res) => {
          this.tbFaturamentoSegmentos = res.data;
          this.carregarFaturamentoLojas();
        })
        .finally(() => (this.loading = false));
    },

    carregarFaturamentoLojas() {
      Api.get(`/v1/faturamento/franquias/lojas/anual/${this.ano}`).then(
        (res) => {
          const lojas = res.data;
          const newtb = this.tbFaturamentoSegmentos.map((el) => {
            el.lojas = lojas.filter((f) => f.cdSegmento == el.cdSegmento);
            return el;
          });
          this.tbFaturamentoSegmentos = newtb;
          this.lojasProntas = true;
        }
      );
    },

    trocarAno(val) {
      this.ano += val;
      this.initialize();
    },
  },

  mounted() {
    this.initialize();
  },
};
</script>
