<template>
  <ModalForm :title="$t('message.sharedLicenses')" ref="LicencasModalForm">
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="aoSalvar">
        <div class="row q-mt-md">
          <div class="col">
            <q-input
              filled
              v-model="usuarioData.donoLicenca"
              :label="$t('message.licenseOwner')"
              disable
              class="q-mx-sm"
            />

            <UsuariosLicencasSGT
              class="q-mx-sm q-mt-xl"
              :required="true"
              :usuarioId="usuarioData.compartilhado"
              :empresa="usuarioData.empresa"
              :rounded="false"
              :filled="true"
              :dense="false"
              @selected="selectedUsuariosLicencas($event)"
            />
          </div>
        </div>
        <div class="row q-mb-xl">
          <div class="col"></div>
        </div>

        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-ml-sm q-mb-md"
        />

        <q-btn
          :label="$t('message.close')"
          @click.prevent="fecharModal()"
          class="bg-red text-white q-ml-md q-mb-md"
        />

        <q-separator class="q-mx-sm" />

        <q-table
          :loading="loader"
          :rows="rows"
          :columns="columns"
          table-header-class="table-header"
          rows-per-page-label="Itens por página"
          :noDataLabel="$t('message.noDataFound')"
          dense
          class="q-mx-sm q-my-md"
        >
          <template v-slot:body-cell-conectado="props">
            <q-td auto-width>
              <q-chip :color="getColor(props.row.conectado)" dark>
                {{
                  props.row.conectado == 1
                    ? $t("message.online")
                    : $t("message.offline")
                }}
              </q-chip>
            </q-td>
          </template>
          <template v-slot:body-cell-actionsDelete="props">
            <q-td auto-width>
              <q-btn flat dense round small>
                <q-icon
                  size="xs"
                  color="red darken-2"
                  class="material-icons"
                  @click="deletarCompartilhado(props.row.id)"
                >
                  delete
                </q-icon>
              </q-btn>
            </q-td>
          </template>
        </q-table>
        <delete-confirmation-dialog
          ref="deleteConfirmationDialog"
          @onConfirm="deleteItem"
        ></delete-confirmation-dialog>
      </q-form>
    </template>
  </ModalForm>
</template>

<script>
import api from "Api";
import ModalForm from "Components/Widgets/ModalForm";
import UsuariosLicencasSGT from "Components/Areas/TI/UsuariosLicencasSGT";
import { showError, showSuccess } from "@/notifications/notify";
import { clearObject } from "Helpers";

export default {
  name: "LicencasCompartilhadasModal",
  components: { ModalForm, UsuariosLicencasSGT },
  props: {
    usuario: Object,
  },

  data() {
    return {
      loader: true,

      rows: [],
      columns: [
        {
          label: this.$t("message.user"),
          field: "compartilhado",
          name: "compartilhado",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.status"),
          field: "conectado",
          name: "conectado",
          align: "center",
          sortable: true,
          headerStyle: "width: 100px",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "left",
          sortable: true,
        },
      ],

      usuarioData: {
        donoLicenca: null,
        codredusuario: null,
        empresa: null,
        compartilhado: null,
      },
      selectItemToDelete: null,
    };
  },

  methods: {
    abrirModal(usuario) {
      this.usuarioData.donoLicenca = usuario.nomeUsuario;
      this.usuarioData.codredusuario = usuario.codredusuario;
      this.usuarioData.empresa = usuario.empresa;
      this.$refs.LicencasModalForm.abrir();
      this.listarLicencasCompartilhadas(usuario);
    },
    fecharModal() {
      this.$refs.LicencasModalForm.fechar();
      this.$emit("atualizarLista");
    },

    listarLicencasCompartilhadas(usuario) {
      api
        .get(
          `/v1/licencas/share?$filter="empresa = ${usuario.empresa} and CODREDUSUARIOOWNER = ${usuario.codredusuario}"`
        )
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    selectedUsuariosLicencas(item) {
      this.usuarioData.compartilhado = item.id;
    },

    aoSalvar() {
      api
        .post("/v1/licencas/share", {
          empresa: this.usuarioData.empresa,
          usuarioPrincipal: this.usuarioData.codredusuario,
          compartilhado: this.usuarioData.compartilhado,
        })
        .then(() => {
          this.listarLicencasCompartilhadas(this.usuarioData);
          this.usuarioData.compartilhado = null;
          showSuccess(this.$t("message.successAddedRecord"));
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    deletarCompartilhado(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectItemToDelete = id;
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();

      await api
        .delete(`v1/licencas/share/${this.selectItemToDelete}`)
        .then(() => {
          this.listarLicencasCompartilhadas(this.usuarioData);
          showSuccess(this.$t("message.successDeletedRecord"));
        })
        .catch((err) => showError(err));
    },

    getColor(v) {
      return v == 1 ? "green-8" : "negative";
    },
  },
};
</script>
