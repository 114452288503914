<template>
  <ModalForm
    ref="modalFormCadastroDocumentos"
    :title="tituloModal"
    :customClass="estilo"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="aoEnviar()">
        <div class="row q-mb-xl q-mt-md items-center">
          <div class="col-6 text-subtitle1 text-weight-medium">
            <p>{{ $t("message.documentDescription") }}</p>
          </div>
          <div class="col-4">
            <q-input
              type="text"
              filled
              required
              clearable
              v-model="formularioData.descricao"
              :label="$t('message.description')"
              :rules="descricaoRule"
            />
          </div>
        </div>

        <div class="row q-mb-xl q-mt-md items-center" v-if="!editandoDocumento">
          <div class="col-6 text-subtitle1 text-weight-medium">
            <p>{{ $t("message.area") }}</p>
          </div>
          <div class="col-4 q-px-xs">
            <AreaSelecao
              :areaId="formularioData.area"
              @selected="selectedArea($event)"
            />
          </div>
        </div>

        <div class="row q-mb-xl q-mt-md items-center" v-if="!editandoDocumento">
          <div class="col-6 text-subtitle1 text-weight-medium">
            <p>{{ $t("message.typeOfAccess") }}</p>
          </div>
          <div class="col-4 q-px-xs">
            <q-btn
              icon="add"
              @click.stop="abrirAcessoDocumentos"
              :label="$t('message.addAccess')"
              class="bg-primary text-white q-py-sm"
              style="width: 100%"
            />
            <TipoDeAcessoDocumentos ref="modalTipoDeAcessoDocumentos" />
          </div>
        </div>

        <div class="row q-mt-xl q-mb-md items-center">
          <div class="col-6 text-subtitle1 text-weight-medium">
            <p>{{ $t("message.document") }}</p>
          </div>

          <div class="col-4 q-px-xs">
            <q-input
              type="file"
              id="input"
              filled
              clearable
              @update:model-value="(val) => (file = val[0])"
            ></q-input>
          </div>
        </div>
        <q-separator />
        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-mt-md"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="aoFechar()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
</template>

<script>
import AreaSelecao from "Components/Areas/Repositorio/AreaSelecao";
import TipoDeAcessoDocumentos from "Components/Areas/Repositorio/TipoDeAcessoDocumentos";
import ModalForm from "Components/Widgets/ModalForm";
import { mapActions, mapGetters } from "vuex";
import { clearObject } from "Helpers";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "FormularioCadastroDocumentos",
  components: { AreaSelecao, TipoDeAcessoDocumentos, ModalForm },
  emits: ["atualizarTabela"],

  data() {
    return {
      estilo: {
        width: "700px",
        height: "600px",
      },
      tituloModal: this.$t("message.addDocument"),
      editandoDocumento: false,
      documentoId: null,
      descricaoDocumento: null,
      formularioData: {
        descricao: null,
        area: null,
        nomeDocumento: null,
        base64: null,
        tipoDocumento: null,
      },
      permissao: {
        usuarios: [],
        niveis: [],
      },
      file: null,
      descricaoRule: [(v) => !!v || this.$t("message.descriptionRequired")],
    };
  },

  computed: {
    ...mapGetters("repositorio", ["getAcessoUsuarios", "getAcessoNivelAcesso"]),
  },
  watch: {
    getAcessoUsuarios: {
      handler(v) {
        this.permissao.usuarios = v.map((v) => v.id);
      },
      deep: true,
    },

    getAcessoNivelAcesso: {
      handler(v) {
        this.permissao.niveis = v.map((v) => v.id);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("repositorio", ["setNullState"]),

    abrirAcessoDocumentos() {
      this.$refs.modalTipoDeAcessoDocumentos.abrir();
    },

    aoAbrir(idDocumento) {
      this.setNullState();
      if (idDocumento) {
        this.documentoId = idDocumento;
        this.editandoDocumento = true;
        this.tituloModal = this.$t("message.editDocument");
        this.obterDocumento(idDocumento);
      }
      this.$refs.modalFormCadastroDocumentos.abrir();
    },

    aoFechar() {
      this.$refs.modalFormCadastroDocumentos.fechar();
      clearObject(this.formularioData, this.$refs.form);
      this.editandoDocumento = false;
    },

    obterDocumento(idDocumento) {
      this.loader = true;
      api
        .get(`v1/repositorios/documento/${idDocumento}`)
        .then((res) => (this.formularioData.descricao = res.data.descricao))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    async aoEnviar() {
      if (this.file) {
        await this.converterArquivo(this.file);
      }

      let method, url;

      const data = {
        idAreaDocumentos: this.formularioData.area,
        descricao: this.formularioData.descricao,
        nomeArquivo: this.formularioData.nomeDocumento,
        arquivo: this.formularioData.base64,
        tipoDeDocumento: this.formularioData.tipoDocumento,
      };

      if (this.editandoDocumento) {
        method = "patch";
        url = `v1/repositorios/documento/${this.documentoId}`;
      } else {
        method = "post";
        url = "v1/repositorios/documentos";
      }

      const mensagemEFinalizacao = () => {
        if (this.editandoDocumento) {
          showSuccess(this.$t("message.successUpdatedRecord"));
        } else {
          showSuccess(this.$t("message.successAddedRecord"));
        }
        this.$emit("atualizarTabela");
        this.aoFechar();
      };

      api[method](url, data)
        .then((res) => {
          if (this.editandoDocumento) {
            mensagemEFinalizacao();
          } else {
            this.$refs.modalTipoDeAcessoDocumentos
              .salvar(res.data.id)
              .then(() => {
                mensagemEFinalizacao();
              });
          }
        })
        .catch((err) => showError(err));
    },

    converterArquivo(file) {
      this.formularioData.nomeDocumento = this.file.name;
      this.formularioData.tipoDocumento = this.file.type;

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          this.formularioData.base64 = encoded;
        };
        reader.onerror = (err) => reject(showError(err));
      });
    },
    selectedArea(item) {
      this.formularioData.area = item.id;
    },
  },
};
</script>
