<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>
    <FloatingButton
      :configuracoes="configuracoesBotao"
      :visivel="botaoVisivel"
      @abrirFecharNavegacao="abrirFecharNavegacao($event)"
    />

    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        id="embedContainer"
        style="width: 100%"
        :style="{ height }"
        :class="className"
      ></div>
    </transition>
  </div>
</template>

<script>
import * as pbi from "powerbi-client";
import api from "Api";
import FloatingButton from "Components/Widgets/FloatingButton";
import { showError } from "@/notifications/notify";
import { mapGetters } from "vuex";

export default {
  name: "PowerBiContainer",
  components: { FloatingButton },
  props: [
    "reportId",
    "dashboardId",
    "groupId",
    "token",
    "filtro",
    "filter",
    "height",
    "className",
  ],
  data() {
    return {
      loader: true,
      navegacao: true,
      reportsData: null,
      botaoVisivel: false,
      sideBar: this.$store.getters["admin/getSideBarToggle"],
      fullScreen: this.$store.getters["admin/getFullScreenToggle"],
      configuracoesBotao: {
        offset: null,
        color: null,
        flat: null,
        icon: null,
        estilo: null,
        mensagem: null,
      },
      endPointUrl: "https://app.powerbi.com",
      embed: this.reportId ? "reportEmbed" : "dashboardEmbed",

      config: {
        accessToken: "",
        id: "",
        type: this.reportId ? "report" : "dashboard",
        tokenType: pbi.models.TokenType.Embed,
        viewMode: pbi.models.ViewMode.View,
        permissions: pbi.models.Permissions.Read,
        embedUrl: "",
      },
    };
  },
  computed: {
    ...mapGetters("admin", ["getSideBarToggle", "getFullScreenToggle"]),
  },
  watch: {
    configurandoBotao() {
      this.configuracoesBotao;
    },
    getSideBarToggle(v) {
      this.sideBar = v;
      this.configurandoBotao();
    },
    getFullScreenToggle(v) {
      this.fullScreen = v;
      this.configurandoBotao();
    },
  },
  methods: {
    configurandoBotao() {
      this.configuracoesBotao.color = null;
      this.configuracoesBotao.estilo = null;
      this.configuracoesBotao.flat = true;
      this.configuracoesBotao.mensagem = this.$t("message.openNavBar");
      this.configuracoesBotao.icon = "menu";

      if (
        (this.sideBar || this.fullScreen) &&
        !this.navegacao &&
        this.botaoVisivel
      ) {
        this.configuracoesBotao.offset = [10, 70];
        this.configuracoesBotao.flat = false;
        this.configuracoesBotao.color = "primary";
        this.configuracoesBotao.estilo = "opacity: 0.6";
      } else if (!this.sideBar && !this.navegacao && this.botaoVisivel) {
        this.configuracoesBotao.offset = [10, 10];
      } else {
        if (this.navegacao && this.botaoVisivel) {
          this.configuracoesBotao.offset = [130, 12];
          this.configuracoesBotao.icon = "close";
          this.configuracoesBotao.mensagem = this.$t("message.closeNavBar");
        }
      }
    },

    async abrirFecharNavegacao() {
      this.navegacao = !this.navegacao;
      const newSettings = {
        panes: {
          pageNavigation: {
            visible: this.navegacao,
          },
        },
      };
      await this.reportsData.updateSettings(newSettings);
      this.configurandoBotao();
    },
    async getToken() {
      const OPT = {
        groupId: this.groupId,
        reportId: this.reportId,
        dashboardId: this.dashboardId,
      };

      if (this.token) {
        this.config.accessToken = this.token;
      } else {
        await api
          .post("/v1/powerbi/obterToken", OPT)
          .then((res) => {
            if (res.status == "200") {
              this.config.accessToken = res.data;
            }
          })
          .catch((err) => showError(err));
      }
      if (this.config.accessToken) {
        setTimeout(() => {
          this.invokeReport();
        }, 100);
      }
    },

    async invokeReport() {
      let loadedResolve,
        reportLoaded = new Promise((res) => {
          loadedResolve = res;
        });
      let renderedResolve,
        reportRendered = new Promise((res) => {
          renderedResolve = res;
        });

      const typeEmbed = this.reportId
        ? `reportId=${this.reportId}`
        : `dashboardId=${this.dashboardId}`;
      this.config.embedUrl = `${this.endPointUrl}/${this.embed}?${typeEmbed}&groupId=${this.groupId}&filter=${this.filtro}`;
      this.config.id = this.reportId ? this.reportId : this.dashboardId;

      var configPbi = this.config;

      function embedPowerBIReport() {
        let config = {
          type: configPbi.type,
          tokenType: configPbi.tokenType,
          accessToken: configPbi.accessToken,
          embedUrl: configPbi.embedUrl,
          id: configPbi.id,
          permissions: configPbi.permissions,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
              pageNavigation: {
                visible: false,
                position: 1,
              },
            },
          },
        };

        let embedContainer = $("#embedContainer")[0];
        let report = powerbi.embed(embedContainer, config);

        report.off("loaded");

        report.on("loaded", function () {
          loadedResolve();
          report.off("loaded");
        });

        report.off("error");

        report.on("error", function (event) {
          console.log(event.detail);
        });

        report.off("rendered");

        report.on("rendered", function () {
          renderedResolve();
          report.off("rendered");
        });
        // report.on("dataSelected", (event) => {
        //   this.$emit("dataSelected", event);
        // });
        // report.on("buttonClicked", (event) => {
        //   console.log(event);
        //   this.$emit("buttonClicked", event);
        // });
        // report.on("commandTriggered", (event) => {
        //   console.log("commandTriggered", event);
        // });
        // report.on("selectionChanged", (event) => {
        //   console.log("selectionChanged", event);
        // });
        // report.on("visualClicked", (event) => {
        //   console.log("visualClicked", event);
        // });
        return report;
      }

      this.reportsData = embedPowerBIReport();
      await reportLoaded;

      // Insert here the code you want to run after the report is loaded

      const pages = await this.reportsData.getPages();
      if (pages.length > 1) {
        const newSettings = {
          panes: {
            pageNavigation: {
              visible: true,
            },
          },
        };
        this.botaoVisivel = true;
        this.configurandoBotao();
        await this.reportsData.updateSettings(newSettings);
      }

      await reportRendered;
      this.loader = false;

      // Insert here the code you want to run after the report is rendered
    },
  },
  created() {
    if (this.groupId && (this.reportId || this.dashboardId)) {
      this.getToken();
    }
  },
};
</script>

<style>
#embedContainer {
  height: 87vh !important;
}
iframe {
  border: 0;
}
/* #q-page-container-full {
  padding-bottom: 0px !important;
} */
</style>
