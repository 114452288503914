<template>
  <app-section-loader :status="loader"></app-section-loader>
  <ModalCard
    ref="modalListaReferencias"
    title="Lista de Referências"
    :style="style"
  >
    <template v-slot:top>
      <q-card-actions horizontal class="justify-around">
        <div class="row">
          <div class="col-10">
            <q-input
              rounded
              outlined
              dense
              v-model="referencia"
              label="Referência"
              @keypress.enter="pesquisar"
              ref="inputReferencias"
            >
              <template v-slot:prepend>
                <q-icon name="mdi-filter" />
              </template>
            </q-input>
          </div>
        </div>
      </q-card-actions>
    </template>

    <template v-slot:body>
      <q-table
        :rows="rows"
        :columns="columns"
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        hide-pagination
        @row-click="selecionarLinha"
        class="q-mx-sm q-my-sm my-sticky-header-table"
        :style="styleTable"
        dense
        virtual-scroll
      >
      </q-table>
    </template>

    <template v-slot:footer>
      <q-btn
        label="Pesquisar"
        @click="pesquisar"
        class="q-ml-md bg-primary text-white"
      />
    </template>
  </ModalCard>
</template>

<script>
import api from "Api";
import ModalCard from "Components/Widgets/ModalCard";
import { showError } from "@/notifications/notify";
import { isMobile } from "Helpers";

export default {
  name: "ModalListaReferencias",
  components: { ModalCard },
  emits: ["selecionado"],
  data() {
    return {
      style: {
        width: "100vw",
        maxWidth: isMobile() ? "100vw" : "45vw",
      },

      pesquisarPorReduzido: false,
      referencia: null,
      loader: false,
      filtro: null,

      rows: [],
      columns: [
        {
          label: "Tipo",
          field: "dsTipo",
          name: "dsTipo",
          align: "left",
          sortable: true,
        },
        {
          label: "Referência",
          field: "referencia",
          name: "referencia",
          align: "left",
          sortable: true,
        },
        {
          label: "Largura",
          field: "largura",
          name: "largura",
          align: "right",
          sortable: true,
        },
        {
          label: "Gramatura",
          field: "gramatura",
          name: "gramatura",
          align: "right",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    styleTable() {
      const rowsLength = this.rows.length;
      let vH = 8;
      if (rowsLength > 0) {
        vH = rowsLength > 10 ? 29 : rowsLength * 3 + 5;
      }
      return { height: `${vH}vh` };
    },
  },
  methods: {
    pesquisar() {
      this.loader = true;
      this.rows = [];

      const referencia = this.referencia || 0;

      const filtrarResultado = (tipo) => {
        let resultado = false;

        if (Array.isArray(this.filtro.tipo)) {
          resultado = this.filtro.tipo.find((f) => f == tipo);
        } else {
          resultado = this.filtro.tipo == tipo;
        }
        return resultado;
      };

      api
        .get(`/v1/produtos/specs/referencias/${referencia}`)
        .then((res) => {
          if (res.data) {
            this.rows = res.data.filter((f) => filtrarResultado(f.tipo));
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    abrirModal(filtro) {
      this.filtro = filtro;
      this.referencia = null;
      this.rows = [];
      this.$refs.modalListaReferencias.abrir();
      setTimeout(() => {
        this.$refs.inputReferencias.focus();
      }, 500);
    },

    selecionarLinha(evt, item) {
      this.$emit("selecionado", item);
      this.$refs.modalListaReferencias.fechar();
    },
  },
};
</script>
