<template>
  <page-title-bar></page-title-bar>

  <div class="q-pa-md">
    <CandidatosQAListComponent />
  </div>
</template>

<script>
import CandidatosQAListComponent from "Components/Areas/RH/QA/CandidatosQAListComponent";

export default {
  name: "CandidatosQAListView",
  components: { CandidatosQAListComponent },
  data() {
    return {};
  },
};
</script>
