<template>
  <div style="height: 100 vh; width: 80%">Teste</div>
</template>
<script>
import AppConfig from "Constants/AppConfig";
import api from "Api";
export default {
  components: {},
  data() {
    return {
      imagem: AppConfig.login, // <--- A imagem fica dentro desse cara
    };
  },
  mounted() {
    const data = {
      descricao: "Teste de Documento Fixo",
      idAreaDocumentos: 10,
    };

    api
      .post("v1/repositorios/documentos", data)
      .then((res) => {})
      .catch((err) => console.log(err.response));
  },
};
</script>
