<template>
  <page-title-bar></page-title-bar>

  <div class="row q-pa-md">
    <div class="col-6 q-pr-lg">
      <PerguntasQAListComponent />
    </div>
    <q-separator inset vertical />
    <div class="col q-pl-lg">
      <RespostasQAListComponent />
    </div>
  </div>
</template>

<script>
import PerguntasQAListComponent from "Components/Areas/RH/QA/PerguntasQAListComponent";
import RespostasQAListComponent from "Components/Areas/RH/QA/RespostasQAListComponent";

export default {
  name: "GabaritoQAListView",
  components: { PerguntasQAListComponent, RespostasQAListComponent },
  data() {
    return {};
  },
};
</script>
