<template>
  <div>
    <page-title-bar></page-title-bar>

    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <PowerBiContainer
        v-if="!loader"
        :dashboardId="dashboardId"
        :reportId="reportId"
        :groupId="groupId"
        className="fullHeight"
      ></PowerBiContainer>
    </div>
  </div>
</template>

<script>
import PowerBiContainer from "Components/Areas/Admin/PowerBi/PowerBiContainer";
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "AnaliseChamados",
  components: { PowerBiContainer },

  data() {
    return {
      loader: true,
      reportName: "visaoGeralDosChamados",
      reportId: null,
      groupId: null,
      dashboardId: null,
    };
  },
  methods: {
    buscarRelatorio() {
      api
        .get(`/v1/powerbi/nomeRelatorio/${this.reportName}`)
        .then((res) => {
          this.reportId = res.data.reportid;
          this.groupId = res.data.groupid;
          //this.groupId = "6c2dc25f-ff63-45b9-a2eb-232a0c4a3f15";
          //this.reportId = "ad701542-dc4d-4f64-8272-8488f2b59b79";
          //this.dashboardId = "efbc1b51-86f8-4a00-a9c3-0393b043d367";
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.buscarRelatorio();
  },
};
</script>
