<template>
  <q-table
    :loading="loader"
    :rows="rows"
    :columns="columns"
    dense
    hide-pagination
    table-header-class="table-header"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
  >
    <template v-slot:top-left>
      <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
        {{ $t("message.add") }}
      </q-btn>
    </template>

    <template v-slot:top-right>
      <SetorQASelectionComponent
        @selected="filtrarPorSetor($event)"
        :required="false"
        :clearable="true"
      />
    </template>

    <template v-slot:body="props">
      <q-tr>
        <q-td
          auto-width
          v-for="col in props.cols"
          :key="col.name"
          :props="props"
        >
          <template v-if="col.field == 'actions'">
            <q-btn
              flat
              dense
              round
              small
              :disable="col.disable(props.row.dtTermino)"
              @click="col.click(props.row.idCandidato)"
            >
              <q-icon size="xs" :color="col.color" class="material-icons">
                {{ col.icon }}
                <tooltip-custom :label="col.tooltip"></tooltip-custom>
              </q-icon>
            </q-btn>
          </template>
          <template v-else>
            {{ col.value }}
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
  <delete-confirmation-dialog
    ref="deleteConfirmationDialog"
    @onConfirm="deleteItem"
  ></delete-confirmation-dialog>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import SetorQASelectionComponent from "Components/Areas/RH/QA/Selecao/SetorQASelectionComponent";

export default {
  name: "CandidatosQAListComponent",
  components: { SetorQASelectionComponent },
  data() {
    return {
      loader: true,
      selectedItemToDelete: null,
      rows: [],
      columns: [
        {
          label: "ID",
          field: "idCandidato",
          name: "idCandidato",
          align: "right",
          sortable: true,
        },
        {
          label: "Nome",
          field: "nome",
          name: "nome",
          align: "left",
          sortable: true,
        },
        {
          label: "Setor",
          field: "descricaoSetor",
          name: "descricaoSetor",
          align: "left",
          sortable: true,
        },
        {
          label: "Vaga",
          field: "vaga",
          name: "vaga",
          align: "left",
          sortable: true,
        },
        {
          label: "Duração do teste",
          field: "duracao",
          name: "duracao",
          align: "center",
          sortable: true,
        },

        {
          label: "",
          field: "actions",
          name: "actionsResultadoCandidato",
          align: "center",
          color: "teal darken-2",
          icon: "book",
          tooltip: this.$t("message.candidateResult"),
          click: (id) => this.onResultadoCandidato(id),
          disable: (dtTermino) => dtTermino == null,
        },
        {
          label: "",
          field: "actions",
          name: "actionsEdit",
          align: "center",
          color: "blue darken-2",
          icon: "edit",
          tooltip: this.$t("message.edit"),
          click: (id) => this.onUpdate(id),
          disable: () => false,
        },
        {
          label: "",
          field: "actions",
          name: "actionsDelete",
          align: "center",
          color: "red darken-2",
          icon: "delete",
          tooltip: this.$t("message.delete"),
          click: (id) => this.onDelete(id),
          disable: () => false,
        },
      ],
    };
  },
  methods: {
    listarCandidatos() {
      this.loader = true;
      api
        .get("/v1/rh/qa/candidatos")
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    filtrarPorSetor(selected) {
      if (!selected) {
        this.listarCandidatos();
        return;
      }
      this.loader = true;
      api
        .get(`/v1/rh/qa/candidatos/setor/${selected.id}`)
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    onAdd() {
      this.$router.push({
        name: "candidatosqadetailview",
        params: { id: 0 },
      });
    },

    onResultadoCandidato(id) {
      this.$router.push({
        name: "resultadocandidatoview",
        params: { id },
      });
    },

    onUpdate(id) {
      this.$router.push({
        name: "candidatosqadetailview",
        params: { id },
      });
    },

    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectedItemToDelete = id;
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      await api
        .delete(`v1/rh/qa/candidato/${this.selectedItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarCandidatos());
    },
  },
  mounted() {
    this.listarCandidatos();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 700px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
