<template>
  <q-card class="card-resumo text-white" :class="styleClass">
    <q-card-section>
      <div class="row">
        <div class="col-12 col-md-1">Tabela:</div>
        <div class="col-12 col-md-3">{{ data?.tabelapreco }}</div>
        <div class="col-12 col-md-1">Descrição:</div>
        <div class="col-12 col-md-7">{{ data?.nome }}</div>
      </div>
      <div class="row">
        <div class="col-12 col-md-1">Emissão:</div>
        <div class="col-12 col-md-3">{{ data?.emissao }}</div>
        <div class="col-12 col-md-1">Validade:</div>
        <div class="col-12 col-md-3">{{ data?.validade }}</div>
        <div class="col-12 col-md-2" v-if="typeTable == 'Atual'">
          Tabela Anterior:
        </div>
        <div class="col-12 col-md-2" v-if="typeTable == 'Atual'">
          {{ data?.tabelaAnterior }}
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ResumoTabelaSelecionada",
  props: {
    styleClass: {
      type: Object,
      default: () => null,
    },
    typeTable: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {};
  },
};
</script>
