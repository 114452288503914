<template>
  <SelectWithFilter
    v-if="!loader"
    :id="usuarioId"
    :list="listaUsuarios"
    :required="required"
    :disabled="disabled"
    :prependIcon="prependIcon"
    :label="$t('message.sharedLicenseUser')"
    @selected="itemSelected($event)"
  />
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "UsuariosLicencasSGT",
  components: { SelectWithFilter },

  props: {
    usuarioId: Number,
    empresa: Number,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loader: true,
      listaUsuarios: null,
    };
  },
  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get(`/v1/licencas/usuarios?$filter="empresa = ${this.empresa}"`)
        .then((res) => {
          if (res.data) {
            this.listaUsuarios = res.data
              .filter((f) => f.empresa == this.empresa)
              .map((element) => {
                return {
                  id: element.codredusuario,
                  description: element.nomeUsuario,
                };
              });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
