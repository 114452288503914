<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-form ref="form" v-model="valid" autofocus @submit="salvar">
        <div class="row">
          <div class="col">
            <q-input
              rounded
              outlined
              dense
              v-model="mensagemData.id"
              label="Id"
              disable
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
          <div class="col">
            <q-input
              ref="titulo"
              rounded
              outlined
              dense
              class="q-ml-sm"
              v-model="mensagemData.titulo"
              :label="$t('message.title')"
              :rules="regrasTitulo"
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <q-select
              rounded
              outlined
              dense
              class="q-mt-md"
              :options="categorias"
              option-label="descricao"
              option-value="id"
              v-model="mensagemData.categoriaId"
              required
              :label="$t('message.categoryId')"
              :prepend-icon="icon"
              map-options
              emit-value
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-select>
          </div>
          <div class="col">
            <q-input
              ref="rota"
              rounded
              outlined
              dense
              class="q-ml-sm q-mt-md"
              v-model="mensagemData.rota"
              :label="$t('message.route')"
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
        </div>

        <q-btn
          color="success"
          class="q-mr-sm q-mt-lg"
          type="submit"
          size="md"
          rounded
        >
          {{ $t("message.save") }}
        </q-btn>

        <q-btn
          color="danger"
          class="q-mt-lg"
          size="md"
          type="sair"
          @click.prevent="$router.go(-1)"
          rounded
        >
          {{ $t("message.close") }}
        </q-btn>
      </q-form>

      <hr />
    </div>
  </div>
</template>

<script>
import api from "Api";
import { clearObject } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "MensagemDetalhe",
  data() {
    return {
      loader: true,
      valid: true,
      id: this.$route.params.id,
      mensagemData: {
        titulo: null,
        categoriaId: null,
        categoriaDesc: null,
        rota: null,
      },
      categorias: [],
      regrasTitulo: [
        (v) => !!v || this.$t("message.titleRequired"),
        (v) =>
          (v && v.length <= 100) ||
          `${this.$t("message.description")}
           ${this.$t("message.mustbelessthan")} 100
           ${this.$t("message.characters")}`,
      ],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterMensagem();
    },
  },
  methods: {
    obterMensagem() {
      this.listarCategoriasMensagens();

      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/mensagens/obtermensagemheader/${this.id}`)
        .then((res) => {
          this.mensagemData = res.data;
          this.loader = false;
        })
        .catch((err) => showError(err));
    },

    listarCategoriasMensagens() {
      api
        .get("/v1/mensagens/listarcategoriasmensagens")
        .then((res) => {
          this.categorias = res.data;
        })
        .catch((err) => showError(err));
    },

    salvar() {
      let message;
      let method;
      let url;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = "alterarmensagemheader";
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "inserirmensagemheader";
      }

      this.loader = true;
      const item = this.mensagemData;

      api[method](`/v1/mensagens/${url}/${this.id}`, item)
        .then(() => {
          if (!this.id) {
            clearObject(this.mensagemData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.obterMensagem();

    this.$refs.titulo.focus();
  },
};
</script>
