<template>
  <page-title-bar class="noPrint" />
  <q-page padding>
    <div class="column q-pl-sm">
      <div class="text-h4">
        {{ titulo }}
      </div>
      <div
        class="text-h6 text-red-10 text-bold"
        v-if="listarDuplicatasAcumulado"
      >
        Valor Total Acumulado: {{ valorTotalAcumulado }}
      </div>
    </div>

    <div class="q-px-xs q-mb-sm" v-if="listarDuplicatasAcumulado">
      <ListarDuplicatasDetalhesComponent
        :empresa="empresa"
        :marca="marca"
        :cliente="cliente"
        subtitulo="A Vencer"
        :valorTotal="valorAVencer"
        dias="0"
      />
    </div>

    <div
      class="row items-start q-px-xs q-mb-sm"
      v-if="listarDuplicatasAcumulado"
    >
      <ListarDuplicatasDetalhesComponent
        :empresa="empresa"
        :marca="marca"
        :cliente="cliente"
        subtitulo="Vencidos 30 Dias"
        :valorTotal="valor30Dias"
        dias="30"
        class="col-3 q-pa-xs"
      />
      <ListarDuplicatasDetalhesComponent
        :empresa="empresa"
        :marca="marca"
        :cliente="cliente"
        subtitulo="Vencidos 60 Dias"
        :valorTotal="valor60Dias"
        dias="60"
        class="col-3 q-pa-xs"
      />
      <ListarDuplicatasDetalhesComponent
        :empresa="empresa"
        :marca="marca"
        :cliente="cliente"
        subtitulo="Vencidos 90 Dias"
        :valorTotal="valor90Dias"
        dias="90"
        class="col-3 q-pa-xs"
      />
      <ListarDuplicatasDetalhesComponent
        :empresa="empresa"
        :marca="marca"
        :cliente="cliente"
        subtitulo="Vencidos 120 Dias"
        :valorTotal="valor120Dias"
        dias="120"
        class="col-3 q-pa-xs"
      />
    </div>

    <div
      class="q-px-xs avoid-page-break"
      v-if="listarDuplicatasVencidas120Mais"
    >
      <ListarDuplicatasDetalhesComponent
        :empresa="empresa"
        :marca="marca"
        :cliente="cliente"
        subtitulo="Vencidos + 120 dias"
        :valorTotal="valor120Mais"
        dias="999"
      />
    </div>
  </q-page>
</template>
<script>
import ListarDuplicatasDetalhesComponent from "Components/Areas/Financeiro/ListarDuplicatasDetalhesComponent";
export default {
  name: "ListarDuplicatasDetalhesView",
  components: { ListarDuplicatasDetalhesComponent },
  data() {
    return {
      params: null,
      empresa: null,
      marca: null,
      cliente: null,
      subtitulo: null,
      listarDuplicatasAcumulado: false,
      listarDuplicatasVencidas120Mais: false,
      valorAVencer: "0",
      valor30Dias: "0",
      valor60Dias: "0",
      valor90Dias: "0",
      valor120Dias: "0",
      valor120Mais: "0",
      valorTotalAcumulado: "0",
    };
  },

  computed: {
    titulo() {
      return `${this.params?.cliente} devendo para: ${this.params?.dsempresa}`;
    },
  },

  methods: {
    initialize() {
      this.params = this.$route.query;
      this.empresa = this.params?.empresa;
      this.marca = this.params?.marca;
      this.cliente = this.params?.cliente;
      if (this.params?.component == "vencidos120diasmais") {
        this.valor120Mais = this.params?.vencido120Mais;
        this.listarDuplicatasVencidas120Mais = true;
      }
      if (this.params?.component == "acumulado") {
        this.valorAVencer = this.params?.aVencer;
        this.valor30Dias = this.params?.vencido30;
        this.valor60Dias = this.params?.vencido60;
        this.valor90Dias = this.params?.vencido90;
        this.valor120Dias = this.params?.vencido120;
        this.valor120Mais = this.params?.vencido120Mais;
        this.valorTotalAcumulado = this.params?.acumulado;
        this.listarDuplicatasVencidas120Mais = true;
        this.listarDuplicatasAcumulado = true;
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style scoped>
@media print {
  .text-h4 {
    font-size: 1.4rem !important;
  }
}
</style>
