<template>
  <q-dialog v-model="openDialog" max-width="600px" persistent>
    <q-card class="pa-4">
      <q-card-section class="text-subtitle1 px-0 pt-0">{{
        heading || $t("message.confirmAction")
      }}</q-card-section>
      <q-card-section class="px-0 pt-0" v-html="formattedMessage" />
      <q-card-actions class="pa-0">
        <q-separator />
        <slot name="buttons" v-if="$slots.buttons"></slot>
        <div v-else>
          <q-btn class="q-mx-sm q-mt-sm" color="danger" @click="close()">{{
            $t("message.cancel")
          }}</q-btn>
          <q-btn
            color="success"
            class="q-mr-sm q-mt-sm"
            @click="$emit('onConfirm')"
            >{{ $t("message.yes") }}</q-btn
          >
        </div>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: ["heading", "message", "onConfirm"],
  data() {
    return {
      openDialog: false,
    };
  },
  computed: {
    formattedMessage() {
      return (
        this.message || this.$t("message.areYouSureYouWantToConfirmThisActions")
      );
    },
  },
  methods: {
    open() {
      this.openDialog = true;
    },
    close() {
      this.openDialog = false;
    },
  },
};
</script>
