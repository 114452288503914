<template>
  <div class="q-pa-md">
    <q-toolbar>
      <q-toolbar-title>
        {{ cliente.nomeCliente }}
      </q-toolbar-title>
      <slot name="icone"></slot>
    </q-toolbar>

    <div class="text-left q-ml-md q-mt-md">
      <p class="text-gray-light">
        <span class="fw-bold text-gray">Id: </span>
        {{ cliente.codigoCliente }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray">Cnpj: </span>
        {{ cliente.cnpj }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.tradingName") }}: </span>
        {{ cliente.nomeFantasia }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.seller") }}: </span>
        {{ cliente.nomeRepresentante }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.manager") }}: </span>
        {{ cliente.nomeGerente }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray text-wrap"
          >{{ $t("message.address") }}:
        </span>
        {{ cliente.endereco }} - {{ cliente.bairro }} - {{ cidUf }}
      </p>

      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.lastOrder") }}: </span>
        {{ cliente.ultimaCompra }}
      </p>

      <p>
        <q-toggle
          :disable="grupoEconomicoDesativado"
          color="blue"
          :label="$t('message.economicGroup')"
          v-model="ativo"
          @click="
            setGrupoEconomico({
              ativo,
              grupoEconomico: cliente.grupoEconomico,
            })
          "
        ></q-toggle>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ResumoDadosCliente",
  props: ["idCliente"],
  data() {
    return {
      dialog: false,
      ativo: false,
      grupoEconomicoDesativado: true,
      settings: {
        maxScrollbarLength: 160,
      },
      cliente: {},
    };
  },
  computed: {
    cidUf() {
      return `${this.cliente.cidade} - ${this.cliente.cdUf}`;
    },
  },
  methods: {
    ...mapActions("clientes", ["setGrupoEconomico"]),
    ...mapGetters("clientes", ["getDadosDoCliente"]),
    receberCliente() {
      this.cliente = this.getDadosDoCliente();
      this.grupoEconomicoDesativado = this.cliente.grupo !== "G";
    },
  },
  mounted() {
    this.receberCliente();
  },
};
</script>
