<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>

    <SelectWithFilter
      v-if="!loader"
      :id="acabamentoFisicoId"
      :list="listaAcabamentoFisico"
      :required="required"
      :disabled="disabled"
      :prependIcon="prependIcon"
      :label="$t('message.physicalFinish')"
      @selected="itemSelected($event)"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "AcabamentoFisicoSelecao",
  components: { SelectWithFilter },

  props: {
    acabamentoFisicoId: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loader: true,
      listaAcabamentoFisico: null,
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("/v1/produtos/specs/acabamentosfisicos")
        .then((res) => {
          if (res.data) {
            this.listaAcabamentoFisico = res.data.map((element) => {
              return {
                id: element.cdAcabFisi,
                description: element.dsAcabFisi,
              };
            });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
