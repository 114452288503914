import PosicaoDoEstoque from "Views/Estoques/PosicaoDoEstoque";

const routes = [
  {
    path: "/posicaodoestoque",
    name: "posicaodoestoque",
    component: PosicaoDoEstoque,
    meta: {
      requiresAuth: true,
      title: "message.stockPosition",
      breadcrumb: ["message.stock", "message.stockPosition"],
    },
  },
];

export default routes;
