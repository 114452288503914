<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div class="row">
    <div class="col-sm-12 col-md-4 q-pr-sm text-center">
      <q-card>
        <q-card-section>
          <q-img
            :src="confeccaoData.imagem"
            spinner-color="white"
            style="max-width: 50%"
          />
        </q-card-section>
        <q-separator />
        <q-card-actions>
          <q-btn
            color="success"
            class="q-ma-sm"
            size="md"
            @click.prevent.stop="$refs.file.click()"
          >
            {{ $t("message.uploadImage") }}
          </q-btn>
          <input
            type="file"
            ref="file"
            style="display: none"
            @change="onChange($event)"
          />
        </q-card-actions>
      </q-card>
    </div>

    <q-card class="col-sm-12 col-md-8 form-card">
      <q-card-section>
        <q-form>
          <div class="row">
            <div class="col q-pr-sm">
              <q-input
                filled
                v-model="confeccaoData.id"
                label="Id:"
                dense
                disable
                class="q-pb-sm"
              />
            </div>
            <div class="col">
              <q-input
                filled
                v-model="confeccaoData.empresa"
                label="Marca:"
                dense
                class="q-pb-sm"
                ref="marca"
              />
            </div>
          </div>

          <div class="row">
            <div class="col q-pr-sm">
              <q-input
                filled
                v-model="confeccaoData.serie"
                label="Série:"
                dense
                class="q-pb-sm"
              />
            </div>
            <div class="col">
              <q-input
                filled
                v-model="confeccaoData.descricao"
                label="Descrição:"
                dense
                class="q-pb-sm"
              />
            </div>
            <div class="col">
              <q-toggle
                v-model="ehRenda"
                color="blue"
                label="Renda ?"
                checked-icon="check"
                unchecked-icon="clear"
              ></q-toggle>
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-separator />
      <q-card-section horizontal>
        <div>
          <span class="q-pl-sm text-weight-bold">Custo Total: </span>
          <span>R$ {{ confeccaoData.custoTotal }}</span>
        </div>
        <q-space />
        <div>
          <span class="text-weight-bold">Última Atualização: </span>
          <span class="q-pr-sm">{{ confeccaoData.ultimaAtualizacao }}</span>
        </div>
      </q-card-section>

      <q-card-actions align="around">
        <q-btn
          color="success"
          class="q-mr-sm q-mt-md"
          size="md"
          @click.prevent.stop="onSave"
        >
          {{ $t("message.save") }}
        </q-btn>

        <q-btn
          color="danger"
          class="q-mt-sm q-mt-md"
          size="md"
          @click.prevent.stop="$router.go(-1)"
        >
          {{ $t("message.close") }}
        </q-btn>
      </q-card-actions>
    </q-card>
    <ProdutosConfeccaoComposicaoLista
      v-if="id && id > 0"
      :idProduto="+id"
      class="col-12 q-mt-md"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import ProdutosConfeccaoComposicaoLista from "./ProdutosConfeccaoComposicaoLista";
import { clearObject } from "Helpers";
export default {
  name: "ProdutosConfeccaoDetailComponent",
  components: { ProdutosConfeccaoComposicaoLista },
  data() {
    return {
      loader: true,
      file: null,
      ehRenda: false,
      confeccaoData: {
        id: null,
        empresa: null,
        serie: null,
        descricao: null,
        custoTotal: null,
        ultimaAtualizacao: null,
        imagem: null,
        ehRenda: null,
      },

      id: this.$route.params.id || 0,

      descriptionRules: [(v) => !!v || this.$t("message.descriptionRequired")],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterProduto();
    },
  },
  methods: {
    async onChange(fileUpload) {
      const file = fileUpload.target.files[0];
      this.confeccaoData.imagem = await this.converterArquivo(file);
    },
    async onSave() {
      let message;
      let method;
      let url;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/produtos/confeccao/${this.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/produtos/confeccao/";
      }

      this.loader = true;
      const item = this.confeccaoData;
      item.ehRenda = this.ehRenda ? 1 : 0;

      api[method](url, item)
        .then(() => {
          if (!this.id) {
            clearObject(this.confeccaoData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.$refs.marca.focus();
        });
    },
    converterArquivo(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (err) => reject(showError(err));
      });
    },
    obterProduto() {
      this.loader = true;
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/produtos/confeccao/${this.id}`)
        .then((res) => {
          this.confeccaoData = res.data;
          this.ehRenda = this.confeccaoData.ehRenda === 1;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.obterProduto();
  },
};
</script>

<style scoped>
.form-card {
  max-width: 100%;
  width: 100vw;
}
</style>
