import en from './en';
import fr from './fr';
import sp from './sp';
import pt from './pt';

export default {
    en: {
        message: en,
    },
    fr: {
        message: fr,
    },
    sp: {
        message: sp,
    },
    pt: {
        message: pt,
    },
};
