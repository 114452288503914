<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>

    <SelectWithFilter
      v-if="!loader"
      :id="familiaEstoqueId"
      :list="listaFamiliaEstoque"
      :required="required"
      :disabled="disabled"
      :prependIcon="prependIcon"
      :label="$t('message.familyStock')"
      @selected="itemSelected($event)"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";
export default {
  name: "FamiliaEstoqueSelecao",
  components: { SelectWithFilter },
  props: {
    familiaEstoqueId: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loader: true,
      listaFamiliaEstoque: null,
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("/v1/produtos/specs/familiasestoques")
        .then((res) => {
          if (res.data) {
            this.listaFamiliaEstoque = res.data.map((element) => {
              return {
                id: element.familiaEstoque,
                description: element.descricao,
              };
            });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
