<template>
  <div class="q-pa-md">
    <app-section-loader :status="loader" />
    <q-toolbar>
      <q-toolbar-title>
        <slot name="nome"></slot>
      </q-toolbar-title>
      <slot name="icone"></slot>
    </q-toolbar>

    <div class="text-left q-ml-md q-mt-md">
      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.creditLimit") }}: </span>
        {{ cliente.limiteDeCredito }}
      </p>

      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.condition") }}: </span>
        {{ cliente.condicao }}
      </p>

      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.block") }}: </span>
        {{ cliente.bloqueio }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray"
          >{{ $t("message.totalValueOrders") }}:
        </span>
        {{ $filters.formatCurrency(cliente.totalpedidos) }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray"
          >{{ $t("message.totalValueOfBonds") }}:
        </span>
        {{ cliente.totaltitulos }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray"
          >{{ $t("message.overdueAmount") }}:
        </span>
        {{ cliente.atraso }}
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray">
          {{ $t("message.creditBalance") }}:
        </span>
        <span :class="getColor(cliente.credito)">
          {{ $filters.formatCurrency(cliente.credito) }}
        </span>
      </p>
      <p class="text-gray-light">
        <span class="fw-bold text-gray">{{ $t("message.message") }}: </span>
        {{ cliente.mensagemDoGrupo }}
      </p>
    </div>
  </div>
</template>

<script>
import api from "Api";

export default {
  name: "ClienteDadosFinanceiros",
  props: ["idCliente"],
  cliente: {},
  data() {
    return {
      dialog: false,
      ativo: false,
      loader: true,
      cliente: {},
      clienteCondicao: {},
    };
  },
  methods: {
    obterDadosFinanceiros() {
      api
        .get(`/v1/clientes/obterdadosfinanceiros/${this.idCliente}`)
        .then((res) => {
          this.loader = true;
          this.cliente = res.data;
          this.cliente.condicao = this.cliente.condicao.replace(
            "&nbsp;",
            "\xa0"
          );
          this.cliente.bloqueio = this.cliente.bloqueio.replace(
            "&nbsp;",
            "\xa0"
          );
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getColor(p) {
      return p < 0 ? "r7-text-color-danger" : "r7-text-color-success";
    },
  },

  created() {
    this.obterDadosFinanceiros();
  },
};
</script>
