<template>
  <app-section-loader :status="loader"></app-section-loader>
  <ModalForm
    ref="modalFormRegistroComposicao"
    :title="$t('message.addRecord')"
    :customClass="estilo"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="onSave()">
        <div class="row q-mb-md">
          <div class="col-12">
            <TiposComposicaoSelecao
              :tiposComposicaoId="registroData.idTipoComposicao"
              @selected="selectTiposComposicao($event)"
              :autofocus="true"
              :disabled="editData"
            />
          </div>
        </div>

        <div class="row q-mb-xs">
          <div class="col-12 col-md q-mr-xs">
            <q-input
              v-model.lazy="registroData.unidadeMedida"
              :filled="true"
              :required="true"
              :label="$t('message.measureUnity')"
            />
          </div>
          <div class="col-12 col-md q-mr-xs">
            <InputQuantity
              v-model.lazy="registroData.qtConsumo"
              :filled="true"
              :required="true"
              precision="3"
              :label="$t('message.amountUsed')"
            />
          </div>
          <div class="col-12 col-md">
            <InputQuantity
              v-model.lazy="registroData.custoUnitario"
              :filled="true"
              :required="true"
              precision="3"
              :label="$t('message.unitCost')"
            />
          </div>
        </div>

        <q-separator />
        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-mt-lg"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="onClose()"
          class="bg-red text-white q-ml-md q-mt-lg"
        />
      </q-form>
    </template>
  </ModalForm>
</template>

<script>
import TiposComposicaoSelecao from "./TiposComposicaoSelecao";
import InputQuantity from "Components/Widgets/InputQuantity";
import ModalForm from "Components/Widgets/ModalForm";
import api from "Api";
import { clearObject } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "FormProdutosComposicao",
  components: {
    TiposComposicaoSelecao,
    InputQuantity,
    ModalForm,
  },
  emits: ["refresh"],
  data() {
    return {
      estilo: {
        width: "30vw",
        height: "30vh",
      },
      loader: false,
      editData: false,
      idProduto: 0,
      registroData: {
        id: null,
        idTipoComposicao: null,
        unidadeMedida: null,
        qtConsumo: null,
        custoUnitario: null,
      },
    };
  },

  methods: {
    onOpen(idProduto, itemComposicao) {
      this.idProduto = idProduto;
      this.editData = false;

      if (itemComposicao) {
        this.editData = true;
        this.registroData = { ...itemComposicao };
      }
      this.$refs.modalFormRegistroComposicao.abrir();
    },

    onClose() {
      clearObject(this.registroData, this.$refs.form);
      this.$refs.modalFormRegistroComposicao.fechar();
    },

    selectTiposComposicao(item) {
      this.registroData.idTipoComposicao = item.id;
    },

    onSave() {
      let message;
      let method;
      let url;

      if (this.editData) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/produtos/confeccao/composicao/${this.registroData.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/produtos/confeccao/composicao/";
      }

      this.loader = true;
      const item = this.registroData;
      item.idProduto = this.idProduto;

      api[method](url, item)
        .then(() => {
          this.$emit("refresh");
          showSuccess(this.$t(message));
          if (this.editData) {
            this.onClose();
          } else {
            clearObject(this.registroData, this.$refs.form);
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
};
</script>
