<template>
  <q-table
    v-if="exibir"
    :title="titulo"
    title-class="text-h5"
    dense
    class="my-sticky-header-table"
    virtual-scroll
    table-header-class="table-header"
    :filter="filter"
    :loading="loader"
    :rows="rows"
    :columns="columns"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
  >
    <template v-slot:top-right>
      <q-input
        class="q-mx-md"
        borderless
        dense
        debounce="300"
        v-model="filter"
        :placeholder="$t('message.search')"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-btn color="green" @click.stop="novoRegistro()">
        {{ $t("message.addRecord") }}
        <tooltip-custom :label="$t('message.addRecord')" />
      </q-btn>
    </template>

    <template v-slot:body-cell-registroInvalido="props">
      <q-td>
        <q-icon
          color="red darken-2"
          class="material-icons"
          size="sm"
          v-if="props.row.registroInvalido"
        >
          error
          <tooltip-custom :label="$t('message.invalidRegisterPleaseEdit')" />
        </q-icon>
      </q-td>
    </template>

    <template v-slot:body-cell-linha="props">
      <q-td auto-width>
        <span>{{ props.rowIndex + 1 }}</span>
      </q-td>
    </template>

    <template v-slot:body-cell-actionsEdit="props">
      <q-td auto-width>
        <q-btn flat dense unelevated round small>
          <q-icon
            color="blue"
            class="mr-2 material-icons"
            @click="onUpdate(props)"
            size="xs"
          >
            edit
            <tooltip-custom :label="$t('message.edit')" />
          </q-icon>
        </q-btn>
      </q-td>
    </template>

    <template v-slot:body-cell-actionsDelete="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="red darken-2"
            class="material-icons"
            @click="deletarRegistro(props.rowIndex)"
          >
            delete
            <tooltip-custom :label="$t('message.delete')" />
          </q-icon>
        </q-btn>
      </q-td>
    </template>
  </q-table>
  <NovoRegistroMetas ref="modalFormNovoRegistro" />
</template>

<script>
import NovoRegistroMetas from "Components/Areas/Comercial/Metas/NovoRegistroMetas";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TabelaMetas",
  components: { NovoRegistroMetas },
  data() {
    return {
      loader: true,
      filter: null,
      titulo: null,
      exibir: false,

      rows: this.$store.state.metas.meta,

      columns: [
        {
          label: "",
          field: "registroInvalido",
          name: "registroInvalido",
          align: "center",
          sortable: true,
        },

        {
          label: this.$t("message.line"),
          field: "linha",
          name: "linha",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.group"),
          field: "grupoDescription",
          name: "grupoDescription",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.month"),
          field: "mesDescription",
          name: "mesDescription",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.seller"),
          field: "representanteDescription",
          name: "representanteDescription",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.amountR$"),
          field: "valor",
          name: "valor",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.kilosKG"),
          field: "quilos",
          name: "quilos",
          align: "right",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("metas", ["getAcao", "getMeta", "getExibir"]),
  },
  watch: {
    getMeta: {
      handler(v) {
        this.rows = v;
      },
      deep: true,
    },
    getAcao(v) {
      if (v == "deletar") return;
      this.obterTituloTabela(v);
    },
    getExibir(v) {
      this.exibir = v;
    },
  },
  methods: {
    ...mapActions("metas", ["setDeletarRegistro"]),

    obterTituloTabela(acao) {
      if (acao == "editar") {
        this.titulo = this.$t("message.editingGoal");
      } else if (acao == "revisar") {
        this.titulo = this.$t("message.revisingGoal");
      } else {
        this.titulo = this.$t("message.newGoal");
      }
    },

    novoRegistro() {
      this.$refs.modalFormNovoRegistro.aoAbrir();
    },

    deletarRegistro(indexLinha) {
      this.setDeletarRegistro(indexLinha);
    },

    onUpdate(props) {
      const data = props.row;
      data.linha = props.rowIndex + 1;
      this.$refs.modalFormNovoRegistro.aoAbrir(data);
    },
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 610px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
