<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md col q-gutter-md lt-md">
    <div class="col-xs-12">
      <MateriaPrima :key="keyMateriaPrimaMobile" />
    </div>
    <div class="col-xs-12">
      <ProdutosConfeccaoMobile
        v-for="(produto, idx) in produtosConfeccao"
        :key="idx"
        :produto="produto"
        @removerItem="removerItem($event)"
      />
    </div>
  </div>
  <div class="row q-pa-md q-gutter-md justify-between gt-sm">
    <div class="col-md-5">
      <MateriaPrima :key="keyMateriaPrimaDesktop" />
    </div>
    <q-separator inset vertical />
    <div class="col-md-6" :key="keyProdutosConfeccao">
      <ProdutosConfeccaoDesktop
        v-for="(produto, idx) in produtosConfeccao"
        :key="idx"
        class="q-mb-xs"
        :produto="produto"
        @removerItem="removerItem($event)"
      />
    </div>
  </div>
  <div class="row flex flex-center justify-around">
    <q-btn :size="size" color="red" class="q-my-md" @click.prevent.stop="limpar"
      >Limpar</q-btn
    >
    <q-btn
      :size="size"
      color="positive"
      class="q-my-md"
      @click.stop.prevent="adicionar"
      :disable="
        !this.calculadora?.referenciaTecido &&
        !this.calculadora?.referenciaRenda
      "
      >Adicionar</q-btn
    >
  </div>
  <ModalListaProdutosConfeccao
    ref="modalListaProdutosConfeccao"
    @selecionado="selecionadoProdutoConfeccao($event)"
    :filtro="filtro"
  />
</template>

<script>
import MateriaPrima from "Components/Areas/Comercial/Calculadora/MateriaPrima";
import ProdutosConfeccaoMobile from "Components/Areas/Comercial/Calculadora/ProdutosConfeccaoMobile";
import ProdutosConfeccaoDesktop from "Components/Areas/Comercial/Calculadora/ProdutosConfeccaoDesktop";
import ModalListaProdutosConfeccao from "Components/Areas/Produtos/Confeccao/ModalListaProdutosConfeccao";
import { isMobile } from "Helpers";
import { mapGetters } from "vuex";

export default {
  name: "CalculadoraCustos",
  components: {
    MateriaPrima,
    ProdutosConfeccaoMobile,
    ProdutosConfeccaoDesktop,
    ModalListaProdutosConfeccao,
  },
  data() {
    return {
      calculadora: null,
      keyMateriaPrimaDesktop: 0,
      keyMateriaPrimaMobile: 0,
      keyProdutosConfeccao: 0,
      produtosConfeccao: [],
    };
  },
  computed: {
    ...mapGetters("comercial", ["getCalculadora"]),
    size() {
      return isMobile() ? "sm" : "lg";
    },
    filtro() {
      let resultado;
      resultado = this.calculadora?.referenciaTecido ? "Tecido" : "";
      resultado += this.calculadora?.referenciaRenda ? "Renda" : "";
      return resultado;
    },
  },
  watch: {
    getCalculadora: {
      handler(v) {
        this.calculadora = v;
      },
      deep: true,
    },
  },

  methods: {
    limpar() {
      this.$store.dispatch("comercial/setCalculadora", null);
      this.produtosConfeccao = [];
      this.keyMateriaPrimaDesktop += 1;
      this.keyMateriaPrimaMobile += 1;
    },
    adicionar() {
      this.$refs.modalListaProdutosConfeccao.abrirModal();
    },
    selecionadoProdutoConfeccao(item) {
      const found = this.produtosConfeccao.find(
        (element) => element.id == item.id
      );

      if (!found) {
        this.produtosConfeccao.push(item);
      }
    },
    removerItem(id) {
      const novaLista = this.produtosConfeccao.filter((f) => f.id !== id);
      this.produtosConfeccao = novaLista;
    },
  },
};
</script>
