<template>
  <div>
    <q-card v-if="dados">
      <app-section-loader :status="loader" />
      <q-card style="height: auto; width: auto" class="q-pa-md">
        <div class="row justify-center items-center q-mb-sm">
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary text-center"> Id: </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="text-center">{{ dados.idpessoafj }}</span>
            <q-btn flat round size="small">
              <q-icon
                :title="$t(`message.search`)"
                class="material-icons"
                @click="abrirPesquisa"
                >search</q-icon
              >
            </q-btn>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary text-center">
              {{ $t("message.recordType") }}:
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="text-center">
              {{ dados.tipocadastro === "F" ? "Físico" : "Jurídico" }}</span
            >
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary text-center"> CNPJ: </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="text-center">{{ dados.cnpj }}</span>
          </div>
        </div>

        <q-separator />

        <div class="row justify-center items-center q-my-md">
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary">
              {{ $t("message.name") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.nome }}</span>
          </div>
        </div>

        <div class="row justify-center items-center q-mb-md">
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary"> Inscrição Estadual: </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.inscricaoestadual }}</span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary"> Inscrição Municipal: </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.inscricaomunicipal }}</span>
          </div>
        </div>

        <div class="row justify-center items-center q-mb-md">
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary">
              {{ $t("message.seller") }} :
            </span>
          </div>

          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.idrepresentante }} - {{ dados.representante }}</span>
          </div>

          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary">
              {{ $t("message.customerType") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span
              >{{ dados.idcanalvenda }} -
              {{ dados.canalvenda }}
            </span>
          </div>
        </div>
        <div class="row justify-center items-center q-mb-md">
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary">
              {{ $t("message.joinOrders") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.agrupapedido }} </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary">
              {{ $t("message.shippingCompany") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.transportadora }} </span>
          </div>
        </div>
        <div class="row justify-center items-center q-mb-md">
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary">
              {{ $t("message.manager") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.responsavelconta }} </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span class="fw-bold text-primary">
              {{ $t("message.status") }} :
            </span>
          </div>
          <div class="col" cols="12" sm="12" md="4">
            <span>{{ dados.situacao }} </span>
          </div>
        </div>
      </q-card>
    </q-card>
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ClienteDadosCadastro",
  props: ["idCliente", "showHeader"],
  data() {
    return {
      dados: null,
      loader: true,
    };
  },
  methods: {
    abrirPesquisa() {
      this.$emit("abrirPesquisa");
    },
    listarDadosCliente() {
      api
        .post("/v1/clientes/listardadoscliente", {
          id: this.idCliente,
        })
        .then((res) => {
          if (res.data) {
            this.loader = true;
            this.dados = res.data[0];
          }
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.listarDadosCliente();
  },
};
</script>
