export default {
  onChangeAtualizar(state) {
    state.atualizar += 1;
  },
  onChangeLoader(state, payload) {
    state.loader = payload;
  },
  setRemoveStyleAndClass(state, payload) {
    state.removeStyleAndClass = payload;
  },
};
