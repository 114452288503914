<template>
  <div class="site-logo">
    <img :src="appLogo" alt="site logo" style="width: 70%" />
  </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["sidebarSelectedFilter"]),
  },
  data() {
    return {
      appLogo: AppConfig.appLogo,
      darkLogo: AppConfig.darkLogo,
    };
  },
};
</script>
