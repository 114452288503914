import CadastroDeDocumentos from "Views/Repositorio/CadastroDeDocumentos";
import CadastroDeAreas from "Views/Repositorio/CadastroDeAreas";
import ConsultaDeDocumentos from "Views/Repositorio/ConsultaDeDocumentos";

const routes = [
  {
    path: "/cadastrodedocumentos",
    name: "cadastrodedocumentos",
    component: CadastroDeDocumentos,
    meta: {
      requiresAuth: true,
      title: "message.documentsQueryRepository",
      breadcrumb: [
        "message.admin",
        "message.repository",
        "message.documentsQueryRepository",
      ],
    },
  },
  {
    path: "/cadastrodeareas",
    name: "cadastrodeareas",
    component: CadastroDeAreas,
    meta: {
      requiresAuth: true,
      title: "message.areaRegistration",
      breadcrumb: [
        "message.admin",
        "message.repository",
        "message.areaRegistration",
      ],
    },
  },
  {
    path: "/consultadedocumentos",
    name: "consultadedocumentos",
    component: ConsultaDeDocumentos,
    meta: {
      requiresAuth: true,
      title: "message.documentQueries",
      breadcrumb: [
        "message.admin",
        "message.repository",
        "message.documentQueries",
      ],
    },
  },
];

export default routes;
