<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <page-title-bar></page-title-bar>
    <div class="q-pa-md">
      <q-form
        ref="form"
        v-model="valid"
        class="q-gutter-md"
        autofocus
        @submit="salvar"
      >
        <div class="row">
          <div class="col-2">
            <q-input
              disable
              rounded
              outlined
              dense
              v-model="nivelAcessoData.id"
              label="Id"
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
          <div class="col-4">
            <q-input
              ref="descricao"
              rounded
              outlined
              dense
              class="q-ml-sm"
              placeholder="Insira a descrição"
              v-model="nivelAcessoData.descricao"
              :label="$t('message.description')"
              :rules="descriptionRules"
              required
            >
              <template v-slot:prepend>
                <q-icon :name="icon" />
              </template>
            </q-input>
          </div>
          <div class="col-4">
            <SeletorDashboardPadrao
              :required="false"
              :dashboardId="nivelAcessoData.dashboardId"
              :nivelAcessoId="id"
              :prependIcon="icon"
              @selected="selectedDashboardPadrao($event)"
              class="q-ml-sm"
              :key="componentKeySeletorDashboardPadrao"
            />
          </div>
          <div class="col-2">
            <q-toggle
              v-model="filtroPowerbi"
              color="blue"
              label="Filtro Powerbi"
              checked-icon="check"
              unchecked-icon="clear"
            ></q-toggle>
          </div>
        </div>
        <q-btn
          color="success"
          class="q-mr-sm q-mt-sm"
          type="submit"
          size="md"
          rounded
        >
          {{ $t("message.save") }}
        </q-btn>

        <q-btn
          color="danger"
          class="q-mt-sm"
          size="md"
          type="sair"
          @click.prevent="$router.go(-1)"
          rounded
        >
          {{ $t("message.close") }}
        </q-btn>
      </q-form>
      <hr />

      <q-card v-if="id">
        <q-tabs
          icons-and-text
          flat
          v-model="tab"
          dense
          class="text-grey-darken-3 bg-grey-2"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
          style="height: 60px"
        >
          <q-tab name="tab-1">
            <q-icon class="material-icons q-mb-sm" size="sm">list_alt</q-icon>
            {{ $t("message.accessLevel") }}
          </q-tab>

          <q-tab name="tab-2">
            <q-icon class="material-icons q-mb-sm" size="sm">dashboard</q-icon>
            {{ $t("message.dashboards") }}
          </q-tab>
        </q-tabs>

        <q-tab-panels v-model="tab" animated keep-alive>
          <q-tab-panel name="tab-1">
            <ListaMenuNivelAcesso
              ref="listaMenuNivelAcesso"
              :nivelAcessoId="id"
            />
          </q-tab-panel>
          <q-tab-panel name="tab-2">
            <ListaDeDashboardsPorNivelAcesso
              ref="listaDashboardsPorNivelAcesso"
              @change="atualizarListaPadrao()"
            />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </div>
</template>

<script>
import api from "Api";
import ListaDeDashboardsPorNivelAcesso from "Components/Areas/Admin/NiveisAcesso/ListaDeDashboardsPorNivelAcesso";
import ListaMenuNivelAcesso from "Components/Areas/Admin/NiveisAcesso/ListaMenuNivelAcesso";
import SeletorDashboardPadrao from "Components/Areas/Admin/Dashboards/SeletorDashboardPadrao";
import { showError, showSuccess } from "@/notifications/notify";
import { clearObject, chaveAleatoriaComponente } from "Helpers";
import { ref } from "vue";

export default {
  name: "NivelAcessoDetalhe",
  components: {
    ListaDeDashboardsPorNivelAcesso,
    ListaMenuNivelAcesso,
    SeletorDashboardPadrao,
  },
  data() {
    return {
      loader: true,

      valid: true,
      id: this.$route.params.id,
      filtroPowerbi: false,

      nivelAcessoId: this.id,
      componentKeySeletorDashboardPadrao: chaveAleatoriaComponente(),

      nivelAcessoData: {
        descricao: null,
        dashboardId: null,
        filtroPowerbi: null,
      },
      descriptionRules: [
        (v) => !!v || this.$t("message.descriptionRequired"),
        (v) =>
          (v && v.length <= 100) ||
          `${this.$t("message.description")}
           ${this.$t("message.mustbelessthan")} 100
           ${this.$t("message.characters")}`,
      ],
      rows: [],
      columns: [
        {
          label: this.$t("message.description"),
          name: "descricao",
          field: "descricao",
          align: "left",
        },
        {
          label: this.$t("message.active"),
          name: "ativo",
          field: "ativo",
        },
      ],
      tab: ref("tab-1"),
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id;
      this.obterNivelAcesso();
    },
  },
  methods: {
    obterNivelAcesso() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }

      api
        .get(`/v1/nivelacesso/${this.id}`)
        .then((res) => {
          this.nivelAcessoData = res.data;
          this.filtroPowerbi = res.data.filtroPowerbi == 1;
          this.$emit("obterCruzamento");
          this.loader = false;
        })
        .catch((err) => showError(err));
    },

    salvar() {
      let message;
      let method;
      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
      } else {
        message = "message.successAddedRecord";
        method = "post";
      }
      this.loader = true;
      this.nivelAcessoData.filtroPowerbi = this.filtroPowerbi ? 1 : 0;
      api[method](`/v1/nivelacesso/${this.id}`, this.nivelAcessoData)
        .then(() => {
          if (!this.id) {
            clearObject(this.nivelAcessoData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },

    atualizarListaPadrao() {
      this.componentKeySeletorDashboardPadrao += chaveAleatoriaComponente();
    },

    selectedDashboardPadrao(item) {
      this.nivelAcessoData.dashboardId = item.id;
    },
  },
  mounted() {
    this.obterNivelAcesso();
    this.$refs.descricao.focus();
  },
};
</script>
