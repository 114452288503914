<template>
  <div class="q-pa-md">
    <title-bar :title="$t('message.ordersSummary')"></title-bar>
    <app-section-loader :status="loader" />

    <div class="q-mt-md q-mb-sm">
      <q-table
        ref="table"
        :rows="rows"
        :columns="columns"
        row-key="descricao"
        dense
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        auto-width
        :noDataLabel="$t('message.noDataFound')"
        selection="multiple"
        v-model:selected="selected"
        @selection="exibirPedidos"
        hide-pagination
      >
        <template v-slot:bottom-row>
          <q-tr class="totalizador">
            <q-td></q-td>
            <Totalizador :dados="rows" :colunas="columns" />
          </q-tr>
        </template>
      </q-table>
    </div>
    <Search
      :label="$t('message.typeOrder')"
      :labelSearch="$t('message.order')"
      @pesquisar="pesquisar($event)"
      @limpar="limpar"
      class="q-pb-lg"
    ></Search>
  </div>
</template>

<script>
import api from "Api";
import Search from "Components/Widgets/Search";
import Totalizador from "Components/Table/Totalizador";
import { mapGetters } from "vuex";
import { showError } from "@/notifications/notify";

export default {
  name: "ResumoSituacaoPedidos",
  props: ["idCliente", "idRepresentante"],
  components: { Totalizador, Search },
  data() {
    return {
      dialog: false,
      loader: true,
      nome: null,
      pedido: null,
      grupoEconomico: null,
      customClass: {
        hideCheckHeader: true,
      },

      rows: [],
      selected: [],
      rowSelected: [],

      columns: [
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
          titleTotal: true,
        },
        {
          label: this.$t("message.kilos"),
          field: "qtPedida",
          name: "qtPedida",
          total: true,
          format: (v) => this.$filters.formatWithoutDecimal(v),
        },
        {
          label: this.$t("message.orders"),
          field: "cntPedido",
          name: "cntPedido",
          total: true,
          format: (v) => this.$filters.formatWithoutDecimal(v),
        },
        {
          label: "Itens",
          field: "cntItem",
          name: "cntItem",
          total: true,
          format: (v) => this.$filters.formatWithoutDecimal(v),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("clientes", ["getGrupoEconomico"]),
  },

  watch: {
    getGrupoEconomico(val) {
      this.grupoEconomico = val;
      this.obterResumoSituacaoPedidos();
    },
  },
  methods: {
    obterResumoSituacaoPedidos() {
      this.loader = true;
      api
        .post("/v1/comercial/pedidos/resumosituacaopedido", {
          idCliente: this.idCliente,
          idRepresentante: this.idRepresentante,
          grupoeconomico: this.grupoEconomico,
          pedido: this.pedido,
        })
        .then((res) => (this.rows = res.data || res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    montarLinhasSelecionadas(rows, added) {
      if (rows.length > 1) {
        this.rowSelected = added ? rows.map((e) => e.status) : [];
      } else {
        if (added) {
          this.rowSelected.push(rows[0].status);
        } else {
          this.rowSelected = this.rowSelected.filter(
            (f) => f !== rows[0].status
          );
        }
      }
    },
    exibirPedidos({ rows, added, evt }) {
      this.montarLinhasSelecionadas(rows, added);
      this.$store.dispatch("comercial/setSituacao", this.rowSelected);
    },
    limpar() {
      this.pesquisar(null);
    },
    pesquisar(e) {
      this.pedido = e;
      this.obterResumoSituacaoPedidos();
      this.$store.dispatch("comercial/setPedido", this.pedido);
    },
  },
  mounted() {
    this.$store.dispatch("comercial/setSituacao", []);
    this.$store.dispatch("comercial/setPedido", null);
    this.obterResumoSituacaoPedidos();
  },
};
</script>
