<template>
  <q-card class="my-card" flat>
    <q-card-section horizontal>
      <q-img :src="appLogo" class="col-5" fit width="278px" />
      <q-card-section class="q-pt-xs">
        <div class="text-h4 q-mt-sm">Questionário Teórico</div>
        <div class="text-subtitle2 q-mt-sm">{{ candidatoData.setor }}</div>
        <div class="text-subtitle2 q-mt-sm">{{ candidatoData.vaga }}</div>
      </q-card-section>
      <q-space />

      <q-card-section class="col-5 flex flex-end">
        <div class="boxResult">
          <span class="result">Tempo Decorrido: {{ tempoDecorrido }}</span>
        </div>
      </q-card-section>
    </q-card-section>

    <q-separator class="q-mt-sm" />
    <template v-if="exibirTela">
      <QuestionarioFinishQAComponent
        v-if="testeFinalizado"
        :nomeCandidato="candidatoData.nome"
      />

      <template v-else>
        <QuestionarioWelcomeQAComponent
          @iniciarTeste="iniciarTeste()"
          v-if="!candidatoData.dtInicio"
          :nomeCandidato="candidatoData.nome"
        />
        <template v-else>
          <q-card-section>
            <div class="row q-mt-xl">
              <div class="col">
                <span class="text-h5 text-weight-bold"
                  >Tema: {{ perguntaData.descricaoTema }}</span
                >
              </div>
            </div>

            <div class="row q-mt-xl">
              <div class="col">
                <span class="pergunta text-h6">
                  Pergunta: {{ perguntaData.pergunta }}</span
                >
              </div>
            </div>

            <QuestionarioComplementoComponent
              :complemento="perguntaData.complemento"
            />

            <template v-if="respostas.length > 0">
              <div
                class="q-mt-sm"
                v-for="resposta in respostas"
                :key="resposta.idResposta"
              >
                <div class="q-mt-md">
                  <q-radio
                    v-model="respostaCandidato"
                    checked-icon="task_alt"
                    unchecked-icon="panorama_fish_eye"
                    :val="resposta"
                    :label="resposta.respostaCandidato"
                  />
                </div>
              </div>
            </template>
            <q-card-actions class="q-mt-xl">
              <q-btn
                :disable="respostaCandidato == null ? true : false"
                color="positive"
                icon-right="mdi-fast-forward-outline"
                :label="$t('message.next')"
                @click="onSave()"
              />
            </q-card-actions>
          </q-card-section>
        </template>
      </template>
    </template>
  </q-card>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import AppConfig from "Constants/AppConfig";
import QuestionarioWelcomeQAComponent from "./QuestionarioWelcomeQAComponent.vue";
import QuestionarioFinishQAComponent from "./QuestionarioFinishQAComponent.vue";
import QuestionarioComplementoComponent from "./QuestionarioComplementoComponent.vue";
import { extrairInfoCandidato } from "Helpers";

export default {
  name: "QuestionarioQAComponent",
  components: {
    QuestionarioWelcomeQAComponent,
    QuestionarioFinishQAComponent,
    QuestionarioComplementoComponent,
  },
  data() {
    return {
      loader: false,
      exibirTela: false,
      idCandidato: this.$route.params.id,
      appLogo: AppConfig.appLogo2,
      questionarioLista: [],
      testeFinalizado: false,
      tempoDecorrido: "00:00:00",
      event: null,
      candidatoData: {
        nome: null,
        dtInicio: null,
        dtTermino: null,
        setor: null,
        vaga: null,
      },
      respostaCandidato: null,
      perguntaData: {
        descricaoTema: null,
        pergunta: null,
        complemento: null,
      },
      respostas: [],
    };
  },

  methods: {
    obterCandidato() {
      const infoCandidato = extrairInfoCandidato();

      if (!infoCandidato) {
        this.$router.push({ name: "loginqa" });
        return;
      }

      this.idCandidato = infoCandidato.idCandidato;

      this.loader = true;
      api
        .get(`/v1/rh/qa/candidato/${this.idCandidato}`)
        .then((res) => {
          if (res.data) {
            this.candidatoData.setor = res.data.descricaoSetor;
            this.candidatoData.vaga = res.data.vaga;
            this.candidatoData.dtInicio = res.data.dtInicio;
            this.candidatoData.dtTermino = res.data.dtTermino;
            this.candidatoData.nome = res.data.nome;

            if (res.data.dtTermino) {
              this.testeFinalizado = true;
            }
            if (res.data.dtInicio && !res.data.dtTermino) {
              this.elapsedTime();

              this.obterPergunta();
            }
          }
        })
        .catch(() => this.$router.push({ name: "loginqa" }))
        .finally(() => {
          this.loader = false;
          this.exibirTela = true;
        });
    },

    elapsedTime() {
      const convertSeconds = (seconds) => {
        const convert = (x) => {
          const y = Math.trunc(x);
          return y < 10 ? "0" + y : y;
        };
        return (
          convert(parseInt(seconds / (60 * 60))) +
          ":" +
          convert(parseInt((seconds / 60) % 60)) +
          ":" +
          convert(seconds % 60)
        );
      };

      this.event = setInterval(() => {
        const dtInicio = new Date(this.candidatoData.dtInicio);
        const agora = new Date();
        const seconds = (agora - dtInicio) / 1000;
        this.tempoDecorrido = convertSeconds(seconds);
      }, 1000);
    },

    obterPergunta() {
      this.loader = true;
      api
        .get(`/v1/rh/qa/questionario/candidato/${this.idCandidato}`)
        .then((res) => {
          if (res.data) {
            this.perguntaData.descricaoTema = res.data.descricaoTema;
            this.perguntaData.pergunta = res.data.pergunta;
            this.perguntaData.complemento = res.data.complemento;
            this.obterResposta(res.data.idPergunta);
          }
        })
        .catch((err) => {
          if (err.response.status == 490) {
            this.finalizarTeste();
          } else {
            showError(err);
          }
        })
        .finally(() => (this.loader = false));
    },

    obterResposta(idPergunta) {
      this.loader = true;
      api
        .get(`/v1/rh/qa/respostas/pergunta/${idPergunta}`)
        .then((res) => {
          if (res.data) {
            this.respostas = res.data.map((elem) => {
              return {
                idResposta: elem.idResposta,
                respostaCandidato: elem.resposta,
              };
            });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    iniciarTeste() {
      api
        .patch(
          `/v1/rh/qa/candidato/${this.idCandidato}/inciarFinalizarTeste/`,
          {
            acao: "iniciar",
          }
        )
        .then(() => this.obterCandidato())
        .catch((err) => showError(err));
    },

    finalizarTeste() {
      api
        .patch(
          `/v1/rh/qa/candidato/${this.idCandidato}/inciarFinalizarTeste/`,
          {
            acao: "finalizar",
          }
        )
        .then(() => {
          clearInterval(this.event);
          this.testeFinalizado = true;
        })
        .catch((err) => showError(err));
    },

    onSave() {
      this.loader = true;
      const data = {
        idCandidato: this.idCandidato,
        idResposta: this.respostaCandidato.idResposta,
      };

      api
        .post(`/v1/rh/qa/respostacandidato/`, data)
        .then(() => {
          this.respostaCandidato = null;
          this.obterPergunta();
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.obterCandidato();
  },
};
</script>

<style scoped>
.flex-end {
  justify-items: flex-end;
}
</style>
