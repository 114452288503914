import { userConfigKey } from "@/global";

import { languages, sidebarFilters, routerAnimations, themes } from "./data";

function setStateStorage (state) {
  localStorage.setItem(userConfigKey, JSON.stringify(state));
}

const state = {
  selectedLocale: languages[1], // selected locale
  languages, // languages
  sidebarSelectedFilter: sidebarFilters[3], // selected sidebar filter
  selectedRouterAnimation: routerAnimations[1], // selected router animation
  themes, // themes
  selectedTheme: themes[0], // selected theme
};

// getters
const getters = {
  selectedLocale: (state) => state.selectedLocale,
  languages: (state) => state.languages,
  sidebarSelectedFilter: (state) => state.sidebarSelectedFilter,
  selectedRouterAnimation: (state) => state.selectedRouterAnimation,
  themes: (state) => state.themes,
  selectedTheme: (state) => state.selectedTheme,
  sidebarFilters: (state) => state.sidebarFilters,
};

// actions
const actions = {
  changeLanguage(context, payload) {
    context.commit("changeLanguageHandler", payload);
  },
  changeSidebarFilter(context, payload) {
    context.commit("changeSidebarFilterHandler", payload);
  },
  changeTheme(context, payload) {
    context.commit("changeThemeHandler", payload);
  },
};

// mutations
const mutations = {
  changeLanguageHandler (state, language) {
    state.selectedLocale = language;
    setStateStorage(state);
  },
  changeSidebarFilterHandlerDefault(state) {
    state.sidebarSelectedFilter = sidebarFilters[3];
  },

  changeSidebarFilterHandlerAlternativeUser(state) {
    state.sidebarSelectedFilter = sidebarFilters[2];
  },
  changeThemeHandler(state, theme) {
    state.selectedTheme = theme;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
