<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog
      v-model="dialog"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card class="cardLgpd">
        <q-card-actions align="right">
          <q-btn flat round color="negative" icon="close" v-close-popup />
        </q-card-actions>
        <q-card-section class="q-pt-none">
          <q-img
            :src="getImagePath(`/static/img/lgpd-alert.png`)"
            class="image"
            @click.stop="goDocuments"
            v-close-popup
          />
        </q-card-section>
        <q-separator />

        <q-card-actions align="right">
          <q-btn
            v-close-popup
            flat
            color="primary"
            label="Clique AQUI se não deseja receber esse aviso novamente!"
            @click.stop="noShowWarning"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import api from "Api";
export default {
  data() {
    return {
      dialog: true,
      getImagePath(path) {
        return process.env.BASE_URL + path;
      },
    };
  },
  methods: {
    goDocuments() {
      this.$router.push("/lgpddocumentos");
    },
    noShowWarning() {
      api.patch("/v1/usuarios/noShowWarningLgpd");
    },
  },
};
</script>

<style>
.image {
  max-width: 100vw;
  /*margin: auto auto -1em;*/
  cursor: pointer;
  width: 50vw;
  height: auto;
}
.cardLgpd {
  max-width: 100vw !important;
  width: auto !important;
  height: auto;
  background: #fff;
  box-shadow: none !important;
}
</style>
