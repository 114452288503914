<template>
  <div>
    <page-title-bar v-if="!popUp"></page-title-bar>

    <div :class="classeLista">
      <app-section-loader :status="loader" />
      <div class="contact-wrapper">
        <Search
          v-if="formularioPesquisa"
          :label="$t('message.typeInformationAboutCustomer')"
          @pesquisar="pesquisar($event)"
          @limpar="limpar"
        ></Search>
        <q-table
          :rows="rows"
          :columns="columns"
          dense
          hide-pagination
          v-if="exibirDados"
          @row-click="selecionar"
          class="r7-cursor-pointer q-mt-md my-sticky-header-table"
          table-header-class="table-header"
          :rows-per-page-options="[0]"
          :noDataLabel="$t('message.noDataFound')"
          virtual-scroll
        />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "Components/Widgets/Search";
import api from "Api";
import { mapActions } from "vuex";
import { showSelectedSuccess } from "@/notifications/notify";

export default {
  name: "ClienteLista",
  components: { Search },
  props: {
    popUp: {
      type: Boolean,
      default: false,
    },
    formularioPesquisa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loader: false,
      exibirDados: false,
      pesquisa: null,

      rows: [],
      columns: [
        {
          label: "Id",
          field: "codigoCliente",
          name: "codigoCliente",
          sortable: true,
          align: "right",
        },
        {
          label: this.$t("message.name"),
          field: "nomeCliente",
          name: "nomeCliente",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.city"),
          field: "cidade",
          name: "cidade",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.state"),
          field: "cdUfBi",
          name: "cdUfBi",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.status"),
          field: "situacao",
          name: "situacao",
          sortable: true,
          align: "left",
        },
      ],
    };
  },
  computed: {
    classeLista() {
      return this.popUp ? "" : "q-pa-md";
    },
  },
  methods: {
    ...mapActions("clientes", ["setDadosDoCliente"]),

    pesquisarClienteDoRepresentante(idRepresentante) {
      this.pesquisa = { idRepresentante };
      this.obterResultado();
    },

    pesquisar(value) {
      this.pesquisa = value;
      this.obterResultado();
    },

    obterResultado() {
      this.exibirDados = true;
      this.loader = true;

      api
        .post("/v1/clientes/listarouobterclientes", {
          pesquisa: this.pesquisa,
        })
        .then((res) => {
          this.rows = res.data;
          this.loader = false;
        });
    },
    limpar() {
      this.exibirDados = false;
      this.clientes = [];
    },
    selecionar(evt, item) {
      this.setDadosDoCliente(item);
      if (this.popUp) {
        this.$emit("clienteSelecionado");
      } else {
        showSelectedSuccess(this.$t("message.customerSelected"));
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 570px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
