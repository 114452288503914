<template>
  <q-card-section>
    <div class="row q-mt-xl">
      <div class="col text-center q-mt-lg">
        <p class="text-h4">Parabéns {{ nomeCandidato }}</p>

        <p class="text-h5">
          Você finalizou o teste, agora aguarde o contato do RH.
        </p>
      </div>
    </div>
  </q-card-section>
</template>

<script>
export default {
  props: {
    nomeCandidato: {
      type: String,
      default: null,
    },
  },
  emits: ["iniciarTeste"],
  name: "QuestionarioFinishQAComponent",
  data() {
    return {};
  },
};
</script>
