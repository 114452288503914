<template>
  <app-section-loader :status="loader" />

  <q-card class="q-pa-md">
    <q-form ref="form" autofocus @submit="onSave">
      <div class="row no-wrap justify-evenly">
        <div class="col-4" cols="12" sm="12" md="4" lg="4">
          <h6 class="text-primary">
            {{ $t("message.candidateInformations") }}:
          </h6>
          <br />

          <q-input
            ref="nome"
            rounded
            outlined
            dense
            v-model="candidatoData.nome"
            :label="$t('message.name')"
            required
            :rules="nameRules"
          />

          <q-input
            class="q-mb-md"
            ref="vaga"
            rounded
            outlined
            dense
            v-model="candidatoData.vaga"
            :label="$t('message.job')"
            required
            :rules="jobRules"
          />

          <SetorQASelectionComponent
            :setorId="candidatoData.idSetor"
            @selected="selectedSetor($event)"
            :required="true"
            :rounded="true"
            :filled="false"
          />

          <q-separator />
          <h6 class="text-primary">{{ $t("message.loginInformations") }}:</h6>

          <q-input
            ref="usuario"
            rounded
            outlined
            dense
            v-model="candidatoData.usuario"
            :label="$t('message.user')"
            required
            :rules="userRules"
            class="q-mb-md"
          />

          <div class="row q-mb-md">
            <q-input
              ref="password"
              rounded
              outlined
              dense
              v-model="candidatoData.password"
              :label="$t('message.password')"
              required
              :rules="userRules"
              disable
              class="col col-sm-10"
            />

            <q-btn
              flat
              rounded
              icon="mdi-content-copy"
              class="q-mb-md q-ml-sm"
              @click="copy()"
            >
              <tooltip-custom :label="$t('message.copy')" />
            </q-btn>
          </div>
          <div class="text-center">
            <q-btn
              color="info"
              class="q-mr-sm q-mt-md"
              type="button"
              size="md"
              rounded
              :disable="id !== ''"
              @click="generatePassword()"
            >
              {{ $t("message.generatePassword") }}
            </q-btn>

            <q-btn
              color="success"
              class="q-mr-sm q-mt-md"
              type="submit"
              size="md"
              rounded
            >
              {{ $t("message.save") }}
            </q-btn>

            <q-btn
              color="danger"
              class="q-mt-sm q-mt-md"
              size="md"
              type="sair"
              @click.prevent="$router.go(-1)"
              rounded
            >
              {{ $t("message.close") }}
            </q-btn>
          </div>
        </div>
      </div>
    </q-form>
  </q-card>
</template>

<script>
import api from "Api";
import { clearObject, randomPassword } from "Helpers";
import {
  showError,
  showSuccess,
  showSelectedSuccess,
} from "@/notifications/notify";
import SetorQASelectionComponent from "Components/Areas/RH/QA/Selecao/SetorQASelectionComponent";

export default {
  name: "CandidatoQADetailComponent",
  components: { SetorQASelectionComponent },
  data() {
    return {
      loader: true,
      valid: true,
      candidatoData: {
        idCandidato: null,
        nome: null,
        vaga: null,
        idSetor: null,
        usuario: null,
        password: null,
      },

      id: this.$route.params.id,

      nameRules: [(v) => !!v || this.$t("message.nameRequired")],
      jobRules: [(v) => !!v || this.$t("message.jobRequired")],
      userRules: [(v) => !!v || this.$t("message.userRequired")],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterCandidato();
    },
  },
  methods: {
    onSave() {
      let message;
      let method;
      let url;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/rh/qa/candidato/${this.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/rh/qa/candidato/";
      }

      this.loader = true;

      api[method](url, this.candidatoData)
        .then(() => {
          if (!this.id) {
            clearObject(this.candidatoData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.$refs.nome.focus();
        });
    },
    obterCandidato() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/rh/qa/candidato/${this.id}`)
        .then((res) => (this.candidatoData = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    selectedSetor(selected) {
      this.candidatoData.idSetor = selected.id;
    },
    generatePassword() {
      this.candidatoData.password = randomPassword();
    },
    copy() {
      navigator.clipboard.writeText(this.candidatoData.password).then(() => {
        showSelectedSuccess("Senha copiada!");
      });
    },
  },
  mounted() {
    this.obterCandidato();
    this.$refs.nome.focus();
  },
};
</script>
