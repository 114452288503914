<template>
  <page-title-bar></page-title-bar>

  <div class="q-pa-md" v-if="releasesArray.length > 0">
    <q-timeline layout="loose">
      <q-infinite-scroll @load="onLoad" :offset="800">
        <template v-for="(release, index) in releasesArray" :key="index">
          <q-timeline-entry
            :loading="loader"
            :subtitle="$filters.formatDate(release.created_at)"
            :side="release.side"
          >
            <q-timeline-entry heading>{{ release.tagName }}</q-timeline-entry>
            <q-list v-for="(titulo, index) in release.titulos" :key="index">
              <q-item-label class="text-h5 fw-bold text-grey-8 q-mt-md">
                {{ titulo.descricao }}</q-item-label
              >
              <hr />
              <q-list>
                <q-item
                  v-for="(item, index) in titulo.itens"
                  :key="index"
                  class="r7-listaItemRelease"
                >
                  <q-item-section class="r7-alinharTextoRelease">
                    <span class="text-h6 text-grey-7 fw-bold">{{
                      item.subtitulo
                    }}</span>
                    <span class="text-h7 text-grey-10">{{
                      item.conteudo
                    }}</span>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-list>
          </q-timeline-entry>
        </template>
        <template v-slot:loading>
          <div class="row justify-center q-my-md">
            <q-spinner-dots color="primary" size="40px" />
          </div>
        </template>
      </q-infinite-scroll>
    </q-timeline>
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ReleasesLista",
  data() {
    return {
      loader: null,
      releasesArray: [],
      releases: [],
    };
  },

  methods: {
    listarReleaseNotes() {
      api
        .get("/v1/github/listarreleases")
        .then((res) => {
          this.loader = false;
          this.tratarDadosRecebidos(res.data);
        })
        .catch((err) => showError(err));
    },
    async tratarDadosRecebidos(dados) {
      if (dados.length === 0) {
        return;
      }
      let side = "left";
      this.releases = await dados.map((dado) => {
        side = side === "right" ? "left" : "right";
        const linhas = dado.body.split("\r\n");
        return {
          tagName: dado.tag_name,
          created_at: dado.created_at,
          titulos: this.montarTitulos(linhas),
          side,
        };
      });
      this.releasesArray.push(this.releases[0]);
    },

    montarTitulos(linhas) {
      const titulos = linhas
        .filter((f) => f.indexOf("##") !== -1)
        .map((titulo) => {
          return {
            descricao: titulo.replace("##", ""),
            itens: this.montarItens(titulo, linhas),
          };
        });

      return titulos;
    },

    montarItens(titulo, linhas) {
      let findItem = false;

      const findItensFromTitle = (linha) => {
        if (linha.indexOf("##") !== -1 && findItem) {
          findItem = false;
          return false;
        }

        if (linha === titulo) {
          findItem = true;
          return false;
        }
        if (linha.length < 3) {
          return false;
        }
        if (findItem) {
          return true;
        }
        return false;
      };

      const itens = linhas
        .filter((linha) => findItensFromTitle(linha))
        .map((linha) => this.tratarItens(linha));

      return itens;
    },

    tratarItens(linha) {
      let subtitulo = "";
      let conteudo = "";

      let init = linha.indexOf("- **");
      let fin = linha.lastIndexOf("**");

      if (init !== -1) {
        init += 4;
        subtitulo = linha.substring(init, fin);
        fin += 2;
      }

      if (init === -1) {
        fin = 0;
      }

      conteudo = linha.substring(fin).replace(":", "").replaceAll("*", "");

      return {
        subtitulo,
        conteudo,
      };
    },
    onLoad(index, done) {
      if (index >= this.releases.length) {
        done();
      } else {
        setTimeout(() => {
          this.releasesArray.push(this.releases[index]);
          done();
        }, 500);
      }
    },
  },
  mounted() {
    this.listarReleaseNotes();
  },
};
</script>

<style scoped>
h3 {
  font-size: 2.5rem !important;
  font-weight: 500;
  color: #464d69 !important;
  padding: 0px;
  position: relative;
  bottom: 20px;
}

.q-timeline--loose .q-timeline__heading-title {
  text-align: unset;
  margin: 0px !important;
  padding: 0px !important;
}

.q-timeline__subtitle {
  font-size: 20px;
  font-weight: 800;
}
</style>
