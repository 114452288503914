<template>
  <q-card class="my-card">
    <q-card-section horizontal class="q-pa-xs">
      <q-card-section class="col-4 flex flex-center">
        <q-img class="rounded-borders" :src="produto?.imagem" />
      </q-card-section>

      <q-card-section class="col-8" vertical>
        <q-list>
          <q-item class="no-padding">
            <q-item-section class="text-h6 text-bold">
              {{ produto?.descricao }}
            </q-item-section>
          </q-item>

          <CalculaComposicaoProdutoConfeccao
            :id="produto?.id"
            @carregarVariacao="variacao = $event"
          />
        </q-list>

        <q-card-section class="border q-mt-xs">
          <div class="row">
            <div class="col"></div>
            <div class="col text-bold">Rosset</div>
            <div class="col text-bold">Concorrente</div>
            <div class="col text-bold">Variação</div>
          </div>
          <div class="row">
            <div class="col text-bold">Custo Peça</div>
            <div class="col">R$ {{ variacao.custoTotalRosset }}</div>
            <div class="col">R$ {{ variacao.custoTotalConcorrente }}</div>
            <div class="col text-bold" :class="color">
              {{ variacao.percentual }}% ({{ variacao.valor }})
            </div>
          </div>
        </q-card-section>

        <q-card-actions>
          <q-btn
            color="negative"
            size="xs"
            label="Remover"
            @click.prevent.stop="removerItem"
          />
        </q-card-actions>
      </q-card-section>
    </q-card-section>
  </q-card>
</template>

<script>
import CalculaComposicaoProdutoConfeccao from "./CalculaComposicaoProdutoConfeccao";
export default {
  name: "ProdutosConfeccaoDesktop",
  components: { CalculaComposicaoProdutoConfeccao },
  emits: ["removerItem"],
  props: {
    produto: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      variacao: {
        percentual: 0,
        valor: 0,
      },
    };
  },
  computed: {
    color() {
      return this.variacao.percentual > 0 ? "text-positive" : "text-negative";
    },
  },
  methods: {
    removerItem() {
      this.$emit("removerItem", this.produto.id);
    },
  },
};
</script>
