<template>
  <q-btn flat round dense class="q-mx-sm" @click="openFaq">
    <q-tooltip
      anchor="bottom middle"
      self="center middle"
      :offset="[10, 10]"
      class="text-body2"
      >Tira Dúvidas</q-tooltip
    >
    <q-icon large class="material-icons text-purple-9 faq-help"> help </q-icon>

    <q-menu auto-close right bottom max-width="350px" max-height="400px">
      <q-list bordered class="rounded-borders" style="min-width: 350px">
        <div class="row">
          <q-item-label header class="text-bold"> FAQ </q-item-label>
          <q-space />
        </div>
        <q-item clickable v-close-popup @click="faqPowerBi('formaCorreta')">
          <q-item-section>
            <q-item-label class="text-grey-9">
              Como acessar os Relatórios em BI ?
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="faqPowerBi('formaErrada')">
          <q-item-section>
            <q-item-label class="text-grey-9">
              Caso esteja aparecendo REQUEST ACCESS, o que fazer ?
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
  <HelpPowerBiVue ref="modalHelpPowerBi" />
</template>

<script>
import HelpPowerBiVue from "./HelpPowerBi";
export default {
  name: "MessageAlert",
  components: { HelpPowerBiVue },
  data() {
    return {};
  },
  methods: {
    openFaq() {
      // console.log("v-step__button-stop")
      $(".v-step__button-stop").trigger("click");
    },
    faqPowerBi(tipo) {
      this.$refs.modalHelpPowerBi.abrirModal(tipo);
    },
  },
};
</script>
