<template lang="">
  <q-card-section>
    <div class="row items-center q-gutter-sm">
      <q-checkbox
        v-model="selecionarTodasValidacoes"
        @update:model-value="togglevalidacoes"
        :disable="!validado"
      />
      <div class="text-subtitle1">{{ titulo }}</div>
      <q-space />
      <slot name="validador" />
    </div>
    <q-list bordered padding>
      <q-item v-for="(validacao, index) in validacoes" :key="index">
        <q-item-section>
          <!-- Flex container principal para alinhar elementos -->
          <div class="row items-center justify-between q-gutter-sm">
            <!-- Agrupando checkbox e nome do aprovador -->
            <div class="row items-center q-gutter-xs">
              <q-checkbox v-model="validacao.aprovado" :disable="!validado" />
              <q-btn
                flat
                dense
                :label="validacao.aprovador"
                @click="openLink(validacao)"
              />
            </div>
            <!-- Valor no final da linha -->
            <span>{{ $filters.formatCurrency(validacao.valor) }}</span>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </q-card-section>

  <ModalInfo
    ref="modalInfo"
    title="Aprovações"
    :persistent="false"
    :width="70"
    :height="70"
  >
    <template v-slot:body>
      <DetalhesValidacaoComponent
        :tipo="tipo"
        :usuario="usuarioSelecionado"
        :validado="validado"
      />
    </template>

    <template v-slot:button2>
      <q-btn
        label="Fechar"
        @click="aoFechar()"
        class="bg-red text-white q-ml-md"
      />
    </template>
  </ModalInfo>
</template>
<script>
import ModalInfo from "Components/Widgets/ModalInfo";
import DetalhesValidacaoComponent from "./DetalhesValidacaoComponent.vue";
export default {
  name: "ValidacaoComponent",
  components: { ModalInfo, DetalhesValidacaoComponent },
  props: {
    tipo: {
      type: String,
      default: null,
    },
    titulo: {
      type: String,
      default: null,
    },
    validacoes: {
      type: Array,
      default: [],
    },
    validado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selecionarTodasValidacoes: false,
      usuarioSelecionado: null,
    };
  },
  methods: {
    openLink(row) {
      this.usuarioSelecionado = row.aprovador;
      this.$refs.modalInfo.abrir();
    },
    // Método para selecionar/deselecionar todos os checkboxes
    togglevalidacoes() {
      this.validacoes.forEach((validacao) => {
        validacao.aprovado = this.selecionarTodasValidacoes;
      });
    },
    aoFechar() {
      this.$refs.modalInfo.fechar();
    },
  },
};
</script>
