<template>
  <q-btn label="menu" size="md" color="primary">
    <q-menu>
      <q-list style="min-width: 100px">
        <q-item
          clickable
          v-close-popup
          @click.stop="invetoryEntireNetwork"
          :disable="getDisableInventory"
        >
          <q-item-section>{{
            $t("message.inventoryEntireNetwork")
          }}</q-item-section>
        </q-item>
        <q-item
          clickable
          v-close-popup
          @click.stop="onInventorySpecificIP"
          :disable="getDisableInventory"
        >
          <q-item-section>{{
            $t("message.inventorySpecificIP")
          }}</q-item-section>
        </q-item>

        <q-separator />

        <q-item
          clickable
          v-close-popup
          @click.stop="lastInventory"
          :disable="getDisableInventory"
        >
          <q-item-section>{{ $t("message.lastInventoryLog") }}</q-item-section>
        </q-item>

        <q-separator />

        <q-item clickable v-close-popup @click.stop="exit()">
          <q-item-section>{{ $t("message.exit") }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
  <modal-inventariar-especifico
    ref="modalInventariarEspecifico"
    @informarIp="inventorySpecificIP($event)"
  />
</template>
<script>
import { mapGetters } from "vuex";
import { showError, showSuccess } from "@/notifications/notify";

import Api from "Api";
import ModalInventariarEspecifico from "./ModalInventariarEspecifico.vue";
export default {
  name: "MenuInventario",
  components: { ModalInventariarEspecifico },
  emits: ["refreshListInventario"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("admin", ["getDisableInventory"]),
  },

  methods: {
    invetoryEntireNetwork() {
      Api.post("/v1/inventarioti/inventariarTodaRede")
        .then(() => {
          this.$emit("refreshListInventario");
          showSuccess("Inventário Iniciado");
        })
        .catch((e) => showError(e));
    },
    onInventorySpecificIP() {
      this.$refs.modalInventariarEspecifico.aoAbrir();
    },
    inventorySpecificIP(ip) {
      try {
        if (!ip) throw new Error("Ip não informado");
        this.$refs.modalInventariarEspecifico.aoFechar();

        Api.put(`/v1/inventarioti/inventariarEspecifico/${ip}`)
          .then(() => {
            this.$emit("refreshListInventario");
            showSuccess("Inventário Iniciado");
          })
          .catch((e) => showError(e));
      } catch (error) {
        showError(error);
      }
    },
    lastInventory() {},
    exit() {},
  },
};
</script>
