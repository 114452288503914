import AppCard from "Components/AppCard/AppCard";
import FormInLineBlock from "Components/Widgets/FormInLineBlock";
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
import ModalCard from "Components/Widgets/ModalCard";
import AppSectionLoader from "Components/Widgets/AppSectionLoader";
import DeleteConfirmationDialog from "Components/Widgets/DeleteConfirmationDialog";
import ConfirmationDialog from "Components/Widgets/ConfirmationDialog";
import InsertItemDialog from "Components/Widgets/InsertItemDialog";
import TitleBar from "Components/Widgets/TitleBar";
import JsonToExcel from "Components/Excel/JsonToExcel";
import QGrid from "Components/Widgets/QGrid";
import TooltipCustom from "Components/Widgets/TooltipCustom";

const registerComponent = (app) => {
  app.component("appCard", AppCard);
  app.component("formInLineBlock", FormInLineBlock);
  app.component("pageTitleBar", PageTitleBar);
  app.component("modalCard", ModalCard);
  app.component("appSectionLoader", AppSectionLoader);
  app.component("deleteConfirmationDialog", DeleteConfirmationDialog);
  app.component("confirmationDialog", ConfirmationDialog);
  app.component("insertItemDialog", InsertItemDialog);
  app.component("titleBar", TitleBar);
  app.component("exportar-excel", JsonToExcel);
  app.component("q-grid", QGrid);
  app.component("tooltip-custom", TooltipCustom);
};

export default registerComponent;
