export default {
  getGrupoEconomico: (state) => {
    return state.grupoEconomico;
  },
  getRegraNegocio: (state) => {
    return state.regraNegocio;
  },
  getDadosDoCliente: (state) => {
    return state.dadosDoCliente;
  },
};
