<template>
  <app-section-loader :status="loader"></app-section-loader>
  <q-table
    ref="myTable"
    :loading="loader"
    :rows="rows"
    :columns="columns"
    dense
    table-header-class="table-header"
    hide-pagination
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
  >
  </q-table>
</template>
<script>
import api from "Api";
import { showError } from "@/notifications/notify";
export default {
  name: "DetalhesValidacaoComponent",
  props: {
    tipo: {
      type: String,
      default: null,
    },
    usuario: {
      type: String,
      default: null,
    },
    validado: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: true,
      rows: [],
      columns: [
        {
          label: "Segmento",
          field: "segmento",
          name: "segmento",
          align: "left",
          sortable: true,
        },
        {
          label: "Marca/Loja",
          field: "empLoja",
          name: "empLoja",
          align: "left",
          sortable: true,
        },
        {
          label: "Grupo",
          field: (data) => data.grupo + " - " + data.dsGrupo,
          name: "dsGrupo",
          align: "left",
          sortable: true,
        },
        {
          label: "Aprovador",
          field: "aprovador",
          name: "aprovador",
          align: "left",
          sortable: true,
        },
        {
          label: "Validador",
          field: "validador",
          name: "validador",
          align: "left",
          sortable: true,
        },
        {
          label: "Data Aprov.",
          field: "dtaprovacao",
          name: "dtaprovacao",
          align: "center",
          sortable: true,
        },
        {
          label: "Valor",
          field: (e) => this.$filters.formatCurrency(e.valor),
          name: "valor",
          align: "right",
          sortable: true,
        },
        {
          label: "Descricao",
          field: "descricao",
          name: "descricao",
          align: "left",
        },
      ],
    };
  },
  methods: {
    carregarDetalhes() {
      const validado = this.validado ? "S" : " ";
      let endpoint = null;
      if (this.tipo == "minhasvalidacoes") {
        endpoint = "detalhesminhasvalidacoespendentes";
      } else if (this.tipo == "validacoesequipe") {
        endpoint = "detalhesvalidacoespendentesequipe";
      } else {
        endpoint = "detalhesvalidacoesdiretor";
      }

      const url = `v1/compras/painelaprovacoes/${endpoint}/${this.usuario}?validado=${validado}`;
      api
        .get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.carregarDetalhes();
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .q-table__container {
    height: 40vh !important;
  }
}
@media only screen and (min-width: 600px) {
  .q-table__container {
    height: 22vh !important;
  }
}
@media only screen and (min-width: 990px) {
  .q-table__container {
    height: 45vh !important;
  }
}
</style>
