<template>
  <page-title-bar />
  <div class="q-px-md">
    <DuplicatasEmAbertoListaComponent />
  </div>
</template>
<script>
import DuplicatasEmAbertoListaComponent from "Components/Areas/Financeiro/DuplicatasEmAbertoListaComponent";
export default {
  name: "DuplicatasEmAbertoListaView",
  components: { DuplicatasEmAbertoListaComponent },
};
</script>
