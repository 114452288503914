<template>
  <div class="text-left">
    <app-section-loader :status="loader" />
    <q-toolbar class="q-mb-md">
      <q-toolbar-title>
        <slot name="nome"></slot>
      </q-toolbar-title>
      <slot name="icone"></slot>
    </q-toolbar>

    <q-table
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      :rows-per-page-options="[0]"
      hide-pagination
      auto-width
      :noDataLabel="$t('message.noDataFound')"
      class="q-ma-md"
    >
      <template v-slot:body-cell-principal="props">
        <q-td auto-width>
          <q-chip :color="getColor(props.row.principal)" dark>
            {{ props.row.principal === "1" ? "Sim" : "Não" }}
          </q-chip>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ClienteContato",
  props: ["idCliente", "showHeader"],
  data() {
    return {
      dialog: false,
      loader: true,
      rows: [],
      columns: [
        {
          label: this.$t("message.contacts"),
          field: "contato",
          name: "contato",
          align: "left",
        },
        { label: "Depto", field: "cargo", name: "cargo", align: "left" },
        {
          label: this.$t("message.phone"),
          field: "telefone",
          name: "telefone",
          align: "left",
        },
        {
          label: this.$t("message.email"),
          field: "email",
          name: "email",
          align: "left",
        },
        {
          label: this.$t("message.default"),
          field: "principal",
          name: "principal",
        },
      ],
    };
  },
  methods: {
    getColor(p) {
      return p === "1" ? "green" : "red";
    },

    listarContatosCliente() {
      api
        .post("/v1/clientes/listarcontatoscliente", {
          id: this.idCliente,
        })
        .then((res) => {
          if (res.data) {
            this.loader = true;
            this.rows = res.data;
          }
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.listarContatosCliente();
  },
};
</script>
