import Nprogress from "nprogress";
import api from "Api";
import { userKey } from "@/global";
import router from "@/router";
import store from "@/store";

import { showSelectedSuccess, showError } from "Source/notifications/notify";
import Dialog from 'quasar/src/plugins/Dialog.js';;

function errorMessage(error) {
  Nprogress.done();
  let msg = error;
  if (error.message) msg = error.message;
  if (error.response?.data) msg = error.response.data;
  showError(msg);
}

function setUserLocalStorage(user) {
  store.dispatch("auth/setUser", user);
  if (user) {
    api.defaults.headers.common.Authorization = `Bearer ${user.token}`;
    localStorage.setItem(userKey, JSON.stringify(user));
  } else {
    localStorage.removeItem(userKey);
    delete api.defaults.headers.common.Authorization;
  }
}

function loginSuccess(user) {
  let userLogin = user;
  const { redirect } = user;

  // const resumeLogin = () => {
  delete userLogin.usuarioDigitado;

  setUserLocalStorage(userLogin);

  if (redirect) {
    router.push(redirect);
  } else {
    router.push({ name: "home" });
  }
  setTimeout(() => {
    showSelectedSuccess("Usuário Logado com sucesso!");
  }, 1000);
  // };

  // if (userLogin.usuario !== userLogin.usuarioPrincipal) {
  //   const message = `Você está simulando o usuário ${userLogin.usuario}`;

  //   const stopSimulation = async () => {
  //     Nprogress.start();
  //     await api
  //       .post(
  //         "/v1/usuarios/trocarUsuarioAlternativo",
  //         {
  //           usuarioAlternativo: "",
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${userLogin.token}`,
  //           },
  //         }
  //       )
  //       .then(async () => {
  //         await api
  //           .post("/auth/signin", userLogin.usuarioDigitado)
  //           .then((res) => {
  //             Nprogress.done();
  //             userLogin = res.data;
  //           })
  //           .catch((error) => {
  //             errorMessage(error);
  //           });
  //       })
  //       .catch((error) => {
  //         errorMessage(error);
  //       });
  //   };

  //   Dialog.create({
  //     title: "Simulando Usuário",
  //     message,
  //     ok: {
  //       push: true,
  //       label: "Continuar Simulando",
  //     },
  //     cancel: {
  //       push: true,
  //       color: "negative",
  //       label: "Parar Simulação",
  //     },
  //     persistent: true,
  //   })
  //     .onOk(() => {
  //       resumeLogin();
  //     })
  //     .onCancel(async () => {
  //       await stopSimulation();
  //       resumeLogin();
  //     });
  // } else {
  //   resumeLogin();
  // }
}

function loginFailure(error) {
  setUserLocalStorage(null);
  errorMessage(error);
}

const auth = {
  async login(user) {
    Nprogress.start();

    await api
      .post("/auth/signin", user)
      .then((res) => {
        Nprogress.done();
        const result = res.data;
        result.usuarioDigitado = user;
        result.redirect = user.redirect;
        loginSuccess(result);
      })
      .catch((error) => {
        loginFailure(error);
      });
  },

  async loginByNginx() {
    Nprogress.start();
    await api
      .get("/auth/checkUserAuthenticated")
      .then((res) => {
        Nprogress.done();
        const result = res.data;
        result.redirect = window.location.pathname.replace("/vue", "");
        loginSuccess(result);
      })
      .catch((error) => {
        loginFailure(error);
      });
  },

  async validateToken(userData) {
    setUserLocalStorage(userData);
    const result = await api
      .post("/auth/validateToken", userData)
      // .then((res) => {
      //   setUserLocalStorage(res.data);
      // })
      .catch((err) => {
        showError(err);
        localStorage.removeItem(userKey);
        router.push({ name: "login" });
      });

    return result;
  },

  logout() {
    setUserLocalStorage(null);
    router.push("/session/login");
  },
};

export { auth };
