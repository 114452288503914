<template>
  <app-section-loader :status="loader"></app-section-loader>

  <q-table
    :loading="loader"
    :rows="rows"
    :columns="columns"
    table-header-class="table-header"
    :rows-per-page-label="$t('message.itemsPerPage')"
    :noDataLabel="$t('message.noDataFound')"
    dense
    :rows-per-page-options="[0]"
    :filter="filter"
    style="z-index: 0"
    :virtual-scroll="!isFullTableMode"
    :class="isFullTableMode ? '' : 'my-sticky-header-table'"
  >
    <template v-slot:top-right>
      <div class="r7-switch-toggle">
        <input
          id="on"
          name="state-d"
          type="radio"
          checked=""
          @click.stop="acao = 'disponivel'"
        />
        <label for="on">{{ $t("message.available") }}</label>

        <input
          id="na"
          name="state-d"
          type="radio"
          checked="checked"
          @click.stop="acao = 'todos'"
        />
        <label for="na">{{ $t("message.all") }}</label>
        <input
          id="off"
          name="state-d"
          type="radio"
          @click.stop="acao = 'indisponivel'"
        />
        <label for="off">{{ $t("message.unavailable") }}</label>
      </div>

      <q-input
        class="q-ml-sm"
        borderless
        dense
        debounce="300"
        v-model="filter"
        :placeholder="$t('message.search')"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <exportar-excel
        v-if="rows"
        :data="rows"
        name="acessosusuariossgt"
        class="q-mx-sm"
      />
      <q-btn
        flat
        round
        size="small"
        :icon="isFullTableMode ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"
        @click.stop="isFullTableMode = !isFullTableMode"
      >
        <q-tooltip
          anchor="bottom middle"
          self="center middle"
          :offset="[10, 10]"
          class="text-body2"
          >{{
            isFullTableMode
              ? $t("message.exitFullTableMode")
              : $t("message.fullTableMode")
          }}</q-tooltip
        >
      </q-btn>
      <q-btn flat round size="small" @click.stop="reload()">
        <q-tooltip
          anchor="bottom middle"
          self="center middle"
          :offset="[10, 10]"
          class="text-body2"
          >{{ $t("message.reload") }}</q-tooltip
        >
        <q-icon class="material-icons text-grey-9"> refresh </q-icon>
      </q-btn>
    </template>

    <template v-slot:body-cell-descricaoSetor="props">
      <q-td
        auto-width
        :props="props"
        class="r7-cursor-pointer"
        @click="escolherSetor(props.row)"
      >
        <span>{{ props.row.descricaoSetor }}</span>
      </q-td>
    </template>

    <template v-slot:body-cell-vips="props">
      <q-td key="vips" :props="props" class="r7-cursor-pointer">
        {{ props.row.vips }}
        <q-icon class="material-icons q-pl-sm" color="blue" size="xs"
          >edit</q-icon
        >
        <q-popup-edit
          v-model="props.row.vips"
          :title="$t('message.updateVipLicenses')"
          buttons
          v-slot="scope"
          :label-set="$t('message.confirm')"
          :label-cancel="$t('message.cancel')"
          @update:model-value="
            atualizarLicencas({ ...props.row }, 'atualizarSetor')
          "
        >
          <q-input type="number" v-model="scope.value" dense autofocus />
        </q-popup-edit>
      </q-td>
    </template>

    <template v-slot:body-cell-temporarios="props">
      <q-td key="temporarios" :props="props" class="r7-cursor-pointer">
        {{ props.row.temporarios }}
        <q-icon class="material-icons q-pl-sm" color="blue" size="xs"
          >edit</q-icon
        >
        <q-popup-edit
          v-model="props.row.temporarios"
          :title="$t('message.updateAvailableLicenses')"
          buttons
          v-slot="scope"
          :label-set="$t('message.confirm')"
          :label-cancel="$t('message.cancel')"
          @update:model-value="atualizarLicencas({ ...props.row }, 'confirmar')"
        >
          <q-input type="number" v-model="scope.value" dense autofocus />
        </q-popup-edit>
      </q-td>
    </template>

    <template v-slot:body-cell-disponiveis="props">
      <q-td
        :props="props"
        auto-width
        class="text-bold"
        :class="props.row.disponiveis == 0 ? 'text-red' : 'text-green'"
      >
        <span>{{ props.row.disponiveis }}</span>
      </q-td>
    </template>

    <template v-slot:body-cell-actionsActualize="props">
      <q-td auto-width>
        <q-btn
          :disable="props.row.permitidos == props.row.temporarios"
          color="green"
          size="sm"
          @click.prevent="atualizarLicencas({ ...props.row }, 'efetivar')"
          :label="$t('message.effect')"
          class="q-my-xs q-py-xs"
          readOnly
        />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import api from "Api";
import { mapActions, mapGetters } from "vuex";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "ListaSetoresUsuariosSGT",
  emits: ["abrirAba"],

  data() {
    return {
      loader: true,
      filter: null,
      acao: "todos",
      isFullTableMode: false,
      rows: [],
      columns: [
        {
          label: this.$t("message.company"),
          field: "dsEmpresa",
          name: "dsEmpresa",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.sector"),
          field: "descricaoSetor",
          name: "descricaoSetor",
          align: "left",
          sortable: true,
          style: "background-color: #eceff1 !important; font-weight: 500",
        },
        {
          label: this.$t("message.collaboratorsNumber"),
          field: "colaboradores",
          name: "colaboradores",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.numberOfVips"),
          field: "vips",
          name: "vips",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.currentAccess"),
          field: "acessosAtuais",
          name: "acessosAtuais",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.allowedAccess"),
          field: "permitidos",
          name: "permitidos",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.temporaryAccess"),
          field: "temporarios",
          name: "temporarios",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.available"),
          field: "disponiveis",
          name: "disponiveis",
          align: "right",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
        },
        {
          label: "",
          field: "actionsConfirm",
          name: "actionsConfirm",
          align: "center",
        },
        {
          label: "",
          field: "actionsActualize",
          name: "actionsActualize",
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("ti", ["getFiltroEmpresaID"]),
  },
  watch: {
    acao() {
      this.aplicarFiltros();
    },
    getFiltroEmpresaID(value) {
      this.empresaId = value;
      this.aplicarFiltros();
    },
  },
  methods: {
    ...mapActions("ti", ["setAtualizar"]),
    ...mapActions("common", ["setLoader"]),
    ...mapActions("ti", ["setDadosUsuariosSGT"]),

    listarAcessos() {
      this.loader = true;
      api
        .get("/v1/licencas")
        .then((res) => {
          this.rows = res.data;
          this.dadosOri = res.data.map((elem) => {
            elem.exibir = false;
            return elem;
          });
          this.setDadosUsuariosSGT(this.dadosOri);
          this.aplicarFiltros();
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.setLoader(false);
          this.loader = false;
        });
    },

    atualizarLicencas(linha, acao) {
      let rota;
      let payload;

      if (acao == "atualizarSetor") {
        rota = `/v1/licencas/setor/${linha.cdSetor}/vip`;
        payload = {
          quantidadeDeVips: linha.vips,
          empresa: linha.empresa,
        };
      } else {
        rota = "/v1/licencas";
        payload = {
          empresa: linha.empresa,
          setor: linha.cdSetor,
          temporario: linha.temporarios,
          acao: acao,
        };
      }

      api
        .patch(rota, { payload })
        .then(() => showSuccess(this.$t("message.successUpdatedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarAcessos());
    },

    reload() {
      this.setAtualizar();
      this.listarAcessos();
    },

    escolherSetor(setor) {
      this.$emit("abrirAba", setor);
    },

    async filtrarSituacao() {
      let dados = this.dadosOri;
      if (this.acao === "disponivel") {
        dados = await dados.filter((v) => {
          return v.disponiveis > 0;
        });
      }
      if (this.acao === "indisponivel") {
        dados = await dados.filter((v) => {
          return v.disponiveis == 0;
        });
      }
      return dados;
    },
    filtrarEmpresa(dados) {
      this.rows = dados.filter((v) => {
        return v.empresa == this.empresaId || this.empresaId == 999;
      });
    },

    aplicarFiltros() {
      if (this.dadosOri) {
        this.filtrarSituacao().then((res) => {
          this.filtrarEmpresa(res);
        });
      }
    },
  },
  mounted() {
    this.listarAcessos();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 430px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
