<template>
  <div>
    <page-title-bar></page-title-bar>
    <div class="r7-container-header" v-if="show">
      <div class="row">
        <div class="col q-pl-md">
          <transition name="flip">
            <q-card
              style="max-height: 400px; height: 400px"
              :key="troca"
              class="q-pt-xm q-my-xs"
            >
              <ResumoDadosCliente
                v-if="!flipped && !flipped2 && !flipped3"
                :key="componentKeyCliente"
                :idCliente="clienteStorage.codigoCliente"
                @ativaDesativaGrupoEconomico="
                  ativaDesativaGrupoEconomico($event)
                "
              >
                <template v-slot:icone>
                  <q-btn
                    flat
                    round
                    dense
                    color="primary"
                    size="md"
                    icon="mdi-account-search"
                    @click="abrirPesquisa"
                  >
                    <q-tooltip
                      anchor="center left"
                      self="center right"
                      :offset="[10, 10]"
                      class="text-body2"
                      >{{ $t(`message.search`) }}</q-tooltip
                    >
                  </q-btn>

                  <q-btn
                    flat
                    round
                    dense
                    size="md"
                    icon="mdi-dots-vertical"
                    color="primary"
                  >
                    <q-tooltip
                      anchor="center left"
                      self="center right"
                      :offset="[10, 10]"
                      class="text-body2"
                      >{{ $t(`message.moreOptions`) }}</q-tooltip
                    >
                    <q-menu>
                      <q-list style="min-width: 100px">
                        <q-item clickable @click="trocarCartao" v-close-popup>
                          <q-item-section>{{
                            $t(`message.contacts`)
                          }}</q-item-section>
                        </q-item>

                        <q-item clickable @click="perfilCliente" v-close-popup>
                          <q-item-section>{{
                            $t(`message.customerProfile`)
                          }}</q-item-section>
                        </q-item>

                        <q-item
                          clickable
                          @click="listarClientesDoGrupoEconomico"
                          v-close-popup
                        >
                          <q-item-section>{{
                            $t(`message.economicGroup`)
                          }}</q-item-section>
                        </q-item>

                        <q-item
                          clickable
                          @click="dadosFinanceirosCliente"
                          v-if="getHighLevel"
                          v-close-popup
                        >
                          <q-item-section>{{
                            $t(`message.financialInformation`)
                          }}</q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </template>
              </ResumoDadosCliente>

              <ClienteGrupoEconomico
                v-if="flipped2"
                :idCliente="clienteStorage.codigoCliente"
                :nome="clienteStorage.nomeCliente"
                :showHeader="true"
              >
                <template v-slot:nome>
                  {{ clienteStorage.nomeCliente }}
                </template>

                <template v-slot:icone>
                  <q-btn
                    flat
                    round
                    dense
                    color="primary"
                    size="md"
                    class="q-ml-xs"
                    icon="mdi-arrow-u-left-top"
                    @click="listarClientesDoGrupoEconomico"
                  />
                </template>
              </ClienteGrupoEconomico>

              <ClienteContato
                v-if="flipped"
                :key="componentKeyClienteContato"
                :idCliente="clienteStorage.codigoCliente"
              >
                <template v-slot:nome>
                  {{ clienteStorage.nomeCliente }}
                </template>
                <template v-slot:icone>
                  <q-btn
                    flat
                    round
                    dense
                    color="primary"
                    size="md"
                    class="q-ml-xs"
                    icon="mdi-arrow-u-left-top"
                    @click="trocarCartao"
                  />
                </template>
              </ClienteContato>

              <keep-alive>
                <ClienteDadosFinanceiros
                  v-if="getHighLevel && flipped3"
                  :key="componentKeyClienteDadosFinanceiros"
                  :idCliente="clienteStorage.codigoCliente"
                >
                  <template v-slot:nome>
                    {{ clienteStorage.nomeCliente }}
                  </template>
                  <template v-slot:icone>
                    <q-btn
                      flat
                      round
                      dense
                      color="primary"
                      size="md"
                      class="q-ml-xs"
                      icon="mdi-arrow-u-left-top"
                      @click="dadosFinanceirosCliente"
                    />
                  </template>
                </ClienteDadosFinanceiros>
              </keep-alive>
            </q-card>
          </transition>
        </div>

        <div class="col q-px-md q-pt-xs">
          <q-card style="max-height: 400px" v-if="show">
            <ResumoSituacaoPedidos
              :key="componentKeyResumoSituacaoPedidos"
              :idCliente="clienteStorage.codigoCliente"
              @listaPedidos="listarPedidos($event)"
              @limparListaPedidos="limparListaPedidos()"
            ></ResumoSituacaoPedidos>
          </q-card>
        </div>
      </div>

      <q-card class="q-ma-md" v-if="show">
        <q-tabs
          icons-and-text
          flat
          v-model="tab"
          dense
          class="text-grey-darken-3 bg-grey-2"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
          style="height: 60px"
        >
          <q-tab name="tab-1">
            <q-icon class="material-icons q-mb-sm" size="sm">list_alt </q-icon>
            {{ $t("message.ordersList") }}
          </q-tab>

          <q-tab name="tab-2">
            <q-icon class="material-icons q-mb-sm" size="sm">block </q-icon>
            {{ $t("message.blockedPreInvoices") }}
          </q-tab>

          <q-tab name="tab-3" v-if="getHighLevel">
            <q-icon class="material-icons q-mb-sm" size="sm">file_open </q-icon>
            {{ $t("message.openDuplicates") }}
          </q-tab>
        </q-tabs>

        <q-tab-panels v-model="tab" animated keep-alive>
          <q-tab-panel name="tab-1">
            <ListaPedidosSituacao
              ref="listaPedidosSituacao"
              :key="componentKeyListaPedidosSituacao"
              :idCliente="clienteStorage.codigoCliente"
            />
          </q-tab-panel>

          <q-tab-panel name="tab-2">
            <ListaPreNotasBloqueadas
              ref="listaPreNotasBloqueadas"
              :key="componentKeyListaPreNotasBloqueadas"
              :idCliente="clienteStorage.codigoCliente"
            />
          </q-tab-panel>

          <q-tab-panel name="tab-3" v-if="getHighLevel">
            <ListaDuplicatasEmAberto
              ref="listaDuplicatasEmAberto"
              :key="componentKeyListaDuplicatasEmAberto"
              :idCliente="clienteStorage.codigoCliente"
            />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>

    <q-dialog v-model="dialog" class="q-pa-md">
      <cliente-lista
        :popUp="true"
        ref="listaCliente"
        @clienteSelecionado="fecharPesquisa()"
        class="r7-dialog-medium"
      >
      </cliente-lista>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ref } from "vue";
import ResumoDadosCliente from "Components/Areas/Clientes/ResumoDadosCliente";
import ClienteContato from "Components/Areas/Clientes/ClienteContato";
import ClienteGrupoEconomico from "Components/Areas/Clientes/ClienteGrupoEconomico";
import ResumoSituacaoPedidos from "Components/Areas/Comercial/ResumoSituacaoPedidos";
import ListaPedidosSituacao from "Components/Areas/Comercial/ListaPedidosSituacao";
import ListaPreNotasBloqueadas from "Components/Areas/Faturamento/ListaPreNotasBloqueadas";
import ListaDuplicatasEmAberto from "Components/Areas/Financeiro/ListaDuplicatasEmAberto";
import ClienteLista from "Views/Clientes/ClienteLista";
import ClienteDadosFinanceiros from "Components/Areas/Clientes/ClienteDadosFinanceiros";

export default {
  name: "ClienteResumo",
  components: {
    ResumoDadosCliente,
    ClienteContato,
    ClienteGrupoEconomico,
    ClienteLista,
    ClienteDadosFinanceiros,
    ResumoSituacaoPedidos,
    ListaPedidosSituacao,
    ListaPreNotasBloqueadas,
    ListaDuplicatasEmAberto,
  },
  data() {
    return {
      dialog: false,
      show1: false,
      show: false,
      cliente: {},
      clienteStorage: {},
      componentKeyResumoSituacaoPedidos: 1000,
      componentKeyCliente: 2000,
      componentKeyClienteContato: 3000,
      componentKeyListaPedidosSituacao: 4000,
      componentKeyListaPreNotasBloqueadas: 5000,
      componentKeyClienteGrupoEconomico: 6000,
      componentKeyClienteDadosFinanceiros: 7000,
      componentKeyListaDuplicatasEmAberto: 8000,

      filter: {},
      flipped: false,
      flipped2: false,
      flipped3: false,
      troca: false,
      situacaoPedido: null,
      grupoEconomico: null,
      tab: ref("tab-1"),
    };
  },
  computed: {
    ...mapGetters("admin", ["getHighLevel"]),
  },
  methods: {
    ...mapGetters("clientes", ["getDadosDoCliente"]),
    abrirPesquisa() {
      this.dialog = true;
    },
    fecharPesquisa() {
      this.dialog = false;
      this.montarTodos();
    },

    montarTodos() {
      this.clienteStorage = this.getDadosDoCliente();
      if (!this.clienteStorage) {
        this.dialog = true;
        return;
      }
      this.show = true;

      if (this.cliente !== this.clienteStorage) {
        this.cliente = this.clienteStorage;
        this.componentKeyCliente += 1;
        this.componentKeyClienteContato += 1;
        this.componentKeyClienteGrupoEconomico += 1;
        this.componentKeyClienteDadosFinanceiros += 1;
        this.montarResumos();
      }
    },

    montarResumos() {
      this.componentKeyResumoSituacaoPedidos += 1;
      this.componentKeyListaPedidosSituacao += 1;
      this.componentKeyListaPreNotasBloqueadas += 1;
      this.componentKeyListaDuplicatasEmAberto += 1;
    },

    trocarCartao() {
      this.flipped = !this.flipped;
      this.troca = !this.troca;
    },
    perfilCliente() {
      this.$router.push({ name: "clienteperfil" });
    },
    listarClientesDoGrupoEconomico() {
      this.flipped2 = !this.flipped2;
      this.troca = !this.troca;
    },
    dadosFinanceirosCliente() {
      this.flipped3 = !this.flipped3;
      this.troca = !this.troca;
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.montarTodos();
    });
  },
};
</script>

<style scoped>
.flip-enter-active {
  transition: all 0.5s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 10;
}
</style>
