<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>

    <SelectWithFilter
      v-if="!loader"
      :id="nivelAcessoId"
      :filled="filled"
      :dense="dense"
      :list="listaNivelAcesso"
      :required="required"
      :disabled="disabled"
      :prependIcon="prependIcon"
      :label="$t('message.accessLevel')"
      :rounded="rounded"
      :clearable="clearable"
      @selected="itemSelected($event)"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "NivelAcessoSelecao",
  components: { SelectWithFilter },

  props: {
    nivelAcessoId: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: true,
      listaNivelAcesso: null,
    };
  },
  watch: {
    nivelAcessoId() {
      this.id = this.nivelAcessoId;
    },
  },
  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("v1/nivelacesso")
        .then((res) => {
          if (res.data) {
            this.listaNivelAcesso = res.data.map((element) => {
              return {
                id: element.id,
                description: element.descricao,
              };
            });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
