<template>
  <div>
    <page-title-bar></page-title-bar>
    <div class="q-pa-md">
      <ListarGruposReferenciasComponent />
    </div>
  </div>
</template>
<script>
import ListarGruposReferenciasComponent from "Components/Areas/Comercial/GruposReferencias/ListarGruposReferenciasComponent";
export default {
  components: { ListarGruposReferenciasComponent },
  name: "ListarGruposReferenciasView",
};
</script>
