<template>
  <app-section-loader :status="loader"></app-section-loader>
  <q-card>
    <q-toolbar>
      <q-toolbar-title>
        <span class="text-weight-bold">{{
          $t("message.customerList")
        }}</span></q-toolbar-title
      >
      <q-btn flat round dense icon="close" v-close-popup />
    </q-toolbar>

    <q-card-section>
      <q-table
        :rows="rows"
        :columns="columns"
        table-header-class="table-header"
        :filter="filter"
        class="r7-cursor-pointer"
        @row-click="selecionarCliente"
        :noDataLabel="$t('message.noDataFound')"
      >
        <template v-slot:top-right>
          <q-input
            ref="filter"
            borderless
            dense
            debounce="300"
            v-model="filter"
            :placeholder="$t('message.search')"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ListaClienteParaUsuario",
  data() {
    return {
      loader: true,
      filter: null,
      rows: [],
      columns: [
        {
          label: "Id",
          name: "idpessoafj",
          field: "idpessoafj",
          sortable: true,
        },
        {
          label: this.$t("message.name"),
          field: "nome",
          name: "nome",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.status"),
          field: "situacao",
          name: "situacao",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    retrieveList() {
      api
        .get("v1/clientes/listarclienteparausuario")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => {
          this.$refs.filter.focus();

          this.loader = false;
        });
    },
    selecionarCliente(evt, row) {
      this.$emit("clienteSelecionado", { ...row });
    },
  },
  mounted() {
    this.retrieveList();
  },
};
</script>
