<template>
  <div class="q-pa-md">
    <app-section-loader :status="loader" />
    <q-toolbar>
      <q-toolbar-title>
        <slot name="nome"></slot>
      </q-toolbar-title>
      <slot name="icone"></slot>
    </q-toolbar>

    <q-table
      :rows="dados"
      :columns="colunas"
      :filter="filter"
      dense
      hide-pagination
      virtual-scroll
      @row-click="selecionar"
      class="r7-cursor-pointer q-mt-md my-sticky-header-table"
      table-header-class="table-header"
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
    >
      <template v-slot:top-right>
        <q-input
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
    </q-table>
  </div>
</template>

<script>
import api from "Api";
import { mapActions } from "vuex";
import { showSuccess } from "@/notifications/notify";

export default {
  name: "ListaClientesDoRepresentante",
  props: {
    idRepresentante: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loader: true,
      filter: null,
      initialPagination: {
        page: 1,
        rowsPerPage: 10,
      },
      dados: [],
      colunas: [
        {
          label: "Id",
          field: "codigoCliente",
          name: "codigoCliente",
          sortable: true,
          align: "right",
        },
        {
          label: this.$t("message.name"),
          field: "nomeCliente",
          name: "nomeCliente",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.city"),
          field: "cidade",
          name: "cidade",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.state"),
          field: "cdUfBi",
          name: "cdUfBi",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.status"),
          field: "situacao",
          name: "situacao",
          sortable: true,
          align: "left",
        },
      ],
    };
  },
  methods: {
    ...mapActions("clientes", ["setDadosDoCliente"]),

    obterResultado() {
      this.loader = true;
      const pesquisa = {
        idRepresentante: this.idRepresentante,
      };

      api
        .post("/v1/clientes/listarouobterclientes", {
          pesquisa,
        })
        .then((res) => (this.dados = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    selecionar(evt, item) {
      this.setDadosDoCliente(item);
      showSuccess(this.$t("message.customerSelected"));
    },
  },
  mounted() {
    this.obterResultado();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 300px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
