<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        dense
        hide-pagination
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
        virtual-scroll
      >
        <template v-slot:top-right>
          <NivelAcessoSelecao
            :rounded="false"
            :filled="true"
            :dense="true"
            :clearable="true"
            class="q-mx-md"
            @selected="filtrarNivelAcesso($event)"
            :key="componentKeyNivelAcessoSelecao"
          />
          <div class="r7-switch-toggle">
            <input
              id="on"
              name="state-d"
              type="radio"
              checked="checked"
              @click="filtrarSituacao('ativo')"
            />
            <label for="on">{{ $t("message.active") }}</label>

            <input
              id="na"
              name="state-d"
              type="radio"
              @click="filtrarSituacao('inativo')"
            />
            <label for="na">{{ $t("message.inactive") }}</label>
            <input
              id="off"
              name="state-d"
              type="radio"
              @click="filtrarSituacao('todos')"
            />
            <label for="off">{{ $t("message.all") }}</label>
          </div>

          <q-input
            class="q-ml-sm"
            borderless
            dense
            debounce="300"
            v-model="filter"
            :placeholder="$t('message.search')"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <exportar-excel
            v-if="rows"
            :data="rows"
            name="listadeusuarios"
            class="q-mx-sm"
          />
          <q-btn flat round size="small" @click.stop="reload()">
            <q-tooltip
              anchor="bottom middle"
              self="center middle"
              :offset="[10, 10]"
              class="text-body2"
              >{{ $t("message.reload") }}</q-tooltip
            >
            <q-icon class="material-icons text-grey-9"> refresh </q-icon>
          </q-btn>
        </template>

        <template v-slot:top-left>
          <q-btn dark size="md" class="q-mb-xm" color="primary" @click="onAdd">
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:body-cell-actionsEdit="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="onUpdate(props.row.id)"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>
        <template v-slot:body-cell-actionsDelete="props">
          <q-td auto-width>
            <q-btn flat dense round small>
              <q-icon
                size="xs"
                color="red darken-2"
                class="material-icons"
                @click="onDelete(props.row.id)"
              >
                delete
              </q-icon>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-highlevel="props">
          <q-td auto-width>
            <q-chip text-color="white" :color="getColor(props.row.highlevel)">
              {{ props.row.highlevel === 1 ? "Sim" : "Não" }}
            </q-chip>
          </q-td>
        </template>

        <template v-slot:body-cell-ativo="props">
          <q-td auto-width>
            <q-chip text-color="white" :color="getColor(props.row.ativo)">
              {{ props.row.ativo === 1 ? "Sim" : "Não" }}
            </q-chip>
          </q-td>
        </template>
      </q-table>
      <delete-confirmation-dialog
        ref="deleteConfirmationDialog"
        @onConfirm="deleteItem"
      ></delete-confirmation-dialog>
    </div>
  </div>
</template>

<script>
import api from "Api";
import NivelAcessoSelecao from "Components/Areas/Admin/NiveisAcesso/NivelAcessoSelecao";
import { chaveAleatoriaComponente } from "Helpers";

import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "UsuarioLista",
  components: { NivelAcessoSelecao },
  data() {
    return {
      loader: true,
      filter: null,
      dadosOri: [],

      filterData: {
        acao: 1,
        nivelAcessoItem: null,
      },

      componentKeyNivelAcessoSelecao: chaveAleatoriaComponente(),

      selectItemToDelete: 0,
      rows: [],
      columns: [
        {
          label: this.$t("message.name"),
          field: "nome",
          name: "nome",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.email"),
          field: "email",
          name: "email",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.user"),
          field: "usuario",
          name: "usuario",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.officeRole"),
          field: "cargo",
          name: "cargo",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.accessLevel"),
          field: "nivelAcesso",
          name: "nivelAcesso",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.active"),
          field: "ativo",
          name: "ativo",
          sortable: true,
          align: "center",
        },
        {
          label: "High Level",
          field: "highlevel",
          name: "highlevel",
          sortable: true,
          align: "left",
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          sortable: false,
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  watch: {
    filterData: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    getColor(p) {
      return p === 1 ? "green" : "red";
    },

    retrieveList() {
      this.loader = true;
      api
        .get("v1/usuarios")
        .then((res) => {
          this.dadosOri = res.data;
          this.filtrar();
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onUpdate(id) {
      this.$router.push({
        name: "usuariodetalhe",
        params: { id },
      });
    },
    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectItemToDelete = id;
    },

    onAdd() {
      this.$router.push({
        name: "usuariodetalhe",
        params: { id: 0 },
      });
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      this.loader = true;
      await api
        .delete(`v1/usuarios/${this.selectItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.retrieveList();
        });
    },

    filtrar() {
      let dados = this.dadosOri;

      if (this.filterData.acao != null) {
        dados = dados.filter((v) => v.ativo == this.filterData.acao);
      }

      if (this.filterData.nivelAcessoItem) {
        dados = dados.filter(
          (v) => v.nivelAcesso == this.filterData.nivelAcessoItem.description
        );
      }

      this.rows = dados;
    },

    filtrarSituacao(acao) {
      this.filterData.acao = null;

      if (acao == "ativo") {
        this.filterData.acao = 1;
      }
      if (acao == "inativo") {
        this.filterData.acao = 0;
      }
    },

    filtrarNivelAcesso(item) {
      this.filterData.nivelAcessoItem = item;
    },

    reload() {
      this.retrieveList();
      this.componentKeyNivelAcessoSelecao += chaveAleatoriaComponente();
    },
  },
  created() {
    this.retrieveList();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
