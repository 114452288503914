import FaturamentovsOrcamento from "Views/Faturamento/FaturamentovsOrcamento";
import FaturamentoFranquiasView from "Views/Faturamento/FaturamentoFranquiasView";
import FaturamentoMesAnoFranquiasView from "Views/Faturamento/FaturamentoMesAnoFranquiasView";
import PerformanceVarejo from "Views/Faturamento/PerformanceVarejoView";
import ListaFranqueadosView from "Views/Faturamento/ListaFranqueadosView";

const routes = [
  {
    path: "/faturamentovsorcamento",
    name: "faturamentovsorcamento",
    component: FaturamentovsOrcamento,
    meta: {
      requiresAuth: true,
      title: "message.billingvsBudget",
      breadcrumb: ["message.billing"],
    },
  },
  {
    path: "/faturamentofranquias",
    name: "faturamentofranquias",
    component: FaturamentoFranquiasView,
    meta: {
      requiresAuth: true,
      title: "message.franchiseRevenue",
      breadcrumb: ["message.franchiseRevenue"],
    },
  },
  {
    path: "/faturamentomesanofranquias",
    name: "faturamentomesanofranquias",
    component: FaturamentoMesAnoFranquiasView,
    meta: {
      requiresAuth: true,
      title: "message.franchiseRevenue",
      breadcrumb: ["message.franchiseRevenue"],
    },
  },
  {
    path: "/performancevarejo",
    name: "performancevarejo",
    component: PerformanceVarejo,
    meta: {
      requiresAuth: true,
      title: "message.performanceRetail",
      breadcrumb: ["message.performanceRetail"],
    },
  },
  {
    path: "/listafranqueados/:segmento?",
    name: "listafranqueados",
    component: ListaFranqueadosView,
    meta: {
      requiresAuth: true,
      title: "message.franchise",
      breadcrumb: ["message.franchise"],
    },
  },
];

export default routes;
