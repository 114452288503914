<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>

    <SelectWithFilter
      v-if="!loader"
      :dense="true"
      :rounded="true"
      :list="listaTabelas"
      :label="label"
      @selected="itemSelected($event)"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "ListaTabelasDePrecos",
  components: { SelectWithFilter },

  props: {
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loader: true,
      listaTabelas: null,
    };
  },
  methods: {
    itemSelected(item) {
      item.type = this.type;
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("v1/comercial/tabelapreco")
        .then((res) => {
          if (res.data) {
            this.listaTabelas = res.data
              .filter((f) => f.tipo == this.type)
              .map((element) => {
                return {
                  id: element.tabelapreco,
                  description: element.nome,
                  data: element,
                };
              });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
