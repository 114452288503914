<template>
  <page-title-bar></page-title-bar>

  <div class="q-pa-md">
    <CandidatosQADetailComponent />
  </div>
</template>

<script>
import CandidatosQADetailComponent from "Components/Areas/RH/QA/CandidatosQADetailComponent";

export default {
  name: "CandidatosQADetailView",
  components: { CandidatosQADetailComponent },
  data() {
    return {};
  },
};
</script>
