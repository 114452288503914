<template>
  <div>
    <page-title-bar></page-title-bar>
    <ListaGruposCompras></ListaGruposCompras>
  </div>
</template>

<script>
import ListaGruposCompras from "Components/Areas/Compras/GruposCompras/ListaGruposCompras";
export default {
  name: "GrupoComprasView",
  components: { ListaGruposCompras },
  data() {
    return {};
  },
  methods: {},
};
</script>
