// nprogress
import "nprogress/nprogress.css";

// animate css
import "../assets/animate.css";

// Global Scss File
import "../assets/scss/_style.scss";

// notifications
import "vue-toastification/dist/index.css";

//Loading
import "vue-loading-overlay/dist/css/index.css";

//Vue Tour
import "v3-tour/dist/vue-tour.css";
