<template>
  <div>
    <page-title-bar></page-title-bar>
    <div class="q-pa-md">
      <FaturamentoMesAnoFranquiasComponent />
    </div>
  </div>
</template>
<script>
import FaturamentoMesAnoFranquiasComponent from "Areas/Faturamento/Franquias/FaturamentoMesAnoFranquiasComponent";
export default {
  name: "FaturamentoMesAnoFranquiasView",
  components: { FaturamentoMesAnoFranquiasComponent },
  data() {
    return {};
  },
};
</script>
