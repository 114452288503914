<template>
  <page-title-bar></page-title-bar>
  <app-section-loader :status="loader"></app-section-loader>
  <div class="q-pa-md">
    <ListaDocumentoConsulta />
  </div>
</template>

<script>
import ListaDocumentoConsulta from "Components/Areas/Repositorio/ListaDocumentoConsulta";
export default {
  name: "ConsultaDeDocumentos",
  components: { ListaDocumentoConsulta },

  data() {
    return {
      loader: false,
    };
  },
};
</script>
