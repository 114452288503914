import Login from "Views/Session/Login";
import LoginQa from "Views/Session/LoginQa";
import ConfirmPassword from "Views/Session/ConfirmPassword";
import Logout from "Views/Session/Logout";

const routes = [
  {
    path: "/session/login",
    name: "login",
    component: Login,
    meta: {
      title: "message.login",
      breadcrumb: null,
    },
  },
  {
    path: "/session/login/qa",
    name: "loginqa",
    component: LoginQa,
    meta: {
      title: "message.login",
      breadcrumb: null,
      qa: true,
    },
  },
  {
    path: "/session/confirm-password/:token",
    name: "confirmpassword",
    component: ConfirmPassword,
    meta: {
      title: "message.confirmPassword",
      breadcrumb: null,
    },
  },
  {
    path: "/session/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "message.logout",
      breadcrumb: null,
    },
  },
];

export default routes;
