import { createRouter, createWebHistory } from "vue-router";
import defaultRoutes from "./default";
import sessionRoutes from "./session";
import standAloneRoutes from "./standalone";

const routes = [defaultRoutes, ...sessionRoutes, ...standAloneRoutes];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
