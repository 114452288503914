<template>
  <LoginTemplate
    @inputUsername="username = $event"
    @inputPassword="password = $event"
    @submit="submit()"
  >
    <template v-slot:botao>
      <q-btn style="min-width: 384px" @click="submit" block color="primary"
        >LOGIN</q-btn
      >
    </template>
  </LoginTemplate>
</template>

<script>
import LoginTemplate from "./LoginTemplate.vue";
import api from "Api";
import { userKey } from "@/global";
import { showSelectedSuccess, showError } from "Source/notifications/notify";

export default {
  name: "LoginQa",
  components: { LoginTemplate },
  data() {
    return {
      username: null,
      password: null,
    };
  },

  methods: {
    submit() {
      const user = {
        user: this.username,
        password: this.password,
      };

      api
        .post("/auth/signin/qa", user)
        .then((res) => {
          const userLogged = res.data;
          api.defaults.headers.common.Authorization = `bearer ${userLogged.token}`;
          localStorage.setItem(userKey, JSON.stringify(userLogged));

          this.$router.push({ name: "questionarioqaview" });
          showSelectedSuccess("Usuário Logado com sucesso!");
        })
        .catch((error) => {
          showError(error);
        });
    },
  },
};
</script>
