<template>
  <div>
    <q-dialog v-model="isModalActive" has-modal-card persistent>
      <q-card class="modal-card" :style="style">
        <q-card-section class="bg-primary">
          <p class="text-white h3">
            {{ title }}
          </p>
        </q-card-section>

        <q-card-section vertical>
          <slot name="top"></slot>
        </q-card-section>

        <q-card-section class="modal-card-body">
          <slot name="body"></slot>
        </q-card-section>
        <q-separator />
        <q-card-section class="modal-card-foot">
          <q-btn label="Fechar" @click="fechar()" class="bg-red text-white" />
          <slot name="footer"></slot>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: "ModalCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    style: {
      type: Object,
      default: function () {
        return {
          width: "30rem",
        };
      },
    },
  },
  data() {
    return {
      isModalActive: false,
      highlights: [],
    };
  },

  methods: {
    abrir() {
      this.isModalActive = true;
    },
    fechar() {
      this.isModalActive = false;
    },
  },
};
</script>
