<template>
  <app-section-loader :status="loader" />

  <q-card class="q-pa-md">
    <q-form ref="form" autofocus @submit="onSave">
      <div class="row no-wrap justify-evenly">
        <div class="col-4" cols="12" sm="12" md="4" lg="4">
          <h6 class="text-primary">{{ $t("message.themeInformations") }}:</h6>
          <br />
          <q-input
            class="q-mt-sm"
            ref="descricaoSetor"
            rounded
            outlined
            dense
            v-model="temaData.descricaoTema"
            :label="$t('message.description')"
            required
            :rules="descriptionRules"
          />

          <SetorQASelectionComponent
            :setorId="temaData.idSetor"
            @selected="selectedSetor($event)"
            :required="true"
            :rounded="true"
            :filled="false"
          />

          <br />
          <div class="text-center">
            <q-btn
              color="success"
              class="q-mr-sm q-mt-md"
              type="submit"
              size="md"
              rounded
            >
              {{ $t("message.save") }}
            </q-btn>

            <q-btn
              color="danger"
              class="q-mt-sm q-mt-md"
              size="md"
              type="sair"
              @click.prevent="$router.go(-1)"
              rounded
            >
              {{ $t("message.close") }}
            </q-btn>
          </div>
        </div>
      </div>
    </q-form>
  </q-card>
</template>

<script>
import api from "Api";
import { clearObject } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";
import SetorQASelectionComponent from "Components/Areas/RH/QA/Selecao/SetorQASelectionComponent";

export default {
  name: "TemasQADetailComponent",
  components: { SetorQASelectionComponent },
  data() {
    return {
      loader: true,
      valid: true,
      temaData: {
        idSetor: null,
        descricaoTema: null,
      },

      id: this.$route.params.id,

      descriptionRules: [(v) => !!v || this.$t("message.descriptionRequired")],
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterTema();
    },
  },
  methods: {
    onSave() {
      let message;
      let method;
      let url;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        url = `/v1/rh/qa/tema/${this.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        url = "/v1/rh/qa/tema/";
      }

      this.loader = true;
      const item = this.temaData;

      api[method](url, item)
        .then(() => {
          if (!this.id) {
            clearObject(this.temaData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.$refs.descricaoSetor.focus();
        });
    },
    obterTema() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.loader = false;
        return;
      }
      api
        .get(`/v1/rh/qa/tema/${this.id}`)
        .then((res) => {
          this.temaData = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    selectedSetor(selected) {
      this.temaData.idSetor = selected.id;
    },
  },
  mounted() {
    this.obterTema();
    this.$refs.descricaoSetor.focus();
  },
};
</script>
