export const userKey = "__rosset_user";
export const userKeyQa = "__rosset_user_qa";

export const userConfigKey = "__rosset_config_user";
export const userTourPowerBi = "__rosset_user_tour_powerbi"
export const userImageKey = "__rosset_image_user";

export const userInformation = "__rosset_information";

export const keySecret = "Azjdine==*8123@/";

export const cookieConsent = "__rosset_cookie_consent";

export default { userKey, userConfigKey, userTourPowerBi, keySecret, cookieConsent };
