<template>
  <div>
    <div class="row justify-between">
      <div class="col-3 q-pr-xs">
        <q-card
          class="q-my-md"
          style="height: 164px; min-height: 164px; z-index: 0"
        >
          <q-card-section style="height: 30px">
            <div class="text-subtitle1 text-weight-bold">
              <span>Filtrar Empresa</span>
            </div>
          </q-card-section>
          <q-card-section>
            <CompanyFilterButton
              :nomeEmpresa="empresasArray"
              color="primary"
              :loading="loader"
              :idEmpresaInicial="user.idCompany"
              class="q-mt-lg"
            />
          </q-card-section>
        </q-card>
      </div>
      <div class="col-3 q-px-xs">
        <CartaoLicencas
          :titulo="$t('message.totalOfLicenses')"
          :total="licencasObj.total"
          :usadas="licencasObj.usadas"
          :disponiveis="licencasObj.disponiveis"
          tipoCartao="licencas"
        />
      </div>

      <div class="col-3 q-px-xs">
        <CartaoLicencas
          :titulo="$t('message.reservedLicenses')"
          :total="licencasObj.totalReservadas"
          :usadas="licencasObj.vipUsadas"
          :disponiveis="licencasObj.vipDisponiveis"
          tipoCartao="licencasReservadas"
        />
      </div>

      <div class="col-3 q-pl-xs">
        <CartaoLicencas
          :titulo="$t('message.employeesTotal')"
          :total="licencasObj.totalColaboradores"
          :usadas="licencasObj.totalColaboradoresSemLic"
          tipoCartao="colaboradores"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "Api";
import CompanyFilterButton from "Components/Widgets/CompanyFilterButton";
import CartaoLicencas from "Components/Areas/TI/CartaoLicencas";

import { showError } from "@/notifications/notify";
import { mapGetters } from "vuex";

export default {
  name: "LicencasUsuariosSGT",
  components: { CompanyFilterButton, CartaoLicencas },

  data() {
    return {
      loader: true,
      user: this.$store.getters["auth/getUser"],
      empresaId: null,
      metaDadosOri: {
        total: 0,
        disponiveis: 0,
        totalReservadas: 0,
        usadas: 0,
        vipDisponiveis: 0,
        vipUsadas: 0,
        totalColaboradores: 0,
        totalColaboradoresSemLic: 0,
      },
      dadosOri: new Object(this.metaDadosOri),
      licencasObj: new Object(this.metaDadosOri),
      empresasArray: [
        {
          cdEmpresa: 1,
          label: this.$t("message.rosset"),
        },
        {
          cdEmpresa: 72,
          label: this.$t("message.salete"),
        },
        {
          cdEmpresa: 999,
          label: this.$t("message.all"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("ti", ["getAtualizar"]),
    ...mapGetters("ti", ["getFiltroEmpresaID"]),
  },
  watch: {
    getAtualizar: "obterLicencas",
    getFiltroEmpresaID(value) {
      this.empresaId = value;
      this.tratarDados(value);
    },
  },
  methods: {
    obterLicencas() {
      this.loader = true;
      this.empresaId = this.$store.getters["ti/getFiltroEmpresaID"];
      api
        .get("/v1/licencas/resumo")
        .then((res) => {
          this.dadosOri = res.data;
          this.tratarDados(this.empresaId);
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    async tratarDados(empresa) {
      if (!Array.isArray(this.dadosOri)) {
        return;
      }
      this.licencasObj = await this.dadosOri
        .filter(
          (v) => v.empresa === empresa || empresa === 999 || empresa === null
        )
        .reduce((a, b) => {
          return new Object({
            total: a.total + b.total,
            disponiveis: a.disponiveis + b.disponiveis,
            totalReservadas: a.totalReservadas + b.totalReservadas,
            usadas: a.usadas + b.usadas,
            vipDisponiveis: a.vipDisponiveis + b.vipDisponiveis,
            vipUsadas: a.vipUsadas + b.vipUsadas,
            totalColaboradores: a.totalColaboradores + b.totalColaboradores,
            totalColaboradoresSemLic:
              a.totalColaboradoresSemLic + b.totalColaboradoresSemLic,
          });
        }, this.metaDadosOri);
    },
  },
  mounted() {
    this.obterLicencas();
  },
};
</script>
