<template>
  <q-card class="my-card">
    <q-card-section class="bg-primary text-white">
      <span class="text-h5">{{ $t("message.workplaceRegistrationForm") }}</span>
    </q-card-section>

    <q-form ref="form" @submit="onAdd()" class="q-pa-md q-ml-md">
      <div class="row q-mb-xl q-mt-md">
        <div class="col-6 text-subtitle1 text-weight-medium">
          <p>{{ $t("message.workplaceDescription") }}</p>
        </div>
        <div class="col-4">
          <q-input
            type="text"
            filled
            required
            clearable
            v-model="setorData.descricao"
            :label="$t('message.description')"
            :rules="descricaoRule"
          />
        </div>
      </div>

      <q-separator />
      <q-btn
        color="success"
        type="submit"
        :label="$t('message.add')"
        class="bg-green text-white q-mt-md"
      />
    </q-form>
  </q-card>
</template>

<script>
import { clearObject } from "Helpers";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "SetoresQADetailComponent",
  emits: ["atualizar"],
  data() {
    return {
      setorData: {
        descricao: null,
      },
      descricaoRule: [(v) => !!v || this.$t("message.descriptionRequired")],
    };
  },
  methods: {
    onAdd() {
      api
        .post("v1/rh/qa/setor", this.setorData)
        .then(() => {
          showSuccess(this.$t("message.successAddedRecord"));
          clearObject(this.setorData, this.$refs.form);
          this.$emit("atualizar");
        })
        .catch((err) => showError(err));
    },
  },
};
</script>
