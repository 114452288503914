<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <PerformanceVarejoComponent />
  </div>
</template>
<script>
import PerformanceVarejoComponent from "Areas/Faturamento/Varejo/PerformanceVarejoComponent";

export default {
  name: "PerformanceVarejoView",
  components: { PerformanceVarejoComponent },
};
</script>
