import DepositosAmostraLista from "Views/Produtos/DepositosAmostraLista";
import ProdutosConfeccaoLista from "Views/Produtos/Confeccao/ProdutosConfeccaoListaView";
import ProdutosConfeccaoDetalhe from "Views/Produtos/Confeccao/ProdutosConfeccaoDetalheView";

const routes = [
  {
    path: "/depositosamostralista",
    name: "depositosamostralista",
    component: DepositosAmostraLista,
    meta: {
      requiresAuth: true,
      title: "message.sampleDepositsList",
      breadcrumb: [
        "message.production",
        "message.registers",
        "message.sampleDepositsList",
      ],
    },
  },
  {
    path: "/produtosconfeccaolista",
    name: "produtosconfeccaolista",
    component: ProdutosConfeccaoLista,
    meta: {
      requiresAuth: true,
      title: "message.confectionProductRegistration",
      breadcrumb: [
        "message.comercial",
        "message.products",
        "message.confectionProductRegistration",
      ],
    },
  },
  {
    path: "/produtosconfeccaodetalhe/:id",
    name: "produtosconfeccaodetalhe",
    component: ProdutosConfeccaoDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.confectionProductRegistration",
      breadcrumb: [
        "message.comercial",
        "message.products",
        "message.confectionProductRegistration",
      ],
    },
  },
];

export default routes;
