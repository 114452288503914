/**
 * Helpers Functions
 */
import moment, { months } from "moment";
import messages from "../lang";
import { createI18n } from "vue-i18n";
import store from "../store";
import { userKey, userConfigKey } from "@/global";

const StringMask = require("string-mask");

const i18n = createI18n({
  locale: store.getters.selectedLocale.locale, // set locale
  messages, // set locale messages
});

const $t = i18n.global.t;

export const sizeTotal = (sizeSubtract = 0) => {
  const header = document.getElementsByTagName("header");
  const titleBar = document.getElementsByClassName("titleBar");
  const footer = document.getElementsByTagName("footer");
  let sizeHeader = 0;
  let sizeTitleBar = 0;
  let sizeFooter = 0;
  let padding = 0;
  if (header && header.length > 0) {
    padding = 32;
    sizeHeader = header[0].clientHeight;
  } else {
    sizeHeader = 42; // Tamanho do menu no Gerencial
  }

  if (titleBar && titleBar.length > 0) {
    sizeTitleBar = titleBar[0].clientHeight;
  }

  if (footer && footer.length > 0) {
    sizeFooter = footer[0].clientHeight;
  }
  const sizeTotal =
    window.innerHeight -
    (sizeHeader + sizeTitleBar + sizeFooter + padding) -
    sizeSubtract;

  return sizeTotal;
};

function formatter(obj) {
  const processed = StringMask.process(obj.text, obj.pattern, obj.options);
  return obj.negative ? `-${processed.result}` : processed.result;
}

// Get Date
export function getTheDate(timestamp, format) {
  const time = timestamp * 1000;
  const formatDate = format || "MM-DD-YYYY";
  return moment(time).format(formatDate);
}

// Convert Date To Timestamp
export function convertDateToTimeStamp(date, format) {
  const formatDate = format || "YYYY-MM-DD";
  return moment(date, formatDate).unix();
}

// Aplica mascara nos numeros sem decimais
export function maskNumberInteger(value, decimal) {
  if (value == 0) return 0;
  if (!value) return null;
  if (typeof value === "number") {
    const negative = value < 0;
    const valueParameter = Math.abs(value);
    const p = {
      text: decimal
        ? Math.trunc(valueParameter * 100)
        : Math.ceil(valueParameter),
      pattern: decimal ? "#,##0.00" : "#,##0",
      negative,
      options: { reverse: true },
    };
    return formatter(p);
  }

  if (typeof value === "object") {
    return value.map((num) => {
      const p = {
        text: decimal ? Math.trunc(num * 100) : Math.ceil(num),
        pattern: decimal ? "#,##0.00" : "#,##0",
        options: { reverse: true },
      };
      return formatter(p);
    });
  }

  return null;
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  let len = length;
  let end = ending;
  if (len == null) {
    len = 100;
  }
  if (end == null) {
    end = "...";
  }
  if (str.length > len) {
    return str.substring(0, len - end.length) + end;
  }
  return str;
}

/**
 * Function to return current app layout
 */
export function getCurrentAppLayout(router) {
  const location = router.history.current.fullPath;
  const path = location.split("/");
  return path[1];
}

/**
 * Camel Case
 */
export const camelCase = (str) => {
  const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");

  return str
    ? str
        .toString()
        .toLowerCase()
        //              .replace(/\s+/g, '')
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/[\s\W-]+/g, "") // Replace spaces, non-word characters and dashes with a single dash (-)
    : null;
};

export const chaveAleatoriaComponente = () => {
  return Math.floor(Math.random() * 10000000);
};

/**
 * Function Wait...
 */
export function aguardar(parm, func, interval = 1000) {
  const aguarde = setInterval(() => {
    clearInterval(aguarde);
    func(parm);
  }, interval);
}

export function menuHierarchy(id, menu) {
  const node = menu.filter((f) => f.id === id)[0];
  const parentId = node.parentId;

  const getParent = (parentId, menu, indent) => {
    const found = menu.filter((item) => item.id === parentId);

    if (found.length > 0) {
      indent += 10;
      const node2 = found[0];
      indent = getParent(node2.parentId, menu, indent);
    }

    return indent;
  };

  const indentation = getParent(parentId, menu, 0);

  return indentation;
}

export function clearObject(object, form) {
  Object.keys(object).forEach((k) => (object[k] = null));
  if (form) {
    form.reset();
  }
}

export function findAsync(array, fnFind) {
  return new Promise((resolve, reject) => {
    try {
      const result = array.find(fnFind);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

export function convertToFloat(value) {
  return !value
    ? 0
    : parseFloat(
        value
          .toString()
          .replace(/[^\d.,]/g, "")
          .replaceAll(".", "")
          .replaceAll(",", ".")
      );
}

export function convertToFloat2(value) {
  return !value
    ? 0
    : value
        .toString()
        .replace(/[^\d.,]/g, "")
        .replaceAll(".", "")
        .replaceAll(",", ".");
}

export const arrayMonths = {
  months: [
    {
      id: 1,
      description: $t("message.january"),
      abrev: $t("message.january").substring(0, 3),
    },
    {
      id: 2,
      description: $t("message.february"),
      abrev: $t("message.february").substring(0, 3),
    },
    {
      id: 3,
      description: $t("message.march"),
      abrev: $t("message.march").substring(0, 3),
    },
    {
      id: 4,
      description: $t("message.april"),
      abrev: $t("message.april").substring(0, 3),
    },
    {
      id: 5,
      description: $t("message.may"),
      abrev: $t("message.may").substring(0, 3),
    },
    {
      id: 6,
      description: $t("message.june"),
      abrev: $t("message.june").substring(0, 3),
    },
    {
      id: 7,
      description: $t("message.july"),
      abrev: $t("message.july").substring(0, 3),
    },
    {
      id: 8,
      description: $t("message.august"),
      abrev: $t("message.august").substring(0, 3),
    },
    {
      id: 9,
      description: $t("message.september"),
      abrev: $t("message.september").substring(0, 3),
    },
    {
      id: 10,
      description: $t("message.october"),
      abrev: $t("message.october").substring(0, 3),
    },
    {
      id: 11,
      description: $t("message.november"),
      abrev: $t("message.november").substring(0, 3),
    },
    {
      id: 12,
      description: $t("message.december"),
      abrev: $t("message.december").substring(0, 3),
    },
  ],
  getMonth: function (month) {
    return this.months.find((v) => v.id == month);
  },
};

export function deepEqual(object1, object2) {
  if (!isObject(object1) || !isObject(object2)) return false;
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export function extrairInfoCandidato() {
  const json = localStorage.getItem(userKey);
  return JSON.parse(json);
}

export function randomPassword() {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789&+/!#";

  let password = "";
  for (let i = 0; i < 15; i++) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return password;
}

export function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function removeAccents(str) {
  const accentsMap = {
    á: "a",
    à: "a",
    ã: "a",
    â: "a",
    ä: "a",
    é: "e",
    è: "e",
    ẽ: "e",
    ê: "e",
    ë: "e",
    í: "i",
    ì: "i",
    ĩ: "i",
    î: "i",
    ï: "i",
    ó: "o",
    ò: "o",
    õ: "o",
    ô: "o",
    ö: "o",
    ú: "u",
    ù: "u",
    ũ: "u",
    û: "u",
    ü: "u",
    ç: "c",
    Á: "A",
    À: "A",
    Ã: "A",
    Â: "A",
    Ä: "A",
    É: "E",
    È: "E",
    Ẽ: "E",
    Ê: "E",
    Ë: "E",
    Í: "I",
    Ì: "I",
    Ĩ: "I",
    Î: "I",
    Ï: "I",
    Ó: "O",
    Ò: "O",
    Õ: "O",
    Ô: "O",
    Ö: "O",
    Ú: "U",
    Ù: "U",
    Ũ: "U",
    Û: "U",
    Ü: "U",
    Ç: "C",
  };

  return str.replace(
    /[áàãâäéèẽêëíìĩîïóòõôöúùũûüçÁÀÃÂÄÉÈẼÊËÍÌĨÎÏÓÒÕÔÖÚÙŨÛÜÇ]/g,
    (match) => accentsMap[match]
  );
}

export function deleteCookie(cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
