<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <FaturamentoFranquiasComponent />
  </div>
</template>

<script>
import FaturamentoFranquiasComponent from "Areas/Faturamento/Franquias/FaturamentoComponent";

export default {
  name: "FaturamentoFranquiasView",
  components: {
    FaturamentoFranquiasComponent,
  },
};
</script>
