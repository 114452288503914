<template>
  <app-section-loader :status="loader"></app-section-loader>
  <ModalCard
    ref="modalListaProdutosConfeccao"
    title="Lista de Produtos Confecção"
    :style="style"
  >
    <template v-slot:body>
      <q-table
        :rows="rows"
        :columns="columns"
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        hide-pagination
        @row-click="selecionarLinha"
        class="q-mx-sm q-my-sm my-sticky-header-table"
        :style="styleTable"
        dense
        virtual-scroll
        v-if="rows.length > 0"
      >
      </q-table>
    </template>
  </ModalCard>
</template>

<script>
import api from "Api";
import ModalCard from "Components/Widgets/ModalCard";
import { showError } from "@/notifications/notify";
import { isMobile } from "Helpers";

export default {
  name: "ModalListaProdutosConfeccao",
  components: { ModalCard },
  emits: ["selecionado"],
  props: {
    filtro: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      style: {
        width: "100vw",
        maxWidth: isMobile() ? "100vw" : "45vw",
      },
      styleTable: {
        height: isMobile() ? "45vh" : "35vh",
      },
      loader: false,
      rows: [],
      columns: [
        {
          label: "Série",
          field: "serie",
          name: "serie",
          align: "right",
          sortable: false,
        },
        {
          label: "Descrição",
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    listarProdutos() {
      this.loader = true;

      const aplicarFiltro = (v) => {
        let resultado = false;

        if (!this.filtro || this.filtro == "TecidoRenda") {
          resultado = true;
        } else {
          if (
            (v.ehRenda == 1 && this.filtro == "Renda") ||
            (v.ehRenda == 0 && this.filtro == "Tecido")
          ) {
            resultado = true;
          }
        }

        return resultado;
      };

      api
        .post(`/v1/produtos/confeccao/`)
        .then((res) => {
          this.rows = res.data.filter((f) => aplicarFiltro(f));
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    abrirModal() {
      this.listarProdutos();
      this.$refs.modalListaProdutosConfeccao.abrir();
    },

    selecionarLinha(evt, item) {
      this.$emit("selecionado", item);
      this.$refs.modalListaProdutosConfeccao.fechar();
    },
  },
};
</script>
