<template>
  <div class="row justify-between" v-if="exibir">
    <div class="col-auto q-px-md">
      <q-select
        filled
        required
        clearable
        option-label="label"
        option-value="value"
        v-model="cabecalho.ano"
        :options="opcoesMeta"
        map-options
        emit-value
        :label="$t('message.year')"
        class="q-mr-lg"
        style="width: 10vw"
        :rules="anoRule"
        :disable="disableAno"
        @update:model-value="atualizarAno($event)"
      />
    </div>
    <div class="col-auto">
      <q-input
        type="text"
        filled
        required
        clearable
        v-model="cabecalho.descricao"
        :label="$t('message.goalDescription')"
        :rules="descricaoRule"
        style="width: 30vw"
        class="q-mx-lg"
        :disable="disableDescricao"
      />
    </div>
    <div class="col-auto">
      <q-input
        v-if="mostrarPeriodo"
        type="text"
        disable
        filled
        v-model="cabecalho.periodo"
        :label="$t('message.period')"
        style="width: 10vw"
        class="q-ml-lg"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "Api";
import { showError, showAlert } from "@/notifications/notify";
import { deepEqual } from "Helpers";

export default {
  name: "HeaderMeta",
  data() {
    return {
      disableAno: false,
      disableDescricao: false,
      exibir: false,
      mostrarPeriodo: false,
      revisaoXlsx: false,
      opcoesMeta: [],
      cabecalho: {
        ano: null,
        descricao: null,
        periodo: null,
      },
      anoRule: [(v) => !!v || this.$t("message.yearRequired")],
      descricaoRule: [(v) => !!v || this.$t("message.descriptionRequired")],
    };
  },
  computed: {
    ...mapGetters("metas", [
      "getAcao",
      "getCabecalho",
      "getExibir",
      "getRevisaoXlsx",
    ]),
  },
  watch: {
    getAcao(v) {
      if (v == "novo" && this.cabecalho.ano) {
        this.atualizarAno(this.cabecalho.ano);
      }

      if (v == "deletar") return;
      this.initialize();
      if ((v == "editar" || v == "revisar") && this.revisaoXlsx == false) {
        this.disableAno = true;
        this.mostrarPeriodo = true;

        if (v == "editar") {
          this.disableDescricao = true;
        }
      } else if (this.revisaoXlsx == true) {
        this.mostrarPeriodo = false;
      }
    },
    getExibir(v) {
      this.exibir = v;
    },
    getCabecalho(n, o) {
      if (deepEqual(n, o)) return;
      this.cabecalho = n;
    },
    cabecalho: {
      handler(v) {
        this.setCabecalho(v);
      },
      deep: true,
    },
    getRevisaoXlsx(v) {
      this.revisaoXlsx = v;
    },
  },
  methods: {
    ...mapActions("metas", ["setCabecalho"]),

    initialize() {
      this.mostrarPeriodo = false;
      this.disableAno = false;
      this.disableDescricao = false;
      this.opcoesMeta = [];
      const anoAtual = new Date().getFullYear();
      for (let i = 0; i < 2; i++) {
        const ano = anoAtual + i;
        const obj = {
          label: ano,
          value: ano,
        };
        this.opcoesMeta.push(obj);
      }
    },

    atualizarAno(value) {
      api
        .get(`v1/comercial/metas?ano=${value}`)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            showAlert(this.$t("message.goalAlreadyExistForTheSelectedYear"));
            this.cabecalho.ano = null;
          }
        })
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
