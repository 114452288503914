<template>
  <app-section-loader :status="loader"></app-section-loader>
  <q-table
    ref="myTable"
    :loading="loader"
    :rows="rows"
    :columns="columns"
    :filter="filter"
    dense
    hide-pagination
    table-header-class="table-header"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
    :style="{ height: alturaTabela + 'px' }"
  >
    <template v-slot:top-left>
      <q-btn color="primary" @click="onAdd" class="noPrint">
        {{ $t("message.add") }}
      </q-btn>
    </template>
    <template v-slot:top-right>
      <div class="noPrint">
        <q-btn class="q-mr-xl" color="green darken-2" @click="onSearchByRef">
          Pesquisar Por Referência
        </q-btn>
      </div>
      <q-input
        class="q-ml-xl noPrint"
        borderless
        dense
        debounce="300"
        v-model="filter"
        :placeholder="$t('message.search')"
      >
        <template v-slot:append>
          <q-icon name="search" class="noPrint" />
        </template>
      </q-input>
    </template>
    <template v-slot:body-cell-actionsEdit="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="blue darken-2"
            class="material-icons noPrint"
            @click="onUpdate(props.row)"
          >
            edit
          </q-icon>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:body-cell-actionsDelete="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="red darken-2"
            class="material-icons noPrint"
            @click="onDelete(props.row)"
          >
            delete
          </q-icon>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:body-cell-actionsRelations="props">
      <q-td auto-width>
        <q-btn flat dense round small :disable="disableRelations">
          <q-icon
            size="xs"
            color="green darken-2"
            class="material-icons noPrint"
            @click="onRelations(props.row)"
          >
            call_split
          </q-icon>
        </q-btn>
      </q-td>
    </template>
  </q-table>

  <ModalForm
    ref="modalFormCadastro"
    title="Cadastro de Grupo Referência"
    :customClass="estilo"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit="save()">
        <div class="row q-mb-sm q-mt-sm items-center">
          <div class="col">
            <q-input
              type="text"
              filled
              required
              clearable
              v-model="grupoReferencia.descricao"
              :label="$t('message.description')"
              :rules="descricaoRule"
            />
          </div>
        </div>
        <q-separator />
        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-mt-md"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="close()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
  <RelacionamentoGrupoReferencia
    ref="modalFormRelacionamentos"
    :referencias="referencias"
    :relacionamentos="relacionamentos"
    :grupoReferencia="grupoReferencia"
  />
  <PesquisarGruposAtravesDaReferencia
    ref="modalFormPesquisarGrupos"
    :relacionamentos="relacionamentos"
  />
  <delete-confirmation-dialog
    ref="deleteConfirmationDialog"
    @onConfirm="deleteItem"
  ></delete-confirmation-dialog>
</template>
<script>
import api from "Api";
import { sizeTotal } from "Helpers";
import { showError, showSuccess } from "@/notifications/notify";
import ModalForm from "Components/Widgets/ModalForm";
import RelacionamentoGrupoReferencia from "./RelacionamentoGrupoReferencia";
import PesquisarGruposAtravesDaReferencia from "./PesquisarGruposAtravesDaReferencia";
import { printTableMixin } from "Mixin/printTableMinx";

export default {
  name: "ListarGruposReferenciasComponent",
  components: {
    ModalForm,
    RelacionamentoGrupoReferencia,
    PesquisarGruposAtravesDaReferencia,
  },
  mixins: [printTableMixin],
  data() {
    return {
      loader: true,
      alturaTabela: 0,
      filter: null,
      relacionados: true,
      referencias: null,
      relacionamentos: null,
      disableRelations: true,
      estilo: {
        width: "30vw",
        height: "51vh",
      },
      grupoReferencia: {
        cdGrupo: 0,
        descricao: null,
      },
      descricaoRule: [(v) => !!v || this.$t("message.descriptionRequired")],
      rows: [],
      columns: [
        {
          label: "Código Grupo",
          field: "cdGrupo",
          name: "cdGrupo",
          align: "center",
          sortable: true,
        },
        {
          label: "Descrição",
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
        },
        {
          label: "",
          field: "actionsRelations",
          name: "actionsRelations",
          align: "center",
        },
      ],
    };
  },
  methods: {
    initialize() {
      this.alturaTabela = sizeTotal(); // 36 é o tamanho do componente antes da tabela
      this.listarGruposReferencias();
    },

    async listarRelacionamentos() {
      await api
        .get("/v1/produtos/specs/relacionamento/gruporeferencia")
        .then((res) => (this.relacionamentos = res.data));
    },

    listarReferencias() {
      this.disableRelations = true;
      api
        .get("/v1/produtos/specs/referencias/0")
        .then((res) => {
          this.referencias = res.data.filter(
            (row, index, self) =>
              index === self.findIndex((r) => r.referencia === row.referencia)
          );
        })
        .finally(() => (this.disableRelations = false));
    },

    listarGruposReferencias() {
      api
        .get("/v1/produtos/specs/gruporeferencia")
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },

    onAdd() {
      this.grupoReferencia.cdGrupo = 0;
      this.grupoReferencia.descricao = null;
      this.$refs.modalFormCadastro.abrir();
    },

    onUpdate(row) {
      this.grupoReferencia.cdGrupo = row.cdGrupo;
      this.grupoReferencia.descricao = row.descricao;
      this.$refs.modalFormCadastro.abrir();
    },

    onDelete(row) {
      this.$refs.deleteConfirmationDialog.open();
      this.grupoReferencia.cdGrupo = row.cdGrupo;
    },

    async onRelations(row) {
      await this.listarRelacionamentos();
      this.grupoReferencia.cdGrupo = row.cdGrupo;
      this.grupoReferencia.descricao = row.descricao;
      this.$refs.modalFormRelacionamentos.abrir();
    },

    async onSearchByRef() {
      await this.listarRelacionamentos();
      this.$refs.modalFormPesquisarGrupos.abrir();
    },

    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      await api
        .delete(
          `v1/produtos/specs/gruporeferencia/${this.grupoReferencia.cdGrupo}`
        )
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.initialize());
    },

    close() {
      this.$refs.modalFormCadastro.fechar();
    },

    save() {
      this.loader = true;

      let method, url, message;

      if (this.grupoReferencia.cdGrupo > 0) {
        method = "put";
        url = `/v1/produtos/specs/gruporeferencia/${this.grupoReferencia.cdGrupo}`;
        message = "message.successUpdatedRecord";
      } else {
        method = "post";
        url = `/v1/produtos/specs/gruporeferencia`;
        message = "message.successAddedRecord";
      }

      const data = { descricao: this.grupoReferencia.descricao };

      api[method](url, data)
        .then(() => {
          showSuccess(this.$t(message));
          this.close();
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.initialize();
        });
    },
  },
  mounted() {
    this.initialize();
    this.listarReferencias();
  },
};
</script>
