export default {
  setAcao(context, payload) {
    context.commit("onChangeAcao", payload);
  },
  setCabecalho(context, payload) {
    context.commit("onChangeCabecalho", payload);
  },
  setRevisaoXlsx(context, payload) {
    context.commit("OnChangeRevisaoXlsx", payload);
  },
  setMeta(context, payload) {
    context.commit("onChangeMeta", payload);
  },
  setExibir(context, payload) {
    context.commit("onChangeExibir", payload);
  },
  setDesabilitarMenu(context, payload) {
    context.commit("OnChangeDesabilitarMenu", payload);
  },

  setNullMetas(context) {
    context.commit("onHandlerMeta");
  },
  setEditarMeta(context, payload) {
    context.commit("onChangeEditarMeta", payload);
  },
  setDeletarRegistro(context, payload) {
    context.commit("onChangeDeletarRegistro", payload);
  },
};
