<template>
  <div class="q-pa-md">
    <q-stepper
      v-model="step"
      vertical
      color="primary"
      :keep-alive="true"
      animated
    >
      <!--(1)Tarefa-->
      <q-step
        :name="1"
        title="Qual é a tarefa que deseja realizar?"
        icon="settings"
        :done="step > 1"
      >
        <q-list>
          <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-radio v-model="tarefa" val="copiarArquivo" color="primary"
                >Copiar dados com base em um <strong>ARQUIVO</strong> (DUMP
                BACKUP)</q-radio
              >
            </q-item-section>
          </q-item>
          <!-- <q-item tag="label" v-ripple>
            <q-item-section avatar>
              <q-radio v-model="tarefa" val="copiarBanco" color="primary"
                >Copiar dados com base em um <strong>BANCO</strong></q-radio
              >
            </q-item-section>
          </q-item> -->
        </q-list>

        <q-stepper-navigation>
          <q-btn @click="step = 2" color="primary" label="Continue" />
        </q-stepper-navigation>
      </q-step>

      <!--(2)Selecao da Instancia-->
      <q-step
        :name="2"
        title="Selecione a Instância"
        icon="storage"
        :done="step > 2"
      >
        <q-option-group
          :options="instancias"
          type="radio"
          v-model="instancia"
          color="primary"
        />
        <q-stepper-navigation>
          <q-btn
            @click="
              () => {
                done1 = true;
                step = nextStep;
              }
            "
            color="primary"
            label="Continue"
          />
          <q-btn
            flat
            @click="step = 1"
            color="primary"
            label="Voltar"
            class="q-ml-sm"
          />
        </q-stepper-navigation>
      </q-step>

      <!--(3)Arquivo-->
      <q-step
        :name="3"
        title="Selecione o Arquivo"
        icon="file_upload"
        :done="step > 3"
        :disable="disableStep3"
        @before-enter="listarArquivos"
      >
        <SelectWithFilter
          :list="arquivos"
          :required="true"
          :label="$t('message.chooseFile')"
          :rounded="false"
          :filled="true"
          :dense="true"
          @selected="arquivo = $event"
        />

        <q-stepper-navigation>
          <q-btn @click="step = 5" color="primary" label="Continue" />
          <q-btn
            flat
            @click="step = 2"
            color="primary"
            label="Voltar"
            class="q-ml-sm"
          />
        </q-stepper-navigation>
      </q-step>

      <!--(4)Servidor de Origem-->
      <q-step
        :name="4"
        title="Selecione o servidor de origem"
        icon="dns"
        :done="step > 4"
        :disable="disableStep4"
      >
        <q-option-group
          :options="servidoresOrigem"
          type="radio"
          v-model="servidorOrigem"
          color="primary"
        />

        <q-stepper-navigation>
          <q-btn @click="step = 5" color="primary" label="Continue" />
          <q-btn
            flat
            @click="step = 2"
            color="primary"
            label="Voltar"
            class="q-ml-sm"
          />
        </q-stepper-navigation>
      </q-step>

      <!--(5)Banco de Origem-->
      <q-step
        :name="5"
        title="Selecione o banco de origem"
        icon="output"
        :done="step > 5"
      >
        <SelectWithFilter
          :list="bancosDeOrigem"
          :required="true"
          :label="$t('message.chooseSourceDataBase')"
          :rounded="false"
          :filled="true"
          :dense="true"
          @selected="bancoDeOrigem = $event"
        />
        <q-stepper-navigation>
          <q-btn @click="step = 6" color="primary" label="Continue" />
          <q-btn
            flat
            @click="
              () => {
                done1 = true;
                step = previousStep;
              }
            "
            color="primary"
            label="Voltar"
            class="q-ml-sm"
          />
        </q-stepper-navigation>
      </q-step>

      <!--(6)Banco de Destino-->
      <q-step
        :name="6"
        title="Selecione o banco de dados de destino"
        icon="input"
        :done="step > 6"
      >
        <SelectWithFilter
          :list="bancosDeDestino"
          :required="true"
          :label="$t('message.chooseTheDatabaseOfDestination')"
          :rounded="false"
          :filled="true"
          :dense="true"
          @selected="bancoDeDestino = $event"
        />

        <q-stepper-navigation>
          <q-btn @click="step = 7" color="primary" label="Continue" />
          <q-btn
            flat
            @click="step = 5"
            color="primary"
            label="Voltar"
            class="q-ml-sm"
          />
        </q-stepper-navigation>
      </q-step>

      <!--(7)Resumo-->
      <q-step :name="7" title="Resumo" icon="checklist" :done="step > 7">
        <q-list v-for="(resumo, idx) in resumos" :key="idx">
          <q-item>
            <q-item-section>
              <q-item-label>{{ resumo.label }}</q-item-label>
              <q-item-label caption lines="2">{{ resumo.value }}</q-item-label>
            </q-item-section>

            <q-item-section side top>
              <q-icon
                :name="resumo.valid ? 'check_circle' : 'error'"
                :color="resumo.valid ? 'green' : 'red'"
              />
            </q-item-section>
          </q-item>

          <q-separator spaced inset />
        </q-list>

        <q-stepper-navigation>
          <q-btn color="primary" label="Finalizar" @click="finalizar" />
          <q-btn
            flat
            @click="step = 6"
            color="primary"
            label="Voltar"
            class="q-ml-sm"
          />
        </q-stepper-navigation>
      </q-step>
    </q-stepper>
  </div>
</template>
<script>
import SelectWithFilter from "Components/Widgets/SelectWithFilter";
import api from "Api";
import apy from "Api/python";
import { showError, showSuccess } from "@/notifications/notify";
export default {
  name: "AutomacaoBackupComponent",
  components: { SelectWithFilter },
  emits: ["refreshBackup"],
  data() {
    return {
      step: 2,
      tarefa: "copiarArquivo",
      tarefas: [
        {
          label: "copiarArquivo",
          value: "Copiar dados com base em um ARQUIVO (DUMP BACKUP)",
        },
        {
          label: "copiarBanco",
          value: "Copiar dados com base em um BANCO DE DADOS",
        },
      ],
      instancia: null,
      instancias: [],
      arquivos: [],
      arquivo: null,
      servidorOrigem: null,
      servidoresOrigem: [
        { label: "Teste(rosset01)", value: "rosset01.rosset.grp" },
        { label: "Produção(rosset88)", value: "rosset88.rosset.grp" },
      ],
      bancoDeOrigem: null,
      bancosDeOrigemOri: [],
      bancoDeDestino: null,
      bancosDeDestinoOri: [],
      script: null,
      disableStep4: true,
      disableStep3: true,
    };
  },
  computed: {
    nextStep() {
      let step = 4;
      if (this.tarefa == "copiarArquivo") {
        this.listarArquivos();
        step = 3;
      }
      this.disableStep3 = step == 4;
      this.disableStep4 = step == 3;
      return step;
    },
    previousStep() {
      return this.tarefa == "copiarArquivo" ? 3 : 4;
    },
    bancosDeOrigem() {
      const banco =
        this.servidorOrigem == "rosset01.rosset.grp"
          ? this.bancosDeDestinoOri
          : this.bancosDeOrigemOri;
      const instance = this.instancias.find((f) => f.value == this.instancia);
      let bancos = [];
      if (instance)
        bancos = banco.filter((f) => f.idinstance == instance.idInstance);
      return bancos;
    },
    bancosDeDestino() {
      const instance = this.instancias.find((f) => f.value == this.instancia);
      let bancos = [];
      if (instance)
        bancos = this.bancosDeDestinoOri.filter(
          (f) => f.idinstance == instance.idInstance
        );
      return bancos;
    },
    resumos() {
      this.script = [
        {
          label: "Tarefa a ser realizada",
          value: this.tarefas.find((f) => f.label == this.tarefa).value,
          valid: true,
        },
        {
          label: "Instância selecionada",
          value: this.instancia || "N/D",
          valid: this.instancia != null,
        },
      ];

      if (this.tarefa == "copiarArquivo") {
        this.script.push({
          label: "Arquivo selecionado",
          value: this.arquivo ? this.arquivo.id : "N/D",
          valid: this.arquivo != null,
        });
      } else {
        this.script.push({
          label: "Servidor de origem",
          value: this.servidorOrigem || "N/D",
          valid: this.servidorOrigem != null,
        });
      }
      this.script.push({
        label: "Banco de Dados de Origem",
        value: this.bancoDeOrigem
          ? `${this.bancoDeOrigem.id} - ${this.bancoDeOrigem.description}`
          : "N/D",
        valid: this.bancoDeOrigem != null,
      });

      this.script.push({
        label: "Banco de Dados de Destino",
        value: this.bancoDeDestino
          ? `${this.bancoDeDestino.id} - ${this.bancoDeDestino.description}`
          : "N/D",
        valid: this.bancoDeDestino != null,
      });

      return this.script;
    },
  },
  methods: {
    getInstancias() {
      api
        .get(`v1/ti/automacao/instancias`)
        .then((res) => {
          this.instancias = res.data.map((item) => {
            return {
              label: item.instancename,
              value: item.instancename,
              idInstance: item.idinstance,
              pathfileprod: item.pathfileprod,
              pathfiletest: item.pathfiletest,
            };
          });
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    getBancosDeOrigem() {
      api
        .get(`v1/ti/automacao/bancos/origem`)
        .then((res) => {
          this.bancosDeOrigemOri = res.data.map((item) => {
            return {
              id: item.databasename,
              description: `Tablespace: ${item.tablespacename} Tam. Utilizado: ${item.utilizado} GB`,
              idinstance: item.idinstance,
              tablespacename: item.tablespacename,
              alocado: item.alocado,
              utilizado: item.utilizado,
              disponivel: item.disponivel,
            };
          });
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    getBancosDeDestino() {
      api
        .get(`v1/ti/automacao/bancos/destino`)
        .then((res) => {
          this.bancosDeDestinoOri = res.data.map((item) => {
            return {
              id: item.databasename,
              description: `Tablespace: ${item.tablespacename} Tam. Alocado: ${item.alocado} GB`,
              idinstance: item.idinstance,
              tablespacename: item.tablespacename,
              alocado: item.alocado,
              utilizado: item.utilizado,
              disponivel: item.disponivel,
            };
          });
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    listarArquivos() {
      if (!this.instancia) {
        showError("Instância não selecionada!");
        return;
      }
      apy
        .get(`dbora/listararquivos?instance=${this.instancia}`)
        .then((res) => {
          this.arquivos = res.data.files.map((file) => {
            return {
              id: file.file,
              description: `Tamanho: ${file.size}`,
            };
          });
        })
        .catch((err) => {
          showError(err);
        });
    },
    finalizar() {
      const data = this.script;
      apy
        .post("dbora/executartarefa", data)
        .then((res) => {
          showSuccess("Tarefa Iniciada");
          this.$emit("refreshBackup");
        })
        .catch((err) => {
          // console.log(err);
          showError(err);
        });
    },
  },
  mounted() {
    this.getInstancias();
    this.getBancosDeOrigem();
    this.getBancosDeDestino();
  },
};
</script>
