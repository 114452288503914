<template>
  <LgpdAviso v-if="exibirAviso" />
  <router-view v-if="continueLoading" />
</template>

<script>
import LgpdAviso from "Components/Areas/Admin/LGPD/LgpdAviso";
import { userKey, userConfigKey } from "@/global";
import router from "@/router";
import { auth } from "Helpers/authentication";

export default {
  name: "App",
  components: { LgpdAviso },
  data() {
    return {
      qa: null,
      exibirAviso: false,
      continueLoading: false,
    };
  },
  methods: {
    getLocalStorage() {
      const json = localStorage.getItem(userConfigKey);
      return JSON.parse(json);
    },

    async validateLogin() {
      this.continueLoading = false;

      const promise = new Promise(function (resolve, reject) {
        resolve(router);
      });

      const qa = await promise.then((res) => res.currentRoute.value.meta?.qa);

      if (qa) {
        localStorage.removeItem(userKey);
        this.$router.push({ name: "loginqa" });
        this.continueLoading = true;
        return;
      }

      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);

      if (!userData) {
        await this.checkUserAuthenticated();
        this.continueLoading = true;
        return;
      }

      const userInformation = await auth.validateToken(userData);

      if (userInformation) {
        this.exibirAviso = userInformation.showNotificationLgpd === 1;
      }

      this.continueLoading = true;
    },

    setLanguageConfiguration() {
      const configData = this.getLocalStorage();
      if (!configData) return;
      this.$i18n.locale = configData.selectedLocale.locale;
      this.$store.dispatch("changeLanguage", configData.selectedLocale);
    },

    async checkUserAuthenticated() {
      await auth.loginByNginx();
    },
  },

  created: async function () {
    this.setLanguageConfiguration();
    await this.validateLogin();
  },
};
</script>
