<template>
  <div>
    <q-card
      class="q-my-md"
      style="height: 164px; min-height: 164px; z-index: -1"
    >
      <q-card-section style="height: 30px">
        <div class="text-subtitle1 text-weight-bold">
          <span>{{ this.titulo }} = </span>
          <span>{{ !this.total ? 0 : this.total }}</span>
        </div>
      </q-card-section>

      <q-card-section v-if="tipoCartao != 'colaboradores'">
        <div class="row justify-center">
          <span class="text-h7 q-mb-sm">{{ $t("message.usedLicenses") }}</span>
          <span class="text-h7 q-mx-sm"> | </span>
          <span class="text-h7 q-mb-sm">{{
            $t("message.availableLicenses")
          }}</span>
        </div>
        <div class="row justify-center">
          <div class="col-6 text-right q-pr-lg">
            <span class="text-h3" :class="getColorUsadas(this.usadas)">
              {{ !this.usadas ? 0 : this.usadas }}
            </span>
          </div>
          <div class="col-6">
            <span
              class="text-h3"
              :class="getColorDisponiveis(this.disponiveis)"
            >
              {{ !this.disponiveis ? 0 : this.disponiveis }}
            </span>
          </div>
        </div>
      </q-card-section>

      <q-card-section v-else>
        <div class="row justify-center">
          <span class="text-h7 q-mb-sm">{{
            $t("message.employeesTotalWithoutLicense")
          }}</span>
        </div>
        <div class="row justify-center">
          <div class="col-6 text-right q-pr-lg">
            <span
              class="text-h3"
              :class="this.usadas < 0 ? 'text-negative' : 'text-green-8'"
            >
              {{ !this.usadas ? 0 : this.usadas }}
            </span>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "CartaoLicencas",

  props: {
    titulo: String,
    total: Number,
    usadas: Number,
    disponiveis: Number,
    tipoCartao: String,
  },

  methods: {
    getColorUsadas(v) {
      if (!v || v == 0) {
        return "text-indigo-10";
      }
      if (
        v.usadas > Math.floor(v.total * 0.95) ||
        v.vipUsadas > Math.floor(v.totalReservadas * 0.95)
      ) {
        return "text-negative";
      } else if (
        v.usadas >= v.total * 0.9 ||
        v.vipUsadas >= v.totalReservadas * 0.9
      ) {
        return "text-yellow-8";
      } else {
        return "text-indigo-10";
      }
    },

    getColorDisponiveis(v) {
      if (!v || v == 0) {
        return "text-negative";
      }
      if (
        v.disponiveis <= v.total * 0.05 ||
        v.vipDisponiveis <= v.totalReservadas * 0.05
      ) {
        return "text-negative";
      } else if (
        v.disponiveis <= v.total * 0.1 ||
        v.vipDisponiveis <= v.totalReservadas * 0.1
      ) {
        return "text-yellow-8";
      } else {
        return "text-green-8";
      }
    },
  },
};
</script>
