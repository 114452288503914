<template>
  <div>
    <page-title-bar></page-title-bar>

    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-table
        :rows="rows"
        :columns="columns"
        dense
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        hide-pagination
        auto-width
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
        virtual-scroll
      >
        <template v-slot:top>
          <q-btn size="md" color="primary" class="q-mb-xm" @click="onAdd">
            {{ $t("message.add") }}
          </q-btn>
        </template>

        <template v-slot:body-cell-actionsModal="props">
          <q-td auto-width>
            <q-btn flat dense unelevated round small>
              <q-tooltip>{{ $t("message.usersList") }}</q-tooltip>
              <q-icon
                color="blue"
                class="mr-2 material-icons"
                @click.stop="exibirLista(props.row.id)"
                size="xs"
              >
                search
              </q-icon>
            </q-btn>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td auto-width>
            <q-btn flat dense unelevated round small>
              <q-icon
                color="blue"
                class="mr-2 material-icons"
                @click="onUpdate(props.row.id)"
                size="xs"
              >
                edit
              </q-icon>
            </q-btn>
          </q-td>
        </template>
      </q-table>
      <UsuarioDeCadaNivelAcesso ref="componenteListaUsuarioDeCadaNivelAcesso" />
    </div>
  </div>
</template>

<script>
import api from "Api";
import UsuarioDeCadaNivelAcesso from "Components/Areas/Admin/NiveisAcesso/UsuarioDeCadaNivelAcesso";
import { showError } from "@/notifications/notify";

export default {
  name: "NivelAcessoLista",
  components: {
    UsuarioDeCadaNivelAcesso,
  },
  data() {
    return {
      loader: true,
      rows: [],
      columns: [
        {
          label: "",
          name: "actionsModal",
          field: "actionsModal",
          align: "center",
          sortable: false,
        },
        {
          label: "Id",
          field: "id",
          name: "id",
          sortable: true,
          align: "right",
        },
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.defaultDashboard"),
          field: "dashboardDescricao",
          name: "dashboardDescricao",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          name: "actions",
          field: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    retrieveList() {
      api
        .get("v1/nivelAcesso/")
        .then((response) => {
          this.rows = response.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onUpdate(id) {
      this.$router.push({ name: "nivelacessodetalhe", params: { id } });
    },

    onAdd() {
      this.$router.push({
        name: "nivelacessodetalhe",
        params: { id: 0 },
      });
    },
    exibirLista(id) {
      this.$refs.componenteListaUsuarioDeCadaNivelAcesso.abrirModal(id);
    },
  },
  created() {
    this.retrieveList();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
