<template>
  <div>
    <q-toolbar class="r7-background-secondary">
      <q-toolbar-title class="r7-text-color-light"
        >{{ title }}
      </q-toolbar-title>
      <q-space />
      <div v-if="minimizarIcon">
        <q-btn flat round size="small">
          <q-icon @click="setAtualizar()" class="material-icons text-white">
            refresh
          </q-icon>
        </q-btn>

        <q-btn
          @click="minimizar = !minimizar"
          v-if="minimizar === true"
          flat
          round
          size="small"
        >
          <q-icon class="material-icons text-white">minimize</q-icon>
        </q-btn>
        <q-btn
          icon
          @click="minimizar = !minimizar"
          v-else
          flat
          round
          size="small"
        >
          <q-icon class="material-icons text-white">crop_square</q-icon>
        </q-btn>
      </div>
    </q-toolbar>
    <div
      v-show="minimizar"
      class="r7-container-form-in-line-block"
      :style="customClass"
    >
      <q-circular-progress
        indeterminate
        color="primary"
        v-if="loader"
      ></q-circular-progress>
      <div class="q-pl-lg q-py-md">
        <div
          class="row"
          v-for="(obj, index) in dadosTratados"
          :key="index"
          no-gutters
        >
          <div
            class="col q-ma-xm"
            v-for="(obj2, index2) in obj"
            :key="index2"
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
            <div v-if="obj2.coluna || obj2.valor">
              <span class="fw-bold">
                <slot :name="'col' + obj2.chave" :value="obj2">
                  {{ obj2.coluna }}:
                </slot>
              </span>
              <slot :name="'val' + obj2.chave" :value="obj2">
                {{ obj2.valor ? textTruncate(obj2.valor) : obj2.valor }}
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        v-if="dadosTratados.length === 0 && !loader"
        justify="center"
        no-gutters
      >
        <div class="col r7-sem-dados-form-in-line-block">
          {{ $t("message.noDataFound") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { textTruncate } from "Helpers";
import { mapActions } from "vuex";

export default {
  name: "FormInLineBlock",
  props: {
    data: Object,
    fields: Array,
    options: Array,
    loader: {
      type: Boolean,
      default: true,
    },
    title: String,
    reloadIcon: {
      type: Boolean,
      default: true,
    },
    customClass: Object,
    minimizarIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dadosTratados: [],
      objArray: [],
      minimizar: true,
      titulo: null,
      alignLoader: {
        textAlign: "center",
      },
    };
  },
  watch: {
    data() {
      this.tratarDadosRecebidos();
    },
  },
  methods: {
    ...mapActions("common", ["setAtualizar"]),
    textTruncate(text) {
      return textTruncate(text, 37);
    },
    tratarDadosRecebidos() {
      this.dadosTratados = [];
      this.objArray = [];
      let count = 0;
      const lengthArray = this.fields.length;
      const lengthColumn =
        this.options?.columns > 4 ? 4 : this.options?.columns || 2;

      const montaDados = (obj) => {
        const newObj = {
          chave: obj.field,
          coluna: obj.label,
          valor: this.data[obj.field],
        };
        count += 1;

        this.objArray.push(newObj);

        if (count % lengthColumn === 0 || count === lengthArray) {
          this.dadosTratados.push(this.objArray);
          this.objArray = [];
        }
      };

      this.fields.forEach((e) => montaDados(e));
    },
  },
};
</script>
