import AnaliseChamados from "Views/TI/AnaliseChamados";
import ConsultaAcessoUsuariosSGTLista from "Views/TI/ConsultaAcessoUsuariosSGTLista";
import ListaAcessosSenior from "Views/TI/ListaAcessosSenior";
import AutomacaoBackupView from "Views/TI/AutomacaoBackupView";

const routes = [
  {
    path: "/analisedoschamados",
    name: "analisedoschamados",
    component: AnaliseChamados,
    meta: {
      requiresAuth: true,
      title: "message.ti",
      breadcrumb: ["message.helpdeskAnalysis"],
    },
  },
  {
    path: "/consultaacessousuariossgtlista",
    name: "consultaacessousuariossgtlista",
    component: ConsultaAcessoUsuariosSGTLista,
    meta: {
      requiresAuth: true,
      title: "message.SGTUsersAcessList",
      breadcrumb: ["message.ti", "message.SGTUsersAcessList"],
    },
  },
  {
    path: "/listaacessossenior",
    name: "listaacessossenior",
    component: ListaAcessosSenior,
    meta: {
      requiresAuth: true,
      title: "message.seniorAccessList",
      breadcrumb: ["message.ti", "message.seniorAccessList"],
    },
  },
  {
    path: "/automacaobackup",
    name: "automacaobackup",
    component: AutomacaoBackupView,
    meta: {
      requiresAuth: true,
      title: "message.backupAutomation",
      breadcrumb: ["message.ti", "message.backupAutomation"],
    },
  },
];

export default routes;
