<template>
  <ModalForm ref="modalFormCopiarLista" :title="$t('message.copyPriceList')">
    <template v-slot:body>
      <q-form ref="form" v-model="valid" autofocus @submit="copiarLista">
        <div class="row q-mt-lg">
          <div class="col-5">
            <q-input
              filled
              v-model="nrTabela"
              disable
              :label="$t('message.referenceTable')"
            />
          </div>
        </div>
        <div class="row q-mt-lg">
          <div class="col-5">
            <q-input
              filled
              v-model="nomeTabela"
              :label="$t('message.insertTableName')"
              required
              autofocus
              cleareble
              :rules="nameRules"
            />
          </div>
          <div class="col-5 q-ml-lg">
            <q-input
              name="dataVigencia"
              filled
              v-model="dataVigencia"
              :label="$t('message.chooseValidityDate')"
              required
              cleareble
              :rules="vigenciaRules"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qValidityDateProxy">
                    <q-date
                      v-model="dataVigencia"
                      minimal
                      mask="DD/MM/YYYY"
                      @click="() => $refs.qValidityDateProxy.hide()"
                    />
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <q-separator />
        <q-btn
          :label="$t('message.copy')"
          color="success"
          type="submit"
          class="bg-green text-white q-mt-md"
        />

        <q-btn
          :label="$t('message.close')"
          @click="aoFecharCopiar()"
          class="bg-red text-white q-ml-md q-mt-md"
        />
      </q-form>
    </template>
  </ModalForm>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

import ModalForm from "Components/Widgets/ModalForm";

export default {
  name: "CopiaListaDePrecosHeader",
  components: { ModalForm },

  data() {
    return {
      loader: true,
      valid: true,

      nrTabela: null,
      nomeTabela: null,
      dataVigencia: null,

      nameRules: [
        (v) => !!v || this.$t("message.nameRequired"),
        (v) =>
          (v && v.length >= 6) ||
          `${this.$t("message.name")}
           ${this.$t("message.mustbegreaterthan")} 6
           ${this.$t("message.characters")}`,
      ],
      emissaoRules: [(v) => !!v || this.$t("message.dateRequired")],
      vigenciaRules: [(v) => !!v || this.$t("message.dateRequired")],
    };
  },
  methods: {
    abrirModalCopiar(nrTabela) {
      this.$refs.modalFormCopiarLista.abrir();
      this.nrTabela = nrTabela;
    },

    copiarLista() {
      api
        .patch(`v1/compras/salete/copiarlistaprecos/${this.nrTabela}`, {
          nome: this.nomeTabela,
          dtVigencia: this.dataVigencia,
        })
        .then(() => {
          showSuccess(this.$t("message.successAddedRecord"));
          this.$emit("atualizarLista");
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.aoFecharCopiar();
        });
    },

    aoFecharCopiar() {
      this.$refs.modalFormCopiarLista.fechar();
      this.nrTabela = null;
      this.nomeTabela = null;
      this.dataVigencia = null;
    },
  },
};
</script>
