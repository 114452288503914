<template>
  <app-section-loader :status="loader"></app-section-loader>

  <SelectWithFilter
    v-if="!loader"
    :id="temaId"
    :list="listaTema"
    :required="required"
    :disabled="disabled"
    :prependIcon="prependIcon"
    :label="$t('message.chooseTheme')"
    :rounded="rounded"
    :filled="filled"
    :dense="false"
    @selected="itemSelected($event)"
    :rules="temaRules"
    :autofocus="autofocus"
    :clearable="clearable"
  />
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "TemaQASelectionComponent",
  components: { SelectWithFilter },
  emits: ["selected"],

  props: {
    temaId: Number,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loader: true,
      listaTema: null,
      temaRules: [
        (v) => v !== null || !this.required || this.$t("message.themeRequired"),
      ],
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get("/v1/rh/qa/temas")
        .then((res) => {
          if (res.data) {
            this.listaTema = res.data.map((element) => {
              return {
                id: element.idTema,
                description: element.descricaoTema,
              };
            });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
