<template>
  <SelectWithFilter
    :id="userId"
    :list="list"
    :required="required"
    :disabled="disabled"
    :prependIcon="prependIcon"
    :label="$t('message.chooseUser')"
    :rounded="true"
    :filled="false"
    :dense="true"
    @selected="itemSelected($event)"
    :autofocus="autofocus"
    :loading="loader"
    ref="selectWithFilter"
  />
</template>

<script>
import { loadData } from "Helpers/rotinas";

import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "UsuariosTecusuaSelectComponent",
  components: { SelectWithFilter },
  emits: ["selected"],

  props: {
    userId: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loader: true,
      list: null,
      request: {
        endpoint: "/cgi-bin/intra4630.exe",
        acao: "listar",
        loadJustCobol: true,
        onDataLoaded: (data) => (this.list = data),
      },
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    async initialize() {
      this.loader = true;
      const moreData = await loadData(this.request);

      this.list = [...this.list, ...moreData];

      this.list = this.list.map((element) => {
        return {
          id: element.j_usuario,
          description: element.j_nome,
          setor: element.j_setor,
        };
      });

      this.$refs.selectWithFilter.refresh();
      this.loader = false;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
