<template>
  <div>
    <q-table
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      :rows-per-page-options="[0]"
      hide-pagination
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
    >
      <template v-slot:body-cell-descricao="props">
        <q-td :style="{ 'text-indent': props.row.indent }">
          <span>
            {{ props.row.descricao }}
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-ativo="props">
        <q-td>
          <q-toggle
            v-model="props.row.ativo"
            color="blue"
            :disable="atualizandoCruzamento"
            :label="$t('message.active')"
            @update:model-value="atualizarCruzamento({ ...props.row })"
            checked-icon="check"
            unchecked-icon="clear"
          ></q-toggle>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
import api from "Api";
import { menuHierarchy } from "Helpers";
import { showError } from "@/notifications/notify";

export default {
  name: "ListaMenuNivelAcesso",
  props: {
    nivelAcessoId: [Number, String],
  },
  data() {
    return {
      id: this.nivelAcessoId,

      atualizandoCruzamento: false,

      rows: [],
      columns: [
        {
          label: this.$t("message.description"),
          name: "descricao",
          field: "descricao",
          align: "left",
        },
        {
          label: this.$t("message.active"),
          name: "ativo",
          field: "ativo",
        },
      ],
    };
  },
  methods: {
    row_classes(item) {
      if (!item.parentid) return "";
      return "row-child";
    },

    obterCruzamento() {
      return api
        .get(`/v1/cruzamentoMenuNivelAcesso/${this.id}`)
        .then((response) => {
          this.rows = response.data.map((elem, idx, array) => {
            const newArray = array.map((e) => {
              return { id: e.menuid, parentId: e.parentid };
            });

            const indent = menuHierarchy(elem.menuid, newArray);
            elem.indent = indent + "%";
            elem.ativo = elem.ativo === 1;
            return elem;
          });
        })
        .catch((err) => showError(err));
    },

    atualizarCruzamento(item) {
      this.atualizandoCruzamento = true;
      item.ativo = item.ativo ? 1 : 0;

      let method, data, url;
      if (item.ativo) {
        url = "/v1/cruzamentoMenuNivelAcesso";
        method = "post";
        data = {
          menuId: item.menuid,
          nivelAcessoId: this.id,
        };
      } else {
        url = `/v1/cruzamentoMenuNivelAcesso/${item.id}`;
        method = "delete";
      }

      api[method](url, data)
        .catch((err) => showError(err))
        .finally(() => {
          this.$emit("obterNivelAcesso");
          this.obterCruzamento().then(() => {
            this.atualizandoCruzamento = false;
          });
        });
    },
  },
  mounted() {
    this.obterCruzamento();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 480px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
