<template>
  <ModalForm
    ref="modalFormEditarRevisarMeta"
    :title="$t('message.redistributeGoal')"
    :customClass="style"
  >
    <template v-slot:body>
      <q-form ref="form" autofocus @submit.prevent="aoSalvar">
        <div class="row q-mt-5 q-col-gutter-xs bg-blue-grey-2">
          <div class="col-6 col-sm-4">
            <span class="text-bold">Tipo de Malharia</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-bold">% Volume</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-bold">% Valor</span>
          </div>
        </div>
        <div
          class="row items-center q-col-gutter-xs"
          v-for="tipo in tipoMalharia"
          :key="tipo"
        >
          <div class="col-6 col-sm-4">{{ tipo }}</div>
          <div class="col-6 col-sm-4">
            <InputQuantity
              v-model.lazy="registroData.tipoMalharia[tipo].volume"
              :required="false"
              class="q-ml-xs"
            />
          </div>
          <div class="col-6 col-sm-4">
            <InputQuantity
              :required="false"
              v-model.lazy="registroData.tipoMalharia[tipo].valor"
              class="q-ml-xs"
            />
          </div>
        </div>

        <div class="row items-center">
          <div class="col-6 col-sm-4">
            <span class="text-red-14 text-weight-bold">Total</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-red-14 text-weight-bold">{{
              totalVolumeMalharia
            }}</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-red-14 text-weight-bold">{{
              totalValorMalharia
            }}</span>
          </div>
        </div>

        <div class="row q-col-gutter-xs bg-blue-grey-2">
          <div class="col-6 col-sm-4">
            <span class="text-bold">Tipo de Tecido</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-bold">% Volume</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-bold">% Valor</span>
          </div>
        </div>
        <div
          class="row items-center q-col-gutter-xs"
          v-for="tipo in tipoTecido"
          :key="tipo"
        >
          <div class="col-6 col-sm-4">{{ tipo }}</div>
          <div class="col-6 col-sm-4">
            <InputQuantity
              v-model.lazy="registroData.tipoTecido[tipo].volume"
              :required="false"
              class="q-ml-xs"
            />
          </div>
          <div class="col-6 col-sm-4">
            <InputQuantity
              :required="false"
              v-model.lazy="registroData.tipoTecido[tipo].valor"
              class="q-ml-xs"
            />
          </div>
        </div>
        <div class="row items-center">
          <div class="col-6 col-sm-4">
            <span class="text-red-14 text-weight-bold">Total</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-red-14 text-weight-bold">{{
              totalVolumeTecido
            }}</span>
          </div>
          <div class="col-6 col-sm-4">
            <span class="text-red-14 text-weight-bold">{{
              totalValorTecido
            }}</span>
          </div>
        </div>

        <q-separator />
        <q-btn
          color="success"
          type="submit"
          :label="$t('message.save')"
          class="bg-green text-white q-mt-lg"
          :disabled="!validaTotais"
        />
        <q-btn
          :label="$t('message.close')"
          @click.prevent="aoFechar()"
          class="bg-red text-white q-ml-md q-mt-lg"
        />
      </q-form>
    </template>
  </ModalForm>
  <confirmation-dialog ref="confirmationDialog" @onConfirm="deletarAoConfirmar">
  </confirmation-dialog>
</template>

<script>
import ModalForm from "Components/Widgets/ModalForm";
import InputQuantity from "Components/Widgets/InputQuantity";
import { showSuccess, showError, showAlert } from "@/notifications/notify";
import { convertToFloat } from "Helpers";

export default {
  name: "RedistribuirMeta",
  components: { ModalForm, InputQuantity },
  emits: ["saveData"],
  data() {
    return {
      style: {
        width: "600px",
        // height: "300px",
      },
      tipoTecido: ["Liso", "Estampado", "Renda", "Laise", "Baby"],
      tipoMalharia: ["Kettens", "Circular", "Renda"],
      registroData: {
        tipoTecido: {
          Liso: {
            volume: null,
            valor: null,
          },
          Estampado: {
            volume: null,
            valor: null,
          },
          Renda: {
            volume: null,
            valor: null,
          },
          Laise: {
            volume: null,
            valor: null,
          },
          Baby: {
            volume: null,
            valor: null,
          },
        },
        tipoMalharia: {
          Kettens: {
            volume: null,
            valor: null,
          },
          Circular: {
            volume: null,
            valor: null,
          },
          Renda: {
            volume: null,
            valor: null,
          },
        },
      },
    };
  },

  computed: {
    totalVolumeMalharia() {
      return this.tipoMalharia.reduce(
        (p, c) => p + convertToFloat(this.registroData.tipoMalharia[c].volume),
        0.0
      );
    },
    totalValorMalharia() {
      return this.tipoMalharia.reduce(
        (p, c) => p + convertToFloat(this.registroData.tipoMalharia[c].valor),
        0.0
      );
    },
    totalVolumeTecido() {
      return this.tipoTecido.reduce(
        (p, c) => p + convertToFloat(this.registroData.tipoTecido[c].volume),
        0.0
      );
    },
    totalValorTecido() {
      return this.tipoTecido.reduce(
        (p, c) => p + convertToFloat(this.registroData.tipoTecido[c].valor),
        0.0
      );
    },
    validaTotais() {
      return (
        this.totalVolumeMalharia == 100 &&
        this.totalValorMalharia == 100 &&
        this.totalVolumeTecido == 100 &&
        this.totalValorTecido == 100
      );
    },
  },

  methods: {
    aoAbrir() {
      this.$refs.modalFormEditarRevisarMeta.abrir();
    },

    aoFechar() {
      this.anoMeta = null;
      this.$refs.modalFormEditarRevisarMeta.fechar();
    },

    aoSalvar() {
      this.$emit("saveData", this.registroData);
      this.aoFechar();
    },
  },
};
</script>
