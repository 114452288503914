<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      :loading="loader"
      :rows="rows"
      :columns="columns"
      :filter="filter"
      dense
      table-header-class="table-header"
      hide-pagination
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
      :style="style"
    >
      <template v-slot:top-right>
        <q-input
          class="q-ml-sm"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <exportar-excel
          v-if="rows"
          :data="rows"
          name="listagemusuariosldap"
          class="q-mx-sm"
        />
      </template>
      <template v-slot:body-cell-status="props">
        <q-td auto-width>
          <q-badge size="xs" :color="props.row.active ? 'green' : 'red'">
            {{ props.row.active ? "Ativo" : "Inativo" }}
          </q-badge>
        </q-td>
      </template>

      <template v-slot:body-cell-moreInfoIcon="props">
        <q-td auto-width>
          <q-btn
            class="q-mb-xm"
            flat
            round
            size="sm"
            color="red darken-2"
            @click="onOpen(props.row)"
            icon="feed"
          />
        </q-td>
      </template>
    </q-table>
  </div>

  <ModalInfo ref="modalInfo" :title="title" :persistent="false" width="50">
    <!-- <template v-slot:search>
      <Search
        :label="$t('message.typeProductId')"
        @pesquisar="aoPesquisar($event)"
      />
    </template> -->

    <template v-slot:body>
      <pre>{{ moreInfo }}</pre>
    </template>

    <template v-slot:button2>
      <q-btn
        label="Fechar"
        @click="aoFechar()"
        class="bg-red text-white q-ml-md"
      />
    </template>
  </ModalInfo>
</template>
<script>
import { sizeTotal } from "Helpers";
import Api from "Api";
import { showError } from "@/notifications/notify";
import ModalInfo from "Components/Widgets/ModalInfo";

export default {
  name: "UsuariosLDAPListaComponent",
  components: { ModalInfo },
  data() {
    return {
      loader: true,
      filter: null,
      moreInfo: null,
      title: null,
      rows: [],
      columns: [
        {
          label: "",
          name: "status",
          fild: "status",
          sortable: true,
          align: "center",
        },
        {
          label: "Usuário(uid)",
          name: "uid",
          field: "uid",
          sortable: true,
          align: "left",
        },
        {
          label: "Nome",
          name: "displayName",
          field: "displayName",
          sortable: true,
          align: "left",
        },
        {
          label: "Departamento",
          name: "department",
          field: "department",
          sortable: true,
          align: "left",
        },
        {
          label: "Cargo",
          name: "title",
          field: "title",
          sortable: true,
          align: "left",
        },
        {
          label: "E-mail",
          name: "mail",
          field: "mail",
          sortable: true,
          align: "left",
        },
        {
          label: "",
          name: "moreInfoIcon",
          field: "moreInfoIcon",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    style() {
      const height = (this.loader ? sizeTotal() : sizeTotal()) + "px";
      return {
        height,
      };
    },
  },
  methods: {
    initialize() {
      this.getList();
    },
    onOpen(row) {
      this.title = `Informações sobre: ${row.uid}`;
      this.moreInfo = row.moreInfo;
      this.$refs.modalInfo.abrir();
    },
    aoFechar() {
      this.$refs.modalInfo.fechar();
      this.moreInfo = null;
    },

    getList() {
      this.loader = true;
      Api.get("/v1/ldap/users")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally((_) => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
