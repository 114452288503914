<template>
  <q-table
    :loading="loader"
    :rows="rows"
    :columns="columns"
    dense
    hide-pagination
    table-header-class="table-header"
    :rows-per-page-options="[0]"
    :noDataLabel="$t('message.noDataFound')"
    class="my-sticky-header-table"
    virtual-scroll
  >
    <template v-slot:body-cell-actionsDownload="props">
      <q-td auto-width>
        <q-btn flat dense round small>
          <q-icon
            size="xs"
            color="primary"
            class="material-icons"
            @click.stop="aoBaixar(props.row)"
          >
            cloud_download
          </q-icon>
        </q-btn>
      </q-td>
    </template>
  </q-table>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ListaDocumentoConsulta",

  data() {
    return {
      loader: true,
      linkDownload: null,
      rows: [],
      columns: [
        {
          label: "ID",
          field: "id",
          name: "id",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.fileDescription"),
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.file"),
          field: "nomeArquivo",
          name: "nomeArquivo",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsDownload",
          name: "actionsDownload",
          align: "center",
        },
      ],
    };
  },
  methods: {
    listarDocumentos() {
      this.loader = true;
      api
        .get("v1/repositorios/documentos/usuario")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    aoBaixar(documento) {
      this.loader = true;
      api
        .get(`v1/repositorios/documento/${documento.id}/baixar`, {
          responseType: "blob",
        })
        .then((res) => {
          const link = document.createElement("a");
          link.download = documento.nomeArquivo;

          let blob = new Blob([res.data], {
            type: documento.tipoDeDocumento,
          });

          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.listarDocumentos();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
