<template>
  <page-title-bar />
  <div class="q-px-md">
    <OmniChannelListaComponent />
  </div>
</template>
<script>
import OmniChannelListaComponent from 'Components/Areas/Valisere/OmniChannelListaComponent';
export default {
  name: "OmniChannelListaView",
  components: {OmniChannelListaComponent},
  data() {
    return {
    
    }
  },
}
</script>
