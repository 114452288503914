<template>
  <div>
    <page-title-bar></page-title-bar>
    <LimitesAprovacaoComprasComponent></LimitesAprovacaoComprasComponent>
  </div>
</template>

<script>
import LimitesAprovacaoComprasComponent from "Components/Areas/Compras/Limites/LimitesAprovacaoComprasComponent";
export default {
  name: "LimitesAprovacaoView",
  components: { LimitesAprovacaoComprasComponent },
};
</script>
