<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md q-gutter-md bg-image-lgpd">
    <LgpdListaTemporalidade />
  </div>
</template>

<script>
import LgpdListaTemporalidade from "Components/Areas/Admin/LGPD/LgpdListaTemporalidade";
export default {
  name: "LgpdTemporalidade",
  components: { LgpdListaTemporalidade },
  data() {
    return {};
  },
  methods: {},
};
</script>
