<template>
  <ModalForm
    ref="modalFormCadastroDocumentos"
    :title="tituloModal"
    :customClass="estilo"
    :persistent="false"
  >
    <template v-slot:body>
      <div class="row">
        <span class="text-h6 text-weight-bold"
          >Grupo Referência: {{ grupoReferencia.cdGrupo }} -
          {{ grupoReferencia.descricao }}</span
        >
      </div>
      <div class="row">
        <div class="col">
          <q-table
            :rows="rows"
            :columns="columns"
            :filter="filter"
            dense
            hide-pagination
            table-header-class="table-header"
            :rows-per-page-options="[0]"
            :noDataLabel="$t('message.noDataFound')"
            class="my-sticky-header-table"
            virtual-scroll
            :style="{ height: '42vh' }"
          >
            <template v-slot:top-left>
              <q-toggle
                v-model="exibirRelacionados"
                color="blue"
                label="Relacionados"
                @input="exibirRelacionados != exibirRelacionados"
              />
            </template>
            <template v-slot:top-right>
              <q-input
                class="q-ml-sm"
                borderless
                dense
                debounce="300"
                v-model="filter"
                :placeholder="$t('message.search')"
              >
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template v-slot:body-cell-actionsRelations="props">
              <q-td auto-width>
                <q-checkbox
                  v-model="props.row.actionsRelations"
                  @update:model-value="checked(props.row)"
                >
                </q-checkbox>
              </q-td>
            </template>
          </q-table>
        </div>
      </div>
    </template>
  </ModalForm>
</template>

<script>
import ModalForm from "Components/Widgets/ModalForm";
import { showError } from "@/notifications/notify";
import api from "Api";

export default {
  name: "RelacionamentoGrupoReferencia",
  components: { ModalForm },
  emits: ["atualizarTabela"],
  props: ["referencias", "relacionamentos", "grupoReferencia"],

  data() {
    return {
      estilo: {
        width: "60vw",
        height: "60vh",
      },
      filter: null,
      dataRelacionados: null,
      alturaTabela: 0,

      rows: [],
      columns: [
        {
          label: "",
          field: "actionsRelations",
          name: "actionsRelations",
          align: "center",
        },

        {
          label: "Referência",
          field: "referencia",
          name: "referencia",
          align: "center",
          sortable: true,
        },
        {
          label: "Descrição",
          field: "dsArtigo",
          name: "dsArtigo",
          align: "left",
          sortable: true,
        },
      ],
      tituloModal: "Relacionamentos",
      exibirRelacionados: true,
    };
  },

  watch: {
    exibirRelacionados() {
      this.fillRows();
    },
  },

  methods: {
    abrir() {
      this.$refs.modalFormCadastroDocumentos.abrir();
      this.dataRelacionados = this.relacionamentos.filter(
        (f) => f.cdGrupo == this.grupoReferencia.cdGrupo
      );
      this.fillRows();
    },

    aoFechar() {
      this.$refs.modalFormCadastroDocumentos.fechar();
    },

    checked(row) {
      const data = {
        ativo: row.actionsRelations,
        cdGrupo: this.grupoReferencia.cdGrupo,
        cdReferencia: row.referencia,
      };
      api
        .post("/v1/produtos/specs/relaciona/gruporeferencia", data)
        .then(() => {
          if (data.ativo) {
            this.dataRelacionados.push({
              cdReferencia: data.cdReferencia,
            });
          } else {
            const index = this.dataRelacionados.findIndex(
              (f) => f.cdReferencia == data.cdReferencia
            );
            this.dataRelacionados.splice(index, 1);
          }
          this.fillRows();
        })
        .catch((err) => showError(err));
    },

    fillRows() {
      this.rows = [];
      this.referencias.forEach((ref) => {
        const relacionado = this.dataRelacionados.find(
          (relacao) => relacao.cdReferencia == ref.referencia
        );
        const found = relacionado != undefined;
        if (this.exibirRelacionados == found) {
          this.rows.push({
            actionsRelations: this.exibirRelacionados,
            referencia: ref.referencia,
            dsArtigo: ref.dsArtigo,
          });
        }
      });
    },
  },
};
</script>
