export default {
  acao: null,
  cabecalho: {
    ano: null,
    descricao: null,
    periodo: null,
  },
  revisaoXlsx: false,
  meta: [],
  exibir: false,
  desabilitarMenu: false,
};
