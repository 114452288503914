<template>
  <q-card class="my-card">
    <q-card-section class="bg-primary text-white">
      <span class="text-h5">{{ $t("message.areaRegistrationForm") }}</span>
    </q-card-section>
    <q-form ref="form" @submit="onAdd()" class="q-pa-md q-ml-md">
      <div class="row q-mb-xl q-mt-md">
        <div class="col-6 text-subtitle1 text-weight-medium">
          <p>{{ $t("message.areaDescription") }}</p>
        </div>
        <div class="col-4">
          <q-input
            type="text"
            filled
            required
            clearable
            v-model="areaData.descricao"
            :label="$t('message.description')"
            :rules="descricaoRule"
          />
        </div>
      </div>

      <div class="row q-mt-xl q-mb-md">
        <div class="col-6 text-subtitle1 text-weight-medium">
          <p>{{ $t("message.path") }}</p>
        </div>

        <div class="col-4">
          <q-input
            type="text"
            filled
            required
            clearable
            v-model="areaData.caminho"
            :label="$t('message.path')"
            :rules="caminhoRule"
          />
        </div>
      </div>
      <q-separator />
      <q-btn
        color="success"
        type="submit"
        :label="$t('message.add')"
        class="bg-green text-white q-mt-md"
      />
    </q-form>
  </q-card>
</template>

<script>
import { mapActions } from "vuex";
import { clearObject } from "Helpers";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "FormularioCadastroArea",
  data() {
    return {
      areaData: {
        descricao: null,
        caminho: null,
      },
      descricaoRule: [(v) => !!v || this.$t("message.descriptionRequired")],
      caminhoRule: [(v) => !!v || this.$t("message.pathRequired")],
    };
  },
  methods: {
    ...mapActions("repositorio", ["setAtualizarRegistros"]),

    onAdd() {
      api
        .post("v1/repositorios/areas", this.areaData)
        .then(() => {
          showSuccess(this.$t("message.successAddedRecord"));
          clearObject(this.areaData, this.$refs.form);
          this.setAtualizarRegistros();
        })
        .catch((err) => showError(err));
    },
  },
};
</script>
