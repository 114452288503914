export default {
  onChangeAtualizarRegistros: (state) => (state.atualizarRegistros += 1),

  onChangeAcessoUsuarios: (state, payload) =>
    state.acessoUsuarios.push(payload),

  onChangeRemoverUsuario: (state, payload) =>
    state.acessoUsuarios.splice(payload, 1),

  onChangeAcessoNivelAcesso: (state, payload) =>
    state.acessoNivelAcesso.push(payload),

  onChangeRemoverNivelAcesso: (state, payload) =>
    state.acessoNivelAcesso.splice(payload, 1),

  onHandlerState: (state) => {
    state.acessoUsuarios = [];
    state.acessoNivelAcesso = [];
  },
};
