<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      :loading="loader"
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      hide-pagination
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
    >
      <template v-slot:top-left>
        <q-btn class="q-mb-xm" color="primary" @click="onAdd">
          {{ $t("message.add") }}
        </q-btn>
      </template>

      <template v-slot:body-cell-actionsEdit="props">
        <q-td auto-width>
          <q-btn flat dense round small>
            <q-icon
              size="xs"
              color="blue darken-2"
              class="material-icons"
              @click="onUpdate(props.row)"
            >
              edit
            </q-icon>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-actionsDelete="props">
        <q-td auto-width>
          <q-btn flat dense round small>
            <q-icon
              size="xs"
              color="red darken-2"
              class="material-icons"
              @click="onDelete(props.row.id)"
            >
              delete
            </q-icon>
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      @onConfirm="deleteItem"
    />
    <FormFiltrosPorRelatorio
      ref="modalFormFiltrosPorRelatorio"
      @refresh="listarFiltrosDoRelatorio"
    />
  </div>
</template>

<script>
import FormFiltrosPorRelatorio from "./FormFiltrosPorRelatorio";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
export default {
  name: "ListaDeFiltrosPorRelatorio",
  props: {
    idRelatorio: {
      type: Number,
      default: 0,
    },
  },
  components: { FormFiltrosPorRelatorio },
  data() {
    return {
      loader: false,
      rows: [],
      columns: [
        {
          label: "Id.",
          field: "id",
          name: "id",
          align: "right",
          sortable: true,
        },
        {
          label: "Descrição",
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
        {
          label: "Filtro",
          field: (f) =>
            f.filtro.length > 50
              ? f.filtro.substring(0, 100) + "..."
              : f.filtro,
          name: "filtro",
          align: "left",
          sortable: false,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
        },
      ],
    };
  },
  methods: {
    listarFiltrosDoRelatorio() {
      this.loader = true;
      api
        .get(`v1/powerbi/filtros/relatorio/${this.idRelatorio}`)
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onAdd() {
      this.$refs.modalFormFiltrosPorRelatorio.onOpen(this.idRelatorio, null);
    },
    onUpdate(item) {
      this.$refs.modalFormFiltrosPorRelatorio.onOpen(this.idRelatorio, item);
    },
    onDelete(id) {
      this.$refs.deleteConfirmationDialog.open();
      this.selectedItemToDelete = id;
    },
    async deleteItem() {
      this.$refs.deleteConfirmationDialog.close();
      await api
        .delete(`/v1/powerbi/filtros/${this.selectedItemToDelete}`)
        .then(() => showSuccess(this.$t("message.successDeletedRecord")))
        .catch((err) => showError(err))
        .finally(() => this.listarFiltrosDoRelatorio());
    },
  },
  mounted() {
    this.listarFiltrosDoRelatorio();
  },
};
</script>
