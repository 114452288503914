<template>
  <div class="q-mt-md">
    <title-bar :title="$t('message.openDuplicates')">
      <template v-slot:funcoes>
        <q-space />

        <q-btn flat round size="small">
          <q-tooltip
            anchor="bottom middle"
            self="center middle"
            :offset="[10, 10]"
            class="text-body2"
            >{{ $t("message.filter") }}</q-tooltip
          >
          <q-icon
            @click="showFilter = !showFilter"
            class="material-icons text-grey-9"
          >
            filter_alt
          </q-icon>
        </q-btn>

        <exportar-excel
          v-if="rows"
          :data="rows"
          name="listapedidosporsituacao"
          class="q-mr-sm q-mt-xs"
        />

        <q-btn flat round size="small" @click="reload()">
          <q-tooltip
            anchor="bottom middle"
            self="center middle"
            :offset="[10, 10]"
            class="text-body2"
            >{{ $t("message.reload") }}</q-tooltip
          >
          <q-icon class="material-icons text-grey-9"> refresh </q-icon>
        </q-btn>
      </template>
    </title-bar>

    <app-section-loader :status="loader" />
    <q-grid
      ref="table"
      :data="rows"
      :columns="columns"
      :dense="true"
      :columns_filter="showFilter"
    >
      <template v-slot:dias="props">
        <span :class="getColor(props.value)"> {{ props.value }} </span>
      </template>
    </q-grid>
  </div>
</template>

<script>
import api from "Api";
import { mapGetters } from "vuex";
import { showError } from "@/notifications/notify";

export default {
  name: "ListaDuplicatasEmAberto",
  props: ["idCliente", "idRepresentante"],
  data() {
    return {
      rows: [],
      grupoEconomico: null,
      loader: true,
      showFilter: false,
      filter: null,
      initialPagination: {
        page: 1,
        rowsPerPage: 10,
      },
      columns: [
        {
          label: this.$t("message.title"),
          field: "titulo",
          name: "titulo",
          sortable: true,
          align: "right",
        },
        {
          label: this.$t("message.name"),
          field: "nomeCliente",
          name: "nomeCliente",
          sortable: true,
          align: "left",
        },

        {
          label: this.$t("message.situation"),
          field: "situacao",
          name: "situacao",
          sortable: true,
          align: "left",
        },
        {
          label: this.$t("message.value"),
          field: "valor",
          name: "valor",
          format: "decimal",
          align: "right",
        },
        {
          label: this.$t("message.issueDate"),
          field: "dtemissao",
          name: "dtemissao",
          sortable: true,
          align: "center",
        },
        {
          label: this.$t("message.dueDate"),
          field: "dtvencimento",
          name: "dtvencimento",
          sortable: true,
          align: "center",
        },
        {
          label: this.$t("message.lastDueDate"),
          field: "dtultvencimento",
          name: "dtultvencimento",
          sortable: true,
          align: "center",
        },
        {
          label: this.$t("message.expirationDays"),
          field: "dias",
          name: "dias",
          align: "right",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("clientes", ["getGrupoEconomico"]),
  },
  watch: {
    getGrupoEconomico(val) {
      this.grupoEconomico = val;
      this.listaDuplicatasEmAberto();
    },
  },
  methods: {
    reload() {
      this.listaDuplicatasEmAberto();
    },
    listaDuplicatasEmAberto() {
      this.loader = true;
      api
        .post("/v1/financeiro/duplicatas/emaberto", {
          idCliente: this.idCliente,
          idRepresentante: this.idRepresentante,
          grupoeconomico: this.grupoEconomico,
        })
        .then((res) => {
          if (res.data) {
            this.rows = res.data;
          }
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
    getColor(p) {
      return p < 0 ? "r7-text-color-danger" : "r7-text-color-success";
    },
  },
  created() {
    this.listaDuplicatasEmAberto();
  },
};
</script>
