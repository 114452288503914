<template>
  <q-layout view="lHh LpR fFf">
    <template v-if="loading"> </template>

    <template v-else>
      <Header v-if="isVisible" class="noPrint" />

      <q-drawer
        v-if="isVisible"
        show-if-above
        :modelValue="getSideBarToggle"
        side="left"
        elevated
        @hide="hideSideBar"
        class="noPrint"
      >
        <q-scroll-area class="fit">
          <Sidebar
            :key="componentKeySidebar"
            @recarregarSidebar="recarregarSidebar"
          />
        </q-scroll-area>
      </q-drawer>

      <q-page-container id="q-page-container-full">
        <router-view :key="componentKeyRouter"></router-view>
      </q-page-container>

      <Footer v-if="isVisible" class="noPrint" />
    </template>
  </q-layout>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "Container/Header";
import Footer from "Container/Footer";
import Sidebar from "Components/Sidebar/Sidebar";
import { chaveAleatoriaComponente } from "Helpers";
import { Loading, QSpinnerFacebook } from "quasar";

export default {
  name: "Full",
  components: { Header, Footer, Sidebar },
  data() {
    return {
      animation: {
        enter: {
          opacity: [1, 0],
          translateX: [0, -300],
          scale: [1, 0.2],
        },
        leave: {
          opacity: 0,
          height: 0,
        },
      },
      loading: true,
      componentKeySidebar: chaveAleatoriaComponente(),
      componentKeyRouter: 0,
      // user: this.$store.getters["auth/getUser"],
      isVisible: true,
    };
  },
  computed: {
    ...mapGetters("admin", ["getSideBarToggle", "getKeyMaster"]),
    ...mapGetters(["selectedRouterAnimation"]),
    ...mapGetters("common", ["getAtualizar"]),
  },
  watch: {
    getAtualizar: "recarregarSidebar",
    getKeyMaster(n) {
      this.componentKeyRouter = n;
    },
  },
  methods: {
    recarregarSidebar() {
      this.componentKeySidebar += chaveAleatoriaComponente();
    },
    hideSideBar() {
      this.$store.dispatch("admin/setSideBarToggleHide");
    },
  },
  mounted() {
    // console.log(window.location);
    const host = window.location.host;
    this.isVisible =
      (host.indexOf("localhost") >= 0 && window.location.port != 9000) ||
      host.indexOf("gruporosset") >= 0;

    Loading.show({
      spinner: QSpinnerFacebook,
      spinnerColor: "blue",
      spinnerSize: 140,
      backgroundColor: "white",
      message: "Aguarde carregando...",
      messageColor: "black",
    });
    setTimeout(() => {
      this.$store.dispatch("admin/setHighLevel");
      this.loading = false;
      Loading.hide();
    }, 2000);
  },
};
</script>
<style>
@media print {
  .noPrint {
    display: none;
  }
}
</style>
