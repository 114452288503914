<template>
  <q-tooltip
    :anchor="anchor"
    :self="self"
    :offset="offset"
    :class="customClass"
    >{{ label }}</q-tooltip
  >
</template>

<script>
export default {
  name: "TooltipCustom",
  props: {
    anchor: {
      type: String,
      default: "bottom middle",
    },
    self: {
      type: String,
      default: "center middle",
    },
    offset: {
      type: Array,
      default() {
        return [10, 10];
      },
    },
    customClass: {
      type: String,
      default: "text-body2",
    },
    label: {
      type: String,
      default: "",
    },
  },
};
</script>
