export default {
  onChangeDadosUsuariosSGT(state, payload) {
    state.dadosUsuariosSGT = payload;
  },
  onChangeFiltroEmpresaID(state, payload) {
    state.filtroEmpresaID = payload;
  },
  onChangeAtualizar(state) {
    state.atualizar += 1;
  },
};
