<template>
  <div class="q-pa-md">
    <QuestionarioQAComponent />
  </div>
</template>

<script>
import QuestionarioQAComponent from "Components/Areas/RH/QA/QuestionarioQAComponent";
export default {
  name: "QuestionarioQAView",
  components: { QuestionarioQAComponent },
};
</script>
