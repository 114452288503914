<template>
  <q-select
    :rounded="rounded"
    :filled="filled"
    outlined
    :dense="dense"
    map-options
    hide-selected
    use-input
    fill-input
    input-debounce="0"
    :label="label"
    :required="required"
    :disable="disabled"
    :options="listFilter"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
    v-model="objectId"
    :readonly="loading"
    option-value="id"
    :option-label="labelDescription"
    :key="refreshKey"
    @update:model-value="itemSelected"
    @filter="filterFn"
  >
    <template v-slot:prepend>
      <q-icon :name="prependIcon" />
    </template>

    <!-- <template v-slot:option="scope">
      <q-item
        v-if="!scope.opt.group"
        v-bind="scope.itemProps"
        v-on="scope.itemEvents"
      >
        <q-item-section>
          <q-item-label v-html="scope.opt.label"></q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        v-if="scope.opt.group"
        v-bind="scope.itemProps"
        v-on="scope.itemEvents"
      >
        <q-item-label header>{{ scope.opt.group }}</q-item-label>
      </q-item>
    </template> -->
  </q-select>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: null,
    },
    label: String,

    id: [String, Number],

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    prependIcon: {
      type: String,
      default: "",
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: null,
    },
  },
  name: "SelectWithFilter",

  data() {
    return {
      objectId: this.id,
      listFilter: null,
      refreshKey: 0,
    };
  },
  watch: {
    id() {
      this.objectId = this.id;
    },
  },

  methods: {
    labelDescription(opt) {
      if (!opt) return;
      if (!this.list) return;
      let id = null;
      let description = null;

      if (!opt.description) {
        id = opt.toString().trim();
        description =
          this.list.find((f) => f.id.toString().trim() == id)?.description ||
          "N/D";
      } else {
        id = opt.id;
        description = opt.description;
      }
      return `${id.toString().trim()} - ${description.trim()}`;
    },

    itemSelected(item) {
      this.$emit("selected", item);
    },
    refresh() {
      this.refreshKey += 1;
    },

    filterFn(val, update) {
      update(() => {
        if (val === "") {
          this.listFilter = this.list;
        } else {
          this.listFilter = this.list.filter(
            (f) =>
              f.description.toLowerCase().indexOf(val.toLowerCase()) >= 0 ||
              f.id.toString().toLowerCase().indexOf(val.toLowerCase()) >= 0
          );
        }
      });
    },
  },
};
</script>
