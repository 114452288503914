<template>
  <!-- <q-list bordered separator :dummy="force_react"> -->
  <q-list bordered separator>
    <q-item
      v-for="(item, idx) in listaComposicao"
      :key="idx"
      class="text-black"
    >
      <q-item-section>{{ item.composicao }}</q-item-section>
      <q-item-section
        >{{ item.qtConsumo }} {{ item.unidadeMedida }}</q-item-section
      >
      <q-item-section>{{ moeda }} {{ item.custoUnitario }}</q-item-section>
      <q-item-section class="text-bold">({{ item.percentual }})</q-item-section>
    </q-item>
  </q-list>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import { mapGetters } from "vuex";

export default {
  name: "CalculaComposicaoProdutoConfeccao",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  emits: ["carregarVariacao"],
  data() {
    return {
      listaComposicao: [],
      calculadora: null,
    };
  },

  computed: {
    ...mapGetters("comercial", ["getCalculadora"]),
    moeda() {
      return this.calculadora.isExportacao ? "US$" : "R$";
    },
  },
  watch: {
    getCalculadora: {
      handler(v) {
        this.calculadora = v;
        this.listarComposicaoProduto();
      },
      deep: true,
    },
  },

  methods: {
    textColor(item) {
      return item.diferencaCusto < 0 ? "text-red" : "text-positive";
    },
    listarComposicaoProduto() {
      if (!this.calculadora) return;
      const data = { ...this.calculadora };
      data.idProduto = this.id;
      api
        .post(`/v1/comercial/calculadora/simulacao`, data)
        .then((res) => {
          if (res.data) {
            this.listaComposicao = res.data.listaMateriaPrima;
            this.$emit("carregarVariacao", res.data.variacao);
          }
        })
        .catch((err) => showError(err));
    },
  },
  mounted() {
    this.calculadora = this.$store.getters["comercial/getCalculadora"];
    this.listarComposicaoProduto();
  },
};
</script>
