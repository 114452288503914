import UsuarioLista from "Views/Admin/Usuarios/UsuarioLista";
import UsuarioDetalhe from "Views/Admin/Usuarios/UsuarioDetalhe";
import MenuDetalhe from "Views/Admin/Menu/MenuDetalhe";
import MenuLista from "Views/Admin/Menu/MenuLista";
import NivelAcessoLista from "Views/Admin/NiveisAcesso/NivelAcessoLista";
import NivelAcessoDetalhe from "Views/Admin/NiveisAcesso/NivelAcessoDetalhe";
import RelatoriosPowerBiLista from "Views/Admin/PowerBi/RelatoriosPowerBiLista";
import RelatoriosPowerBiDetalhe from "Views/Admin/PowerBi/RelatoriosPowerBiDetalhe";
import ReleasesLista from "Views/Admin/Releases/ReleasesLista";
import Playground from "Views/Admin/Playground";
import Container from "Views/Admin/Container";
import DashboardsLista from "Views/Admin/Dashboards/DashboardsLista";
import DashboardsDetalhe from "Views/Admin/Dashboards/DashboardsDetalhe";
import MensagemLista from "Views/Admin/Mensagens/MensagemLista";
import MensagemDetalhe from "Views/Admin/Mensagens/MensagemDetalhe";
import PowerBiRelatorio from "Views/Admin/PowerBi/PowerBiRelatorio";
import LgpdDocumentos from "Views/Admin/LGPD/LgpdDocumentos";
import LgpdTemporalidade from "Views/Admin/LGPD/LgpdTemporalidade";
import UsuariosLDAPListaView from "Views/Admin/LDAP/UsuariosLDAPListaView";
import SimularUsuarioView from "Views/Admin/Usuarios/SimularUsuarioView";
import InventarioTIView from "Views/Admin/InventarioTI/InventarioTIView";
import ConsoleOutputView from "Views/Admin/InventarioTI/ConsoleOutputView";

const routes = [
  {
    path: "/usuariolista",
    name: "usuariolista",
    component: UsuarioLista,
    meta: {
      requiresAuth: true,
      title: "message.usersList",
      breadcrumb: ["message.admin", "message.usersList"],
    },
  },
  {
    path: "/usuariodetalhe/:id",
    name: "usuariodetalhe",
    component: UsuarioDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.userProfile",
      breadcrumb: ["message.userProfile"],
    },
  },
  {
    path: "/menudetalhe/:id",
    name: "menudetalhe",
    component: MenuDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.menu",
      breadcrumb: ["message.menuList", "message.menu"],
    },
  },
  {
    path: "/menulista",
    name: "menulista",
    component: MenuLista,
    meta: {
      requiresAuth: true,
      title: "message.menuList",
      breadcrumb: ["message.admin", "message.menu"],
    },
  },
  {
    path: "/nivelacessolista",
    name: "nivelacessolista",
    component: NivelAcessoLista,
    meta: {
      requiresAuth: true,
      title: "message.accessLevelList",
      breadcrumb: ["message.admin", "message.accessLevel"],
    },
  },
  {
    path: "/nivelacessodetalhe/:id",
    name: "nivelacessodetalhe",
    component: NivelAcessoDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.accessLevel",
      breadcrumb: ["message.accessLevelList", "message.accessLevel"],
    },
  },
  {
    path: "/relatoriospowerbilista",
    name: "relatoriospowerbilista",
    component: RelatoriosPowerBiLista,
    meta: {
      requiresAuth: true,
      title: "message.reports",
      breadcrumb: ["message.admin", "message.reports"],
    },
  },
  {
    path: "/relatoriospowerbidetalhe/:id",
    name: "relatoriospowerbidetalhe",
    component: RelatoriosPowerBiDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.reportsDetails",
      breadcrumb: [
        "message.admin",
        "message.reports",
        "message.reportsDetails",
      ],
    },
  },
  {
    path: "/releaseslista",
    name: "releaseslista",
    component: ReleasesLista,
    meta: {
      requiresAuth: true,
      title: "message.releaseNotes",
      breadcrumb: ["message.admin"],
    },
  },
  {
    path: "/playground",
    name: "playground",
    component: Playground,
    meta: {
      requiresAuth: true,
      title: "message.playground",
      breadcrumb: ["message.admin"],
    },
  },
  {
    path: "/container",
    name: "container",
    component: Container,
    meta: {
      requiresAuth: true,
      title: "message.container",
      breadcrumb: ["message.admin"],
    },
  },
  {
    path: "/dashboardslista",
    name: "dashboardslista",
    component: DashboardsLista,
    meta: {
      requiresAuth: true,
      title: "message.dashboardsList",
      breadcrumb: ["message.admin", "message.dashboardsList"],
    },
  },
  {
    path: "/dashboardsdetalhe",
    name: "dashboardsdetalhe",
    component: DashboardsDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.dashboard",
      breadcrumb: [
        "message.admin",
        "message.dashboardsList",
        "message.dashboard",
      ],
    },
  },
  {
    path: "/mensagemlista",
    name: "mensagemLista",
    component: MensagemLista,
    meta: {
      requiresAuth: true,
      title: "message.systemMessageList",
      breadcrumb: ["message.admin", "message.systemMessageList"],
    },
  },
  {
    path: "/mensagemdetalhe",
    name: "mensagemdetalhe",
    component: MensagemDetalhe,
    meta: {
      requiresAuth: true,
      title: "message.message",
      breadcrumb: ["message.admin", "message.message"],
    },
  },
  {
    path: "/powerbirelatorio/:id",
    name: "powerbirelatorio",
    component: PowerBiRelatorio,
    meta: {
      requiresAuth: true,
      title: "message.reports",
      breadcrumb: ["message.reports"],
    },
  },
  {
    path: "/lgpddocumentos",
    name: "lgpddocumentos",
    component: LgpdDocumentos,
    meta: {
      requiresAuth: true,
      title: "message.documentsList",
      breadcrumb: ["LGPD", "message.documentsList"],
    },
  },
  {
    path: "/lgpdtemporalidade",
    name: "lgpdtemporalidade",
    component: LgpdTemporalidade,
    meta: {
      requiresAuth: true,
      title: "Temporalidade",
      breadcrumb: ["LGPD", "Temporalidade"],
    },
  },
  {
    path: "/usuariosldap",
    name: "usuariosldap",
    component: UsuariosLDAPListaView,
    meta: {
      requiresAuth: true,
      title: "message.usersList",
      breadcrumb: ["message.admin", "message.LDAP", "message.usersList"],
    },
  },
  {
    path: "/simularusuario",
    name: "simularusuario",
    component: SimularUsuarioView,
    meta: {
      requiresAuth: true,
      title: "message.userSimulate",
      breadcrumb: ["message.admin", "message.users", "message.userSimulate"],
    },
  },
  {
    path: "/inventarioti",
    name: "inventarioti",
    component: InventarioTIView,
    meta: {
      requiresAuth: true,
      title: "message.inventory",
      breadcrumb: ["message.admin", "message.inventory"],
    },
  },
  {
    path: "/simularusuario",
    name: "simularusuario",
    component: SimularUsuarioView,
    meta: {
      requiresAuth: true,
      title: "message.userSimulate",
      breadcrumb: ["message.admin", "message.users", "message.userSimulate"],
    },
  },
  {
    path: "/consoleoutputinventarioti",
    name: "consoleoutputinventarioti",
    component: ConsoleOutputView,
    meta: {
      requiresAuth: true,
      title: "message.consoleOutput",
      breadcrumb: [
        "message.admin",
        "message.inventory",
        "message.consoleOutput",
      ],
    },
  },
];

export default routes;
