<template>
  <div>
    <app-section-loader :status="loader" />
    <q-table
      :loading="loader"
      :rows="rows"
      :columns="columns"
      table-header-class="table-header"
      :rows-per-page-label="$t('message.itemsPerPage')"
      :noDataLabel="$t('message.noDataFound')"
      dense
      :rows-per-page-options="[0]"
      :filter="filter"
      style="z-index: 0"
      :virtual-scroll="!isFullTableMode"
      :class="isFullTableMode ? '' : 'my-sticky-header-table'"
    >
      <template v-slot:top-right>
        <q-input
          class="q-ml-sm"
          borderless
          dense
          debounce="300"
          v-model="filter"
          :placeholder="$t('message.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
        <exportar-excel
          v-if="rows"
          :data="rows"
          name="usuariossetoresgt"
          class="q-mx-sm"
        />
        <q-btn
          flat
          round
          size="small"
          :icon="isFullTableMode ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'"
          @click.stop="isFullTableMode = !isFullTableMode"
        >
          <q-tooltip
            anchor="bottom middle"
            self="center middle"
            :offset="[10, 10]"
            class="text-body2"
            >{{
              isFullTableMode
                ? $t("message.exitFullTableMode")
                : $t("message.fullTableMode")
            }}</q-tooltip
          >
        </q-btn>
        <q-btn flat round size="small" @click.stop="reload()">
          <q-tooltip
            anchor="bottom middle"
            self="center middle"
            :offset="[10, 10]"
            class="text-body2"
            >{{ $t("message.reload") }}</q-tooltip
          >
          <q-icon class="material-icons text-grey-9"> refresh </q-icon>
        </q-btn>
      </template>

      <template v-slot:body-cell-actionsShare="props">
        <q-td auto-width>
          <q-btn
            flat
            dense
            size="sm"
            round
            @click.stop="abrirCompartilhamento(props.row)"
          >
            <q-tooltip
              anchor="bottom middle"
              self="center middle"
              :offset="[10, 10]"
              class="text-body2"
            >
              {{ getMessageTooltip(props.row) }}
            </q-tooltip>
            <q-icon
              class="material-icons"
              :class="getColorCompartilhamento(props.row.licencaCompartilhada)"
              >group</q-icon
            >
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-conectado="props">
        <q-td auto-width>
          <q-chip :color="getColor(props.row.conectado)" dark>
            {{
              props.row.conectado == 1
                ? $t("message.online")
                : $t("message.offline")
            }}
          </q-chip>
        </q-td>
      </template>

      <template v-slot:body-cell-vip="props">
        <q-td auto-width>
          <q-toggle
            v-model="props.row.vip"
            color="blue"
            :disable="atualizandoVip"
            @update:model-value="atualizarVip({ ...props.row })"
            checked-icon="check"
            unchecked-icon="clear"
            :true-value="1"
            :false-value="0"
          >
          </q-toggle>
        </q-td>
      </template>

      <template v-slot:body-cell-actionsRemoveConnection="props">
        <q-td>
          <q-btn
            :disable="props.row.conectado == 0"
            color="yellow-8 material-icons-outlined "
            size="sm"
            @click="removerConexao(props.row)"
            :label="$t('message.removeConnection')"
            class="q-my-xs q-py-xs"
            icon="lock_open"
          />
        </q-td>
      </template>
    </q-table>
    <confirmation-dialog ref="confirmationDialog" @onConfirm="removeConnection">
    </confirmation-dialog>
    <LicencasCompartilhadasModal
      ref="licencasCompartilhadas"
      @atualizarLista="reload()"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import LicencasCompartilhadasModal from "Components/Areas/TI/LicencasCompartilhadasModal";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AcessosAtuaisUsuariosSGT",
  components: { LicencasCompartilhadasModal },

  props: {
    setor: Object,
    empresa: Number,
  },

  data() {
    return {
      loader: true,
      filter: null,
      empresaId: this.empresa,
      isFullTableMode: false,

      atualizandoVip: false,
      selectItemToDelete: null,
      rows: [],
      columns: [
        {
          label: this.$t("message.company"),
          field: "dsEmpresa",
          name: "dsEmpresa",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.sector"),
          field: "descricaoSetor",
          name: "descricaoSetor",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.userName"),
          field: "nomeUserliteral",
          name: "nomeUserliteral",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.user"),
          field: "nomeUsuario",
          name: "nomeUsuario",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsShare",
          name: "actionsShare",
          align: "center",
        },
        {
          label: this.$t("message.userSO"),
          field: "osUser",
          name: "osUser",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.terminal"),
          field: "terminal",
          name: "terminal",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.logonDate"),
          field: "dtLogon",
          name: "dtLogon",
          align: "center",
          sortable: true,
        },
        {
          label: this.$t("message.status"),
          field: "conectado",
          name: "conectado",
          align: "center",
          sortable: true,
          headerStyle: "width: 100px",
        },
        {
          label: this.$t("message.vip"),
          field: "vip",
          name: "vip",
          align: "center",
          sortable: true,
        },
        {
          label: "",
          field: "actionsRemoveConnection",
          name: "actionsRemoveConnection",
          align: "center",
          sortable: true,
          headerStyle: "width: 180px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("ti", ["getFiltroEmpresaID"]),
  },
  watch: {
    getFiltroEmpresaID(value) {
      this.empresaId = value;
      this.listarAcessos();
    },
  },
  methods: {
    ...mapActions("ti", ["setAtualizar"]),

    listarAcessos() {
      this.loader = true;
      let filter = "";
      if (this.empresaId && this.empresaId !== 999) {
        filter = `empresa = ${this.empresaId}`;
      } else {
        if (this.setor) {
          filter = `empresa = ${this.setor.empresa} and cd_setor = ${this.setor.cdSetor}`;
        }
      }
      api
        .get(`/v1/licencas/usuarios?$filter="${filter}"`)
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    abrirCompartilhamento(v) {
      if (v.licencaCompartilhada == "COMPARTILHADA") {
        return;
      } else {
        this.$refs.licencasCompartilhadas.abrirModal(v);
      }
    },

    atualizarVip(item) {
      const posicaoItem = this.rows.findIndex(
        (v) =>
          v.codredusuario == item.codredusuario &&
          v.chaveDoSetor == item.chaveDoSetor
      );
      this.atualizandoVip = true;
      api
        .patch("/v1/licencas/vip", {
          empresa: item.empresa,
          codredusuario: item.codredusuario,
          acao: item.vip,
        })
        .then(() => showSuccess(this.$t("message.successUpdatedRecord")))
        .catch((err) => {
          (this.rows[posicaoItem].vip = 0), showError(err);
        })
        .finally(() => {
          this.setAtualizar();
          this.atualizandoVip = false;
        });
    },

    removerConexao(row) {
      this.$refs.confirmationDialog.open();
      this.selectItemToDelete = row;
    },

    async removeConnection() {
      this.$refs.confirmationDialog.close();
      await api
        .post(`/v1/licencas/finalizar`, this.selectItemToDelete)
        .then(() => {
          this.reload();
          showSuccess(this.$t("message.successUpdatedRecord"));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },

    getColor(v) {
      return v == 1 ? "green-8" : "negative";
    },
    getColorCompartilhamento(v) {
      if (v == "SIM") {
        return "text-green-8";
      } else if (v == "NÃO") {
        return "text-negative";
      } else {
        return "text-yellow-8";
      }
    },

    getMessageTooltip(v) {
      if (v.licencaCompartilhada == "COMPARTILHADA") {
        return `${this.$t("message.licenseOwner")}: ${v.donoLicenca}`;
      }
      if (v.licencaCompartilhada == "SIM") {
        return this.$t("message.sharedLicense");
      }
      if (v.licencaCompartilhada == "NÃO") {
        return this.$t("message.notSharedLicense");
      }
    },

    reload() {
      this.listarAcessos();
      this.setAtualizar();
    },
  },
  mounted() {
    this.listarAcessos();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 390px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
