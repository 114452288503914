<template>
  <div>
    <q-table
      :loading="loader"
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      hide-pagination
      virtual-scroll
    >
      <template v-slot:body-cell-permitido="props">
        <q-td>
          <q-toggle
            v-model="props.row.permitido"
            color="blue"
            :disable="atualizandoPermissao"
            :label="definirLegenda(props.row.permitido)"
            @update:model-value="atualizarPermissao({ ...props.row })"
            checked-icon="check"
            unchecked-icon="clear"
            :true-value="1"
            :false-value="0"
            :toggle-indeterminate="false"
          ></q-toggle>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ListaDeDashboardsPorNivelAcesso",

  data() {
    return {
      loader: true,

      rows: [],

      atualizandoPermissao: false,

      id: this.$route.params.id,

      columns: [
        {
          label: "ID",
          field: "dashboardId",
          name: "dashboardId",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.description"),
          field: "dashboardDescricao",
          name: "dashboardDescricao",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.permission"),
          field: "permitido",
          name: "permitido",
          align: "center",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    listarDashboards() {
      api
        .get(`v1/nivelAcesso/${this.id}/dashboards/`)
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    atualizarPermissao(item) {
      this.atualizandoPermissao = true;
      item.permitido = item.permitido ? 1 : 0;
      const refresh = () => {
        this.listarDashboards();
        this.atualizandoPermissao = false;
        this.$emit("change");
      };
      if (item.permitido) {
        api
          .post("/v1/dashboards/concederpermissao", {
            dashboardId: item.dashboardId,
            nivelAcessoId: this.id,
          })
          .catch((err) => showError(err))
          .finally(() => refresh());
      } else {
        api
          .delete(`/v1/dashboards/revogarpermissao/${item.dashboardAllowId}`)
          .catch((err) => showError(err))
          .finally(() => refresh());
      }
    },

    definirLegenda(v) {
      return v == 1
        ? this.$t("message.allowed")
        : this.$t("message.notAllowed");
    },
  },
  created() {
    this.listarDashboards();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 480px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
