<template>
  <div class="q-pa-xs">
    <page-title-bar :bi="true" />
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-xs">
      <power-bi-container-iframe
        v-if="!loader && embedContent"
        :embedContent="embedContent"
        :key="keyIframe"
      ></power-bi-container-iframe>
    </div>
  </div>
</template>

<script>
import PowerBiContainerIframe from "Components/Areas/Admin/PowerBi/PowerBiContainerIframe";
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "PowerBiRelatorio",
  components: { PowerBiContainerIframe },

  data() {
    return {
      loader: true,
      embedContent: null,
      keyIframe: 0,
    };
  },
  watch: {
    $route: function (route) {
      if (route.name == "powerbirelatorio") {
        const id = route.params.id || 0;
        this.buscarRelatorio(id);
      }
    },
  },
  methods: {
    buscarRelatorio(id) {
      api
        .get(`/v1/powerbi/${id}`)
        .then((res) => {
          this.embedContent = res.data.embedcontent;
          if (!this.embedContent) {
            throw "URL do Powerbi não definida, avisar o T.I.";
          } else {
            const filtro = res.data.filtro;
            this.embedContent = this.embedContent + `&filter=${filtro}`;
          }
          this.keyIframe++;
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    const id = this.$route.params.id;
    this.buscarRelatorio(id);
  },
};
</script>
