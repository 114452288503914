<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <q-table
        :loading="loader"
        :rows="rows"
        :columns="columns"
        :filter="filter"
        dense
        hide-pagination
        table-header-class="table-header"
        :rows-per-page-options="[0]"
        :noDataLabel="$t('message.noDataFound')"
        class="my-sticky-header-table"
        virtual-scroll
      >
        <template v-slot:top-right>
          <div class="r7-switch-toggle switch-3 switch-candy">
            <input id="on" name="state-d" type="radio" checked="" />
            <label for="on" @click="filtrar('ativos')">Regra Ativa</label>

            <input id="na" name="state-d" type="radio" checked="checked" />
            <label for="na" @click="filtrar('todos')">Todas</label>
            <input id="off" name="state-d" type="radio" />
            <label for="off" @click="filtrar('inativos')">Regra Inativa</label>
          </div>

          <q-input
            class="q-ml-sm"
            borderless
            dense
            debounce="300"
            v-model="filter"
            :placeholder="$t('message.search')"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <exportar-excel
            v-if="rows"
            :data="rows"
            name="listaprodutoscru"
            class="q-mx-sm"
          />
        </template>
        <template v-slot:body-cell-flexigenegociacaocom="props">
          <q-td auto-width>
            <q-checkbox
              v-model="props.row.flexigenegociacaocom"
              true-value="1"
              false-value="0"
              @input="checked(props.row.flexigenegociacaocom)"
              @click="props.row.click = true"
              :type="props.row.click ? isDanger : isInfo"
            >
            </q-checkbox>
          </q-td>
        </template>
      </q-table>
    </div>
  </div>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "RegraNegocio",
  data() {
    return {
      loader: true,
      isDanger: "danger",
      isInfo: "primary",
      dadosOri: [],
      rows: [],
      filter: null,
      columns: [
        {
          label: this.$t("message.rawArticleCode"),
          field: "cditemartigocru",
          name: "cditemartigocru",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.rawArticleItem"),
          field: "dsitemartigocru",
          name: "dsitemartigocru",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.tradingRules"),
          field: "flexigenegociacaocom",
          name: "flexigenegociacaocom",
          align: "center",
        },
      ],
    };
  },
  methods: {
    checked(item) {
      this.rows.find(
        (e) => e.cditemartigocru === item.cditemartigocru
      ).flexigenegociacaocom = item.flexigenegociacaocom;

      api
        .put(`/v1/comercial/regradenegocio/atualizar/${item.cditemartigocru}`, {
          flexigenegociacaocom: item.flexigenegociacaocom,
        })
        .then(() => {
          showSuccess(this.$t("message.successUpdatedRecord"));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
    colorChange(e) {
      this.$emit("change", e);
    },

    retrieveList() {
      api
        .post("/v1/produtos/cru/listaprodutos")
        .then((response) => {
          this.loader = false;
          this.rows = response.data;
          this.dadosOri = this.rows;
        })
        .catch((err) => showError(err));
    },
    async filtrar(acao) {
      this.rows = this.dadosOri;
      let dados = this.dadosOri;
      if (acao === "ativos") {
        dados = await this.rows.filter((v) => {
          return v.flexigenegociacaocom === "1";
        });
      }
      if (acao === "inativos") {
        dados = await this.rows.filter((v) => v.flexigenegociacaocom === "0");
      }

      this.rows = dados;
    },
  },
  created() {
    this.retrieveList();
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 710px
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
