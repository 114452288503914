<template lang="">
  <div class="row">
    <div class="col-3">
      <span class="text-bold">Localização</span>
    </div>
    <div class="col-3">
      <span class="text-bold">Data</span>
    </div>
    <div class="col-6">
      <span class="text-bold">Status</span>
    </div>
  </div>
  <q-separator />
  <template v-for="(evento, index) in eventos" :key="index">
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col">
            {{ evento.cidade }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span v-if="evento.estado">({{ evento.estado }})</span>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row">
          <div class="col">{{ evento.data }},</div>
        </div>
        <div class="row">
          <div class="col">{{ evento.horario }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-10">
            {{ evento.status }}
          </div>
          <div class="col-2">
            <q-btn flat dense round small v-if="evento.comprovante">
              <q-icon
                title="Ver Comprovante de Entrega"
                size="xs"
                color="blue darken-2"
                class="material-icons"
                @click="abrirComprovante(evento.comprovante)"
              >
                edit_location_alt
              </q-icon>
            </q-btn>
          </div>
        </div>
      </div>
    </div>
    <q-separator />
  </template>
</template>
<script>
import Api from "Api";
import { showError } from "@/notifications/notify";
import { Loading, QSpinnerGears } from "quasar";
export default {
  name: "RastreioObjetosView",
  data() {
    return {
      loader: true,
      objeto: this.$route.params.objeto,
      eventos: [
        {
          cidade: null,
          estado: null,
          data: null,
          horario: null,
          status: null,
          comprovante: null,
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.objeto = to.params.objeto;
    },
  },
  methods: {
    obterRastreio() {
      let URL = `/v1/rastreio/correios/`;

      if (/^[0-9]+$/.test(this.objeto)) {
        URL = `/v1/rastreio/loggi/`;
      }
      Loading.show({
        message: "Buscando...",
        spinner: QSpinnerGears,
      });

      Api.get(URL + this.objeto)
        .then((res) => {
          this.eventos = res.data;
        })
        .catch((err) => {
          if ((err.response.status = "404")) {
            showError("Rastreio não encontrado");
          } else {
            showError(err);
          }
        })
        .finally(Loading.hide);
    },

    abrirComprovante(comprovante) {
      window.open(comprovante.url, "_blank");
    },
  },
  mounted() {
    this.obterRastreio();
  },
};
</script>
