<template>
  <div>
    <q-card flat tile class="q-mb-xm bg-grey-4">
      <q-toolbar dense>
        <q-toolbar-title>{{ title }}</q-toolbar-title>
        <q-space />
        <slot name="funcoes"></slot>
      </q-toolbar>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  props: ["title", "customClass"],
};
</script>
