import { useToast } from "vue-toastification";
const toast = useToast();

const errors = [
  { status: 401, description: "Não Autenticado" },
  { status: 404, description: "Página/Api não encontrada" },
  { status: 403, description: "Acesso Negado" },
  { status: 400, description: "Requisição Inválida" },
  { status: 490, description: "Dados não encontrados!" },
];

export function showError(e) {
  const trataMessagemOracle = (msg) => msg.substr(msg.lastIndexOf("ORA-") + 11);

  if (!e) return;
  const objError = {
    status: e.response?.status,
    texto: e.response?.statusText,
    metodo: e.response?.config.method,
    url: e.response?.config.url,
    data: e.response?.data,
    message: e.message,
  };
  let msg = e;

  if (objError && objError.texto) {
    const msgError = errors.filter((f) => f.status === objError.status);
    if (msgError.length > 0) {
      msg = objError.data || msgError[0].description;
      if (msgError[0].status === 404) {
        msg = `${msgError[0].description} [ ${objError.metodo}/${objError.url} ]`;
      }
      if (msgError[0].status === 400) {
        if (objError.data?.error) {
          msg = objError.data.error;
        } else {
          msg = objError.data;
        }
      }
    } else {
      msg = `${objError.status} - ${objError.texto} [ ${objError.metodo}/${objError.url} ]`;
    }
  } else if (e && e.response && e.response.data) {
    msg = e.response.data;
  } else if (typeof e === "string" || objError.message) {
    const error = objError.message ? objError.message : e;
    msg = error.indexOf("ORA-") !== -1 ? trataMessagemOracle(error) : error;
  } else {
    msg = "Ooops...Ocorreu um erro inesperado!";
  }
  toast.error(msg, { timeout: 5000 });
}

export function showSuccess(e) {
  toast.success(e, { timeout: 2000 });
}

export function showAlert(e) {
  toast.warning(e, { timeout: 2000 });
}

export function showSelectedSuccess(e) {
  toast.info(e, { timeout: 2000 });
}

export default { showError, showSuccess, showAlert, showSelectedSuccess };
