<template>
  <page-title-bar></page-title-bar>
  <div class="q-px-md">
    <ProdutosConfeccaoListaComponente />
  </div>
</template>

<script>
import ProdutosConfeccaoListaComponente from "Components/Areas/Produtos/Confeccao/ProdutosConfeccaoListaComponente";
export default {
  name: "ProdutosConfeccaoListaView",
  components: {
    ProdutosConfeccaoListaComponente,
  },
};
</script>

<style></style>
