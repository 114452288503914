<template>
  <page-title-bar></page-title-bar>
  <div class="q-pa-md">
    <ListaFranqueadosComponent />
  </div>
</template>

<script>
import ListaFranqueadosComponent from "Areas/Faturamento/Franquias/ListaFranqueadosComponent";

export default {
  name: "ListaFranqueadosView",
  components: {
    ListaFranqueadosComponent,
  },
};
</script>
