// messages.js
export default {
  general: "General",
  home: "Home",
  overview: "Visión de conjunto",
  customizer: "Personalizador",
  applications: "Aplicaciones",
  features: "Caracteristicas",
  components: "Componentes",
  salesAndVisitStats: "Estadísticas de ventas y visitas",
  orderAndProjectStats: "Estadísticas de pedidos y proyectos",
  fitnessStats: "Estadísticas de estado físico",
  supportAndUsefulWidgets: "Soporte y widgets útiles",
  dashboard: "Tablero",
  dashboardOverview: "Vista general del tablero",
  dashboardv1: "Tablero V1",
  dashboardv2: "Tablero V2",
  widgets: "Widgets",
  horizontalMenu: "Menú horizontal",
  pages: "Páginas",
  gallery: "Galería",
  pricing: "Precios",
  pricing1: "Precios 1",
  pricing2: "Precios 2",
  blank: "Blanco",
  session: "Sesión",
  signUp1: "Regístrese 1",
  signUp2: "Registrarse 2",
  login1: "Login 1",
  login2: "Login 2",
  lockScreen: "Bloquear pantalla",
  uiElements: "Elementos de UI",
  buttons: "Botones",
  cards: "Tarjetas",
  checkbox: "Caja",
  carousel: "Carrusel",
  chips: "Papas fritas",
  datepicker: "Selector de fechas",
  dialog: "Diálogo",
  grid: "Cuadrícula",
  input: "Entrada",
  list: "Lista",
  menu: "Menú",
  progress: "Progreso",
  radio: "Radio",
  select: "Seleccionar",
  slider: "Control deslizante",
  snackbar: "Snackbar",
  tabs: "Pestañas",
  toolbar: "Barra de herramientas",
  tooltip: "Información sobre herramientas",
  timepicker: "Timepicker",
  forms: "Formularios",
  formValidation: "Validación de formulario",
  stepper: "Paso a paso",
  charts: "Gráficos",
  vueChartjs: "Vista de Chartjs",
  vueEcharts: "Vue Echarts",
  icons: "Iconos",
  themify: "Themify",
  material: "Material",
  tables: "Mesas",
  standard: "Estándar",
  slots: "Slots",
  selectable: "Seleccionable",
  searchRow: "Fila de búsqueda",
  maps: "Mapas",
  googleMaps: "mapas de Google",
  leafletMaps: "Mapas de folletos",
  jvectorMap: "Mapa Jvector",
  inbox: "Bandeja de entrada",
  users: "Usuarios",
  userProfile: "Perfil del usuario",
  usersList: "Lista de usuarios",
  calendar: "Calendario",
  editor: "Editor",
  quillEditor: "Quill Editor",
  wYSIWYG: "WYSIWYG",
  vue2Dragula: "Vue2 Dragula",
  dragAndDrop: "Arrastrar y soltar",
  vueDraggable: "Vue Draggable",
  draggableResizeable: "Dragizable Resizeable",
  chat: "Charla",
  todo: "Todo",
  modules: "Módulos",
  user: "Usuario",
  miscellaneous: "Diverso",
  promo: "Promoción",
  connections: "Conexiones",
  follow: "Seguir",
  unfollow: "Dejar de seguir",
  basicValidation: "Validación Básica",
  validationWithSubmitAndclear: "Validación con submit & clear",
  clear: "Claro",
  step1: "Paso 1",
  step2: "Paso 2",
  step3: "Paso 3",
  continue: "Continuar",
  cancel: "Cancelar",
  totalEarnings: "Ganancias Totales",
  onlineRevenue: "Ingresos en línea",
  offlineRevenue: "Ingresos sin conexión",
  marketingExpenses: "Gastos de mercadotecnia",
  newCustomers: "nuevos clientes",
  visitAndSalesStatistics: "Estadísticas de visita y ventas",
  collapse: "Colapso",
  reload: "Recargar",
  close: "Cerca",
  bandwidthUsage: "Uso de Ancho de Banda",
  shoppingCart: "Carrito de compras",
  totalDownloads: "Descargas totales",
  productSales: "Venta de productos",
  projectStatus: "Estado del proyecto",
  averageSteps: "Pasos promedio",
  todaysDistance: "Distancia de hoy",
  todaysStep: "El paso de hoy",
  todaysGoal: "Meta de hoy",
  calories: "Calorías",
  weeklySummary: "Resumen semanal",
  supportTickets: "Boletos de soporte",
  todoList: "Lista de quehaceres",
  newsletterCampaign: "Campaña del boletín",
  currentVisitors: "Visitantes actuales",
  newMembers: "Nuevos miembros",
  addNewBlog: "Agregar nuevo blog",
  add: "Añadir",
  logOut: "Añadir",
  totalAppMemory: "Total de memoria de la aplicación",
  totalMemoryUsed: "Memoria total utilizada",
  unreadMail: "12 correo no leído",
  feedback: "Realimentación",
  flatButton: "Botón plano",
  raisedButton: "Botón elevado",
  gradientButton: "Botón de degradado",
  buttonDropdownVariants: "Variantes desplegables de botones",
  buttonToggle: "Botón Alternar",
  icon: "Icono",
  normal: "Normal",
  disabled: "Discapacitado",
  floating: "Flotante",
  loaders: "Cargadores",
  sizing: "Dimensionamiento",
  outline: "contorno",
  round: "Redondo",
  block: "Bloquear",
  search: "Buscar",
  checkboxesBoolean: "Casillas de verificación Booleanas",
  checkboxesArray: "Casillas de verificación Matriz",
  checkboxesStates: "Casillas de verificación: estados",
  checkboxesColors: "Casillas de verificación - Colores",
  switches: "Interruptores",
  default: "Defecto",
  customTransition: "Transición personalizada",
  customDelimiter: "Delimitador personalizado",
  hideControls: "Ocultar controles",
  hideDelimiters: "Ocultar delimitadores",
  colored: "De colores",
  label: "Etiqueta",
  closeable: "Cerrable",
  datePickerHorizontal: "Selector de fecha horizontal",
  datePickerVertical: "Selector de fecha vertical",
  datePickersInDialogAndMenu: "Selector de Fecha- En Diálogo y Menú",
  datePickersWithModal: "Selector de fecha con modal",
  datePickersFormattingDate: "Selector de fecha: fecha de formateo",
  simpleDialogs: "Cuadros de diálogo simples",
  withoutActivator: "Sin activador",
  scrollable: "Desplazable",
  listOfAllMaterialIcons: "Lista de todos los iconos de material",
  arrowsAndDirectionsIcons: "FLECHAS Y ICONOS DE DIRECCIÓN",
  webAppIcons: "ICONOS DE APLICACIONES WEB",
  CONTROLICONS: "ICONOS DE CONTROL",
  TEXTEDITOR: "EDITOR DE TEXTO",
  LAYOUTICONS: "ICONOS DE DISEÑO",
  BRANDICONS: "ICONOS DE MARCA",
  newClients: "Nuevos clientes",
  recurringClients: "Clientes recurrentes",
  bounceRate: "Porcentaje de rebote",
  pageViews: "Vistas de página",
  usefulWidgets: "Widgets útiles",
  sale: "Venta",
  sales: "Ventas",
  invoices: "Facturas",
  referrals: "Referencias",
  serverLoad: "Carga del servidor",
  recentSale: "Venta reciente",
  supportRequest: "Solicitud de soporte",
  productSale: "Venta de productos",
  viewAll: "VER TODO",
  barChart: "Gráfico de barras",
  polarAreaChart: "Gráfico de área polar",
  lineChart: "Gráfico de linea",
  radarChart: "Gráfico de radar",
  doughnutChart: "Carta de Donut",
  bubbleChart: "Gráfico de burbujas",
  gradientLineChart: "Gradient Line Chart",
  pieChart: "Gráfico circular",
  funnelChart: "Gráfico de embudo",
  loginNow: "Inicia sesión ahora",
  createAccount: "Crear una cuenta",
  termsOfService: "Términos de servicio",
  bySigningUpYouAgreeTo: "Al registrarte aceptas",
  loginToAdmin: "Ingresar a la administración",
  enterUsernameAndPasswordToAccessControlPanelOf:
    "Ingrese nombre de usuario y contraseña",
  // "Ingrese nombre de usuario y contraseña para acceder al panel de control de",
  havingAnAccount: "Tener una cuenta?",
  password: "Contraseña",
  remember: "Recuerda",
  quickLinks: "enlaces rápidos",
  plans: "Planes",
  chooseThePlanThatWorksForYou: "Elija el plan que funcione para usted.",
  monthly: "Mensual",
  yearlyGet2MonthExtra: "Anualmente (obtenga 2 meses adicionales)",
  basic: "BASIC",
  pro: "Pro",
  advanced: "Avanzado",
  basicFree: "Básico (Gratis)",
  startToBasic: "Comience a básico",
  upgradeToPro: "Actualizar a Pro.",
  upgradeToAdvance: "Actualizar a Advance",
  comparePlans: "Comparar planes",
  frequentlyAskedQuestions: "Preguntas frecuentes",
  defaultInput: "Entrada predeterminada",
  activator: "Activador",
  hover: "Flotar",
  menus: "Menús",
  indeterminate: "Indeterminado",
  sizeAndWidth: "Tamaño y ancho",
  rotate: "Girar",
  determinate: "Determinado",
  buffer: "Buffer",
  radiosDefault: "Radios predeterminados",
  radiosDirectionRow: "Línea de dirección de radios",
  radiosDirectionColumn: "Columna de dirección de radios",
  radiosColors: "Radios - Colores",
  switchesColors: "Interruptores - Colores",
  continuous: "Continuo",
  discrete: "Discreto",
  customColors: "Colores personalizados",
  editableNumericValue: "Valor numérico editable",
  contextualSnackbar: "Barra de snack contextual",
  showSnackbar: "Mostrar Snackbar",
  centeredTabs: "Fichas centradas",
  toolbarTabs: "Pestañas de la barra de herramientas",
  theDefaultColorToolbar: "La barra de herramientas de color predeterminada",
  appBar: "Barra de aplicaciones",
  appBarWithExtension: "Barra de aplicaciones con extensión",
  visibility: "Visibilidad",
  top: "Parte superior",
  right: "Derecha",
  bottom: "Fondo",
  left: "Izquierda",
  toggle: "Palanca",
  timePicker: "Selector de tiempo",
  timePickerInDialogAndMenu:
    "Selector de tiempo: en el cuadro de diálogo y el menú",
  itemsAndHeaders: "Artículos y encabezados",
  selectableRows: "Filas seleccionables",
  headerCell: "Header CellHeader Cell",
  expand: "Expandir",
  recentChat: "Chat reciente",
  previousChats: "Chats anteriores",
  addNewItems: "Agregar nuevos artículos",
  addToDo: "Añadir para hacer",
  yes: "Sí",
  ok: "De acuerdo",
  activeUsers: "Usuarios activos",
  addNew: "Agregar nuevo",
  readMore: "Lee mas",
  assignNow: "Asignar ahora",
  totalRequest: "Solicitud total",
  new: "Nuevo",
  pending: "Pendiente",
  update: "Actualizar",
  updated10MinAgo: "Actualizado hace 10 min",
  addNewCustomer: "Añadir nuevo cliente",
  name: "Nombre",
  email: "Email",
  editMember: "Editar miembro",
  scheduleDate: "Fecha de programacion",
  title: "Título",
  newEmails: "Nuevos correos electrónicos",
  newEmail: "Nuevo Email",
  employeePayroll: "Nómina de empleados",
  forMostOfTheUsers: "Para la mayoría de los usuarios",
  choosePlan: "Elija Plan",
  mega: "Mega",
  master: "Dominar",
  forDeveloper: "Para desarrollador",
  forLargeEnterprises: "Para grandes empresas",
  composeEmail: "Escribir correo",
  mailboxes: "Buzones",
  folders: "Carpetas",
  inbox5: "Bandeja de entrada (5)",
  draft: "Borrador",
  starred: "Sembrado de estrellas",
  sentMessages: "Mensajes enviados",
  spam: "Correo no deseado",
  delete: "Borrar",
  work: "Trabajo",
  personal: "Personal",
  manageFolders: "Administrar carpetas",
  contacts: "Contactos",
  settings: "Configuraciones",
  themeOptions: "Opciones de tema",
  darkMode: "Modo oscuro",
  collapseSidebar: "Contraer la barra lateral",
  boxLayout: "Diseño de caja",
  rtlLayout: "Diseño Rtl",
  backgroundImage: "Imagen de fondo",
  sidebarFilters: "Filtros de la barra lateral",
  danger: "Peligro",
  primary: "Primario",
  warning: "Advertencia",
  success: "Éxito",
  info: "Información",
  dailySales: "Ventas diarias",
  trafficChannel: "Canal de tráfico",
  campaignPerformance: "Rendimiento de la campaña",
  goToCampaign: "Ir a Campaña",
  fullScreen: "Pantalla completa",
  ecommerce: "E-Commerce",
  shopWithAlgolia: "Tienda Con Algolia",
  cart: "Carro",
  total: "Total",
  totalPrice: "Precio total",
  viewCart: "Ver carro",
  checkout: "Revisa",
  apply: "Aplicar",
  noItemsFound: "No se encontraron artículos",
  billingDetails: "Detalles de facturación",
  placeOrder: "Realizar pedido",
  addNewCard: "Agregar nueva tarjeta",
  devicesShare: "Dispositivos compartidos",
  customerOverview: "Descripción del cliente",
  reviews: "Comentarios",
  weeklySales: "Ventas semanales",
  recentOrders: "órdenes recientes",
  categorySales: "Categoría Ventas",
  webAnalytics: "analista de la red",
  topSelling: "Más vendidos",
  social: "Social",
  newPost: "Nueva publicación",
  publish: "Publicar",
  magazine: "Revista",
  editUser: "editar usuario",
  addNewUser: "Añadir nuevo usuario",
  dark: "Oscuro",
  light: "Ligero",
  recentUsers: "Usuarios recientes",
  activityAroundWorld: "Actividad alrededor del mundo",
  adsIncome: "Ingresos de anuncios",
  recentComments: "Comentarios Recientes",
  recentNotifications: "Notificaciones recientes",
  messages: "Mensajes",
  edit: "Editar",
  topAuthors: "Autores principales",
  marketingCampaign: "CAMPAÑA de Marketing",
  article: "Artículo",
  websiteTraffic: "Tráfico del sitio web",
  agency: "Agencia",
  recent: "Reciente",
  trending: "Tendencias",
  totalPageViews: "Páginas vistas en total",
  impressions: "Impresiones",
  deviceSeparations: "Separaciones de dispositivos",
  browserStatics: "Estadísticas del navegador",
  totalDownloading: "Descarga total",
  notifications: "Notificaciones",
  totalBookmarked: "Total marcado como favorito",
  itemsDownloads: "Artículos Descargas",
  itemsUploaded: "Artículos cargados",
  totalAnalytics: "Total Analytics",
  hotKeywords: "Palabras clave calientes",
  MaleUsers: "Usuarios Masculinos",
  serverStatus: "El estado del servidor",
  purchaseVuely: "Compra Vuely",
  letsGetInTouch: "Mantengámonos en contacto",
  maleUsers: "Usuarios Masculinos",
  femaleUsers: "Usuarios femeninos",
  androidUsers: "Usuarios de Android",
  iOSUsers: "Usuarios de iOS",
  advance: "Avanzar",
  routerAnimation: "Animación de enrutador",
  salesAndEarning: "Ventas y Ganancias",
  netProfit: "Beneficio neto",
  totalExpences: "Gastos totales",
  photos: "Fotos",
  adCampaignPerfomance: "Campaña publicitaria Perfomance",
  profitShare: "División de ganancias",
  topSellingTheme: "Tema más vendido",
  newRequest: "Nueva solicitud",
  followers: "Seguidores",
  mailbox: "Buzón",
  sent: "Expedido",
  birthdays: "Cumpleaños",
  events: "Eventos",
  newsletter: "Hoja informativa",
  lastMonth: "El mes pasado",
  allTime: "Todo el tiempo",
  composeNewEmail: "Componer nuevo correo electrónico",
  activity: "Actividad",
  message: "Mensaje",
  selectTheme: "Seleccione el tema",
  continueShopping: "Seguir comprando",
  proceedToCheckout: "Pasar por la caja",
  headerFilters: "Filtros de encabezado",
  blog: "Blog",
  signUp: "BlogRegistrarse",
  login: "Iniciar sesión",
  news: "Noticias",
  topHeadlines: "Titulares principales",
  visitors: "Visitantes",
  subscribers: "Suscriptores",
  twitterFeeds: "Twitter Feeds",
  extensions: "Extensiones",
  imageCropper: "Imagen Cropper",
  videoPlayer: "Reproductor de video",
  dropzone: "Zona de descenso",
  baseConfig: "Configuración de base",
  audioTrackAndPlaysInline: "Pista de audio y reproducciones en línea",
  hlsLive: "HLS Live",
  forgotPassword: "Se te olvidó tu contraseña",
  resetPassword: "Restablecer la contraseña",
  backToSignIn: "Volver a iniciar sesión",
  enterYourEmailToSendYouAResetLink:
    "Ingrese su correo electrónico para enviarle un enlace de reinicio",
  pleaseEnterYourPasswordToReset:
    "Por favor ingrese su contraseña para restablecer",
  saas: "SAAS",
  overallTrafficStatus: "Estado general del tráfico",
  projectTaskManagement: "Project Task Management",
  totalSales: "Ventas totales",
  simple: "Sencillo",
  transitions: "Transiciones",
  tax: "Impuesto",
  expenses: "Gastos",
  images: "Imágenes",
  ratings: "Calificaciones",
  containAndCover: "Contener y cubrir",
  height: "Altura",
  fixedRatio: "Proporción fija",
  gradients: "Gradientes",
  sizeVariants: "Variantes de tamaño",
  newIcons: "Nuevos iconos",
  webApplication: "Aplicación web",
  person: "Persona",
  file: "Archivo",
  comment: "Comentario",
  form: "Formar",
  hardware: "Hardware",
  directional: "Direccional",
  mapAliases: "Mapa (alias)",
  dateAndTime: "Fecha y hora",
  groups: "Los grupos",
  buttonGroups: "Grupos de botones",
  itemGroups: "Grupos de artículos",
  windows: "Windows",
  onBoarding: "En el embarque",
  accountCreation: "Creación de cuenta",
  treeview: "Vista de árbol",
  customSelectableIcons: "Iconos seleccionables personalizados",
  timelines: "Líneas de tiempo",
  scopedSlots: "Ranuras de ámbito",
  asyncItems: "Async Items",
  smallDots: "Pequeños puntos",
  iconDots: "Puntos de iconos",
  coloredDots: "Puntos de colores",
  oppositeSlot: "Ranura opuesta",
  denseAlert: "Alerta densa",
  fixedTabs: "Pestañas fijas",
  rightAlignedTabs: "Pestañas alineadas a la derecha",
  content: "Contenido",
  withSearch: "Con busqueda",
  iconsAndText: "Iconos y texto",
  grow: "Crecer",
  pagination: "Paginación",
  customIcons: "Iconos personalizados",
  courses: "Los cursos",
  coursesList: "Lista de cursos",
  courseDetail: "Detalles de los cursos",
  signIn: "Registrarse",
  payment: "Pago",
  LearnWithYourConvenience: "Aprende con tu conveniencia",
  management: "administración",
  design: "Diseño",
  development: "Desarrollo",
  bestseller: "Mejor vendido",
  addToCart: "Añadir a la cesta",
  popularInstructors: "Instructores populares",
  moreCoursesFromJamesColt: "Más cursos de James Colt",
  whatYoWillLearn: "Lo que vas a aprender",
  description: "Descripción",
  aboutInstructor: "Sobre el instructor",
  userSignIn: "Iniciar sesión de usuario",
  guestCheckout: "Checkout de invitado",
  continueAsGuest: "Continua como invitado",
  paymentOptions: "Opciones de pago",
  offerCode: "Ofrece código",
  enterCardDetails: "Ingrese los detalles de la tarjeta",
  submit: "Enviar",
  makePayment: "Hacer el pago",
  usage: "Uso",
  withContent: "Con contenido",
  customHeight: "Altura personalizada",
  viewer: "Espectadora",
  trade: "Comercio",
  upcomingEvents: "Próximos Eventos",
  ongoingProjects: "Proyectos en marcha",
  viewAllNotifications: "Ver todas las notificaciones",
  learnMore: "Aprende más",
  payments: "Pagos",
  taxRates: "Las tasas de impuestos",
  addTickets: "Añadir entradas",
  projects: "Proyectos",
  clients: "Clientela",
  reports: "Informes",
  officeManagement: "Office Management",
  projectManagement: "Project Management",
  hotelManagement: "Hotel Management",
  projectGrid: "Proyecto Cuadrícula",
  projectData: "Datos del proyecto",
  videoCallingApp: "Video Calling App",
  hospitalAdministration: "Hospital Administration",
  revenue: "Ingresos",
  deals: "Ofertas",
  transactionList: "Lista de transacciones",
  transferReport: "Informe de transferencia",
  expenseCategory: "Categoría de gastos",
  allClients: "Todos los clientes",
  recentlyAdded: "Recientemente añadido",
  favourite: "Favorita",
  tradeHistory: "Historia del comercio",
  safeTrade: "Comercio seguro",
  exchangeStatistics: "Estadísticas de intercambio",
  quickTrade: "Comercio rapido",
  recentTrades: "Oficios recientes",
  exchangeRate: "Tipo de cambio",
  coinsList: "Lista de monedas",
  currenciesAvailable: "Monedas disponibles",
  receivedAmount: "Cantidad recibida",
  sentAmount: "Cantidad enviada",
  totalAmount: "Cantidad total",
  walletAddress: "Dirección del monedero",
  withdraw: "Retirar",
  deposit: "Depositar",
  bankDetails: "Detalles del banco",
  phone: "Teléfono",
  account: "Cuenta",
  buyCryptocurrency: "Comprar criptomoneda",
  expenditureStats: "Estadísticas de gastos",
  sellCryptocurrency: "Vender criptomoneda",
  transferCryptocurrency: "Criptomoneda de transferencia",
  buyOrSell: "Comprar / Vender",
  crm: "CRM",
  crypto: "Cripto",
  supervisor: "Supervisora",
  duration: "Duración",
  netWorth: "Valor neto",
  liveChatSupport: "Soporte de chat en vivo",
  marketCap: "Tapa del mercado",
  wallet: "Billetera",

  budget: "Presupuesto",
  department: "Departamento",
  projectManager: "Gerente de proyecto",
  team: "Equipo",
  status: "Estado",
  deadline: "Fecha tope",
  filesUploaded: "Archivos subidos",
  statistics: "Estadística",
  projectGallery: "Galería de proyectos",
  projectDetails: "detalles del proyecto",
  client: "Cliente",
  projectList: "Lista de proyectos",
  shop: "tienda",
  productDetail: "Detalle del producto",
  addProduct: "Añadir Producto",
  editProduct: "Editar producto",
  editDetail: "Editar detalle",

  fileInput: "Entrada de archivo",
  colorPickers: "Selectores de color",
  size: "tamaño",
  validation: "Validación",
  playground: "Patio de recreo",
  chipGroups: "Grupos de chips",
  overlays: "Superposiciones",
  slideGroups: "Grupos de diapositivas",
  simpleTable: "Tabla simple",
  listItemGroups: "Grupos de elementos de lista",
  banners: "Pancartas",
  appBars: "Barras de aplicaciones",

  // Novos
  emailRequired: "Correo electronico requerido",
  userRequired: "Usuario requerido",
  passwordRequired: "Se requiere contraseña",

  required: "Obligatorio",
  maxOccursExceeded: "Se excedió el número máximo de ocurrencias",
  confirm: "Confirmar",
  areYouSureYouWantToDelete: "¿Estas seguro que quieres borrarlo?",
  areYouSureYouWantToDeleteThisRecord:
    "¿Está seguro de que desea eliminar este registro de forma permanente?",
  descriptionRequired: "Se requiere una descripción",
  successAddedRecord: "¡Registro incluido con Success!",
  successUpdatedRecord: "¡Registro actualizado con éxito!",
  successDeletedRecord: "¡Registro eliminado correctamente!",
  nameRequired: "¡Se requiere el nombre!",
  emailMustBeValid: "El email debe ser válido",
  accessLevel: "Nivel de acesso",
  save: "Guardar",
  accessLevelRequired: "Nivel de acceso requerido",
  mustbelessthan: "debe tener menos de",
  characters: "caracteres",
  requestSent: "Solicitud enviada",
  confirmPassword: "Confirmar Contraseña",
  newPassword: "Nueva contraseña",
  messagepPasswordNotMatch: "Las contraseñas no coinciden",
  patternPassword:
    "La contraseña debe tener al menos 8 caracteres y contener números, mayúsculas, minúsculas y signos de puntuación.",
  passwordConfirmed: "Contraseña confirmada",
  passwordLastSet: "Última contraseña establecida",
  badPasswordCount: "Intentos de inicio de sesión no válidos",
  lastBadPassword: "Última contraseña incorrecta",
  admin: "Administration",
  menuList: "Lista de menú",
  multiLanguage: "Multi lenguaje",
  route: "Ruta",
  order: "Pedido",
  orders: "Pedidos",
  sort: "Ordenar",
  active: "Activo",
  sortRequired: "Orden requerido",
  pageNotFound: "PÁGINA NO ENCONTRADA",
  goToHomePage: "IR A LA PÁGINA DE INICIO",
  accessLevelList: "Lista de niveles de acceso",
  language: "Idioma",
  customer: "Cliente",
  city: "Ciudad",
  state: "Estado",
  comercial: "Comercial",
  customerList: "Lista de Clientes",
  typeInformationAboutCustomer:
    "Escriba la información sobre el cliente que desea buscar",
  customerSelected: "Cliente seleccionado",
  customerProfile: "Perfil de cliente",
  seller: "Vendedor",
  manager: "Gerente",
  address: "Dirección",
  billingLast3Years: "Facturación de los últimos 3 años",
  amount: "Valor",
  kilos: "Kilos",
  alternativeUser: "Usuario alternativo",
  ordersSummary: "Resumen de pedidos",
  billing: "Facturación",
  billingHistory: "Historial de facturación",
  reportsDetails: "Detalles de informes",
  reportName: "Reportar nombre",
  tradingName: "Nombre comercial",
  lastOrder: "Último Pedido",
  customerSummary: "Resumen del cliente",
  registrationData: "Datos de registro",
  addresses: "Direcciones",
  history: "Histórico",
  mainAddress: "Dirección principal",
  billingAddress: "Dirección de facturación",
  deliveryAddress: "Dirección de entrega",
  district: "Barrio",
  zipCode: "Código postal",
  customerType: "Tipo de cliente",
  joinOrders: "Unir pedidos",
  shippingCompany: "Compañía de envios",
  recordType: "Tipo de registro",
  goBack: "Regrear",
  orderDate: "Fecha del pedido",
  releaseDate: "Fecha de lanzamiento",
  promiseDate: "Fecha prometida",
  deliveryDate: "Fecha de entrega",
  customerOrder: "Pedido del cliente",
  productId: "ID de producto",
  productCode: "Código de producto",
  colorCode: "Código de color",
  designCode: "Código de diseño",
  preInvoice: "Prefactura",
  statusPreInvoice: "Estado Prefactura",
  invoiceDocument: "Documento de factura",
  invoiceIssueDate: "Fecha de emisión de la factura",
  invoiceReleaseDate: "Fecha de publicación de la factura",
  ordersList: "Lista de pedidos",
  typeOrder: "Tipo Orden",
  immediateDelivery: "Pronta Entrega",
  quantity: "Cantidad",
  quantityKg: "Cantidad(KG)",
  balance: "Saldo",
  orderType: "Clase de Pedido",
  reservedQuantityKg: "Reservada(KG)",
  reservedQuantityQtd: "Reservada(QTD)",
  reservedQuantityFat: "Reservada(FAT)",
  partialDelivery: "Parcial",
  combinedOrders: "Pedidos Combinados",
  requirements: "Exigencias",
  economicGroup: "Grupo Economico",
  billOfSale: "Factura de venta",
  carrier: "Transportador",
  pickupNumber: "Número de recogida",
  pickupDate: "Fecha de recogida",
  lockReason: "Motivo de Bloqueo",
  lockDate: "Fecha de Bloqueo",
  grossWeight: "Peso Bruto",
  netWeight: "Peso Neto",
  volume: "Volumen",
  blockedPreInvoices: "Prefacturas Bloqueadas",
  scheduledDeliveryDate: "Previsão de Entrega",
  businessRules: "Normas Comerciales",
  salesOrder: "Órdenes de Venta",
  deliveryWeek: "Semana de Entrega",
  color: "Color",
  tradingRules: "regla Comercial",
  rawArticleCode: "Código de Artículo Sin Procesar",
  rawArticleItem: "Artículo de Item Crudo",
  financialInformation: "Información Financiera",
  creditLimit: "Límite de Crédito",
  totalValueOrders: "Órdenes de Valor Total",
  totalValueOfBonds: "Valor Total de Los Bonos (Abiertos)",
  overdueAmount: "Monto Vencido",
  creditBalance: "Saldo de Crédito",
  openDuplicates: "Abrir Duplicados",
  situation: "Situación",
  value: "Valor",
  issueDate: "Fecha de Emisión",
  dueDate: "Fecha de Vencimiento",
  lastDueDate: "Última Fecha de Vencimiento",
  expirationDays: "Días de Vencimiento",
  filial: "Subsidiaria",
  billingType: "Tipo de Facturación",
  thirdPartyCustomer: "Cliente Terceros",
  billingBlock: "Bloque de Facturación",
  paymentTerms: "Condición de Pago",
  commission: "Comisión",
  salesChannel: "Canal de Ventas",
  federativeUnit: "Unidad Federativa",
  triangularCustomer: "Cliente Triangular",
  viaTransport: "Vía Transporte",
  clientOrderNumber: "Número de Pedido del Cliente",
  complete: "Completo",
  anticipate: "Prever",
  askedFor: "Requerido Para",
  preOrder: "Hacer un Pedido",
  conjugate: "Conjugado",
  typist: "Mecanógrafo",
  billingStatus: "Estado de Facturación",
  orderValue: "Valor Pedido",
  approver: "Aprobador",
  lots: "Un Montón",
  historyReport: "Historial/Informe",
  orderInquiry: "Consulta Pedido",

  reference: "Referencia",
  print: "Impresión",
  finishing: "Acabados",
  unitPrice: "Precio Unitario",
  unit: "Unidad",
  ordered: "Solicitud",
  reserved: "Reservado",
  weighted: "Pesado",
  ofItem: "OF",
  billed: "Facturado",
  canceled: "Cancelado",
  productSituation: "Estado del Producto",
  quality: "Calidad",
  discount: "Descuento",
  noDataFound: "Datos no Encontrados",

  orderItemList: "Lista de Artículos de Pedido",

  productTagId: "ID de Etiqueta de Producto",
  price: "Precio",

  movideskUserList: "Lista de Usuarios de Movidesk",
  userId: "Id de Usuario",

  orderData: "Datos de Los Pedidos",
  clientsData: "Datos del Cliente",
  businessData: "Datos Comerciales",
  generalData: "Datos Generales",

  releaseNotes: "Release Notes",

  condition: "Condición",

  sellerSummary: "Resumen Vendedores",
  sellerOrderList: "Lista de Pedidos de Vendedores",
  sellerList: "Lista de Vendedores",
  typeInformationAboutSeller:
    "Ingrese la información sobre el vendedor que desea buscar",
  sellerSelected: "Vendedor Seleccionado",

  sellerClients: "Clientes del Vendedor",
  version: "Versión",

  moreOptions: "Mas Opciones",

  externalLink: "Enlace Externo",
  systemVersion: "Versión del Sistema",

  movideskTickets: "Movidesk Boleto",

  widgetsList: "Lista de Widgets",
  widget: "Widget",
  component: "Componente",
  id: "Id",

  widgetInformations: "Información de Widget",
  acessLevels: "Niveles de Acceso",

  movideskList: "Lista de Entradas de Movidesk",
  movideskDetail: "Detalle Del Ticket Movidesk",
  movidesk: "Movidesk",

  fullscreenMode: "modo de pantalla completa",
  homePage: "Página de Inicio",
  systemReleases: "Lanzamientos Del Sistema",

  notificationsSetup: "Configuración de Notificaciones",
  markMessagesAsRead: "Marcar Mensajes Como Leídos",
  markRead: "Marcar como Leído",
  deleteMessage: "Borrar Mensaje",

  changeEmailFrequency: "Cambiar la Frecuencia del Correo Eletrónico",
  neverSend: "Nunca Enviar",
  sendEveryHour: "Enviar Cada Hora",
  sendInstantly: "Enviar al Instante",
  desktopNotification: "Notificacion Desktop",
  doNotNotify: "No Notifiques",
  notify: "Notificar",

  messageRegistration: "Registro de Mensajes",
  systemMessageList: "Lista de Mensajes del Sistema",
  categoryId: "Categoria ID",

  titleRequired: "¡Se Requiere Título!",
  successMarkAsRead: "¡Mensaje Marcado Como Leído!",
  successMarkAllAsRead: "¡Mensajes Marcados Como Leídos!",
  successUpdateMessageConfigurations:
    "¡Configuración de Mensajes Actualizada con Éxito!",
  noMessagesAvailable: "No Hay Mensajes Disponibles",

  category: "Categoría",

  ticketNumber: "Numero de Ticket",
  subject: "Tema en Cuestion",
  origin: "Origen",
  type: "Tipo",
  createdBy: "Criado por",
  createdDate: "Fecha de Creación",
  lastUpdate: "Última Actualización",

  sampleDepositsList: "Lista de Depósitos de Muestra",
  sampleDeposits: "Depósitos de Muestra",
  depositCode: "Código de Depósito",
  depositDescription: "Descripción del Depósito",
  updateDate: "Fecha de Actualización",
  updateUser: "MActualización de Usuario",
  changeSituation: "Cambiar Estado",
  registerSampleDeposit: "Registrar Depósito de Muestra",
  insertDepositCode: "Insertar Código de Depósito",
  insertDepositDescription: "Insertar Descripción del Depósito",
  codeRequired: "El Código es Obligatorio",
  code: "Código",
  mustBe: "debe Ser de",
  successSubmitedDeposit: "¡Depósito registrado con éxito!",

  production: "Producción",
  registers: "Registros",

  send: "Mandar",
  copy: "Copiar",
  registerPriceList: "Registrar Lista de Precios",
  insertTableName: "Insertar Nombre de Tabla",
  chooseTableStatus: "Elige la Situación de la Mesa",
  chooseEmissionDate: "Elegir Fecha de Emisión",
  chooseValidityDate: "Elija la Fecha de Validez",
  inactive: "Inactivo",
  saletePriceList: "Salete Lista de Precios",
  purchases: "Compras",

  copyPriceList: "Copiar Lista de Precios",
  referenceTable: "Tabla de Referencia",

  saletePriceListItem: "Artículo de Lista de Precios Salete",
  saletePriceItem: "Precio del Artículo Salete",
  table: "Tabla",
  validity: "Duracion",
  baseTable: "Mesa Base",
  familyStock: "Familia de Acciones",
  familyStockDescription: "Descripción Stock Familia",
  physicalFinish: "Acabado Físico",
  physicalFinishDescription: "Descripción Acabado Físico",
  hasPrint: "¿Estampado?",
  variantOf: "Variante De",
  variantUntil: "Variante Hasta",
  quantityOf: "Cantidad De",
  quantityUntil: "Cantidad Hasta",
  tableNumber: "Numero de Mesa",
  item: "Ít",
  productInfo: "Información del Producto",
  typeProductId: "Introduce el código del producto que quieres buscar",
  validityDate: "Fecha Efectiva",
  isTableActive: "¿Activo?",
  emissionDate: "Fecha de Emisión",
  stock: "Stock",
  stockPosition: "Posición de los stocks",
  ti: "T.I.",
  helpdeskAnalysis: "Help Desk Analysis",
  billingvsBudget: "Facturación vs Presupuesto",

  priceListUpdate: "Actualización de Lista de Precios",
};
