<template>
  <div class="q-pa-md">
    <app-section-loader :status="loader" />
    <q-toolbar class="q-mb-md">
      <q-toolbar-title>
        <slot name="nome"></slot>
      </q-toolbar-title>
      <slot name="icone"></slot>
    </q-toolbar>

    <q-table
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      :rows-per-page-options="[0]"
      hide-pagination
      auto-width
      :noDataLabel="$t('message.noDataFound')"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "ClienteGrupoEconomico",
  props: ["idCliente", "nome"],
  data() {
    return {
      dialog: false,
      loader: true,
      rows: [],
      columns: [
        {
          label: "Id",
          field: "idpessoafj",
          name: "idpessoafj",
          align: "right",
        },
        {
          label: this.$t("message.name"),
          field: "nome",
          name: "nome",
          align: "left",
        },
        {
          label: this.$t("message.economicGroup"),
          field: "grupoeconomico",
          name: "grupoeconomico",
          align: "right",
        },
      ],
    };
  },
  methods: {
    listarClientesGrupoEconomico() {
      this.loader = true;
      api
        .post("/v1/clientes/listarclientesgrupoEconomico", {
          id: this.idCliente,
        })
        .then((res) => {
          if (res.data) {
            this.loader = true;
            this.rows = res.data;
          }
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
  },
  mounted() {
    this.listarClientesGrupoEconomico();
  },
};
</script>
