<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      id="embedContainer"
      allowFullScreen="true"
    ></iframe>
  </div>
  <v-tour name="TourPowerBi" :steps="steps" :callbacks="callbacks"></v-tour>
</template>

<script>
import { userTourPowerBi } from "@/global";

export default {
  name: "PowerBiContainerIframe",
  props: ["embedContent"],
  data() {
    return {
      loader: false,
      steps: [
          {
            target: '.faq-help',  
            header: {
              title: 'Acesso ao PowerBi',
            },
            content: `Clique <strong>Aqui</strong> e veja como acessar os relatórios do B.I.!`
          },
      ],
      callbacks: {
        onFinish: this.finishTour,
        onStop: this.finishTour,
      }
    };
  },

  methods: {
    finishTour () {
      const tourPowerBi = "complete"
      localStorage.setItem(userTourPowerBi, JSON.stringify(tourPowerBi));
    },

    initialize () {
      let embedContainer = $("#embedContainer")[0];
      embedContainer.src = this.embedContent;

      const json = localStorage.getItem(userTourPowerBi);
      const tourPowerBi = JSON.parse(json);
      
      if (tourPowerBi !== "complete") {
        this.$tours['TourPowerBi'].start()
      }
    }
  },

  mounted () {
    this.initialize();
  },
};
</script>
