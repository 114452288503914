import OmniChannelListaView from "Views/Valisere/OmniChannelListaView";
import OmniChannelDetalheView from "Views/Valisere/OmniChannelDetalheView";
import OffPremiumListaView from "Views/Valisere/OffPremiumListaView";
import RastreioObjetosView from "Views/Valisere/RastreioObjetosView";

const routes = [
  {
    path: "/omnichannellista",
    name: "omnichannellista",
    component: OmniChannelListaView,
    meta: {
      requiresAuth: true,
      title: "message.omniChannel",
      breadcrumb: ["message.omniChannel"],
    },
  },
  {
    path: "/omnichanneldetalhe/:conta?",
    name: "omnichanneldetalhe",
    component: OmniChannelDetalheView,
    meta: {
      requiresAuth: true,
      title: "message.omniChannel",
      breadcrumb: ["message.omniChannel"],
    },
  },
  {
    path: "/offpremiumlista",
    name: "offpremiumlista",
    component: OffPremiumListaView,
    meta: {
      requiresAuth: true,
      title: "message.offPremium",
      breadcrumb: ["message.offPremium"],
    },
  },
  {
    path: "/rastreioobjetos/:objeto?",
    name: "rastreioobjetos",
    component: RastreioObjetosView,
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
