<template>
  <q-header reveal elevated class="text-dark">
    <q-toolbar class="r7-background-base-light">
      <q-btn dense flat round @click="setSideBarToggle()">
        <q-icon class="material-icons text-grey-9">menu</q-icon>
      </q-btn>

      <q-toolbar-title class="gt-sm"> Grupo Rosset® Têxtil </q-toolbar-title>

      <q-space />

      <Toolbar />
    </q-toolbar>
  </q-header>
</template>

<script>
import { mapActions } from "vuex";

import Toolbar from "Components/Header/Toolbar";

export default {
  name: "Header",
  components: { Toolbar },

  data() {
    return {};
  },
  methods: {
    ...mapActions("admin", ["setSideBarToggle"]),
  },
};
</script>
