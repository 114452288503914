<template>
  <app-section-loader :status="loader"></app-section-loader>
  <q-page class="q-page-w">
    <q-card class="q-pa-md" flat bordered>
      <!-- Título do painel -->
      <q-card-section>
        <div class="text-h6">Painel de Validação</div>
      </q-card-section>

      <!-- Botão de Aprovar -->
      <q-card-section>
        <q-btn
          label="Aprovar"
          color="primary"
          class="full-width"
          @click="aprovar"
        />
      </q-card-section>
      <!-- Painel Minhas Validações -->
      <div v-if="!controladoria">
        <validacao-component
          titulo="Minhas Validações"
          tipo="minhasvalidacoes"
          :validacoes="minhasValidacoes"
          :validado="true"
          :key="componentKey1"
        />

        <!-- Painel Validações Equipe -->
        <validacao-component
          titulo="Validações da Equipe"
          tipo="validacoesequipe"
          :validado="validahead"
          :validacoes="validacoesEquipe"
          :key="componentKey2"
        >
          <template v-slot:validador>
            <q-toggle
              v-model="validahead"
              checked-icon="check"
              color="green"
              unchecked-icon="clear"
              label="Validado pelo Head"
            />
          </template>
        </validacao-component>
      </div>

      <!-- Painel Validações Controladoria -->
      <validacao-component
        titulo="Validações Controladoria"
        tipo="validacoescontroladoria"
        :validado="validadiretor"
        :validacoes="validacoesControladoria"
        :key="componentKey3"
        v-else
      >
        <template v-slot:validador>
          <q-toggle
            v-model="validadiretor"
            checked-icon="check"
            color="green"
            unchecked-icon="clear"
            label="Validado pelo Diretor"
          />
        </template>
      </validacao-component>
    </q-card>
  </q-page>
</template>

<script>
import ValidacaoComponent from "Areas/Compras/PainelValidacao/ValidacaoComponent";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
const random = () => Math.floor(Math.random() * 1000);

export default {
  components: { ValidacaoComponent },
  data() {
    return {
      loader: false,
      minhasValidacoes: [],
      validacoesEquipe: [],
      validacoesControladoria: [],
      validahead: false,
      validadiretor: false,
      controladoria: false,
      componentKey1: 0,
      componentKey2: 0,
      componentKey3: 0,
    };
  },
  watch: {
    validahead(o, n) {
      this.componentKey2 += random();
      this.carregarValidacoesEquipe();
    },
    validadiretor(o, n) {
      this.componentKey3 += random();
      this.carregarValidacoesControladoria();
    },
  },
  methods: {
    initialize() {
      this.controladoria = this.$route.query.controladoria == "S";
      this.componentKey1 += random();
      this.componentKey2 += random();
      this.componentKey3 += random();
      this.minhasValidacoes = [];
      this.validacoesEquipe = [];
      this.validacoesControladoria = [];
      this.validahead = false;
      this.validadiretor = false;

      if (this.controladoria) {
        this.carregarValidacoesControladoria();
      } else {
        this.carregarMinhasValidacoes();
        this.carregarValidacoesEquipe();
      }
    },
    carregarMinhasValidacoes() {
      api
        .get("/v1/compras/painelaprovacoes/minhasvalidacoespendentes")
        .then((res) => {
          if (res?.data) {
            this.minhasValidacoes = this.mapValidations(res.data);
          }
        })
        .catch((err) => showError(err));
    },
    carregarValidacoesEquipe() {
      const validado = this.validahead ? "S" : " ";
      api
        .get(
          `/v1/compras/painelaprovacoes/validacoespendentesequipe?validado=${validado}`
        )
        .then((res) => {
          if (res?.data) {
            this.validacoesEquipe = this.mapValidations(res.data);
          }
        })
        .catch((err) => showError(err));
    },
    carregarValidacoesControladoria() {
      const validado = this.validadiretor ? "S" : " ";
      api
        .get(
          `/v1/compras/painelaprovacoes/validacoesdiretor?validado=${validado}`
        )
        .then((res) => {
          if (res?.data) {
            this.validacoesControladoria = this.mapValidations(res.data);
          }
        })
        .catch((err) => showError(err));
    },
    mapValidations: (validations) =>
      validations.map((e) => {
        return {
          ...e,
          aprovado: false,
        };
      }),
    async aprovar() {
      //Aprovar Minhas Validacoes
      await this.chamaAprovacao(
        this.minhasValidacoes.filter((f) => f.aprovado),
        "M"
      )
        .then((res) => {
          if (res) {
            showSuccess(res);
            this.initialize();
          }
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => (this.loader = false));

      //Aprovar Validacoes da Equipe
      await this.chamaAprovacao(
        this.validacoesEquipe.filter((f) => f.aprovado),
        "E"
      )
        .then((res) => {
          if (res) {
            showSuccess(res);
            this.initialize();
          }
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => (this.loader = false));

      //Aprovar Validacoes da Controladoria
      await this.chamaAprovacao(
        this.validacoesControladoria.filter((f) => f.aprovado),
        "C"
      )
        .then((res) => {
          if (res) {
            showSuccess(res);
            this.initialize();
          }
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => (this.loader = false));
    },

    chamaAprovacao(arr, tipo) {
      this.loader = true;
      return new Promise((resolve, reject) => {
        if (!arr || arr.length === 0) {
          return resolve();
        }

        const promises = arr.map((element) => {
          const data = {
            aprovador: element.aprovador,
            tipo,
          };

          return api
            .post("/v1/compras/painelaprovacoes/aprovar", data)
            .catch((err) => {
              return Promise.reject(err.response?.data || "Erro desconhecido");
            });
        });

        Promise.allSettled(promises).then((results) => {
          const errors = results
            .filter((result) => result.status === "rejected")
            .map((result) => result.reason);
          if (errors.length > 0) {
            reject(errors);
          } else {
            resolve("Todas as aprovações foram bem-sucedidas.");
          }
        });
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style>
@media screen and (min-width: 1200px) {
  .q-page-w {
    max-width: 50%;
  }
}
.q-page {
  padding: 20px;
  margin: 0 auto;
}
</style>
