<template>
  <div class="q-pa-sm row items-start q-gutter-md">
    <q-card class="my-card" flat bordered>
      <q-item>
        <q-item-section avatar>
          <q-avatar>
            <q-icon color="green darken-2" size="lg" class="material-icons">
              check_circle
            </q-icon>
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <q-item-label class="text-h5 col-12 text-weight-bold"
            >Console Output</q-item-label
          >
        </q-item-section>
      </q-item>
      <q-separator />
      <q-card-section class="bg-grey-3">
        <pre>
              {{ messageString }}
        </pre>
      </q-card-section>
    </q-card>
  </div>
</template>
<script>
const baseURL = (
  process.env.VUE_APP_ENDPOINT?.replaceAll("/api", "") ||
  `${window.location.protocol}//${window.location.host}`
)
  ?.replaceAll("http://", "")
  .replaceAll("https://", "");
const protocol =
  process.env.VUE_APP_ENDPOINT.indexOf("localhost") !== -1 ? "ws" : "wss";
const ws = new WebSocket(`${protocol}://${baseURL}`);

export default {
  name: "ConsoleOutputComponent",
  data() {
    return {
      messages: [],
      messageString: null,
    };
  },
  methods: {
    getMessage() {},
  },
  mounted() {
    ws.onmessage = (event) => {
      this.messages.push(event.data);
      this.messageString = this.messages.join("\n");
    };
  },
};
</script>
<style scoped>
.my-card {
  width: 100vw;
  max-width: 100vw;
}
pre {
  display: block;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  color: #14141f;
  font-family: ui-monospace, sfmono-regular, sf mono, jetbrainsmono, consolas,
    monospace;
  font-weight: 500;
  font-size: 0.95em;
  line-height: 1.66;
  margin: 0 0 1.75rem;
  padding: 0.8rem 1rem;
  white-space: pre-line;
  word-wrap: break-word;
}
</style>
