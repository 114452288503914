<template>
  <app-section-loader :status="loader"></app-section-loader>
  <q-table
    :loading="loader"
    :rows="rows"
    :columns="columns"
    table-header-class="table-header"
    dense
    :filter="filter"
    virtual-scroll
    :noDataLabel="$t('message.noDataFound')"
    :rows-per-page-options="[0]"
    class="my-sticky-header-table"
  >
    <template v-slot:top-right>
      <q-input
        class="q-ml-sm"
        borderless
        dense
        debounce="300"
        v-model="filter"
        :placeholder="$t('message.search')"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>

      <q-btn flat round size="small" @click.stop="initialize()">
        <q-tooltip
          anchor="bottom middle"
          self="center middle"
          :offset="[10, 10]"
          class="text-body2"
          >{{ $t("message.reload") }}</q-tooltip
        >
        <q-icon class="material-icons text-grey-9"> refresh </q-icon>
      </q-btn>
    </template>

    <template v-slot:body-cell-killUser="props">
      <q-td>
        <q-btn
          v-if="getHighLevel"
          :disable="disableButton"
          color="red-8 material-icons-outlined "
          size="xs"
          @click="killSession(props.row)"
          class="q-my-xs q-py-xs"
          icon="logout"
        />
      </q-td>
    </template>

    <template v-slot:body-cell-prevExecStart="props">
      <q-td align="center">
        {{ props.row.prevExecStart }}
        <q-icon name="help">
          <q-tooltip class="bg-black text-white">{{
            props.row.lastCommandExecuted
          }}</q-tooltip>
        </q-icon>
      </q-td>
    </template>
  </q-table>
  <confirmation-dialog
    ref="confirmationDialog"
    :message="message"
    @onConfirm="removeConnection"
  >
  </confirmation-dialog>
</template>

<script>
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";
import { mapGetters } from "vuex";

export default {
  name: "AcessosSenior",
  data() {
    return {
      loader: true,
      disableButton: false,
      selectItemToKill: null,
      message: null,
      filter: null,
      rows: [],
      columns: [
        {
          label: "",
          field: "killUser",
          name: "killUser",
          align: "center",
        },

        {
          label: "AUDSID",
          field: "audsid",
          name: "audsid",
          align: "right",
          sortable: true,
        },
        {
          label: "SID",
          field: "sid",
          name: "sid",
          align: "right",
          sortable: true,
        },
        {
          label: "Serial#",
          field: "serial",
          name: "serial",
          align: "right",
          sortable: true,
        },
        {
          label: this.$t("message.user"),
          field: "osuser",
          name: "osuser",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.status"),
          field: "status",
          name: "status",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.program"),
          field: "program",
          name: "program",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.schemaName"),
          field: "schemaname",
          name: "schemaname",
          align: "left",
          sortable: true,
        },
        {
          label: this.$t("message.logonDate"),
          field: "logonTime",
          name: "logonTime",
          align: "center",
          sortable: true,
        },
        {
          label: this.$t("message.previousExecuteStart"),
          field: "prevExecStart",
          name: "prevExecStart",
          align: "center",
          sortable: true,
        },
        {
          label: this.$t("message.waitTimeInSeconds"),
          field: "waitTimeSecond",
          name: "waitTimeSecond",
          align: "right",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("admin", ["getHighLevel"]),
  },
  methods: {
    initialize() {
      api
        .get("/v1/senior/admin/conectados")
        .then((res) => {
          if (res.data) {
            this.rows = res.data;
          }
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
    killSession(row) {
      this.message = `Deseja realmente eliminar a sessão <span class="destaque-texto"> ${
        row.audsid
      } </span> do usuário <span class="destaque-texto"> ${row.osuser.toUpperCase()} </span>`;
      this.$refs.confirmationDialog.open();
      this.selectItemToKill = row;
    },
    async removeConnection() {
      this.disableButton = true;
      this.$refs.confirmationDialog.close();

      const data = {
        sid: this.selectItemToKill.sid,
        serial: this.selectItemToKill.serial,
      };

      this.loader = true;
      await api
        .post(`/v1/senior/admin/finalizarsessao`, data)
        .then(() => {
          this.initialize();
          showSuccess(this.$t("message.successUpdatedRecord"));
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
          this.disableButton = false;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
<style lang="sass" scoped>
.my-sticky-header-table
  height: 70vh
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
