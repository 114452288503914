<template>
  <div>
    <q-dialog v-model="isModalActive" has-modal-card :persistent="persistent">
      <q-card class="modal-card" :style="child">
        <q-card-section class="bg-primary">
          <div class="row items-center no-wrap">
            <div class="col">
              <p class="text-white h3">
                {{ title }}
              </p>
            </div>
            <div class="col-auto">
              <q-btn color="white" round flat icon="close" @click="fechar()" />
            </div>
          </div>
        </q-card-section>
        <q-card-section class="modal-card-body">
          <slot name="body"></slot>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  name: "ModalForm",
  props: {
    title: {
      type: String,
      default: null,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    customClass: Object,
  },
  data() {
    return {
      isModalActive: false,
      highlights: [],
      estiloPadrao: {
        width: "35rem",
      },
      child: this.customClass ? this.customClass : this.estiloPadrao,
    };
  },

  methods: {
    abrir() {
      this.isModalActive = true;
    },
    fechar() {
      this.isModalActive = false;
    },
  },
};
</script>

<style scoped>
.q-dialog__inner--minimized > div {
  max-width: 2000px;
}
</style>
