<template>
  <q-item>
    <q-item-section avatar top>
      <q-icon :name="icon" color="black" size="34px" />
    </q-item-section>

    <q-item-section top class="col-4 gt-sm">
      <q-item-label
        class="q-mt-sm text-primary text-weight-bold"
        @click.stop="openDocument(pdf)"
      >
        <span class="cursor-pointer">{{ description }}</span>
      </q-item-label>
    </q-item-section>

    <q-item-section top v-if="sub">
      <q-item-label
        class="q-mt-sm text-body2 text-weight-bold text-primary text-uppercase"
        @click.stop="openDocument(sub.pdf)"
      >
        <span class="cursor-pointer">{{ sub.description }}</span>
      </q-item-label>
    </q-item-section>
    <q-item-section top v-if="html">
      <q-item-label
        class="q-mt-sm text-body2 text-weight-bold text-warning text-uppercase"
      >
        <router-link :to="html.path">
          <span class="cursor-pointer">{{ html.description }}</span>
        </router-link>
      </q-item-label>
    </q-item-section>
  </q-item>

  <LgpdVisualizarDocumento ref="modalDocumento" />
</template>

<script>
import LgpdVisualizarDocumento from "./LgpdVisualizarDocumento";

export default {
  name: "LgpdLista",
  props: {
    icon: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    pdf: {
      type: String,
      default: null,
    },
    sub: {
      type: Object,
    },
    html: {
      type: Object,
    },
  },
  components: { LgpdVisualizarDocumento },
  data() {
    return {};
  },
  methods: {
    openDocument(document) {
      this.$refs.modalDocumento.openDocument(document);
    },
  },
};
</script>
