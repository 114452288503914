<template>
  <div class="q-px-md q-mt-lg">
    <PagSeguroComponent
      v-if="idNotaFiscalCapa > 0"
      :idnotafiscalcapa="idNotaFiscalCapa"
    />
  </div>
</template>
<script>
import PagSeguroComponent from "Components/Areas/Comercial/PagSeguroComponent";
export default {
  name: "PagSeguroView",
  components: { PagSeguroComponent },
  data() {
    return {
      idNotaFiscalCapa: 0,
    };
  },
  watch: {
    $route(to) {
      this.idNotaFiscalCapa =
        to.params.idnotafiscalcapa === 0 ? null : to.params.idnotafiscalcapa;
    },
  },
  mounted() {
    const params = this.$route.params;
    this.idNotaFiscalCapa = params.idnotafiscalcapa;
  },
};
</script>
