<template>
  <div>
    <app-section-loader :status="loader"></app-section-loader>

    <SelectWithFilter
      v-if="!loader"
      :id="dashboardId"
      :list="listaDashboards"
      :required="required"
      :disabled="disabled"
      :prependIcon="prependIcon"
      :label="$t('message.defaultDashboard')"
      @selected="itemSelected($event)"
    />
  </div>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import SelectWithFilter from "Components/Widgets/SelectWithFilter";

export default {
  name: "SeletorDashboardPadrao",
  components: { SelectWithFilter },

  props: {
    dashboardId: Number,
    nivelAcessoId: [Number, String],
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      loader: true,
      listaDashboards: null,

      idNivelAcesso: this.nivelAcessoId || 0,
    };
  },

  methods: {
    itemSelected(item) {
      this.$emit("selected", item);
    },
    initialize() {
      api
        .get(`/v1/nivelacesso/${this.idNivelAcesso}/dashboards`)
        .then((res) => {
          if (res.data) {
            this.listaDashboards = res.data
              .filter((f) => f.permitido == 1)
              .map((element) => {
                return {
                  id: element.dashboardId,
                  description: element.dashboardDescricao,
                };
              });
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
