<template>
  <div>
    <ModalCard
      ref="modalListaNivelAcesso"
      :title="$t('message.accessLevelList')"
    >
      <template v-slot:body>
        <q-table
          :loading="loader"
          :rows="rows"
          :columns="columns"
          table-header-class="table-header"
          rows-per-page-label="Itens por página"
          :noDataLabel="$t('message.noDataFound')"
          @row-click="selecionarLinha"
          class="q-mx-sm q-my-sm"
          dense
        >
          <template v-slot:body-cell="props">
            <q-td
              :class="
                props.row.tipo == 1
                  ? 'bg-green-2 text-black text-weight-medium'
                  : 'bg-yellow-2 text-black text-weight-medium'
              "
              auto-width
            >
              <span>{{ props.row.descricao }}</span>
            </q-td>
          </template>
        </q-table>
      </template>
    </ModalCard>
  </div>
</template>

<script>
import api from "Api";
import ModalCard from "Components/Widgets/ModalCard";
import { showError } from "@/notifications/notify";

export default {
  name: "NivelAcessoDeCadaDashboard",
  components: { ModalCard },

  data() {
    return {
      loader: true,

      id: null,

      rows: [],
      columns: [
        {
          label: this.$t("message.description"),
          field: "descricao",
          name: "descricao",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    obterNivelAcessoUsuariosDashboard(id) {
      api
        .get(`v1/dashboards/${id}/niveisacesso/usuarios/`)
        .then((res) => {
          this.rows = res.data;
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    abrirModal(id) {
      this.$refs.modalListaNivelAcesso.abrir();
      this.obterNivelAcessoUsuariosDashboard(id);
    },

    selecionarLinha(evt, item) {
      let name;

      if (item.tipo == 1) {
        name = "nivelacessodetalhe";
      } else {
        name = "usuariodetalhe";
      }

      this.$router.push({
        name: name,
        params: { id: item.id },
      });
    },
  },
};
</script>
