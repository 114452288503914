<template>
  <app-section-loader :status="loader"></app-section-loader>
  <div>
    <q-table
      ref="myTable"
      :loading="loader"
      :rows="rows"
      :columns="columns"
      dense
      table-header-class="table-header"
      hide-pagination
      :rows-per-page-options="[0]"
      :noDataLabel="$t('message.noDataFound')"
      class="my-sticky-header-table"
      virtual-scroll
    >
      <template v-slot:top-left>
        <q-btn class="q-mb-xm noPrint" color="primary" @click="onAdd">
          {{ $t("message.add") }}
        </q-btn>
      </template>

      <template v-slot:body-cell-situacao="props">
        <q-td auto-width>
          <q-badge size="xs" :color="props.row.situacao == 0 ? 'red' : 'green'">
            {{ props.row.situacao == 0 ? "Inativo" : "Ativo" }}
          </q-badge>
        </q-td>
      </template>

      <template v-slot:body-cell-actionsEdit="props">
        <q-td auto-width>
          <q-btn flat dense round small>
            <q-icon
              size="xs"
              color="blue darken-2"
              class="material-icons noPrint"
              @click="onUpdate(props.row.conta)"
            >
              edit
            </q-icon>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </div>
</template>
<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import { printTableMixin } from "Mixin/printTableMinx";

export default {
  name: "OmniChannelListaComponent",
  mixins: [printTableMixin],
  data() {
    return {
      loader: true,
      conta: null,
      rows: [],
      columns: [
        {
          label: "Conta",
          field: "conta",
          name: "conta",
          align: "left",
          sortable: true,
        },
        {
          label: "Loja",
          field: "loja",
          name: "loja",
          align: "left",
          sortable: true,
        },
        {
          label: "Situação",
          field: "situacao",
          name: "situacao",
          align: "left",
          sortable: true,
        },
        {
          label: "Dt. Cadastro",
          field: "data",
          name: "data",
          align: "left",
          sortable: true,
        },
        {
          label: "Usuário Cadastro",
          field: "usuario",
          name: "usuario",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsEdit",
          name: "actionsEdit",
          align: "center",
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          align: "center",
        },
      ],
    };
  },
  methods: {
    listarContas() {
      this.loader = true;
      api
        .get("/v1/valisere/omnichannel")
        .then((res) => (this.rows = res.data))
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },
    onAdd() {
      this.$router.push({
        name: "omnichanneldetalhe",
        params: { conta: "" },
      });
    },
    onUpdate(conta) {
      this.$router.push({
        name: "omnichanneldetalhe",
        params: { conta },
      });
    },
  },
  mounted() {
    this.listarContas();
  },
};
</script>
