<template>
  <div :class="[colClasses, { flex: colClasses }]">
    <div class="app-card" :class="[customClasses, { 'd-none': close }]">
      <app-card-heading
        :heading="heading"
        :closeable="closeable"
        :reloadable="reloadable"
        :with-tabs="withTabs"
        :tabs="tabs"
        :customHeaderClasses="customHeaderClasses"
        @onChangeTabCallback="onChangeTab"
        @onReload="onReload"
        @onClose="onClose"
      />
      <app-card-content
        :extra-class="[contentCustomClass]"
        :full-block="fullBlock"
      >
        <slot />
      </app-card-content>
      <app-card-footer v-if="footer">
        <slot name="footer" />
      </app-card-footer>
      <app-section-loader :status="reload" />
    </div>
  </div>
</template>

<script>
import AppSectionLoader from "Components/Widgets/AppSectionLoader";
import AppCardHeading from "./AppCardHeading";
import AppCardFooter from "./AppCardFooter";
import AppCardContent from "./AppCardContent";

export default {
  components: {
    AppCardHeading,
    AppCardFooter,
    AppCardContent,
    AppSectionLoader,
  },
  props: [
    "heading",
    "colClasses",
    "customClasses",
    "customHeaderClasses",
    "fullBlock",
    "contentCustomClass",
    "closeable",
    "reloadable",
    "footer",
    "withTabs",
    "tabs",
    "onChangeTabCallback",
  ],
  data() {
    return {
      reload: false,
      close: false,
    };
  },
  methods: {
    onReload() {
      this.reload = true;
      const self = this;
      setTimeout(() => {
        self.reload = false;
      }, 1500);
    },
    onClose() {
      this.close = true;
    },
    onChangeTab(value) {
      this.$emit("onChangeTabCallback", value);
    },
  },
};
</script>
