<template>
  <div>
    <q-toolbar class="bg-primary text-white q-my-md">
      <q-btn flat round dense size="md" icon="mdi-menu" color="white">
        <q-tooltip
          anchor="center left"
          self="center right"
          :offset="[10, 10]"
          class="text-body2"
          >{{ $t(`message.moreOptions`) }}</q-tooltip
        >
        <q-menu right bottom>
          <q-list style="min-width: 100px">
            <q-item
              v-for="campo in campos4"
              :key="campo.label"
              @click="() => {}"
              clickable
              v-close-popup
            >
              <q-item-section>{{ campo.label }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>

      <q-toolbar-title>
        Pedido: {{ dados.pedido }} - Cliente:
        {{ dados.codigoCliente }}
      </q-toolbar-title>
      <q-space />

      <q-btn flat round size="small">
        <q-icon @click="setAtualizar()" class="material-icons text-white">
          refresh
        </q-icon>
      </q-btn>

      <q-btn
        @click="minimizar = !minimizar"
        v-if="minimizar === true"
        flat
        round
        size="small"
      >
        <q-icon class="material-icons text-white">minimize</q-icon>
      </q-btn>
      <q-btn
        icon
        @click="minimizar = !minimizar"
        v-else
        flat
        round
        size="small"
      >
        <q-icon class="material-icons text-white">crop_square</q-icon>
      </q-btn>
    </q-toolbar>

    <div class="r7-container-header" v-show="minimizar">
      <div class="row">
        <div class="col">
          <q-card flat style="height: 100%">
            <form-in-line-block
              :data="dados"
              :fields="campos1"
              :title="$t('message.orderData')"
              :loader="loader"
              class="r7-background-danger-light-1"
              :reloadIcon="false"
              :minimizarIcon="false"
              style="height: 100%"
            >
              <template v-slot:valdescSituacaoPedido="{ value }">
                <span class="r7-text-color-success">
                  {{ value.valor }}
                </span>
              </template>
              <template v-slot:valcompleto="{ value }">
                <span class="r7-text-color-success"> {{ value.valor }}: </span>
              </template>
              <template v-slot:valprontaEntrega="{ value }">
                <span class="r7-text-color-success"> {{ value.valor }}: </span>
              </template>
              <template v-slot:valdsAntecipaEntrega="{ value }">
                <span class="r7-text-color-success"> {{ value.valor }}: </span>
              </template>
              <template v-slot:valdtEntrega="{ value }">
                <span :class="definirCor(value.programacao)">
                  {{ value.valor }}
                </span>
              </template>
            </form-in-line-block>
          </q-card>
        </div>
        <div class="col">
          <q-card flat style="height: 100%" class="q-mx-sm">
            <form-in-line-block
              :data="dados"
              :fields="campos2"
              :title="$t('message.clientsData')"
              :loader="loader"
              :reloadIcon="false"
              :minimizarIcon="false"
              class="r7-background-success-light-1"
            >
              <template v-slot:valvalorPedido="{ value }">
                <span>
                  {{ $filters.formatCurrency(value.valor) }}
                </span>
              </template>
              <template v-slot:colcdCliter="{ value }">
                <span class="r7-text-color-danger"> {{ value.coluna }}: </span>
              </template>
            </form-in-line-block>
          </q-card>
        </div>
        <div class="col">
          <q-card flat style="height: 100%" class="r7-cartao-blue-light">
            <form-in-line-block
              :data="dados"
              :fields="campos3"
              :title="$t('message.businessData')"
              :loader="loader"
              :reloadIcon="false"
              :minimizarIcon="false"
            >
              <template v-slot:valpercComissaoRep="{ value }">
                <span>
                  {{ $filters.formatDecimal(value.valor) }}
                </span>
              </template>
            </form-in-line-block>
          </q-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "Api";
import { showError } from "@/notifications/notify";

export default {
  name: "HeaderPedido",
  props: ["idPedido"],
  data() {
    return {
      loader: true,
      minimizar: true,
      dados: {},
      options: {
        columns: 3,
      },
      campos1: [
        { label: this.$t("message.filial"), field: "cdFilial" },
        {
          label: this.$t("message.order"),
          field: "pedido",
        },
        {
          label: this.$t("message.conjugate"),
          field: "pedidosconjugados",
        },
        {
          label: this.$t("message.situation"),
          field: "descSituacaoPedido",
        },
        { label: this.$t("message.complete"), field: "completo" },
        {
          label: this.$t("message.immediateDelivery"),
          field: "prontaEntrega",
        },
        {
          label: this.$t("message.anticipate"),
          field: "dsAntecipaEntrega",
        },

        { label: this.$t("message.issueDate"), field: "dtPedido" },
        {
          label: this.$t("message.askedFor"),
          field: "dtSolicitadoPara",
        },
        {
          label: this.$t("message.releaseDate"),
          field: "dtLiberacao",
        },
        {
          label: this.$t("message.deliveryDate"),
          field: "dtEntrega",
        },
      ],
      campos2: [
        {
          label: this.$t("message.client"),
          field: "codigoCliente",
        },
        {
          label: this.$t("message.clientOrderNumber"),
          field: "pedidoCliente",
        },
        {
          label: this.$t("message.seller"),
          field: "codigoRepresentante",
        },
        { label: this.$t("message.city"), field: "cidade" },
        { label: this.$t("message.federativeUnit"), field: "uf" },
        {
          label: this.$t("message.thirdPartyCustomer"),
          field: "cdCliter",
        },
        {
          label: this.$t("message.triangularCustomer"),
          field: "cdTriangular",
        },
        {
          label: this.$t("message.salesChannel"),
          field: "dsCanalVenda",
        },
        {
          label: this.$t("message.customerType"),
          field: "tipoCliente",
        },
        {
          label: this.$t("message.supervisor"),
          field: "dsSupervisor",
        },
        { label: this.$t("message.manager"), field: "dsGerente" },
      ],
      campos3: [
        {
          label: this.$t("message.orderValue"),
          field: "valorPedido",
        },
        {
          label: this.$t("message.billingType"),
          field: "descTpPedido",
        },
        {
          label: this.$t("message.paymentTerms"),
          field: "codigoCondPgto",
        },
        {
          label: this.$t("message.billingBlock"),
          field: "bloqueioFaturamento",
        },
        {
          label: this.$t("message.commission"),
          field: "percComissaoRep",
        },
        {
          label: this.$t("message.shippingCompany"),
          field: "codigoTransportadora",
        },
        {
          label: this.$t("message.viaTransport"),
          field: "nomeViaTransporte",
        },
        { label: this.$t("message.typist"), field: "digitador" },
        { label: this.$t("message.approver"), field: "aprovador" },
      ],
      campos4: [
        { label: "Histórico", route: "home" },
        { label: "Laudo", route: "home" },
        { label: "Pré-Pedido", route: "home" },
        { label: "Lotes", route: "home" },
        { label: "Peças", route: "home" },
        { label: "Sit. Faturamento", route: "home" },
      ],
    };
  },
  methods: {
    ...mapActions("common", ["setAtualizar"]),

    definirCor(p) {
      return p > 0 ? "r7-text-color-danger" : "r7-text-color-success";
    },
    listarPedidoUnico() {
      this.loader = true;
      api
        .get(`/v1/comercial/pedidos/listarpedidounico/${this.idPedido}`)
        .then((res) => {
          this.tratarDadosRecebidos(res.data);
        })
        .catch((err) => showError(err))
        .finally(() => {
          this.loader = false;
        });
    },
    tratarDadosRecebidos(dadosRecebidos) {
      this.dados = dadosRecebidos;

      this.dados.cdFilial = `${this.dados.cdFilial} - ${this.dados.dsFilial}`;
      this.dados.codigoCliente = `${this.dados.codigoCliente} - ${this.dados.nomeCliente}`;
      this.dados.codigoRepresentante = `${this.dados.codigoRepresentante} - ${this.dados.nomeRepresentante}`;
      this.dados.codigoCondPgto = `${this.dados.codigoCondPgto} - ${this.dados.descricaoCondPgto}`;
      this.dados.codigoTransportadora = `${this.dados.codigoTransportadora} - ${this.dados.nomeTransportadora}`;
      this.dados.cdTriangular = this.dados.nmTriangular
        ? `${this.dados.cdTriangular} - ${this.dados.nmTriangular}`
        : "";
      this.dados.cdCliter = this.dados.nmCliter
        ? `${this.dados.cdCliter} - ${this.dados.nmCliter}`
        : "";

      delete this.dados.dsFilial;
      delete this.dados.nomeCliente;
      delete this.dados.nomeRepresentante;
      delete this.dados.descricaoCondPgto;
      delete this.dados.nomeTransportadora;
      delete this.dados.nmTriangular;
      delete this.dados.nmCliter;
    },
  },
  mounted() {
    this.listarPedidoUnico();
  },
};
</script>
<style lang="sass" scoped>
.my-card
  width: 100vw
  max-width: 100%
</style>
