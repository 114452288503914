<template>
  <div class="q-pa-md">
    <q-toolbar>
      <q-toolbar-title>
        {{ representante.nomeRepresentante }}
      </q-toolbar-title>
      <slot name="icone"></slot>
    </q-toolbar>

    <div class="text-left q-ml-md q-mt-md">
      <p>
        <span class="fw-bold text-gray">UF:</span>
        {{ this.representante.uf }}
      </p>
      <p>
        <span class="fw-bold text-gray">Cidade:</span>
        {{ this.representante.cidade }}
      </p>
      <p>
        <span class="fw-bold text-gray">Situação:</span>
        {{ this.representante.situacao }}
      </p>
      <p>
        <span class="fw-bold text-gray">Id:</span>
        {{ this.representante.idrepresentante }}
      </p>
      <p>
        <span class="fw-bold text-gray">Cnpj:</span>
        {{ this.representante.cnpj }}
      </p>
      <p>
        <span class="fw-bold text-gray">Supervisor:</span>
        {{ this.representante.supervisor }}
      </p>
      <p>
        <span class="fw-bold text-gray">Gerente:</span>
        {{ this.representante.gerente }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ResumoDadosRepresentante",
  props: ["idRepresentante"],
  data() {
    return {
      dialog: false,
      ativo: false,
      representante: {},
    };
  },
  methods: {
    ...mapGetters("representantes", ["getDadosDoRepresentante"]),
    receberRepresentante() {
      this.representante = this.getDadosDoRepresentante();
    },
  },
  mounted() {
    this.receberRepresentante();
  },
};
</script>
