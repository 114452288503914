<template>
  <ModalForm
    :title="$t('message.documentAccess')"
    ref="TipoDeAcessoModalForm"
    :customClass="estilo"
  >
    <template v-slot:body>
      <div class="row q-px-md">
        <span class="text-h5" v-if="editandoDocumento"
          >{{ $t("message.editingDocument") }}: {{ documentoNome }}</span
        >
      </div>

      <div class="row items-center justify-evenly">
        <div class="col-6 q-px-md">
          <q-card class="q-px-md q-mt-md" style="height: 430px">
            <q-form ref="form" @submit="onAdd('usuario')">
              <div class="row q-pt-md items-center justify-between">
                <div class="col-auto q-mr-xl self-center">
                  <p class="text-subtitle1">{{ $t("message.addUser") }}</p>
                </div>
                <div class="col-auto self-center">
                  <UsuarioSelecao
                    :usuarioId="usuarioId"
                    @selected="selectedUsuario($event)"
                    :required="false"
                  />
                </div>
                <div class="col-auto q-pl-xl self-center">
                  <q-btn
                    id="adicionarUsuario"
                    color="success"
                    type="submit"
                    :label="$t('message.add')"
                    class="bg-green text-white q-ml-xl"
                  />
                </div>
              </div>
            </q-form>
            <q-separator class="q-my-sm" />
            <div class="row q-py-md items-center justify-center">
              <q-table
                :loading="loader"
                :rows="rowsUsuarios"
                :columns="columnsUsuarios"
                table-header-class="table-header"
                :noDataLabel="$t('message.noDataFound')"
                dense
                class="my-sticky-header-table"
                virtual-scroll
                hide-pagination
                :rows-per-page-options="[0]"
              >
                <template v-slot:body-cell-actionsDelete="props">
                  <q-td auto-width>
                    <q-btn flat dense round small>
                      <q-icon
                        size="xs"
                        color="red darken-2"
                        class="material-icons"
                        @click="deletar(props.rowIndex, 'usuario')"
                      >
                        delete
                      </q-icon>
                    </q-btn>
                  </q-td>
                </template>
              </q-table>
            </div>
          </q-card>
        </div>

        <div class="col-6 q-px-md">
          <q-card class="q-px-md q-mt-md" style="height: 430px">
            <q-form ref="form" @submit="onAdd('nivelAcesso')">
              <div class="row q-pt-md items-center justify-between q-pb-md">
                <div class="col-auto q-mr-xl">
                  <p class="text-subtitle1">
                    {{ $t("message.addAccessLevel") }}
                  </p>
                </div>
                <div class="col-auto">
                  <NivelAcessoSelecao
                    :nivelAcessoId="nivelAcessoId"
                    @selected="selectedNivelAcesso($event)"
                    :rounded="false"
                    :filled="true"
                    :dense="false"
                  />
                </div>
                <div class="col-auto">
                  <q-btn
                    id="adicionarNivelAcesso"
                    color="success"
                    type="submit"
                    :label="$t('message.add')"
                    class="bg-green text-white q-ml-xl"
                  />
                </div>
              </div>
              <q-separator class="q-my-sm" />
              <div class="row q-py-md items-center justify-center">
                <q-table
                  :loading="loader"
                  :rows="rowsNiveisAcesso"
                  :columns="columnsNiveisAcesso"
                  table-header-class="table-header"
                  :noDataLabel="$t('message.noDataFound')"
                  dense
                  class="my-sticky-header-table q-pt-xs"
                  virtual-scroll
                  hide-pagination
                  :rows-per-page-options="[0]"
                >
                  <template v-slot:body-cell-actionsDelete="props">
                    <q-td auto-width>
                      <q-btn flat dense round small>
                        <q-icon
                          size="xs"
                          color="red darken-2"
                          class="material-icons"
                          @click="deletar(props.rowIndex, 'nivelAcesso')"
                        >
                          delete
                        </q-icon>
                      </q-btn>
                    </q-td>
                  </template>
                </q-table>
              </div>
            </q-form>
          </q-card>
        </div>
      </div>

      <q-separator class="q-mx-md q-mt-lg" />

      <q-btn
        :label="titulo"
        @click.prevent="salvar(documentoId)"
        class="bg-green text-white q-ml-md q-mt-md"
      />

      <q-btn
        :label="$t('message.close')"
        @click.prevent="fechar()"
        class="bg-red text-white q-ml-md q-mt-md"
      />
    </template>
  </ModalForm>
</template>

<script>
import UsuarioSelecao from "Components/Areas/Admin/Usuarios/UsuarioSelecao";
import NivelAcessoSelecao from "Components/Areas/Admin/NiveisAcesso/NivelAcessoSelecao";
import ModalForm from "Components/Widgets/ModalForm";
import { mapActions, mapGetters } from "vuex";
import api from "Api";
import { showError, showSuccess } from "@/notifications/notify";

export default {
  name: "TipoDeAcessoDocumentos",
  components: { UsuarioSelecao, NivelAcessoSelecao, ModalForm },

  data() {
    return {
      loader: false,
      documentoId: null,
      documentoNome: null,
      usuarioId: null,
      usuario: null,
      nivelAcessoId: null,
      nielAcesso: null,
      estilo: {
        width: "1600px",
        height: "660px",
      },
      editandoDocumento: false,
      rowsUsuarios: [],
      rowsNiveisAcesso: [],
      columnsUsuarios: [
        {
          label: this.$t("message.user"),
          field: "description",
          name: "description",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          sortable: false,
          align: "center",
        },
      ],
      columnsNiveisAcesso: [
        {
          label: this.$t("message.accessLevel"),
          field: "description",
          name: "description",
          align: "left",
          sortable: true,
        },
        {
          label: "",
          field: "actionsDelete",
          name: "actionsDelete",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("repositorio", ["getAcessoUsuarios", "getAcessoNivelAcesso"]),
    titulo() {
      return this.documentoId
        ? this.$t("message.save")
        : this.$t("message.add");
    },
  },
  watch: {
    getAcessoUsuarios: {
      handler(v) {
        this.rowsUsuarios = v;
      },
      deep: true,
    },

    getAcessoNivelAcesso: {
      handler(v) {
        this.rowsNiveisAcesso = v;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions("repositorio", [
      "setAcessoUsuarios",
      "setAcessoNivelAcesso",
      "setRemoverUsuario",
      "setRemoverNivelAcesso",
      "setNullState",
    ]),

    abrir(documento) {
      this.$refs.TipoDeAcessoModalForm.abrir();
      if (documento) {
        this.documentoId = documento.id;
        this.documentoNome = documento.descricao;
        this.editandoDocumento = true;
        this.listarPermissoesDocumento(this.documentoId);
      }
    },
    fechar() {
      this.$refs.TipoDeAcessoModalForm.fechar();
    },

    listarPermissoesDocumento(idDocumento) {
      this.loader = true;
      this.setNullState();
      api
        .get(`v1/repositorios/documento/${idDocumento}/permissoes`)
        .then((res) => {
          if (res.data.length > 0) {
            for (const permissao of res.data) {
              if (permissao.idUsuario) {
                this.setAcessoUsuarios({
                  id: permissao.idUsuario,
                  description: permissao.nomeUsuario,
                });
              } else {
                this.setAcessoNivelAcesso({
                  id: permissao.idNivelAcesso,
                  description: permissao.dsNivelAcesso,
                });
              }
            }
          }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    salvar(id) {
      if (id) {
        const permissao = {
          usuarios: this.rowsUsuarios.map((v) => v.id),
          niveis: this.rowsNiveisAcesso.map((v) => v.id),
        };
        return api
          .post(`v1/repositorios/documento/${id}/permissoes`, permissao)
          .then(() => {
            if (this.documentoId) {
              showSuccess(this.$t("message.successUpdatedRecord"));
            }
            this.setNullState();
          })
          .catch((err) => showError(err))
          .finally((_) => this.fechar());
      }
      this.fechar();
    },

    validaRegistro(arrayTabela, id) {
      return arrayTabela.find((v) => v.id == id);
    },

    async onAdd(modelo) {
      if (!this.usuario && !this.nivelAcesso) return;

      if (modelo == "usuario") {
        const validador = this.validaRegistro(
          this.rowsUsuarios,
          this.usuarioId
        );
        if (validador) {
          this.usuarioId = null;
          showError(this.$t("message.registerAlreadyExists"));
        } else {
          this.setAcessoUsuarios(this.usuario);
        }
      } else {
        const validador = this.validaRegistro(
          this.rowsNiveisAcesso,
          this.nivelAcessoId
        );
        this.nivelAcessoId = null;
        if (validador) {
          showError(this.$t("message.registerAlreadyExists"));
        } else {
          this.setAcessoNivelAcesso(this.nivelAcesso);
        }
      }
    },

    deletar(idx, modelo) {
      if (modelo == "usuario") {
        this.setRemoverUsuario(idx);
      } else if (modelo == "nivelAcesso") {
        this.setRemoverNivelAcesso(idx);
      }
    },

    selectedUsuario(item) {
      this.usuarioId = item.id;
      this.usuario = item;
    },

    selectedNivelAcesso(item) {
      this.nivelAcessoId = item.id;
      this.nivelAcesso = item;
    },
  },
};
</script>

<style lang="sass" scoped>
.my-sticky-header-table
  height: 290px
  width: 100%
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
</style>
