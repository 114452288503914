<template>
  <ModalInfo ref="modalInfoReduzido" :title="$t('message.productInfo')">
    <template v-slot:search>
      <Search
        :label="$t('message.typeProductId')"
        @pesquisar="aoPesquisar($event)"
      />
    </template>

    <template v-slot:body>
      <q-card v-if="exibirDados">
        <div class="row">
          <div class="col-3 q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.industrialCode") }}: </span>
          </div>
          <div class="col-1 q-ml-md q-mt-md">
            <span>{{ dadosReduzido.codigo }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.reference2") }}: </span>
          </div>

          <div class="col q-ml-md q-mt-md">
            <span>{{ dadosReduzido.dsArtigo }}</span>
          </div>

          <div class="col q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.varRef") }}: </span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span>{{ dadosReduzido.varReferencia }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.familyStock") }}: </span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span
              >{{ dadosReduzido.familiaEstoque }} -
              {{ dadosReduzido.dsFamiliaEstoque }}
            </span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.dimension") }}: </span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span>{{ dadosReduzido.cdDimensao }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-1 q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.color") }}: </span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span
              >{{ dadosReduzido.corFundo }} - {{ dadosReduzido.dsCor }}</span
            >
          </div>
        </div>

        <div class="row">
          <div class="col q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.print6") }}: </span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span>{{ dadosReduzido.desenho6 }}</span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span class="text-bold">{{ $t("message.varDes") }}: </span>
          </div>
          <div class="col q-ml-md q-mt-md">
            <span>{{ dadosReduzido.varDesenho }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col q-ml-md q-my-md">
            <span class="text-bold">{{ $t("message.physicalFinish") }}: </span>
          </div>
          <div class="col q-ml-md q-my-md">
            <span
              >{{ dadosReduzido.cdAcabFisi }} -
              {{ dadosReduzido.dsAcabFisi }}</span
            >
          </div>

          <div class="col q-ml-md q-my-md">
            <span class="text-bold">{{ $t("message.chemicalFinish") }}: </span>
          </div>
          <div class="col q-ml-md q-my-md">
            <span
              >{{ dadosReduzido.cdAcabQuimi }} -
              {{ dadosReduzido.dsAcabQuimi }}</span
            >
          </div>
        </div>
      </q-card>
    </template>

    <template v-slot:button>
      <q-btn
        label="Preencher Formulário"
        @click.prevent="preencherFormulário()"
        class="bg-primary text-white"
      />
    </template>

    <template v-slot:button2>
      <q-btn
        label="Fechar"
        @click="aoFechar()"
        class="bg-red text-white q-ml-md"
      />
    </template>
  </ModalInfo>
</template>

<script>
import api from "Api";
import { showError } from "@/notifications/notify";
import { mapActions } from "vuex";

import ModalInfo from "Components/Widgets/ModalInfo";
import Search from "Components/Widgets/Search";

export default {
  name: "InfoReduzidoListaDePrecosItemDetalhe",
  components: {
    ModalInfo,
    Search,
  },
  data() {
    return {
      loader: true,
      exibirDados: false,
      idReduzido: null,
      dadosReduzido: {},
    };
  },

  methods: {
    ...mapActions("produtos", ["setReduzido"]),

    abrirInfoReduzido() {
      this.$refs.modalInfoReduzido.abrir();
    },

    aoPesquisar(valor) {
      this.idReduzido = valor;
      this.exibirDados = false;
      this.loader = true;

      api
        .get(`/v1/produtos/specs/reduzido/${this.idReduzido}`)
        .then((res) => {
          this.dadosReduzido = res.data;
          this.mostrarResultados();
        })
        .catch((err) => showError(err))
        .finally(
          () => this.setReduzido(this.dadosReduzido),
          (this.loader = false)
        );
    },

    mostrarResultados() {
      setTimeout(() => {
        this.exibirDados = true;
      }, 500);
    },

    aoFechar() {
      this.$refs.modalInfoReduzido.fechar();
      this.exibirDados = false;
    },

    preencherFormulário() {
      this.aoFechar();
      this.$emit("preencherForm");
    },
  },
};
</script>
