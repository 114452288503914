<template>
  <div>
    <page-title-bar></page-title-bar>
    <app-section-loader :status="loader"></app-section-loader>

    <div class="q-pa-md">
      <HeaderTabelaListaDePrecosItem />
    </div>

    <div class="q-pa-md" v-if="!loader">
      <div>
        <q-form ref="form" v-model="valid" autofocus @submit="aoSalvar">
          <div class="row">
            <div class="col-3 offset-md-3" cols="12" sm="12" md="4" lg="4">
              <FamiliaEstoqueSelecao
                class="q-mt-sm q-mb-md"
                :required="true"
                :familiaEstoqueId="itemData.familiaEstoque"
                :prependIcon="icon"
                @selected="selectedFamiliaEstoque($event)"
              />
            </div>
            <div class="col-3 offset-md-1" cols="12" sm="12" md="4" lg="4">
              <q-input
                rounded
                outlined
                dense
                v-model="itemData.referencia"
                :label="$t('message.reference2')"
                class="q-mt-sm"
              >
                <template v-slot:prepend>
                  <q-icon :name="icon" />
                </template>
              </q-input>
            </div>
          </div>

          <div class="row">
            <div class="col-3 offset-md-3" cols="12" sm="12" md="4" lg="4">
              <q-select
                :options="opcoesEstampa"
                rounded
                outlined
                dense
                option-label="label"
                option-value="value"
                v-model="itemData.temEstampa"
                required
                :label="$t('message.hasPrint')"
                :prepend-icon="icon"
                map-options
                emit-value
              >
                <template v-slot:prepend>
                  <q-icon :name="icon" />
                </template>
              </q-select>
            </div>

            <div class="col-3 offset-md-1" cols="12" sm="12" md="4" lg="4">
              <AcabamentoFisicoSelecao
                :acabamentoFisicoId="itemData.cdAcabFisi"
                :prependIcon="icon"
                @selected="selectedAcabamentoFisico($event)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-3 offset-md-3" cols="12" sm="12" md="4" lg="4">
              <q-input
                rounded
                outlined
                dense
                class="q-mt-md"
                v-model="itemData.varianteDe"
                :label="$t('message.variantOf')"
              >
                <template v-slot:prepend>
                  <q-icon :name="icon" />
                </template>
              </q-input>
            </div>

            <div class="col-3 offset-md-1" cols="12" sm="12" md="4" lg="4">
              <q-input
                rounded
                outlined
                dense
                v-model="itemData.varianteAte"
                :label="$t('message.variantUntil')"
                class="q-mt-md"
              >
                <template v-slot:prepend>
                  <q-icon :name="icon" />
                </template>
              </q-input>
            </div>
          </div>

          <div class="row">
            <div class="col-3 offset-md-3" cols="12" sm="12" md="4" lg="4">
              <q-input
                rounded
                outlined
                dense
                class="q-mt-md"
                v-model="itemData.quantidadeDe"
                :label="$t('message.quantityOf')"
                required
                hint="Modelo: 0.000,00"
                hide-hint
              >
                <template v-slot:prepend>
                  <q-icon :name="icon" />
                </template>
              </q-input>
            </div>
            <div class="col-3 offset-md-1" cols="12" sm="12" md="4" lg="4">
              <q-input
                rounded
                outlined
                dense
                class="q-mt-md"
                v-model="itemData.quantidadeAte"
                :label="$t('message.quantityUntil')"
                required
                hint="Modelo: 0.000,00"
                hide-hint
              >
                <template v-slot:prepend>
                  <q-icon :name="icon" />
                </template>
              </q-input>
            </div>
          </div>

          <div class="row">
            <div class="col-3 offset-md-3" cols="12" sm="12" md="4" lg="4">
              <q-input
                rounded
                outlined
                dense
                v-model="itemData.preco"
                :label="$t('message.price')"
                class="q-mt-md"
                required
                hint="Modelo: 0.000,00"
                hide-hint
              >
                <template v-slot:prepend>
                  <q-icon :name="icon" />
                </template>
              </q-input>
            </div>
          </div>

          <div class="row">
            <div class="col offset-md-3">
              <q-btn
                color="success"
                class="q-mr-sm q-mt-xl"
                type="submit"
                size="md"
                rounded
              >
                {{ $t("message.save") }}
              </q-btn>

              <q-btn
                dark
                rounded
                size="md"
                class="q-mr-sm q-mt-xl"
                color="primary"
                icon="mdi-magnify"
                :label="$t('message.reduceInfo')"
                @click="infoReduzido()"
              >
              </q-btn>

              <q-btn
                color="danger"
                class="q-mt-xl"
                size="md"
                type="sair"
                @click.prevent="$router.go(-1)"
                rounded
              >
                {{ $t("message.close") }}
              </q-btn>
            </div>
          </div>
        </q-form>
      </div>

      <InfoReduzidoListaDePrecosItemDetalhe
        ref="infoReduzido"
        @preencherForm="preencherInput()"
      />
    </div>
  </div>
</template>

<script>
import FamiliaEstoqueSelecao from "Components/Areas/Produtos/FamiliaEstoque/FamiliaEstoqueSelecao";
import AcabamentoFisicoSelecao from "Components/Areas/Produtos/AcabamentoFisico/AcabamentoFisicoSelecao";
import HeaderTabelaListaDePrecosItem from "Components/Areas/Compras/Salete/ListaDePrecosItem/HeaderTabelaListaDePrecosItem";
import InfoReduzidoListaDePrecosItemDetalhe from "Components/Areas/Compras/Salete/ListaDePrecosItemDetalhe/InfoReduzidoListaDePrecosItemDetalhe";
import { mapGetters } from "vuex";
import { showError, showSuccess } from "@/notifications/notify";
import api from "Api";
import { clearObject } from "Helpers";

export default {
  name: "ListaDePrecosItemDetalhe",
  components: {
    FamiliaEstoqueSelecao,
    AcabamentoFisicoSelecao,
    InfoReduzidoListaDePrecosItemDetalhe,
    HeaderTabelaListaDePrecosItem,
  },

  data() {
    return {
      loader: true,
      valid: true,
      id: this.$route.params.id,

      opcoesEstampa: [
        {
          value: "N",
          label: "Não",
        },
        {
          value: "S",
          label: "Sim",
        },
      ],

      itemData: {
        nrTabela: null,
        familiaEstoque: null,
        cdAcabFisi: null,
        referencia: null,
        temEstampa: null,
        varianteDe: null,
        varianteAte: null,
        quantidadeDe: null,
        quantidadeAte: null,
        preco: null,
      },
    };
  },
  computed: {
    icon() {
      return this.id ? "edit" : "add";
    },
  },
  watch: {
    $route(to) {
      this.id = to.params.id === 0 ? null : to.params.id;
      this.obterPrecoItem();
    },
  },
  methods: {
    ...mapGetters("compras", ["getDadosListaPrecoSalete"]),
    ...mapGetters("produtos", ["getReduzido"]),

    obterPrecoItem() {
      if (!this.id || this.id == 0) {
        this.id = "";
        this.dadosLista = this.getDadosListaPrecoSalete();
        this.itemData.nrTabela = this.dadosLista.nrTabela;
        this.loader = false;
        return;
      }
      api
        .get(`/v1/compras/salete/obterprecoitem/${this.id}`)
        .then((res) => {
          this.itemData = res.data;
          this.itemData.preco = this.$filters.formatDecimal(
            this.itemData.preco
          );

          this.itemData.quantidadeDe = this.$filters.formatDecimal(
            this.itemData.quantidadeDe
          );

          this.itemData.quantidadeAte = this.$filters.formatDecimal(
            this.itemData.quantidadeAte
          );

          // if (!this.itemData.quantidadeDe) {
          //   this.itemData.quantidadeDe = 0;
          // }
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    aoSalvar() {
      let message;
      let method;
      let route;

      if (this.id) {
        message = "message.successUpdatedRecord";
        method = "put";
        route = `/v1/compras/salete/alterarprecoitem/${this.id}`;
      } else {
        message = "message.successAddedRecord";
        method = "post";
        route = "/v1/compras/salete/inserirprecoitem";
      }

      this.loader = true;
      const item = this.itemData;

      api[method](route, item)
        .then(() => {
          if (!this.id) {
            clearObject(this.itemData, this.$refs.form);
          }
          showSuccess(this.$t(message));
        })
        .catch((err) => showError(err))
        .finally(() => (this.loader = false));
    },

    infoReduzido() {
      this.$refs.infoReduzido.abrirInfoReduzido();
    },
    preencherInput() {
      this.reduzidoData = this.getReduzido();
      if (this.reduzidoData.desenho6 !== "000000") {
        this.itemData.temEstampa == "S";
      } else {
        this.itemData.temEstampa == "N";
      }
      if (this.reduzidoData.cdAcabFisi !== "000") {
        this.itemData.cdAcabFisi = this.reduzidoData.cdAcabFisi;
      } else {
        this.itemData.cdAcabFisi = null;
      }
      this.itemData.familiaEstoque = this.reduzidoData.familiaEstoque;
    },

    selectedFamiliaEstoque(item) {
      this.itemData.familiaEstoque = item.id;
    },

    selectedAcabamentoFisico(item) {
      this.itemData.cdAcabFisi = item.id;
    },
  },
  mounted() {
    this.obterPrecoItem();
  },
};
</script>
