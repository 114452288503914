<template>
  <q-card-section>
    <div class="row q-mt-xl">
      <div class="col">
        <p>
          Olá <span class="text-bold">{{ nomeCandidato }} </span> você está
          participando do processo seletivo no Grupo Rosset, para isso,
          elaboramos um questionário teórico que tem o intuito em avaliar o seu
          conhecimento com a vaga na qual você irá atuar, para isso, siga os
          passos abaixo:
        </p>
        <ul>
          <li>Leia com atenção as perguntas e respostas;</li>
          <li>
            Antes de passar para a próxima pergunta, selecione a resposta
            correta
          </li>
          <li>
            Não feche o navegador, pois você perderá tempo logando novamente
          </li>
          <li>
            Ao término basta fechar o seu navegador e aguardar o contato do RH
          </li>
        </ul>

        <p>E aí, vamos começar ? Clique no botão abaixo e boa sorte!!!</p>
      </div>
    </div>

    <q-card-actions class="q-mt-xl">
      <q-btn
        color="positive"
        icon-right="mdi-play"
        :label="$t('message.start')"
        @click="onStart()"
      />
    </q-card-actions>
  </q-card-section>
</template>

<script>
export default {
  props: {
    nomeCandidato: {
      type: String,
      default: null,
    },
  },
  emits: ["iniciarTeste"],
  name: "QuestionarioWelcomeQAComponent",
  data() {
    return {};
  },

  methods: {
    onStart() {
      this.$emit("iniciarTeste");
    },
  },
};
</script>

<style scoped>
.flex-end {
  justify-items: flex-end;
}
</style>
